import { isDeepEquals, Style, Unformatted } from '@sitecore-feaas/sdk'
import { Button, Grid, GridItem, Switch, FormLabel, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { mergeDeep } from '@sitecore-feaas/sdk'
import NumberField from '../fields/NumberField.js'
import SwitchGroupField from '../fields/SwitchGroupField.js'
import FieldsetField from '../../FieldsetField.js'
import { StyleFormProps } from './index.js'

const borders: { label: string; prop: keyof Style.Decoration.BorderRadius }[] = [
  { label: 'Top left', prop: 'borderTopLeftRadius' },
  { label: 'Top right', prop: 'borderTopRightRadius' },
  { label: 'Bottom left', prop: 'borderBottomLeftRadius' },
  { label: 'Bottom right', prop: 'borderBottomRightRadius' }
]

const DecorationBorderRadiusFieldset = ({
  rule: style,
  currentRule: currentStyle,
  isActive,
  onChange,
  label
}: StyleFormProps<'decoration'>) => {
  const onUpdate = (changes: Unformatted<Style.Decoration.BorderRadius>) =>
    onChange(mergeDeep(currentStyle, { props: mergeDeep(currentStyle.props, changes) }))

  const allValuesOf = (value: { value: number }) => {
    return borders.reduce((changes, { prop }) => {
      return Object.assign(changes, { [prop]: value })
    }, {})
  }

  const radiuses = borders.map(({ prop }) => currentStyle.props[prop])
  const oldRadiuses = borders.map(({ prop }) => style.props[prop])

  const getIndex = () => {
    if (!Style.Decoration.BorderRadius.isValid(currentStyle.props)) {
      return 0
    }

    if (radiuses.every((radius) => radius.value === 9999)) {
      return 1
    }

    if (radiuses.every((radius) => radius.value === radiuses[0].value)) {
      return 2
    }

    return 3
  }

  const [index, setIndex] = useState(getIndex())

  useEffect(() => setIndex(getIndex()), [style, isActive])

  useEffect(() => {
    if (
      index == 0 ||
      (index == 2 && radiuses.every((radius) => radius.value === 9999)) ||
      (index == 3 && radiuses.every((radius) => radius.value === 9999))
    ) {
      onUpdate(allValuesOf({ value: 0 }))
    }

    if (index == 1) {
      onUpdate(allValuesOf({ value: 9999 }))
    }
  }, [index])

  return (
    <>
      {!isActive && (
        <FormLabel display='flex' alignItems='center' mb={0}>
          <Switch isChecked={index !== 0} onChange={(e) => setIndex(e.target.checked ? 1 : 0)} />
          <Text ml='2'>{label}</Text>
        </FormLabel>
      )}
      {(isActive || index !== 0) && (
        <>
          <SwitchGroupField value={index} onChange={setIndex} mb={6} display={'flex'} maxWidth={'fit-content'}>
            {isActive && <Button>None</Button>}
            <Button>Rounded</Button>
            <Button>Identical</Button>
            <Button>Custom</Button>
          </SwitchGroupField>
          {index == 2 && (
            <Grid templateColumns='repeat(2, 1fr)' rowGap={isActive ? 5 : 4} columnGap={3}>
              <GridItem>
                <FieldsetField isChanged={!isDeepEquals(radiuses[0], oldRadiuses[0])} label='Corner size'>
                  <NumberField
                    value={radiuses[0].value}
                    onChange={(value) => onUpdate(allValuesOf({ value }))}
                    max={9999}
                    withAddon='px'
                    size={!isActive ? 'sm' : null}
                  />
                </FieldsetField>
              </GridItem>
            </Grid>
          )}
          {index == 3 && (
            <Grid templateColumns='repeat(2, 1fr)' rowGap={isActive ? 5 : 4} columnGap={3} mt={6}>
              {borders.map((border: { label: string; prop: keyof Style.Decoration.BorderRadius }) => {
                const newBorder = currentStyle.props[border.prop]

                return (
                  <GridItem key={border.prop}>
                    <FieldsetField isChanged={!isDeepEquals(newBorder, style.props[border.prop])} label={border.label}>
                      <NumberField
                        value={newBorder.value}
                        onChange={(value) => onUpdate({ [border.prop]: { value } })}
                        max={9999}
                        withAddon='px'
                        size={!isActive ? 'sm' : null}
                      />
                    </FieldsetField>
                  </GridItem>
                )
              })}
            </Grid>
          )}
        </>
      )}
    </>
  )
}

export default DecorationBorderRadiusFieldset
