import { Style } from '@sitecore-feaas/sdk'
import { mdiAlertOutline, mdiInformationOutline } from '@mdi/js'
import { Badge, Icon, Tooltip, HStack, Text } from '@chakra-ui/react'
import type { DialogStyleGeneric } from './Dialog.js'

const BADGE_STYLES = {
  info: { colorScheme: 'teal', icon: mdiInformationOutline },
  warning: { colorScheme: 'orange', icon: mdiAlertOutline },
  default: { colorScheme: 'blackAlpha', icon: '' }
}

const DialogStyleElementBadge = ({ rules, customRules, context, themeClassList }: DialogStyleGeneric) => {
  const element = Style.Set.getContextElement(rules, context)
  const definition = Style.Set.getContextDefinition(rules, context)
  const combo = Style.Set.getContextCombo(rules, context, themeClassList)
  const elementStyle = element || Style.Set.findById(rules, combo?.refId)
  const effectiveRules = Style.Set.join(rules, customRules)
  const assignedRules = Style.Set.getContextAssigned(effectiveRules, context)
  const assignedAspects = assignedRules.filter((style) =>
    Style.TypeDefinitions[definition.type].aspects.some((aspect) => style.type == aspect)
  ) as Style.Rule.ElementAspect<Style.Type.Element>[]

  const customizationType = element
    ? Style.Set.getElementCustomizationType(effectiveRules, element, assignedAspects)
    : Style.Set.getDefinitionCustomizationType(effectiveRules, definition, assignedAspects)
  const badgeText = elementStyle?.details.title || 'No styles'

  const label = element
    ? customizationType !== 'default'
      ? 'This element does not belong to the current theme'
      : 'This element does not use a theme style'
    : combo && !combo.isDefault
    ? customizationType === 'oneOff'
      ? 'This element has customised styles and will not follow theme updates'
      : 'This element uses a non-default theme style'
    : customizationType === 'oneOff'
    ? 'This element has customised styles and will not follow theme updates'
    : customizationType === 'allowed'
    ? 'This element uses a non-default theme style'
    : ''

  const { colorScheme, icon } =
    element || customizationType === 'oneOff'
      ? BADGE_STYLES.warning
      : (combo && !combo.isDefault) || customizationType === 'allowed'
      ? BADGE_STYLES.info
      : BADGE_STYLES.default
  return (
    <Badge colorScheme={colorScheme}>
      <Tooltip placement='top-end' hasArrow={false} label={label}>
        <HStack>
          {icon && (
            <Icon boxSize='icon-sm'>
              <path d={icon} />
            </Icon>
          )}
          <Text>{badgeText}</Text>
        </HStack>
      </Tooltip>
    </Badge>
  )
}

export default DialogStyleElementBadge
