import {
  Box,
  Text,
  Card,
  Divider,
  Icon,
  VStack,
  Button,
  CardBody,
  Switch,
  FormLabel,
  FormControl,
  Flex,
  HStack,
  useToast,
  Tooltip
} from '@chakra-ui/react'
import { PickerWrapper } from './PickerWrapper.js'
import {
  mdiAlert,
  mdiAlertCircle,
  mdiAlertCircleOutline,
  mdiAlertOutline,
  mdiBookOpenPageVariantOutline,
  mdiCheckCircle,
  mdiChevronRight,
  mdiCloudUploadOutline,
  mdiContentCopy,
  mdiInformationOutline,
  mdiOpenInNew
} from '@mdi/js'
import React, { ChangeEvent, useContext } from 'react'
import { ComponentModel, isDeepEquals, VersionModel } from '@sitecore-feaas/sdk'
import { ConfirmationContext } from '../../exports.js'
import { EditorContext } from '../../contexts/EditorContext.js'
import VersionEmbed from '../editor/version/VersionEmbed.js'
import useStylesUpToDateConfirmation from '../../hooks/useStylesUpToDateConfirmationAction.js'
import { PickerProps } from './Picker.js'

const PickerOverview = ({
  component,
  onModeChange
}: {
  component: ComponentModel
  onModeChange: (mode?: PickerProps['mode'], dialog?: string) => void
}) => {
  const [editorContext, setEditorContext] = useContext(EditorContext)
  const { setConfirm } = useContext(ConfirmationContext)
  const toast = useToast()

  const bundles = component.getBundles()
  const versions = component.getNotDeletedVersions()

  const differentDatasources = versions
    .map((v) => v.datasourceIds)
    .find((ids, index, self) => !isDeepEquals(ids, self[0]))
  const usedDatasources = component.getDatasources()
  const datasourceUpdates = usedDatasources.find((datasource) => datasource.conflictDefinition)

  const handleGetEmbeddingCode = () => {
    setConfirm({
      type: 'modal',
      title: 'Embedding code',
      body: <VersionEmbed componentId={component.id} />,
      button: (
        <>
          <Icon boxSize='icon-xl' mr='2'>
            <path d={mdiContentCopy} />
          </Icon>
          Copy code
        </>
      ),
      action: (e) => {
        toast({
          duration: 4000,
          status: 'success',
          title: 'Code has been copied',
          description: 'Paste it into your app now'
        })
        navigator.clipboard.writeText(document.querySelector('#generated-code').textContent)
        e.preventDefault()
      }
    })
  }

  return (
    <PickerWrapper title={'Overview'}>
      <VStack alignItems={'stretch'} px={3} spacing={6} py={6}>
        <PickerOverviewStatus component={component} versions={versions} editor={editorContext.editor} />
        {differentDatasources && (
          <PickerOverviewCard
            colorScheme={'orange'}
            icon={mdiAlertOutline}
            title={'Versions use different datasources'}
          />
        )}
        {datasourceUpdates && (
          <PickerOverviewCard colorScheme={'orange'} icon={mdiAlertOutline} title={'Data source has new update'} />
        )}
        <Card variant={'outline'} borderRadius={'lg'}>
          <CardBody>
            <VStack align={'left'}>
              <Text color={'blackAlpha.500'} fontWeight={600}>
                Inspect mode
              </Text>
              <FormControl display='flex' flexDirection={'column'} width='auto' mt={2}>
                <VStack align={'left'} spacing={4}>
                  <HStack>
                    <Switch
                      id={'display-mapped-data'}
                      isChecked={editorContext.isDataDisplayed}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setEditorContext({ isDataDisplayed: e.target.checked })
                      }
                      size='md'
                    />
                    <FormLabel htmlFor='display-mapped-date' mb='0' ml={4}>
                      <Text>Display mapped data</Text>
                    </FormLabel>
                  </HStack>
                  <HStack>
                    <Switch
                      id={'show-all-data-collections'}
                      isChecked={editorContext.isDataRepeated}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setEditorContext({ isDataRepeated: e.target.checked })
                      }
                      size='md'
                    />
                    <FormLabel htmlFor='show-all-data-collections' mb='0' ml={4}>
                      <Text>Show data collections</Text>
                    </FormLabel>
                  </HStack>
                  <HStack>
                    <Switch
                      id={'hide-hidden-elements'}
                      isChecked={!editorContext.isHiddenDisplayed}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setEditorContext({ isHiddenDisplayed: !e.target.checked })
                      }
                      size='md'
                    />
                    <FormLabel htmlFor='hide-hidden-elements' mb='0' ml={4}>
                      <Text>Hide hidden elements</Text>
                    </FormLabel>
                  </HStack>
                </VStack>
              </FormControl>
            </VStack>
          </CardBody>
        </Card>
        <Card variant={'outline'} borderRadius={'lg'}>
          <CardBody>
            <VStack align={'left'} spacing={4}>
              <HStack>
                <Text color={'blackAlpha.500'} fontWeight={600}>
                  Embeddable Versions
                </Text>
                <Tooltip
                  label={
                    'List of versions that are allowed to be be embedded into Pages that will not change based on screen size'
                  }
                >
                  <Icon>
                    <path d={mdiInformationOutline} />
                  </Icon>
                </Tooltip>
              </HStack>
              <VStack alignItems={'stretch'}>
                <HStack
                  justifyContent={'space-between'}
                  onClick={() => {
                    onModeChange('bundles', 'specific')
                  }}
                  cursor={'pointer'}
                >
                  <Text>Available versions in Pages</Text>
                  <HStack>
                    <Text>{versions.filter((v) => v.isEmbeddable).length}</Text>
                    <Icon boxSize={'icon-lg'} m={'0px !important'}>
                      <path d={mdiChevronRight} />
                    </Icon>
                  </HStack>
                </HStack>
              </VStack>

              <Divider />

              <HStack>
                <Text color={'blackAlpha.500'} fontWeight={600}>
                  Responsive bundles
                </Text>
                <Tooltip
                  label={
                    'Bundle is a list of versions of one component that are grouped together to be displayed based on screen size'
                  }
                >
                  <Icon>
                    <path d={mdiInformationOutline} />
                  </Icon>
                </Tooltip>
              </HStack>

              <VStack alignItems={'stretch'}>
                {bundles.map((bundle) => {
                  const usedVersions = bundle.getUsedVersions()
                  const hasWarnings = bundle.getWarnings().find((w) => w.warning)

                  return (
                    <HStack
                      justifyContent={'space-between'}
                      onClick={() => {
                        onModeChange('bundles', bundle.id)
                      }}
                      alignItems={'baseline'}
                      cursor={'pointer'}
                      key={bundle.id}
                    >
                      <Text>{bundle.name}</Text>
                      <HStack>
                        <Text whiteSpace={'nowrap'} fontSize={'13px'} color={'blackAlpha.600'}>
                          {usedVersions.length} version{usedVersions.length !== 1 ? 's' : ''}
                        </Text>
                        {hasWarnings && (
                          <Icon boxSize={'icon-sm'}>
                            <path color={'var(--chakra-colors-orange-500)'} d={mdiAlert} />
                          </Icon>
                        )}
                        <Icon boxSize={'icon-lg'} m={'0px !important'}>
                          <path d={mdiChevronRight} />
                        </Icon>
                      </HStack>
                    </HStack>
                  )
                })}
              </VStack>

              <VStack align={'flex-start'} divider={<Divider />} spacing={4}>
                <Tooltip label={'Responsive bundles require some versions of a component to be staged and published'}>
                  <Button
                    variant={'primary'}
                    size={'xs'}
                    onClick={() => {
                      const bundle = bundles.add({ name: 'New bundle' })
                      onModeChange('bundles', bundle.id)
                    }}
                  >
                    Create responsive bundle
                  </Button>
                </Tooltip>
                <Button variant={'secondary'} size={'xs'} onClick={handleGetEmbeddingCode}>
                  Get embedding code
                </Button>
              </VStack>
            </VStack>
          </CardBody>
        </Card>

        <PickerOverviewCard
          colorScheme={'blackAlpha'}
          icon={mdiBookOpenPageVariantOutline}
          title={'Components documentation'}
          action={
            <Button
              as={'a'}
              variant={'secondary'}
              size={'xs'}
              target={'_blank'}
              href={'https://doc.sitecore.com/xmc/en/users/xm-cloud/build-components.html'}
            >
              Documentation{' '}
              <Icon boxSize='icon-md' ml={2}>
                <path d={mdiOpenInNew} />
              </Icon>
            </Button>
          }
        />
      </VStack>
    </PickerWrapper>
  )
}

const PickerOverviewCard = ({
  colorScheme,
  icon,
  iconColor,
  title,
  action
}: {
  colorScheme: string
  icon: string
  iconColor?: string
  title: string
  action?: React.ReactNode
}) => {
  return (
    <Card
      variant={'outline'}
      background={colorScheme !== 'blackAlpha' ? `${colorScheme}.100` : 'none'}
      borderRadius={'lg'}
      outline={colorScheme !== 'blackAlpha' ? `1px solid var(--chakra-colors-${colorScheme}-600)` : 0}
    >
      <CardBody p={3}>
        <VStack align='stretch' spacing='3'>
          <HStack textAlign='center' align={'baseline'}>
            <Box>
              <Icon boxSize='icon-lg'>
                <path color={`${iconColor ? iconColor : colorScheme}`} d={icon} />
              </Icon>
            </Box>
            <Text textAlign={'start'} fontSize={'14x'} fontWeight={400}>
              {title}
            </Text>
          </HStack>
          {action && <Box>{action}</Box>}
        </VStack>
      </CardBody>
    </Card>
  )
}

const PickerOverviewStatus = ({
  component,
  versions,
  editor
}: {
  component: ComponentModel
  versions: VersionModel[]
  editor: EditorContext['editor']
}) => {
  const neverStaged = !component.getVersions().filter((version) => version.getCurrent(['staged'])).length
  const neverPublished = !component.getVersions().filter((version) => version.getCurrent(['published'])).length
  const unstagedVersions = component.getNotDeletedVersions().filter((version) => version.status === 'saved')
  const unpublishedVersions = component.getNotDeletedVersions().filter((version) => version.status === 'staged')

  const status = neverStaged
    ? 'never staged'
    : unstagedVersions.length
    ? 'not staged'
    : neverPublished
    ? 'never published'
    : unpublishedVersions.length
    ? 'not published'
    : 'up to date'

  const handleStageAll = useStylesUpToDateConfirmation(
    'stage',
    null,
    editor,
    () => {
      Promise.all(
        unstagedVersions.map(async (version) => {
          await version.saveData().stage()
        })
      )
    },
    unstagedVersions
  )
  const handlePublishAll = useStylesUpToDateConfirmation(
    'publish',
    null,
    editor,
    () => {
      Promise.all(
        unpublishedVersions.map(async (version) => {
          await version.publish()
        })
      )
    },
    unpublishedVersions
  )

  const cardProps = {
    'never staged': {
      colorScheme: 'blackAlpha',
      icon: mdiAlertCircleOutline,
      title: 'No versions have been staged'
    },
    'not staged': {
      colorScheme: 'orange',
      icon: mdiAlertCircle,
      title: 'Some previously staged versions are not up-to-date',
      action: (
        <Button variant={'outline'} size={'xs'} onClick={() => handleStageAll()}>
          <Flex alignItems={'end'}>
            <Icon boxSize='icon-md' mr={2}>
              <path d={mdiCloudUploadOutline} />
            </Icon>
            <Text>Stage all</Text>
          </Flex>
        </Button>
      )
    },
    'never published': {
      colorScheme: 'blackAlpha',
      icon: mdiAlertCircleOutline,
      title: 'No versions have been published'
    },
    'not published': {
      colorScheme: 'orange',
      icon: mdiAlertCircle,
      title: 'Some previously published versions are not up-to-date',
      action: (
        <Button variant={'outline'} size={'xs'} onClick={() => handlePublishAll()}>
          <Flex alignItems={'end'}>
            <Icon boxSize='icon-md' mr={2}>
              <path d={mdiCloudUploadOutline} />
            </Icon>
            <Text>Publish all</Text>
          </Flex>
        </Button>
      )
    },
    'up to date': {
      colorScheme: 'blackAlpha',
      icon: mdiCheckCircle,
      iconColor: 'green',
      title: 'All versions are up-to-date'
    }
  }
  return <PickerOverviewCard {...cardProps[status]} />
}

export default PickerOverview
