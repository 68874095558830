import { Box, Button, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { DatasourceModel } from '@sitecore-feaas/sdk'

const DatasourceFetchButton = ({
  currentDatasource,
  onChange,
  isBlock
}: {
  currentDatasource: DatasourceModel
  onChange: (datasource: DatasourceModel) => void
  isBlock: boolean
}) => {
  const toast = useToast()
  const [isFetching, setIsFetching] = useState(false)

  const onStart = async () => {
    setIsFetching(true)

    try {
      onChange(await currentDatasource.fetchUpdates())
    } catch (e) {
      console.log(e)
      toast({
        isClosable: true,
        duration: 4000,
        status: 'error',
        title: 'Data is not valid JSON',
        description: 'Please specify a correct JSON file'
      })
    } finally {
      setIsFetching(false)
    }
  }

  return (
    <Box mt={'26px'}>
      <Button
        display='flex'
        variant='primary'
        aria-label='Fetch data from remote data source'
        className='fetch'
        ml={isBlock && 'auto'}
        isLoading={isFetching}
        onClick={onStart}
      >
        Fetch
      </Button>
    </Box>
  )
}

export default DatasourceFetchButton
