import React from 'react'
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/react'

const OpacityField = ({
  value: valueProps,
  disabled,
  onChange,
  size
}: {
  value: any
  disabled?: boolean
  onChange: (value: any) => void
  size?: 'sm' | 'md' | 'lg'
}) => {
  const value = valueProps ?? 1

  const format = (val: any) => (val !== '' ? Math.round(val * 100) + `%` : val)
  const parse = (val: any) => (val !== '' ? val / 100 : val)

  return (
    <NumberInput
      flex={2}
      min={0}
      onBlur={() => !value && onChange(1)}
      step={1}
      max={100}
      value={format(value)}
      onChange={(val) => onChange(parse(val))}
      inputMode='numeric'
      keepWithinRange={true}
      isDisabled={disabled}
      size={size}
    >
      <NumberInputField bg='white' />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  )
}

export default OpacityField
