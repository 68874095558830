import FieldsetField from '../FieldsetField.js'
import { DatasourceModel } from '@sitecore-feaas/sdk'
import { stringify } from '../../utils/string.js'
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, HStack, VStack, Text, Textarea } from '@chakra-ui/react'
import DatasourceFetchButton from './DatasourceFetchButton.js'
import React from 'react'

const DatasourceConflictDefinition = ({
  currentDatasource,
  onChange
}: {
  currentDatasource: DatasourceModel
  onChange: (datasource: DatasourceModel) => void
}) => {
  return (
    <FieldsetField required={true} label='JSON'>
      <Alert status='warning' mb={3}>
        <AlertIcon />
        <HStack justifyContent='space-between' width='100%'>
          <VStack>
            <Box>
              <AlertTitle>There are conflicts between the fields of remote datasource and the current one</AlertTitle>

              <AlertDescription>
                <Text>Fetch to preview changes</Text>
              </AlertDescription>
            </Box>
          </VStack>

          <DatasourceFetchButton currentDatasource={currentDatasource} isBlock={true} onChange={onChange} />
        </HStack>
      </Alert>

      <Textarea disabled rows={10} value={stringify(currentDatasource.sample)} />
    </FieldsetField>
  )
}

export default DatasourceConflictDefinition
