import { Box, Button, HStack, Icon, useToast, Select } from '@chakra-ui/react'
import { CSS, DELETE_PROPERTY, Style, Unformatted, mergeDeep } from '@sitecore-feaas/sdk'
import React, { useEffect, useMemo, useState } from 'react'
import { useSDK } from '../../../hooks/useData.js'
import { parseGoogleFont } from '../../../utils/font.js'
import FieldsetField from '../../FieldsetField.js'
import FontVariants from '../FontVariants.js'
import { PreviewFontsStylesheet } from '../previews/index.js'
import { StyleFormProps } from './index.js'
import OverrideFieldset from './OverrideFieldset.js'
import ButtonGroupSwitch from '../../ButtonGroupSwitch.js'
import { mdiDownload } from '@mdi/js'

export type GoogleFontVariant = 'regular' | 'italic'
export type GoogleFontSubset = 'latin' | 'latin-ext'

export interface GoogleFontFiles {
  regular?: string
  italic?: string
}

export interface GoogleFont {
  family: string
  variants: GoogleFontVariant[]
  subsets: GoogleFontSubset[]
  revision: string
  lastModified: string
  files: GoogleFontFiles
  category: string
  kind: string
}

export const getFontVariantLabel = (weight: number) => {
  const mapping = {
    100: 'Thin',
    200: 'ExtraLight',
    300: 'Light',
    400: 'Regular',
    500: 'Medium',
    600: 'SemiBold',
    700: 'Bold',
    800: 'ExtraBold',
    900: 'Black'
  }

  return mapping[weight as keyof typeof mapping] ?? ''
}

const FontFieldset = ({ currentRule, onChange, rule, rules, errors }: StyleFormProps<'font'>) => {
  const sdk = useSDK()
  const {
    props: { familyName, platform, variants }
  } = currentRule

  const {
    props: { familyName: oldFamilyName, platform: oldPlatform, variants: oldVariants }
  } = rule

  const [usedFonts, setUsedFonts] = useState<Style.Font.Props[]>([])

  const fonts: Style.Font.Props[] = usedFonts
  const font = fonts.find((font) => font.familyName === familyName)
  const fontVariants = font?.variants || []

  const stylesheet = useMemo(() => {
    return <PreviewFontsStylesheet styles={[Style.Rule(currentRule)]} />
  }, [platform, font, currentRule.details.override])

  const onUpdate = (changes: Unformatted<Style.Font.Props>) =>
    onChange(
      mergeDeep(currentRule, {
        props: mergeDeep(currentRule.props, changes),
        details: {
          title: changes.familyName || currentRule.props.familyName || ''
        }
      })
    )

  // Based on the platform, need fetch the available fonts
  useEffect(() => {
    if (platform === 'google') {
      fetchGoogleFonts()
    }
  }, [platform])

  useEffect(() => {
    if (platform == 'custom') {
      const families: Record<string, Style.Font.Props> = {}
      const fonts = CSS.getFonts(CSS.parse(currentRule.details.override || '')).forEach((variant) => {
        if (!families[variant.familyName]) {
          families[variant.familyName] = Style.Font.Props({
            familyName: variant.familyName,
            platform: 'custom',
            variants: []
          })
        }
        const formattedVariant = Style.Font.FontVariant(variant)
        if (!families[variant.familyName].variants.find((v) => v.name == formattedVariant.name)) {
          families[variant.familyName].variants.push(Style.Font.FontVariant(formattedVariant))
        }
      })
      setUsedFonts(Object.values(families))
      const family = Object.values(families)[0]
      const familyName = family?.familyName || ''
      if (familyName != currentRule.props.familyName) {
        onUpdate({ familyName: familyName, variants: [] })
      }
    }
  }, [platform, currentRule.details.override])

  // Fetch Google Fonts list from the Google Platform SDK
  const fetchGoogleFonts = async () => {
    const response = await fetch(
      'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyC2Q34wSNXyM_dKY8gaydCvVAvd2-SX8Ok'
    )

    const data = await response.json()

    setUsedFonts(data.items.map(parseGoogleFont))
  }

  const fetchAdobeFonts = async () => {}

  const onSelectVariant = (variant: Style.Font.Variant): void => {
    const variantExists = variants.find((v) => v.name === variant.name)

    if (variantExists) {
      onUpdate({ variants: variants.filter((v) => v.name !== variant.name) })

      return
    }

    onUpdate({
      variants: fontVariants.filter((_variant: Style.Font.Variant) =>
        [...variants, variant].map((v) => v.name).includes(_variant.name)
      )
    })
  }

  const [renders, setRender] = useState(0)
  const toast = useToast()

  return (
    <>
      {stylesheet}
      <FieldsetField label='Font type' isChanged={familyName !== oldFamilyName} mb={5}>
        <HStack spacing='6' justifyContent={'space-between'}>
          <ButtonGroupSwitch
            value={platform}
            onChange={(platform) => {
              onChange(
                mergeDeep(currentRule, {
                  props: { platform, familyName: null, variants: [] },
                  details: {
                    override: DELETE_PROPERTY
                  }
                })
              )
            }}
          >
            <Button value='google'>Google fonts</Button>
            <Button value='custom'>Custom CSS</Button>
          </ButtonGroupSwitch>
          <Button
            leftIcon={
              <Icon>
                <path d={mdiDownload} />
              </Icon>
            }
            aria-label='Fetch remote CSS file'
            variant='secondary'
            onClick={() => {
              const url = prompt('Enter the URL of the CSS file to fetch')
              if (url) {
                sdk
                  .proxy(url, {})
                  .then((response) =>
                    response.text().then((css) => {
                      const parsed = CSS.parse(css)
                      if (parsed[0][0] == 'error') {
                        console.log(parsed[0])
                        throw new Error('Could not parse CSS')
                      }
                      if (!CSS.getFonts(parsed).length) {
                        throw new Error('CSS file contains no @font-face declaration')
                      }
                      setRender((r) => r + 1)
                      onChange(mergeDeep(currentRule, { props: { platform: 'custom' }, details: { override: css } }))
                    })
                  )
                  .catch((e) => {
                    toast({
                      isClosable: true,
                      duration: 4000,
                      status: 'error',
                      title: 'Error trying to fetch css file',
                      description: e.message
                    })
                  })
              }
            }}
          >
            Fetch remote CSS file
          </Button>
        </HStack>
      </FieldsetField>
      {platform == 'custom' && (
        <Box mb={3}>
          <OverrideFieldset
            errors={errors}
            key={'r' + renders}
            rules={rules}
            maxHeight={'200px'}
            currentRule={currentRule}
            rule={rule}
            isActive={true}
            onChange={onChange}
            prompt='Paste @font-face rules below'
          />
        </Box>
      )}
      {fonts.length > 1 && (
        <FieldsetField label='Font family' isChanged={familyName !== oldFamilyName} mb={5}>
          <Box maxWidth='xs'>
            <Select
              aria-label='Select font'
              value={familyName || ''}
              onChange={(e) =>
                onUpdate({
                  familyName: e.target.value,
                  variants: []
                })
              }
            >
              <option hidden disabled value=''>
                Select font
              </option>

              {fonts.map((font, index) => (
                <option key={index}>{font.familyName}</option>
              ))}
            </Select>
          </Box>
        </FieldsetField>
      )}
      {!!fontVariants.length && (
        <FieldsetField label='Available font weights'>
          <FontVariants
            order={5}
            allVariants={fontVariants}
            fontVariants={variants}
            onSelectVariant={onSelectVariant}
            exampleContent={currentRule.details.exampleContent}
            familyName={currentRule.props.familyName}
            platform={currentRule.props.platform}
          />
        </FieldsetField>
      )}
    </>
  )
}

export default FontFieldset
