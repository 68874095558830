import {
  Badge,
  Button,
  ButtonProps,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Img,
  StackProps,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import { mdiLink } from '@mdi/js'
import { createObjectSummary, findContentfulObject, findObjectValue, getColorForValue } from '@sitecore-feaas/sdk'
import * as inflection from 'inflection'
import React from 'react'

const DataItem = <T extends Record<string, any>>({
  data,
  limit = 7,
  variant,
  nameOnly,
  isSelected,
  onSelect,
  isDisabled,
  byocProps,
  ...props
}: {
  data: T
  nameOnly?: boolean
  variant?: string
  limit?: number
  isSelected?: boolean
  isDisabled?: boolean
  onSelect?: (selected: boolean) => void
  byocProps?: Record<string, any>
} & ButtonProps &
  StackProps) => {
  const object = findContentfulObject(data || {})
  const { summary, keyMap } = createObjectSummary(object || {})
  const texts = [keyMap.title, keyMap.description, keyMap.date, ...keyMap.extras, ...keyMap.unknown]
  const textLimit = limit - (summary.image ? 2 : 0) - (summary.status || summary.number || summary.category ? 1 : 0)
  function sanitize(text: string) {
    return String(text ?? '')
      .replace(/<[^>]+>/g, ' ')
      .replace(/\s>/g, ' ')
      .trim()
  }
  return (
    <VStack as={Button} spacing='2' p='3' align='stretch' {...props} variant={'dialogPane'} background='#fff'>
      {nameOnly ? (
        <>
          <HStack align='flex-start'>
            <Text width='35%' flexShrink={0} flexBasis={'35%'} color='blackAlpha.500' fontSize='12px' noOfLines={3}>
              {inflection.titleize('name')}
            </Text>
            <Text
              size='sm'
              variant='solid'
              flexGrow={1}
              noOfLines={3}
              color='blackAlpha.800'
              fontWeight={400}
              fontSize='12px'
            >
              {sanitize(summary.title)}
            </Text>
          </HStack>
        </>
      ) : (
        <>
          {summary.image || summary.status || summary.number || summary.category || !isDisabled ? (
            <VStack
              align='stretch'
              position={'relative'}
              minHeight={'24px'}
              mr={summary.url && !summary.image ? '6' : '0'}
            >
              {summary.image && (
                <Tooltip label={keyMap.image} placement='top-end'>
                  <Img
                    src={summary.image}
                    height='80px'
                    width='auto'
                    alignSelf={'flex-start'}
                    border='1px solid'
                    borderColor='blackAlpha.200'
                    borderRadius={'3px'}
                  />
                </Tooltip>
              )}
              <HStack>
                {summary.icon && (
                  <Tooltip label={keyMap.icon}>
                    <Img src={summary.icon} boxSize='24px' background='#ccc' rounded='full' />
                  </Tooltip>
                )}
                {summary.status && (
                  <Tooltip label={keyMap.status}>
                    <Badge
                      colorScheme={getColorForValue(summary.status)}
                      flexShrink={1}
                      minWidth={0}
                      noOfLines={1}
                      display={'block'}
                    >
                      {sanitize(summary.status)}
                    </Badge>
                  </Tooltip>
                )}
                {summary.number && (
                  <Tooltip label={keyMap.number}>
                    <Badge flexShrink={0.1} minWidth={0} noOfLines={1} display={'block'}>
                      {sanitize(summary.number)}
                    </Badge>
                  </Tooltip>
                )}
                {summary.category && (
                  <Tooltip label={keyMap.category} placement='top-end'>
                    <Badge
                      colorScheme={getColorForValue(summary.category)}
                      flexShrink={6}
                      minWidth={0}
                      noOfLines={1}
                      display={'block'}
                    >
                      {sanitize(summary.category)}
                    </Badge>
                  </Tooltip>
                )}
                <VStack position={'absolute'} top={0} right={0}>
                  {!isDisabled && (
                    <Checkbox
                      isChecked={isSelected}
                      onChange={() => {
                        isSelected ? onSelect(false) : onSelect(true)
                      }}
                    />
                  )}
                  {summary.url && (
                    <Tooltip label={`${keyMap.url}: ${summary.url}`} placement='top-end'>
                      <IconButton
                        as='span'
                        aria-label='Go to url'
                        size='xs'
                        icon={
                          <Icon fontSize='icon-md'>
                            <path d={mdiLink} />
                          </Icon>
                        }
                      />
                    </Tooltip>
                  )}
                </VStack>
              </HStack>
            </VStack>
          ) : null}
          {texts
            .filter((prop) => object[prop] != null && findObjectValue(object, prop, null))
            .slice(0, textLimit)
            .map((prop, index) => {
              return (
                <HStack key={index} align='flex-start'>
                  <Text
                    width='35%'
                    flexShrink={0}
                    flexBasis={'35%'}
                    color='blackAlpha.500'
                    fontSize='12px'
                    noOfLines={3}
                  >
                    {inflection.titleize(prop)}
                  </Text>
                  <Text
                    size='sm'
                    variant='solid'
                    flexGrow={1}
                    noOfLines={3}
                    color='blackAlpha.800'
                    fontWeight={400}
                    fontSize='12px'
                  >
                    {sanitize(findObjectValue(object, prop, null))}
                  </Text>
                </HStack>
              )
            })}
        </>
      )}
    </VStack>
  )
}

export default DataItem
