import FieldsetField from '../FieldsetField.js'
import ButtonGroupSwitch from '../ButtonGroupSwitch.js'
import {
  Text,
  Button,
  Flex,
  Icon,
  Input,
  Spacer,
  Switch,
  Tooltip,
  VStack,
  AlertTitle,
  AlertDescription,
  Alert
} from '@chakra-ui/react'
import NumberField from '../styles/fields/NumberField.js'
import { mdiInformationOutline } from '@mdi/js'
import { DataPath, DataScope, DataSettings } from '@sitecore-feaas/clientside/headless'
import React, { ChangeEvent, useReducer, useState } from 'react'
import DataItem from '../DataItem.js'
import { DatasourceModel } from '@sitecore-feaas/sdk'
import isDataSettings = DataSettings.isDataSettings
import { PickerProps } from './Picker.js'

/**
 * Keep in mind that this component is used both in Pages (where datasource path looks like $.[some-path].*) and Components Builder (where datasource path looks like {datasource.id}.[some-path].*
 * This function replaces the {datasource.id} with "$" so that in both cases the path looks like $.[some-path].*
 */
const getOriginalPath = (jsonpath: string, datasource: DatasourceModel) =>
  jsonpath.substring(0, datasource.id.length + 1) == datasource.id + '.' || jsonpath === datasource.id
    ? jsonpath.replace(datasource.id, '$')
    : jsonpath

const PickerDatasourcesItems = ({
  datasource,
  jsonpath,
  repeating,
  qualifier,
  variant,
  isBYOC,
  onRepeatingChange,
  onConfigure
}: {
  datasource: DatasourceModel
  jsonpath: string
  repeating: 'no-limit' | 'limit' | 'range'
  qualifier: string
  isBYOC?: boolean
  variant?: PickerProps['variant']
  onRepeatingChange: (repeating: 'no-limit' | 'limit' | 'range') => void
  onConfigure: (value: any, attribute?: string) => void
}) => {
  if (!datasource || !jsonpath) return <>Please select a datasource and a path to be able to view items</>

  const rawPath = qualifier ? jsonpath?.substring(0, jsonpath.length - qualifier.length) : jsonpath

  const root = DataScope.queryObjectRaw(datasource.sample, getOriginalPath(rawPath, datasource))[0] as any
  const isArray = Array.isArray(root)
  const isFetched = isDataSettings(datasource.settings) && datasource.settings?.url

  const [isInvalid, setIsInvalid] = useState<boolean>(false)
  const [hideDetails, setHideDetails] = useState(false)
  const items =
    datasource && jsonpath
      ? (DataScope.queryObject(datasource.sample, getOriginalPath(rawPath, datasource)) as any[])
      : []
  const selectedItems =
    datasource && jsonpath
      ? DataScope.queryObject(datasource.sample, getOriginalPath(rawPath, datasource) + qualifier).map((item, index) =>
          items.findIndex((i) => i === item)
        )
      : []
  const [renderItemNumbersInput, rerenderItemNumbersInput] = useReducer((r) => r + 1, 0)

  function reconfigure(mode: typeof repeating) {
    setIsInvalid(false)
    if (!rawPath) onConfigure(null)
    else if (mode == 'no-limit') onConfigure(rawPath + '.*')
    else if (mode == 'limit') onConfigure(rawPath + '[0:10]')
    else if (mode == 'range') onConfigure(rawPath + '[0,1,2]')
  }

  // increment numbers by given numbers, exclude second value of ranges to ensure inclusivity
  function adjustNumbers(string: string, diff: number) {
    return string?.replace(/(.|^)(\d+)/g, (m, b, n) => b + (b == ':' ? n : String(parseInt(n) + diff)))
  }

  function onQualifierChange(nextQualifier: string, isLimit = false) {
    const parsedLimit = isLimit ? DataPath.parseLimit(nextQualifier) : DataPath.parseRange(nextQualifier)
    setIsInvalid(parsedLimit == null)
    if (parsedLimit) onConfigure(rawPath + nextQualifier)
  }

  return (
    <>
      {isArray && (isFetched || variant != 'pages') ? (
        <>
          <FieldsetField label='Items to use'>
            <ButtonGroupSwitch
              value={repeating}
              onChange={(value) => {
                reconfigure(value)
                onRepeatingChange(value)
              }}
            >
              <Button size='sm' value='no-limit'>
                All
              </Button>
              <Button size='sm' value='limit'>
                Limit
              </Button>
              <Button size='sm' value='range'>
                Specify
              </Button>
            </ButtonGroupSwitch>
          </FieldsetField>
          {repeating == 'limit' && (
            <FieldsetField label={'Max items'}>
              <NumberField
                placeholder='e.g. 10'
                min={1}
                value={parseInt(DataPath.parseLimit(qualifier))}
                onChange={(value) => {
                  const nextQualifier = '[0:' + value + ']'
                  onQualifierChange(nextQualifier, true)
                }}
                withStepper={true}
              />
            </FieldsetField>
          )}
          {repeating == 'range' && (
            <FieldsetField
              label={
                <>
                  Item numbers{' '}
                  <Tooltip
                    label={
                      <pre>
                        Examples:
                        <br />
                        1 &nbsp; -- only first item
                        <br />
                        1-3 -- First three items
                        <br />
                        2,3 -- Second and third item
                      </pre>
                    }
                  >
                    <Icon boxSize='icon-lg' ml={1} verticalAlign='middle'>
                      <path d={mdiInformationOutline}></path>
                    </Icon>
                  </Tooltip>
                </>
              }
            >
              <Input
                key={renderItemNumbersInput}
                isInvalid={isInvalid}
                defaultValue={DataPath.parseRange(adjustNumbers(qualifier, 1))}
                placeholder='e.g. 1'
                onChange={(e) => {
                  const nextQualifier =
                    '[' + adjustNumbers(e.target.value.replace(/(\d)\s*-\s*(\d)/g, '$1:$2'), -1) + ']'
                  onQualifierChange(nextQualifier)
                }}
              />
            </FieldsetField>
          )}
          <Spacer />
          <FieldsetField
            label='Display only data item names'
            htmlFor='only-names'
            extraProps={{
              display: 'flex',
              whiteSpace: 'nowrap',
              color: 'blackAlpha.600',
              labelRight: true
            }}
          >
            <Switch
              size={'sm'}
              mt={'3px'}
              mr={2}
              id='only-names'
              onChange={(e: ChangeEvent<HTMLInputElement>) => setHideDetails(e.target.checked)}
              isChecked={hideDetails}
            ></Switch>
          </FieldsetField>
          <Spacer />
        </>
      ) : (
        !isBYOC && (
          <Alert>
            <VStack align='flex-start'>
              {!isFetched ? (
                <>
                  <AlertTitle>Configure fetching</AlertTitle>
                  <AlertDescription>
                    Data item picker allows limiting and choosing specific data items from fetched data source. To
                    enable this, data source needs to be configured for data fetching (i.e. needs to have URL specified)
                  </AlertDescription>
                </>
              ) : (
                <>
                  <AlertTitle>Select an array</AlertTitle>
                  <AlertDescription>
                    Data item picker allows Pages users to configure sets of data items. To enable this, you must select
                    an array in the data tree. If you need to, edit the data source to include an array in its data
                    structure.
                  </AlertDescription>
                </>
              )}
            </VStack>
          </Alert>
        )
      )}
      <VStack mt='5' spacing='4' alignItems='stretch' width={'100%'}>
        {items.map((item: any, index: number) => (
          <DataItem
            key={index}
            isDisabled={repeating != 'range' || !isFetched}
            data={item}
            nameOnly={hideDetails}
            isSelected={selectedItems.includes(index)}
            data-datasource-type={datasource.type}
            data-datasource-id={datasource.id}
            onClick={() => {
              if (isFetched) onConfigure(jsonpath)
            }}
            onSelect={(selected) => {
              const nextSelectedItems = selected ? [...selectedItems, index] : selectedItems.filter((i) => i !== index)
              const nextQualifier = '[' + nextSelectedItems.join(',') + ']'
              onQualifierChange(nextQualifier)
              onRepeatingChange('range')
              rerenderItemNumbersInput()
            }}
          />
        ))}
      </VStack>
    </>
  )
}

export default PickerDatasourcesItems
