import { Box } from '@chakra-ui/react'
import Preview, { PreviewFontsStylesheet } from '../../styles/previews/index.js'
import { DialogMenu } from './DialogMenu.js'
import { PickerProps } from '../../picker/Picker.js'
import { Style } from '@sitecore-feaas/sdk'
import { Text } from '@chakra-ui/react'

export function DialogMenuTheme({
  onModeChange,
  variant,
  theme,
  rules
}: { theme: Style.Rule<'theme'> } & Pick<PickerProps, 'variant' | 'onModeChange' | 'rules'>) {
  const themes = Style.Set.filterByType(rules, 'theme')
  return (
    <DialogMenu
      title='Theme'
      variant={variant}
      onNavigate={() => onModeChange('themes')}
      items={[
        {
          onNavigate: () => onModeChange('themes'),
          id: 'themes',
          label: theme.details.title,
          icon: null,
          isDisabled: themes.length < 2,
          preview: () => {
            return (
              <>
                <PreviewFontsStylesheet styles={rules} />
                <Box
                  maxWidth={'200%'}
                  width='200%'
                  transform='scale(0.5)'
                  transformOrigin={'0 0 '}
                  pointerEvents={'none'}
                >
                  <Preview rules={rules} rule={theme} variant='compact' />
                </Box>
              </>
            )
          },
          badge: () => {
            return (
              themes.length > 1 && (
                <Text fontWeight={400} fontSize='11px'>
                  {themes.length} themes
                </Text>
              )
            )
          }
        }
      ]}
    />
  )
}
