import { Box, Button, Flex, HStack, Icon, IconButton, Text, VStack } from '@chakra-ui/react'
import { mdiChevronLeft, mdiClose } from '@mdi/js'
import { Style, StylesheetModel } from '@sitecore-feaas/sdk'
import React, { useEffect, useMemo, useState } from 'react'
import type { PickerProps } from './Picker.js'
import PickerStylePreview from './PickerStylePreview.js'
import PickerThemesCollections from './PickerThemesCollections.js'
import { PickerWrapper } from './PickerWrapper.js'
import { PreviewFontsStylesheet } from '../styles/previews/index.js'

export default function PickerThemes({
  classList,
  placeholder,
  onModeChange,
  onChange,
  onComboChange,
  rules: rules,
  variant
}: {
  placeholder?: string
  classList: string[]
  onModeChange: PickerProps['onModeChange']
  onChange: (style: Style.Rule<'theme'>) => any
  onComboChange: (combo: Style.Theme.Combo, category: keyof Style.Theme.Props, theme: Style.Rule<'theme'>) => any
  rules: Style.Rule[]
  variant?: PickerProps['variant']
}) {
  const usedComboIds = Style.ClassList.getThemeComboIds(classList)
  const themes = Style.Set.filterByType(rules, 'theme')
  const currentTheme = Style.Set.getContextTheme(rules, classList)
  const comboCategories = Object.keys(currentTheme?.props || {}) as (keyof Style.Theme.Props)[]
  const [customizationMode, setCustomizationMode] = useState<boolean>(false)
  const [handleBackButton, setHandleBackButton] = useState<() => void>()
  const [currentCollection, setCurrentCollection] = useState<Style.RuleCollection<Style.Type.Element>>()

  const enableCustomizationMode = () => {
    setCustomizationMode(true)
    setHandleBackButton((prevState) => () => {
      setCustomizationMode(false)
      setHandleBackButton(() => prevState)
    })
  }

  return (
    <PickerWrapper
      variant={variant}
      onBack={customizationMode ? handleBackButton : () => onModeChange()}
      title={
        customizationMode
          ? !currentCollection
            ? 'Theme: ' + currentTheme.details.title
            : `${currentCollection.details.title} styles`
          : 'Themes'
      }
    >
      <VStack justifyContent={'stretch'} alignItems='stretch' spacing={'4'} py='6' px='3'>
        {customizationMode ? (
          currentTheme ? (
            <PickerThemesCollections
              currentTheme={currentTheme}
              usedComboIds={usedComboIds}
              comboCategories={comboCategories}
              onComboChange={onComboChange}
              rules={rules}
              currentCollection={currentCollection}
              setCurrentCollection={setCurrentCollection}
              setHandleBackButton={setHandleBackButton}
            />
          ) : null
        ) : (
          <>
            {placeholder && (
              <HStack justifyContent={'space-between'}>
                <Text>{placeholder}</Text>
                {currentTheme && (
                  <Button variant='primary' onClick={() => onChange(null)} size='sm'>
                    Apply
                  </Button>
                )}
              </HStack>
            )}
            {themes.map((theme, index) => (
              <PickerStylePreview
                rules={rules}
                isCustomized={theme == currentTheme && Style.ClassList.getThemeComboIds(classList).length > 0}
                key={index}
                style={theme}
                isSelected={theme == currentTheme}
                onChange={onChange}
                enableCustomizationMode={enableCustomizationMode}
                isCustomizable={true}
              />
            ))}
          </>
        )}
        <PreviewFontsStylesheet styles={rules} />
      </VStack>
    </PickerWrapper>
  )
}
