import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Text,
  Tooltip,
  VStack,
  IconButton
} from '@chakra-ui/react'
import { mdiAlertOutline, mdiRestore } from '@mdi/js'
import { Style, isDeepEquals } from '@sitecore-feaas/sdk'
import { css } from '@emotion/react'
import Fieldsets from '../styles/fieldsets/index.js'
import PickerElementFloatingFooter from './PickerElementFloatingFooter.js'

interface Props {
  style: Style.Rule<Style.Type.ElementAspect>
  rules: Style.Rule[]
  currentStyle: Style.Rule
  onChange: (style: Style.Rule) => void
  onSave: () => void
}

const StylerRuleStyle = css`
  .chakra-button__group[role='group'] button {
    height: 32px;
  }
`

const PickerElementAspectCustom = ({ rules, style, currentStyle: currentRule, onChange, onSave }: Props) => {
  const hasChanges = !isDeepEquals(style, currentRule)

  return (
    <Flex flexDirection='column' h='full' css={StylerRuleStyle}>
      <HStack justifyContent='space-between' mb={4} minH='icon-xl'>
        <Text fontSize='md' as='h4' fontWeight='600'>
          Custom {style.type}
        </Text>
        {hasChanges && (
          <Tooltip hasArrow={false} label='Reset to default style' placement='top-end'>
            <IconButton
              aria-label='Reset to default style'
              icon={
                <Icon display='block' mx='auto' color='primary.600' boxSize='icon-xl'>
                  <path d={mdiRestore} />
                </Icon>
              }
              size='xs'
              onClick={() => {
                onChange(style)
              }}
            ></IconButton>
          </Tooltip>
        )}
      </HStack>
      <Alert status='warning' p={2} mb={4} flex='0 0 auto'>
        <AlertIcon as={Icon} boxSize='icon-sm'>
          <path d={mdiAlertOutline} />
        </AlertIcon>
        <AlertDescription lineHeight='19px'>
          This is a custom design and the style library will not be affected. For reusability create a new style.
        </AlertDescription>
      </Alert>
      <VStack spacing={4} align='stretch' mb={8}>
        {Style.TypeDefinitions[style.type].forms
          .map(({ label, id }) => {
            if (label == 'Details') return
            const Fieldset = Fieldsets[id]
            return (
              <Fieldset
                key={id}
                rules={rules}
                currentRule={currentRule}
                onChange={onChange}
                rule={style}
                label={label}
              />
            )
          })
          .filter(Boolean)}
      </VStack>

      <PickerElementFloatingFooter ml={-3} mr={-3}>
        <Button variant='solid' colorScheme='primary' px={12} isDisabled={!hasChanges} onClick={onSave}>
          Save
        </Button>
      </PickerElementFloatingFooter>
    </Flex>
  )
}

export default PickerElementAspectCustom
