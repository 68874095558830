import {
  Box,
  Button,
  Card,
  CardBody,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { mdiBookOpenPageVariantOutline, mdiOpenInNew } from '@mdi/js'
import { EmbeddableComponent } from '@sitecore-feaas/sdk'

const PickerLibraryCreateWebComponent = ({
  onCreate
}: {
  onCreate: (component: EmbeddableComponent, attributes?: any) => void
}) => {
  const [name, setName] = useState<string>('')
  const [url, setUrl] = useState<string>('')

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)
  const handleURLChange = (e: React.ChangeEvent<HTMLInputElement>) => setUrl(e.target.value)
  const [errors, setErrors] = useState<string[]>([])
  const validate = () => {
    const errors = []
    if (name === '') errors.push('name')
    if (url === '') errors.push('url')
    return errors
  }

  const handleCreate = () => {
    const errors = validate()
    if (!errors.length) {
      onCreate(null, { 'data-embed-as': name, 'data-embed-src': url })
    } else {
      setErrors(errors)
    }
  }

  return (
    <>
      <Box p={3}>
        <Card variant={'outline'}>
          <CardBody>
            <VStack alignItems={'start'}>
              <HStack>
                <Box alignSelf={'start'} color={'blackAlpha.500'}>
                  <Icon boxSize='icon-lg'>
                    <path d={mdiBookOpenPageVariantOutline} />
                  </Icon>
                </Box>
                <Box>
                  <Text fontSize={'14px'} fontWeight={600}>
                    Web component
                  </Text>
                  <Text fontSize={'12px'} color={'blackAlpha.500'}>
                    Web components allow adding clientside interactive features to the design. One-off web component can
                    be created using this form, alternatively it can be made reusable through BYOC mechanism
                  </Text>
                </Box>
              </HStack>
              <Spacer />
              <Button
                as={'a'}
                variant={'outline'}
                size={'xs'}
                target={'_blank'}
                href={'https://doc.sitecore.com/xmc/en/users/xm-cloud/build-components.html'}
              >
                Documentation{' '}
                <Icon boxSize='icon-md' ml={2}>
                  <path d={mdiOpenInNew} />
                </Icon>
              </Button>
            </VStack>
          </CardBody>
        </Card>
        <FormControl isInvalid={errors.includes('name')}>
          <FormLabel mt={3}>Name</FormLabel>
          <Input value={name} onChange={handleNameChange} />
          {errors.includes('name') && <FormErrorMessage>Name is required.</FormErrorMessage>}
        </FormControl>
        <FormControl isInvalid={errors.includes('url')}>
          <FormLabel mt={3}>Javascript source URL</FormLabel>
          <Input value={url} onChange={handleURLChange} />
          {errors.includes('url') && <FormErrorMessage>URL is required.</FormErrorMessage>}
        </FormControl>
      </Box>
      {/* footer */}
      <Box
        justifyContent={'space-between'}
        bottom={0}
        width={'fill-available'}
        position={'fixed'}
        background={'white'}
        boxShadow={'0px -1px 2px 0px #0000000F; box-shadow: 0px -1px 3px 0px #0000001A'}
        p={3}
      >
        <HStack justifyContent={'flex-end'}>
          <Button size={'sm'} variant={'primary'} onClick={handleCreate}>
            Create
          </Button>
        </HStack>
      </Box>
    </>
  )
}

export default PickerLibraryCreateWebComponent
