import { Box, Flex } from '@chakra-ui/react'
import { useContext } from 'react'
import { EntityContext } from '../../contexts/EntityContext.js'
import { useSlots } from '../../hooks/useSlots.js'
import EntityActions from './EntityActions.js'
import EntityModal from './EntityModal.js'
import EntityActionsMenu from './EntityActionsMenu.js'

const EntityBlock = ({ children, className }: { children: any; className?: string }) => {
  const {
    isActive,
    isNonEditable,
    ref,
    id,
    getClassname,
    activityAttribute,
    onToggleEntity,
    onMouseEnterUpper,
    wrapperClassname,
    onMouseLeaveUpper,
    withModal,
    isNew
  } = useContext(EntityContext)

  if (withModal) {
    return useSlots(children, ({ preview, details, form, extraMenuActions, menuDetails, children }) => (
      <Box
        {...activityAttribute}
        px={2}
        pt={2}
        pb={1}
        position='relative'
        layerStyle={isNonEditable ? 'outline' : 'outline.interactive'}
        id={`entity-${id}`}
        ref={ref}
        onMouseEnter={onMouseEnterUpper}
        onMouseLeave={onMouseLeaveUpper}
        className={wrapperClassname}
        onClick={onToggleEntity}
        cursor={!isNonEditable && 'pointer'}
      >
        <Box className={getClassname('upper')}>
          {preview && <Box className={getClassname('preview')}>{preview}</Box>}
        </Box>

        <Box marginTop={1} position='relative'>
          {details && <Box className={getClassname('details')}>{details}</Box>}

          {!isNew && (
            <Box position='absolute' top={0} right={0}>
              <EntityActionsMenu extraMenuActions={extraMenuActions} menuDetails={menuDetails} />
            </Box>
          )}
        </Box>

        <EntityModal>
          <Box className={getClassname('form')}>{form}</Box>
        </EntityModal>

        {children}
      </Box>
    ))
  }

  return useSlots(children, ({ preview, details, form, extraActions, extraMenuActions, children }) => (
    <Box
      {...activityAttribute}
      position='relative'
      borderRadius='base'
      marginTop='5'
      layerStyle={isNonEditable ? 'outline' : 'outline.interactive'}
      id={`entity-${id}`}
      ref={ref}
      onMouseEnter={onMouseEnterUpper}
      onMouseLeave={onMouseLeaveUpper}
      className={wrapperClassname}
    >
      <Flex onClick={onToggleEntity} cursor={!isNonEditable && 'pointer'}>
        <Box flex='1' position='relative' py='5' p='5' className={getClassname('upper')}>
          {preview && <Box className={getClassname('preview')}>{preview}</Box>}

          {details && (
            <Box mt={preview && '5'} className={getClassname('details')}>
              {details}
            </Box>
          )}
        </Box>

        <Flex pt='5' pr='5' ml='auto' className={getClassname('actions')}>
          <EntityActions extraMenuActions={extraMenuActions} extraActions={extraActions} />
        </Flex>
      </Flex>

      {isActive && (
        <Box px='5' pb='5' className={getClassname('form')}>
          {form}
        </Box>
      )}

      {children}
    </Box>
  ))
}

export default EntityBlock
