import { Box, Button, Flex, Icon, IconButton, Text } from '@chakra-ui/react'
import { mdiClose, mdiPlus } from '@mdi/js'
import { Style, Unformatted, isDeepEquals, mergeDeep } from '@sitecore-feaas/sdk'
import { ReactNode, useMemo } from 'react'
import FieldsetField from '../../FieldsetField.js'
import { Select } from '../../Select.js'
import SwitchGroupField from '../fields/SwitchGroupField.js'
import TypographySizeSettingFieldset from './TypographySizeSettingFieldset.js'
import { StyleFormProps } from './index.js'

function BreakpointLabel({ id, onUpdate }: { id: any; onUpdate: any }) {
  return (
    <Flex alignItems='center' w='full'>
      <Box>When screen size is:</Box>

      <IconButton
        ml='auto'
        aria-label='Delete'
        boxSize='icon-xl'
        icon={
          <Icon boxSize='icon-md'>
            <path d={mdiClose} />
          </Icon>
        }
        onClick={() => onUpdate({ overrides: { [id]: null } })}
      />
    </Flex>
  )
}

function BreakpointOptionLabel({ title, description }: { title: string; description: string }) {
  return (
    <Flex width='calc(100% - 3em)' display='inline-flex' verticalAlign={'middle'}>
      <Text>{title}</Text>

      <Text pl='4rem' ml='auto' color='gray.400'>
        {description}
      </Text>
    </Flex>
  )
}

const TypographySizeFieldset = ({
  rule,
  rules,
  currentRule,
  onChange,
  isActive,
  label
}: StyleFormProps<'typography'> & { isActive?: boolean }) => {
  const {
    props: {
      base: currentBase,
      overrides: currentOverrides,
      fontVariant: currentFontVariant,
      allowBoldAndItalic: currentAllowBoldAndItalic,
      textTransform: currentTextTransform
    }
  } = currentRule

  const {
    props: {
      base: previousBase,
      overrides: previousOverrides,
      fontVariant: previousFontVariant,
      allowBoldAndItalic: previousAllowBoldAndItalic,
      textTransform: previousTextTransform
    }
  } = rule

  const breakpoints = Style.Set.filterByType(rules, 'breakpoint')
  const available = breakpoints.filter((breakpoint) => !currentOverrides[breakpoint.details.id])

  const breakpointOptions = useMemo(() => {
    const grouped = breakpoints.reduce(
      (
        options: Record<string, { label: ReactNode; value: string }[]>,
        { details: { title, description, id: value, collectionId } }
      ) => {
        const collection = rules.find(({ details: { id } }) => id === collectionId)

        const label =
          description !== null && description !== undefined ? (
            <BreakpointOptionLabel title={title} description={description} />
          ) : (
            title
          )

        if (!(collection.details.id in options)) {
          options[collection.details.id] = []
        }

        options[collection.details.id].push({ label, value })

        return options
      },
      {}
    )

    return Object.keys(grouped).map((collectionId) => {
      return {
        label: rules.find(({ details: { id } }) => id === collectionId).details.title,
        options: grouped[collectionId]
      }
    })
  }, [breakpoints])

  const breakpointIds = breakpoints
    .map(({ details: { id } }) => id)
    .filter((breakpointId) => currentOverrides[breakpointId] !== null && currentOverrides[breakpointId] !== undefined)

  const textTransformOptions = [
    { label: 'None', value: null },
    { label: 'Aa', value: 'capitalize' },
    { label: 'AA', value: 'uppercase' },
    { label: 'aa', value: 'lowercase' }
  ]

  const textTransformIndex = useMemo(() => {
    const index = textTransformOptions.findIndex(({ value }) => value === currentTextTransform)

    return index === -1 ? 0 : index
  }, [currentTextTransform])

  const onUpdate = (changes: Unformatted<Style.Typography.Props>) => {
    let newStyle = mergeDeep(currentRule, { props: mergeDeep(currentRule.props, changes) })

    onChange({
      ...newStyle,
      props: {
        ...newStyle.props,
        overrides: Object.keys(newStyle.props.overrides).reduce((newOverrides, breakpointId) => {
          return newStyle.props.overrides[breakpointId] === null || newStyle.props.overrides[breakpointId] === undefined
            ? newOverrides
            : {
                ...newOverrides,
                [breakpointId]: newStyle.props.overrides[breakpointId]
              }
        }, {})
      }
    })
  }

  const getValue = (id: string) => {
    for (const option of breakpointOptions) {
      const value = option.options.find(({ value }) => value === id)

      if (value) return value
    }
  }

  const onAddBreakpoint = () => {
    const smallest = available[available.length - 1]
    onUpdate({ overrides: { [smallest.details.id]: currentBase } })
  }

  return (
    <>
      {label && (
        <Text fontSize='md' as='h4' fontWeight='600'>
          {label}
        </Text>
      )}
      <TypographySizeSettingFieldset
        values={currentBase}
        previousValues={previousBase}
        onUpdate={(base) => onUpdate({ base })}
        isActive={isActive}
      />
      <FieldsetField
        mt={6}
        label='Case style'
        color='blue'
        isChanged={!isDeepEquals(previousTextTransform, currentTextTransform)}
        labelProps={{ fontSize: 'md', fontWeight: 'semibold', color: 'blackAlpha.800' }}
      >
        <SwitchGroupField
          value={textTransformIndex}
          onChange={(index) => onUpdate({ textTransform: textTransformOptions[index].value })}
        >
          {textTransformOptions.map(({ label, value }) => (
            <Button key={value}>{label}</Button>
          ))}
        </SwitchGroupField>
      </FieldsetField>

      <Text fontSize='md' as='h4' fontWeight='600' mb={2} mt={6}>
        Breakpoints
      </Text>

      {breakpointIds.map((id) => (
        <Box key={id} mt={2} mb={8} className={`typography-breakpoint-${id.split(' ').join('-')}`}>
          <Flex>
            <Box w='full'>
              <FieldsetField key={id} label={<BreakpointLabel id={id} onUpdate={onUpdate} />}>
                <Select
                  menuPosition='fixed'
                  isSearchable={false}
                  options={breakpointOptions}
                  value={getValue(id)}
                  placeholder='Select size'
                  onChange={({ value }) => {
                    onUpdate({ overrides: { [id]: null, [value]: currentOverrides[id] } })
                  }}
                  size={!isActive ? 'sm' : null}
                />
              </FieldsetField>
            </Box>
          </Flex>

          <Flex mt={5}>
            <TypographySizeSettingFieldset
              values={currentOverrides[id]}
              previousValues={previousOverrides?.[id] || Style.Typography.Size({})}
              onUpdate={(changes) => onUpdate({ overrides: { [id]: changes } })}
              isActive={isActive}
            />
          </Flex>
        </Box>
      ))}

      <Box>
        <Button
          isDisabled={!available.length}
          onClick={onAddBreakpoint}
          variant='outline'
          colorScheme='primary'
          size='sm'
          leftIcon={
            <Icon boxSize='icon-xl'>
              <path d={mdiPlus} />
            </Icon>
          }
        >
          Add size for breakpoint
        </Button>
      </Box>
    </>
  )
}

export default TypographySizeFieldset
