import React, { createContext, forwardRef, useContext, useEffect, useRef, useState } from 'react'
import EntityBlock from './EntityBlock.js'
import EntityOverlay from './EntityOverlay.js'
import useOnClickOutside from '../../hooks/useOnClickOutside.js'
import useConfirmation from '../../hooks/useConfirmation.js'
import { Text, useToast } from '@chakra-ui/react'
import { EntitiesContext } from '../../contexts/EntitiesContext.js'
import { EntityContext } from '../../contexts/EntityContext.js'
import { scrollForTogglingCard } from '../../utils/dom.js'
import * as inflection from 'inflection'
import EntityCollection from './EntityCollection.js'
import EntityBlockOpen from './EntityBlockOpen.js'

const EntityProvider = forwardRef(
  (
    {
      mode = 'block',
      id,
      isNew = false,
      className,
      onDelete,
      onDiscard,
      hasChanges,
      isBordered = true,
      onSave,
      hasErrors = false,
      onCopy,
      isNonEditable,
      safeDeleteLabel,
      onMouseEnterUpper,
      onMouseLeaveUpper,
      name,
      modalHeader,
      modalFooter,
      type,
      preventSaveToast,
      invalidNonEmptyFields = [],
      emptyRequiredFields = [],
      withModal = false,
      children
    }: {
      className?: string
      mode?: 'block' | 'overlay' | 'collection' | 'open'
      id: string
      isNew?: boolean
      name: string
      modalHeader?: any
      modalFooter?: any
      type?: string
      isBordered?: boolean
      onDelete?: () => void
      onDiscard: () => void
      hasChanges: boolean
      safeDeleteLabel?: any
      onSave: () => void
      hasErrors?: boolean
      preventSaveToast?: boolean
      invalidNonEmptyFields?: string[]
      emptyRequiredFields?: string[]
      onCopy?: () => void
      onMouseEnterUpper?: () => void
      onMouseLeaveUpper?: () => void
      isNonEditable?: boolean
      withModal?: boolean
      children: any
    },
    passedRef: any
  ) => {
    const {
      editedEntityId,
      setEditedEntityId,
      activeEntityId,
      onToggle,
      setEditingAlert,
      setNewEntityId,
      newEntityId
    } = useContext(EntitiesContext)

    const isActive = activeEntityId === id

    const ref = passedRef || useRef(null)

    const selectRef = useRef(null)

    useEffect(() => {
      setEditedEntityId(isActive && hasChanges ? id : null)
    }, [hasChanges])

    useEffect(() => {
      setNewEntityId(isNew ? id : null)
    }, [isNew])

    useOnClickOutside(ref, (e) => {
      if (!isActive || withModal) return

      const isClickOnAllowable = (e.target as HTMLElement).closest('.confirm-dialog')
      const isClickOnOtherEntity = (e.target as HTMLElement).closest('.entity-wrapper')

      if (isClickOnAllowable) return

      if (isNew && isUnsafeDeletable) {
        onUnsafeDelete()

        return
      }

      if (!hasChanges && !isNew) {
        // onToggleEntity(isClickOnOtherEntity && isClickOnOtherEntity.id.split('entity-')[1])

        return
      }

      setEditingAlert({
        onDiscard: _onDiscard,
        invalidNonEmptyFields,
        emptyRequiredFields
      })
      e.preventDefault()
      e.stopImmediatePropagation()
      e.stopPropagation()
    })

    const onToggleEntity = (e?: any, force?: boolean) => {
      if (isNonEditable) return

      if (
        e &&
        !force &&
        (e?.target?.type === 'button' ||
          e.target?.classList?.includes?.('clickable') ||
          e.target?.closest('.clickable'))
      )
        return

      onToggle(id, force)
    }

    const onUnsafeDelete = () => {
      if (isActive) {
        onToggleEntity(null, true)
      }
      setEditedEntityId(null)
      setNewEntityId(null)
      onDelete()
    }

    const _onDiscard = () => {
      onDiscard()

      if (isNew) {
        onDelete()
      }

      onToggleEntity(null, true)
    }

    const toast = useToast()

    const _onSave = () => {
      onSave()

      if (preventSaveToast) return

      toast({
        duration: 4000,
        status: 'success',
        title: isNew ? `${inflection.titleize(name)} added` : 'Changes saved'
      })
    }

    const onSafeDelete = useConfirmation(
      onUnsafeDelete,
      onDelete
        ? {
            title: `Delete ${name}`,
            button: 'Delete',
            body: <Text>{safeDeleteLabel || `Are you sure you want to delete this ${name}?`}</Text>,
            variant: 'danger'
          }
        : null
    )

    const isUnsafeDeletable = isNew && !hasChanges

    const onDeleteComputed = !onDelete
      ? null
      : (): any => {
          if (isActive) {
            onToggleEntity(null, true)
          }

          if (isUnsafeDeletable) {
            onUnsafeDelete()
            return
          }

          onSafeDelete()
        }

    useEffect(() => {
      if (!isActive) return

      return scrollForTogglingCard(ref.current)
    }, [isActive])

    const computeClassnames = () => {
      const classNamesArray = [className, `entity-${mode}`]

      if (isNew) {
        classNamesArray.push('entity-new')
      }

      if (hasChanges) {
        classNamesArray.push('entity-changed')
      }

      if (isActive) {
        classNamesArray.push('entity-active')
      }

      return classNamesArray.filter(Boolean)
    }

    const getClassname = (suffix: string, clickable?: boolean) =>
      `entity-${mode}-${suffix}${clickable ? ' clickable' : ''}`

    const activityAttribute = isActive ? { 'data-active': true } : {}

    const wrapperClassname = [
      className,
      'entity-wrapper',
      `entity-${mode}`,
      isActive && 'entity-is-active',
      ...computeClassnames()
    ]
      .filter(Boolean)
      .join(' ')

    useEffect(() => {
      document.body.classList.toggle('has-edited-entity', !!editedEntityId && !withModal)
    }, [editedEntityId])

    useEffect(() => {
      document.body.classList.toggle('has-edited-entity', !!newEntityId && !withModal)
    }, [newEntityId])

    useEffect(() => {
      if (typeof activeEntityId === 'boolean') return

      document.body.classList.toggle('has-active-entity', !!activeEntityId && !withModal)
    }, [activeEntityId])

    return (
      <EntityContext.Provider
        value={{
          isActive,
          onToggleEntity,
          isNew,
          id,
          onSafeDelete,
          onDiscard: _onDiscard,
          hasChanges,
          onUnsafeDelete,
          onDelete: onDeleteComputed,
          onSave: _onSave,
          activityAttribute,
          hasErrors,
          onCopy,
          getClassname,
          isNonEditable,
          onMouseEnterUpper,
          emptyRequiredFields,
          invalidNonEmptyFields,
          onMouseLeaveUpper,
          preventSaveToast,
          wrapperClassname,
          name,
          modalHeader,
          modalFooter,
          type,
          isBordered,
          withModal,
          selectRef,
          ref
        }}
      >
        {mode === 'block' && <EntityBlock className={className}>{children}</EntityBlock>}
        {mode === 'overlay' && <EntityOverlay className={className}>{children}</EntityOverlay>}
        {mode === 'collection' && <EntityCollection className={className}>{children}</EntityCollection>}
        {mode === 'open' && <EntityBlockOpen className={className}>{children}</EntityBlockOpen>}
      </EntityContext.Provider>
    )
  }
)

export default EntityProvider
