import FieldsetField from '../FieldsetField.js'
import { Textarea } from '@chakra-ui/react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { isValidJSON, stringify } from '../../utils/string.js'
import { DatasourceModel, isDeepEquals } from '@sitecore-feaas/sdk'

const DatasourceSample = ({
  currentDatasource,
  previousDatasource,
  onChange,
  isBlock,
  isSampleInvalid,
  setIsSampleInvalid
}: {
  currentDatasource: DatasourceModel
  previousDatasource: DatasourceModel
  onChange: (datasource: DatasourceModel) => void
  isBlock: boolean
  isSampleInvalid: boolean
  setIsSampleInvalid: Dispatch<SetStateAction<boolean>>
}) => {
  const [isDirty, setIsDirty] = useState(false)
  const [input, setInput] = useState(stringify(currentDatasource.sample))

  const hasChanges = !isDeepEquals(currentDatasource.sample, previousDatasource.sample)
  const errors = isValidJSON(input) ? null : 'Not valid JSON'
  const showErrors = isDirty && errors

  useEffect(() => {
    setInput(stringify(currentDatasource.sample))
  }, [currentDatasource.sample])

  useEffect(() => {
    if (isDirty) return

    setIsDirty(input !== stringify(currentDatasource.sample))
  }, [input])

  useEffect(() => {
    const isValid = isValidJSON(input)
    setIsSampleInvalid(!isValid)

    if (!isValid) return

    onChange(currentDatasource.change({ sample: JSON.parse(input) }))
  }, [input])

  return (
    <FieldsetField
      error={showErrors}
      required={true}
      label='JSON'
      extraProps={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      isChanged={hasChanges}
    >
      <Textarea
        flexGrow={1}
        minWidth={isBlock && '425px'}
        onChange={(e) => setInput(e.target.value)}
        maxWidth={'100%'}
        rows={10}
        value={input}
        isDisabled={!currentDatasource.isInternal()}
        isInvalid={!!showErrors}
        placeholder='Paste JSON'
      />
    </FieldsetField>
  )
}

export default DatasourceSample
