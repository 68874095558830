import { Button, Flex } from '@chakra-ui/react'
import type { RJSFSchema, UiSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { ExternalComponent } from '@sitecore-feaas/sdk'
export type { ExternalComponent } from '@sitecore-feaas/sdk'
import { objectKeysToKebabCase } from '@sitecore/byoc'
import { SchemaForm } from '../../SchemaForm.js'
const DialogAttributesExternalSchema = ({
  attributes,
  embed,
  onChange
}: {
  attributes: any
  embed: ExternalComponent
  onChange: (data: any, attribute: string) => void
}) => {
  return (
    <DialogAttributesExternalSchemaForm
      embed={embed}
      defaultData={attributes}
      onChange={(data) => {
        onChange(JSON.stringify(objectKeysToKebabCase(data)), 'data-attributes')
      }}
    />
  )
}

const DialogAttributesExternalSchemaForm = ({
  embed,
  defaultData,
  onChange
}: {
  embed: ExternalComponent
  defaultData: any
  onChange: (data: any) => any
}) => {
  return (
    <>
      {embed && (
        <SchemaForm
          schema={embed.schema}
          uiSchema={embed.uiSchema as UiSchema}
          //@ts-ignore
          validator={validator}
          formData={defaultData}
          onSubmit={({ formData }) => {
            onChange(formData)
          }}
        >
          <Flex alignContent={'flex-end'} mt={10}>
            <Button type='submit' variant='primary' size='md' ml={'auto'}>
              Update
            </Button>
          </Flex>
        </SchemaForm>
      )}
    </>
  )
}

export default DialogAttributesExternalSchema
