import {
  mdiArrowExpandHorizontal,
  mdiCardOutline,
  mdiCellphoneLink,
  mdiCreditCardChipOutline,
  mdiDotsGrid,
  mdiDrawing,
  mdiFormatColorFill,
  mdiFormatFont,
  mdiFormatLetterCase,
  mdiFormatTextbox,
  mdiPaletteAdvanced,
  mdiPaletteOutline,
  mdiShapePlus
} from '@mdi/js'
import { Style } from '@sitecore-feaas/sdk'

type CollectionIds = 'basics' | 'reusables' | 'elements' | 'blocks' | 'scene' | 'concepts'

interface Collection {
  title: string
  id: CollectionIds
}
export function getIconByStyleType(name: string) {
  switch (name) {
    case 'font':
      return mdiFormatFont
    case 'color':
      return mdiPaletteOutline
    case 'graphic':
      return mdiDrawing
    case 'breakpoint':
      return mdiCellphoneLink
    case 'fill':
      return mdiFormatColorFill
    case 'typography':
      return mdiFormatLetterCase
    case 'decoration':
      return mdiShapePlus
    case 'spacing':
      return mdiArrowExpandHorizontal
    case 'layout':
      return mdiDotsGrid
    case 'dimensions':
      return mdiArrowExpandHorizontal
    case 'palette':
      return mdiPaletteOutline
    case 'text':
      return mdiFormatTextbox
    case 'inline':
      return mdiCreditCardChipOutline
    case 'block':
      return mdiCardOutline
    case 'theme':
      return mdiPaletteAdvanced
  }
}

export const sidebarCollections: Collection[] = [
  { title: 'Basics', id: 'basics' },
  { title: 'Rules', id: 'reusables' },
  { title: 'Elements', id: 'elements' },
  { title: 'Concepts', id: 'concepts' }
]

export interface StylePage {
  type: Style.Rule['type'] | string
  title: string
  description: string
  collectionId: CollectionIds
  disabled?: boolean
}

export const sidebarStyles: StylePage[] = [
  {
    type: 'font',
    title: 'Fonts',
    description: 'Typefaces and variants',
    collectionId: 'basics'
  },
  {
    type: 'color',
    title: 'Colors',
    description: 'Groups and alternatives',
    collectionId: 'basics'
  },
  {
    type: 'graphic',
    title: 'Graphics',
    description: 'Lorem Ipsum',
    collectionId: 'basics'
  },
  {
    type: 'breakpoint',
    title: 'Breakpoints',
    description: 'Lorem Ipsum',
    collectionId: 'basics'
  },
  {
    type: 'typography',
    title: 'Typographies',
    description: 'Font variants with sizes',
    collectionId: 'reusables'
  },
  {
    type: 'decoration',
    title: 'Decorations',
    description: 'Block-level visual accent',
    collectionId: 'reusables'
  },
  {
    type: 'fill',
    title: 'Fills',
    description: 'Block-level backgrounds',
    collectionId: 'reusables'
  },
  {
    type: 'spacing',
    title: 'Spacings',
    description: 'Lorem Ipsum',
    collectionId: 'reusables'
  },
  {
    type: 'text',
    title: 'Text elements',
    description: 'Lorem Ipsum',
    collectionId: 'elements'
  },
  {
    type: 'inline',
    title: 'Inline elements',
    description: 'Lorem Ipsum',
    collectionId: 'elements'
  },
  {
    type: 'block',
    title: 'Block elements',
    description: 'Lorem Ipsum',
    collectionId: 'elements'
  },
  {
    type: 'theme',
    title: 'Themes',
    description: '',
    collectionId: 'concepts'
  }
]
