import { isDeepEquals, Style, Unformatted } from '@sitecore-feaas/sdk'
import SizeField from '../fields/SizeField.js'
import FieldsetField from '../../FieldsetField.js'
import { Grid, GridItem } from '@chakra-ui/react'

const TypographySizeSettingFieldset = ({
  values: { fontSize, lineHeight, letterSpacing, paragraphSpacing, iconSize },
  previousValues: {
    fontSize: previousFontSize,
    lineHeight: previousLineHeight,
    letterSpacing: previousLetterSpacing,
    paragraphSpacing: previousParagraphSpacing,
    iconSize: previousIconSize
  } = Style.Typography.Size(),
  onUpdate,
  isActive
}: {
  values: Style.Typography.Size
  previousValues: Style.Typography.Size
  onUpdate: (change: Unformatted<Style.Typography.Size>) => void
  isActive?: boolean
}) => (
  <>
    {/* Use isActive to prop to differentiate between Styles page and Picker */}
    <Grid templateColumns='repeat(2, 1fr)' rowGap={isActive ? 6 : 4} columnGap={isActive ? 6 : 3}>
      <GridItem>
        <FieldsetField label='Font size' isChanged={!isDeepEquals(previousFontSize, fontSize)}>
          <SizeField
            allowEmpty={true}
            onChange={(fontSize) => onUpdate({ fontSize })}
            placeholder={String(fontSize?.value ?? (isActive ? 'Inherit' : 'auto'))}
            length={fontSize}
            ariaLabel='Font size'
            size={!isActive ? 'sm' : null}
          />
        </FieldsetField>
      </GridItem>

      <GridItem>
        <FieldsetField label='Letter spacing' isChanged={!isDeepEquals(previousLetterSpacing, letterSpacing)}>
          <SizeField
            allowEmpty={true}
            onChange={(letterSpacing) => onUpdate({ letterSpacing })}
            units={['px', 'em']}
            placeholder={String(letterSpacing?.value ?? (isActive ? 'Inherit' : 'auto'))}
            length={letterSpacing}
            allowDecimal={true}
            size={!isActive ? 'sm' : null}
          />
        </FieldsetField>
      </GridItem>

      <GridItem>
        <FieldsetField label='Line height' isChanged={!isDeepEquals(previousLineHeight, lineHeight)}>
          <SizeField
            allowEmpty={true}
            onChange={(lineHeight) => onUpdate({ lineHeight })}
            placeholder={String(lineHeight?.value ?? (isActive ? 'Inherit' : 'auto'))}
            length={lineHeight}
            size={!isActive ? 'sm' : null}
          />
        </FieldsetField>
      </GridItem>

      <GridItem>
        <FieldsetField label='Paragraph spacing' isChanged={!isDeepEquals(previousParagraphSpacing, paragraphSpacing)}>
          <SizeField
            allowEmpty={true}
            onChange={(paragraphSpacing) => onUpdate({ paragraphSpacing })}
            placeholder={String(paragraphSpacing?.value ?? (isActive ? 'Unspecified' : 'auto'))}
            length={paragraphSpacing}
            size={!isActive ? 'sm' : null}
          />
        </FieldsetField>
      </GridItem>
      <GridItem>
        <FieldsetField label='Icon size' isChanged={!isDeepEquals(previousIconSize, iconSize)}>
          <SizeField
            allowEmpty={true}
            onChange={(iconSize) => onUpdate({ iconSize })}
            placeholder={String(iconSize?.value ?? (isActive ? 'Same as line height' : 'auto'))}
            length={iconSize}
            size={!isActive ? 'sm' : null}
          />
        </FieldsetField>
      </GridItem>
    </Grid>
  </>
)

export default TypographySizeSettingFieldset
