import FieldsetField from '../FieldsetField.js'
import { Textarea } from '@chakra-ui/react'
import { DatasourceModel } from '@sitecore-feaas/sdk'

const DatasourceDescription = ({
  currentDatasource,
  previousDatasource,
  onChange,
  isBlock
}: {
  currentDatasource: DatasourceModel
  previousDatasource: DatasourceModel
  onChange: (datasource: DatasourceModel) => void
  isBlock: boolean
}) => (
  <FieldsetField label='Description' isChanged={currentDatasource.description !== previousDatasource.description}>
    <Textarea
      value={currentDatasource.description}
      onChange={(e) => onChange(currentDatasource.change({ description: e.target.value }))}
      width={isBlock && '425px'}
      isDisabled={!currentDatasource.isInternal()}
      placeholder='Describe the data source'
    />
  </FieldsetField>
)

export default DatasourceDescription
