import { Box, Button, VStack } from '@chakra-ui/react'
import { Style, mergeDeep } from '@sitecore-feaas/sdk'
import { useState } from 'react'
import ButtonGroupSwitch from '../../ButtonGroupSwitch.js'
import FieldsetField from '../../FieldsetField.js'
import SizeField from '../../styles/fields/SizeField.js'
import type { DialogStyleProps } from './Dialog.js'

export default function DialogDimensions({ context, onChange, rules, customRules }: DialogStyleProps) {
  const style =
    Style.Set.getContextElementAspect(rules, context, 'dimensions', customRules) || Style.Rule({ type: 'dimensions' })

  const [index, setIndex] = useState(() => {
    return Style.stringifyLength(style.props.minHeight, null) == Style.stringifyLength(style.props.maxHeight, null) &&
      Style.stringifyLength(style.props.minWidth, null) == Style.stringifyLength(style.props.maxWidth, null)
      ? 0
      : 1
  })

  const definition = Style.Context.getDefinition(context)

  const onChangeIndex = (index: number) => {
    setIndex(index)

    if (index == 0) {
      setProperties({
        maxWidth: style.props.maxWidth,
        maxHeight: style.props.maxHeight
      })
    } else {
      setProperties({
        maxWidth: style.props.minWidth,
        maxHeight: style.props.minHeight
      })
    }
  }

  function setProperties(props: Partial<typeof style.props>) {
    onChange(
      mergeDeep(style, {
        props: props
      })
    )
  }

  return (
    <>
      <Box>
        <ButtonGroupSwitch value={index} onChange={onChangeIndex}>
          <Button>Simple</Button>
          <Button>Advanced</Button>
        </ButtonGroupSwitch>
      </Box>

      {index == 0 && (
        <VStack spacing={4}>
          <FieldsetField label='Width'>
            <SizeField
              length={style.props.minWidth}
              allowEmpty={true}
              onChange={(value) => {
                setProperties({
                  minWidth: value,
                  maxWidth: value
                })
              }}
            />
          </FieldsetField>

          {definition.id != 'section' && (
            <FieldsetField label='Height'>
              <SizeField
                allowEmpty={true}
                length={style.props.minHeight}
                onChange={(value) => {
                  setProperties({
                    minHeight: value,
                    maxHeight: value
                  })
                }}
              />
            </FieldsetField>
          )}
        </VStack>
      )}

      {index == 1 && (
        <VStack alignItems={'stretch'} spacing='4'>
          <FieldsetField label='Min-width'>
            <SizeField
              min={8}
              allowEmpty={true}
              length={style.props.minWidth}
              onChange={(value) => {
                setProperties({
                  minWidth: value
                })
              }}
            />
          </FieldsetField>

          {definition.id != 'section' && (
            <FieldsetField label='Min-height'>
              <SizeField
                min={8}
                allowEmpty={true}
                length={style.props.minHeight}
                onChange={(value) => {
                  setProperties({
                    minHeight: value
                  })
                }}
              />
            </FieldsetField>
          )}

          <FieldsetField label='Max-width'>
            <SizeField
              allowEmpty={true}
              length={style.props.maxWidth}
              onChange={(value) => {
                setProperties({
                  maxWidth: value
                })
              }}
            />
          </FieldsetField>

          {definition.id != 'section' && (
            <FieldsetField label='Max-height'>
              <SizeField
                allowEmpty={true}
                length={style.props.maxHeight}
                onChange={(value) => {
                  setProperties({
                    maxHeight: value
                  })
                }}
              />
            </FieldsetField>
          )}
        </VStack>
      )}
    </>
  )
}
