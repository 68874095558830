import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  StackProps,
  Text,
  VStack
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import {
  CollectionModel,
  ComponentModel,
  EmbeddableComponent,
  ExternalComponentModel,
  getEmbedDefinition
} from '@sitecore-feaas/sdk'
import React, { useMemo, useState } from 'react'
import ComponentVersionThumbnail, { ScrollableThumbnail } from '../ComponentVersionThumbnail.js'
import ComponentVersionThumbnailWrapper from '../ComponentVersionThumbnailWrapper.js'
import type { PickerProps } from './Picker.js'
import { PickerWrapper } from './PickerWrapper.js'
import PickerLibraryCreateWebComponent from './PickerLibraryCreateWebComponent.js'

export function EmbeddableComponentThumbnail({
  component,
  height,
  ...props
}: { component: EmbeddableComponent; height?: number } & StackProps) {
  return (
    <ComponentVersionThumbnailWrapper background={'white'} height={height} {...props}>
      {!ExternalComponentModel.isExternalComponent(component) ? (
        component && <ComponentVersionThumbnail component={component} targetHeight={height} />
      ) : (
        <ScrollableThumbnail
          src={component.thumbnail || 'https://feaasstatic.blob.core.windows.net/assets/thumbnails/byoc.svg'}
        />
      )}
    </ComponentVersionThumbnailWrapper>
  )
}
export function EmbeddableComponentList({
  collection,
  onChange,
  value,
  ...props
}: {
  collection: EmbeddableComponent[]
  value: EmbeddableComponent
  onChange: (component: EmbeddableComponent) => void
} & Omit<StackProps, 'onChange'>) {
  return (
    <VStack align={'stretch'} py={4} gap={6} {...props}>
      {collection.map((component: EmbeddableComponent) => (
        <Button
          variant={'dialogPane'}
          flexShrink={1}
          className='component'
          key={component.id}
          isActive={component.id == value?.id}
          onClick={() => {
            onChange(component)
          }}
        >
          <EmbeddableComponentThumbnail component={component} height={140} px={'3'} pt={'3'} />
          <VStack p={3} align='stretch'>
            <Box
              css={css`
                flex-shrink: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
              fontSize='md'
              fontWeight='semibold'
              cursor='pointer'
            >
              {'title' in component ? component.title : component.name}
            </Box>
          </VStack>
        </Button>
      ))}
    </VStack>
  )
}

const PickerLibrary = ({
  externalComponents,
  component,
  context,
  embed,
  variant,
  onChange,
  onModeChange,
  collections
}: {
  externalComponents: EmbeddableComponent[]
  context: PickerProps['context']
  embed: PickerProps['embed']
  component: ComponentModel
  variant?: PickerProps['variant']
  onModeChange: PickerProps['onModeChange']
  onChange: (component: EmbeddableComponent, attributes?: any) => void
  collections: CollectionModel[]
}) => {
  const [isWebComponentView, setIsWebComponentView] = useState(false)
  const stagedComponents = collections.flatMap((c) => c.components).filter((comp) => comp.stagedAt)
  const allComponents = [...stagedComponents, ...externalComponents] as EmbeddableComponent[]
  const embedDefinition = getEmbedDefinition(context)
  const pickerMode = useMemo(() => {
    return embedDefinition.type == 'form' ? 'form' : null
  }, [])
  const filteredComponents = allComponents.filter((c) => {
    return pickerMode == 'form'
      ? ExternalComponentModel.isExternalComponent(c) && ExternalComponentModel.isComponentForm(c)
      : true
  })

  const groups = filteredComponents.reduce((groups, comp) => {
    if (comp instanceof ComponentModel) {
      // avoid nesting components into themselves
      if (comp.id == component?.id) return groups
      var groupName = comp.collection.name
    } else {
      var groupName = comp.group
    }
    ;(groups[groupName] ||= []).push(comp)
    return groups
  }, {} as Record<string, EmbeddableComponent[]>)

  const groupNames = Object.keys(groups)
  const activeIndex = groupNames.findIndex((groupName) => groups[groupName].some((c) => c.id == embed?.id))
  return (
    <PickerWrapper
      variant={variant}
      onBack={isWebComponentView ? () => setIsWebComponentView(false) : !embed ? null : () => onModeChange()}
      title={pickerMode == 'form' ? 'Sitecore Forms' : 'Components'}
    >
      {!isWebComponentView ? (
        <>
          <Text color='gray.400' m={4}>
            {filteredComponents.length} component{filteredComponents.length == 1 ? '' : 's'} available
          </Text>
          {groupNames.length > 1 && (
            <Accordion
              allowToggle={true}
              defaultIndex={groupNames.findIndex((groupName) => groups[groupName].some((c) => c.id == embed?.id))}
            >
              {groupNames.map((groupName) => (
                <AccordionItem key={groupName} borderTop={0} borderBottom={'1px'} borderBottomColor={'gray.200'}>
                  {({ isExpanded }: { isExpanded: boolean }) => (
                    <>
                      <AccordionButton py={3} pr={3} aria-label={`Components in ${groupName}`}>
                        <HStack justifyContent={'space-between'} width='100%'>
                          <Text fontWeight={600} fontSize={13}>
                            {groupName}
                          </Text>
                          <AccordionIcon boxSize='icon-xl' />
                        </HStack>
                      </AccordionButton>
                      <AccordionPanel>
                        {isExpanded && (
                          <EmbeddableComponentList collection={groups[groupName]} value={embed} onChange={onChange} />
                        )}
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
          )}
          {groupNames.length <= 1 && (
            <>
              <EmbeddableComponentList
                collection={groups[groupNames[0]] || []}
                value={embed}
                onChange={onChange}
                px={3}
              />
            </>
          )}

          {/* footer */}
          {variant != 'pages' && embedDefinition?.type !== 'form' && (
            <>
              <Box height='50px' />
              <Box
                justifyContent={'space-between'}
                bottom={0}
                width='var(--picker-width, 100%)'
                position={'fixed'}
                background={'white'}
                boxShadow={'0px -1px 2px 0px #0000000F; box-shadow: 0px -1px 3px 0px #0000001A'}
                p={3}
              >
                <HStack justifyContent={'flex-end'}>
                  <Text color={'blackAlpha.400'}>or</Text>
                  <Button variant={'outline'} size={'xs'} alignSelf={'end'} onClick={() => setIsWebComponentView(true)}>
                    Create web component
                  </Button>
                </HStack>
              </Box>
            </>
          )}
        </>
      ) : (
        <PickerLibraryCreateWebComponent onCreate={onChange} />
      )}
    </PickerWrapper>
  )
}

export default PickerLibrary
