import { Box, Button, FormLabel, HStack, Switch, Text } from '@chakra-ui/react'
import { Style, Unformatted, mergeDeep } from '@sitecore-feaas/sdk'
import { useState } from 'react'
import ButtonGroupSwitch from '../../ButtonGroupSwitch.js'
import FieldsetField from '../../FieldsetField.js'
import { Select } from '../../Select.js'
import { DialogStyleProps } from './Dialog.js'

export default function DialogSpacing({ context, customRules, rules, themeClassList, onChange }: DialogStyleProps) {
  const classList = Style.Context.getClassList(context)
  const elementStyle = Style.Set.getContextElement(rules, classList)
  const [allValues, setAllValues] = useState(false)
  const allowedInitialSpacingStyles = allValues
    ? Style.Set.filterByType(rules, 'spacing')
    : Style.Set.getContextAspectChoices(rules, context, 'spacing')

  // get currently active spacing rule
  const style =
    Style.Set.getContextElementAspect(rules, context, 'spacing', customRules) ||
    Style.Set.getContextComboAspect(rules, context, 'spacing', themeClassList) ||
    Style.Rule({ type: 'spacing' })

  const [index, setIndex] = useState(() => {
    const stringified: Unformatted<typeof style.props> = Object.keys(style.props).reduce(
      (obj, key: keyof typeof style.props) => {
        return Object.assign(obj, { [key]: Style.stringifyLength(style.props[key]) })
      },
      Style.Spacing.Props()
    )
    if (
      stringified.paddingTop == stringified.paddingBottom &&
      stringified.paddingTop == stringified.paddingLeft &&
      stringified.paddingTop == stringified.paddingRight &&
      stringified.columnGap == stringified.rowGap
    ) {
      return 0
    }
    if (stringified.paddingTop == stringified.paddingBottom && stringified.paddingLeft == stringified.paddingRight) {
      return 1
    }
    return 2
  })

  const setTabIndex = (nextIndex: number) => {
    const oldIndex = index
    setIndex(nextIndex)
    onChange(
      mergeDeep(style, {
        props: {
          paddingBottom: style.props.paddingTop,
          paddingLeft: oldIndex == 0 ? style.props.paddingTop : style.props.paddingLeft,
          paddingRight: oldIndex == 0 ? style.props.paddingTop : style.props.paddingLeft,
          columnGap: style.props.columnGap,
          rowGap: nextIndex == 0 ? style.props.columnGap : style.props.rowGap
        }
      })
    )
  }

  function aggregateValues(properties: (keyof Style.Rule<'spacing'>['props'])[], includeZero: boolean = false) {
    return properties
      .reduce((values, property) => {
        return values.concat(
          ...allowedInitialSpacingStyles.map((spacingStyle: Style.Rule<'spacing'>) => {
            return (spacingStyle.props[property] || []) as Style.Length[]
          })
        )
      }, [])
      .concat(includeZero ? { value: 0, unit: 'px' } : [])
      .map((value) => {
        return Style.stringifyLength(value)
      })
      .filter(Boolean)
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => {
        return parseFloat(a) - parseFloat(b)
      })
  }

  function SelectFor(properties: (keyof Style.Rule<'spacing'>['props'])[]) {
    const options = aggregateValues(properties, !!properties[0].match('Gap')).map((value) => {
      return { label: value, value: value }
    })
    const current = Style.stringifyLength(style.props[properties[0]])
    var value = options.find((opt) => current == opt.value)
    if (value == null) {
      value = { value: current, label: current }
      options.push(value)
    }
    return (
      <Select
        options={options}
        value={value}
        onChange={({ value }) => {
          onChange(
            mergeDeep(style, {
              props: Style.Spacing.Props(
                properties.reduce(
                  (obj, key) => {
                    return Object.assign(obj, { [key]: Style.stringifyLength(value) })
                  },
                  { ...style.props }
                )
              )
            })
          )
        }}
      />
    )
  }

  return (
    <>
      <Box>
        <ButtonGroupSwitch value={index} onChange={setTabIndex}>
          <Button>Sync</Button>
          <Button>Sides</Button>
          <Button>All custom</Button>
        </ButtonGroupSwitch>
      </Box>

      {elementStyle?.props && 'spacingIds' in elementStyle?.props && elementStyle.props.spacingIds.length > 0 && (
        <FormLabel display='flex' mt={4}>
          <Switch isChecked={!allValues} onChange={() => setAllValues((v) => !v)} />
          <Text ml={3}>Only values appropriate for element</Text>
        </FormLabel>
      )}

      {index == 0 && (
        <HStack spacing='4'>
          <FieldsetField label='Padding'>
            {SelectFor(['paddingTop', 'paddingBottom', 'paddingLeft', 'paddingRight'])}
          </FieldsetField>
          <FieldsetField label='Gaps'>{SelectFor(['rowGap', 'columnGap'])}</FieldsetField>
        </HStack>
      )}

      {index == 1 && (
        <>
          <HStack spacing='4'>
            <FieldsetField label='Vertical padding'>{SelectFor(['paddingTop', 'paddingBottom'])}</FieldsetField>
            <FieldsetField label='Vertical gap'>{SelectFor(['rowGap'])}</FieldsetField>
          </HStack>
          <HStack spacing='4'>
            <FieldsetField label='Horizontal padding'>{SelectFor(['paddingLeft', 'paddingRight'])}</FieldsetField>
            <FieldsetField label='Horizontal gap'>{SelectFor(['columnGap'])}</FieldsetField>
          </HStack>
        </>
      )}

      {index == 2 && (
        <>
          <HStack spacing='4'>
            <FieldsetField label='Top padding'>{SelectFor(['paddingTop'])}</FieldsetField>

            <FieldsetField label='Bottom padding'>{SelectFor(['paddingBottom'])}</FieldsetField>
          </HStack>
          <HStack spacing='4'>
            <FieldsetField label='Left padding'>{SelectFor(['paddingLeft'])}</FieldsetField>

            <FieldsetField label='Right padding'>{SelectFor(['paddingRight'])}</FieldsetField>
          </HStack>
          <HStack spacing='4'>
            <FieldsetField label='Horizontal gap'>{SelectFor(['columnGap'])}</FieldsetField>

            <FieldsetField label='Vertical gap'>{SelectFor(['rowGap'])}</FieldsetField>
          </HStack>
        </>
      )}
    </>
  )
}
