import { ReactElement, useEffect, useState } from 'react'
import { EntitiesContext } from '../../contexts/EntitiesContext.js'
import EntitiesAlert from './EntitiesAlert.js'
import { useNavigate } from 'react-router'

const EntitiesProvider = ({
  url,
  activeEntityId,
  children,
  ids,
  pre,
  navigate
}: {
  children: ReactElement
  url: string
  activeEntityId: string
  ids: string[]
  pre?: string
  navigate?: (url: string) => void
}) => {
  const [editedEntityId, setEditedEntityId] = useState<string>(null)
  const [newEntityId, setNewEntityId] = useState<string>(null)
  const [editingAlert, setEditingAlert] = useState<{
    onDiscard: () => void
    invalidNonEmptyFields: string[]
    emptyRequiredFields: string[]
  }>(null)

  navigate ||= useNavigate()

  const onToggle = (id: string, force?: boolean) => {
    if ((editedEntityId || newEntityId) && !force) return

    if (!activeEntityId || activeEntityId !== id) {
      navigate(`${url}/${pre ? `${pre}/` : ''}${id}`)
      return
    }

    navigate(`${url}`)
  }

  useEffect(() => {
    if (editedEntityId && !ids.includes(editedEntityId)) {
      setEditedEntityId(null)
    }

    if (newEntityId && !ids.includes(newEntityId)) {
      setNewEntityId(null)
    }
  }, [ids])

  return (
    <EntitiesContext.Provider
      value={{
        activeEntityId,
        editedEntityId,
        setEditedEntityId,
        onToggle,
        editingAlert,
        setEditingAlert,
        newEntityId,
        setNewEntityId
      }}
    >
      {children}
      <EntitiesAlert />
    </EntitiesContext.Provider>
  )
}

export default EntitiesProvider
