import FieldsetField from '../FieldsetField.js'
import { Textarea } from '@chakra-ui/react'
import { DatasourceModel } from '@sitecore-feaas/sdk'

const DatasourceSettingsGraphqlQuery = ({
  currentDatasource,
  previousDatasource,
  onChange,
  isBlock
}: {
  currentDatasource: DatasourceModel
  previousDatasource: DatasourceModel
  onChange: (datasource: DatasourceModel) => void
  isBlock: boolean
}) => (
  <FieldsetField
    label='Query'
    isChanged={
      previousDatasource.settings?.body?.query === undefined && currentDatasource.settings?.body?.query === ''
        ? false
        : currentDatasource.settings?.body?.query !== previousDatasource.settings?.body?.query
    }
  >
    <Textarea
      name='query'
      rows={10}
      value={currentDatasource.settings?.body?.query}
      onChange={(e) =>
        onChange(
          currentDatasource.change({
            settings: {
              ...currentDatasource.settings,
              body: { ...currentDatasource.settings.body, query: e.target.value }
            }
          })
        )
      }
    />
  </FieldsetField>
)

export default DatasourceSettingsGraphqlQuery
