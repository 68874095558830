import { Button } from '@chakra-ui/react'
import { useContext } from 'react'
import EntityActionsMenu from './EntityActionsMenu.js'
import { EntityContext } from '../../contexts/EntityContext.js'

const EntityBlockActions = ({ extraMenuActions, extraActions, menuDetails }: any) => {
  const {
    isActive,
    onToggleEntity,
    isNew,
    onSafeDelete,
    onDiscard,
    name,
    hasChanges,
    onUnsafeDelete,
    onSave,
    hasErrors,
    id,
    withModal
  } = useContext(EntityContext)

  const suffix = `entity ${name} with id: ${id}`

  return (
    <>
      {extraActions}

      {isActive && (
        <>
          {!withModal && isNew && onSafeDelete && (
            <Button mr='2' aria-label={`Delete ${suffix}`} variant='minimal' size='sm' onClick={() => onUnsafeDelete()}>
              Cancel
            </Button>
          )}

          {!withModal && hasChanges && !isNew && (
            <Button mr='2' variant='minimal' size='sm' aria-label={`Cancel ${suffix}`} onClick={onDiscard}>
              Cancel
            </Button>
          )}

          {!withModal &&
            (hasChanges || isNew ? (
              <Button
                width='68px'
                size='sm'
                variant='primary'
                aria-label={`Save ${suffix}`}
                onClick={onSave}
                isDisabled={hasErrors}
              >
                Save
              </Button>
            ) : (
              <Button
                width='68px'
                size='sm'
                variant='secondary'
                className='close-entity'
                aria-label={`Close ${suffix}`}
                onClick={(e) => onToggleEntity(e, true)}
              >
                Close
              </Button>
            ))}
        </>
      )}

      {(withModal || (!isNew && !isActive)) && (
        <EntityActionsMenu extraMenuActions={extraMenuActions} menuDetails={menuDetails} />
      )}
    </>
  )
}

export default EntityBlockActions
