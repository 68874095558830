import { Box, Flex, Grid, GridItem, Input } from '@chakra-ui/react'
import { mergeDeep } from '@sitecore-feaas/sdk'
import { useEffect, useState } from 'react'
import NumberField from '../fields/NumberField.js'
import OpacityField from '../fields/OpacityField.js'
import FieldsetField from '../../FieldsetField.js'
import tinycolor from 'tinycolor2'
import { StyleFormProps } from './index.js'
import { Select } from '../../Select.js'

type HEX = string

interface HSL {
  hue: number
  saturation: number
  lightness: number
}

interface RGB {
  red: number
  green: number
  blue: number
}

type RGBID = 'red' | 'green' | 'blue'
type HSLID = 'hue' | 'saturation' | 'lightness'

const rgbMapping = [
  { label: 'Red', id: 'red' },
  { label: 'Green', id: 'green' },
  { label: 'Blue', id: 'blue' }
]
const isValidHex = (hex: string) => /^#?([0-9a-f]{3}){1,2}$/i.test(hex)

const ColorsFieldset = ({ rule, currentRule: currentStyle, errors, onChange }: StyleFormProps<'color'>) => {
  const RGBColor = { red: currentStyle.props.red, green: currentStyle.props.green, blue: currentStyle.props.blue }
  const previousRGBColor = { red: rule.props.red, green: rule.props.green, blue: rule.props.blue }
  const alpha = currentStyle.props.alpha
  const previousAlpha = rule.props.alpha

  const [hex, setHex] = useState(rgbToHex(RGBColor))
  const [type, setType] = useState<'HEX' | 'RGB'>('HEX')

  const previousHex = rgbToHex(previousRGBColor)
  const isInvalidHex = !isValidHex(hex)

  useEffect(() => {
    if (tinycolor.equals(hex, rgbToHex(RGBColor))) return

    setHex(rgbToHex(RGBColor))
  }, [currentStyle])

  useEffect(() => {
    if (!isValidHex(hex)) return

    onChange(mergeDeep(currentStyle, { props: hexToRgb(hex) }))
  }, [hex])

  function rgbToHex({ red, green, blue }: RGB): HEX {
    return tinycolor({ r: red, g: green, b: blue }).toHexString()
  }

  function hexToRgb(hex: string): RGB {
    if (!isValidHex(hex)) return
    const { r: red, g: green, b: blue } = tinycolor(hex).toRgb()
    return { red, green, blue }
  }

  return (
    <>
      <Flex mt={5}>
        <FieldsetField label='Type' mr={3} flex={'0 0 100px'}>
          <Select
            menuPosition='fixed'
            value={{ label: type, value: type }}
            options={[
              { label: 'HEX', value: 'HEX' },
              { label: 'RGB', value: 'RGB' }
            ]}
            onChange={({ value }) => setType(value)}
          />
        </FieldsetField>

        {type === 'HEX' && (
          <FieldsetField
            label='HEX code'
            isChanged={previousHex !== hex && previousHex !== `#${hex}`}
            error={isInvalidHex && 'Hex is invalid'}
          >
            <Input
              placeholder='Eg. #000000'
              type='text'
              value={hex}
              onChange={(e) => setHex(e.target.value)}
              onBlur={() => {
                if (!isInvalidHex) return
                setHex(rgbToHex(RGBColor))
              }}
            />
          </FieldsetField>
        )}

        {type === 'RGB' && (
          <Grid templateColumns='repeat(3, 1fr)' gap={3}>
            {rgbMapping.map(({ label, id }: { label: string; id: RGBID }) => (
              <GridItem colSpan={1} key={id}>
                <FieldsetField label={label} isChanged={RGBColor[id] !== previousRGBColor[id]}>
                  <NumberField
                    max={255}
                    value={RGBColor[id]}
                    onChange={(value) => onChange(mergeDeep(currentStyle, { props: { ...RGBColor, [id]: value } }))}
                  />
                </FieldsetField>
              </GridItem>
            ))}
          </Grid>
        )}
      </Flex>

      <Box mt={5}>
        <FieldsetField label='Opacity' isChanged={previousAlpha !== alpha} w={28}>
          <OpacityField
            value={alpha}
            onChange={(alpha) => {
              onChange(
                mergeDeep(currentStyle, {
                  props: { ...currentStyle.props, alpha }
                })
              )
            }}
          />
        </FieldsetField>
      </Box>
    </>
  )
}

export default ColorsFieldset
