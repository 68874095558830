import DatasourceHttpSegmentTable from './DatasourceHttpSegmentTable.js'
import DatasourceQuery from './DatasourceQuery.js'
import DatasourceVariables from './DatasourceVariables.js'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import DatasourceHttpSegmentFlex from './DatasourceHttpSegmentFlex.js'
import { DatasourceModel, isDeepEquals } from '@sitecore-feaas/sdk'
import { Dot } from '../Dot.js'
import { omit } from 'lodash-es'
import { DataSettings } from '@sitecore-feaas/sdk'

const DatasourceHttpSegment = ({
  currentDatasource,
  isBlock,
  previousDatasource,
  isFetch,
  isGraphql,
  httpSegmentIndex,
  onChange,
  setHttpSegmentIndex
}: {
  currentDatasource: DatasourceModel
  isBlock: boolean
  httpSegmentIndex: number
  isFetch: boolean
  onChange: any
  isGraphql: boolean
  setHttpSegmentIndex: any
  previousDatasource: DatasourceModel
}) => {
  const Component = isBlock ? DatasourceHttpSegmentTable : DatasourceHttpSegmentFlex

  const onChangeKey = (oldKey: string, newKey: string, property: 'headers' | 'params' | 'body') => {
    onChange(
      currentDatasource.change({
        settings: {
          ...currentDatasource.settings,
          [property]: {
            ...omit(currentDatasource.settings[property], oldKey),
            [newKey]: currentDatasource.settings[property][oldKey]
          }
        }
      })
    )
  }

  const onChangeValue = (key: string, newValue: string, property: 'headers' | 'params' | 'body') => {
    onChange(
      currentDatasource.change({
        settings: {
          ...currentDatasource.settings,
          [property]: { ...currentDatasource.settings[property], [key]: newValue }
        }
      })
    )
  }

  const onCreate = (key: string, value: string, property: 'headers' | 'params' | 'body') =>
    onChange(
      currentDatasource.change({
        settings: {
          ...currentDatasource.settings,
          [property]: { ...currentDatasource.settings[property], [key]: value }
        }
      })
    )

  const onDelete = (key: string, property: 'headers' | 'params' | 'body') =>
    onChange(
      currentDatasource.change({
        settings: { ...currentDatasource.settings, [property]: omit(currentDatasource.settings[property], [key]) }
      })
    )

  return (
    <Tabs mt='6' index={httpSegmentIndex} onChange={setHttpSegmentIndex}>
      <TabList>
        <Tab>
          Params {!isDeepEquals(currentDatasource.settings.params, previousDatasource.settings.params) && <Dot />}
        </Tab>

        <Tab>
          Headers {!isDeepEquals(currentDatasource.settings.headers, previousDatasource.settings.headers) && <Dot />}
        </Tab>

        <Tab hidden={currentDatasource.settings.method !== 'POST'}>
          Body {!isDeepEquals(currentDatasource.settings.body, previousDatasource.settings.body) && <Dot />}
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel bg='white' p='6' py={!isBlock && 2} w='full'>
          <Component
            onChangeKey={(oldKey, newKey) => onChangeKey(oldKey, newKey, 'params')}
            onChangeValue={(oldValue, newValue) => onChangeValue(oldValue, newValue, 'params')}
            onCreate={(key, value) => onCreate(key, value, 'params')}
            onDelete={(key) => onDelete(key, 'params')}
            isGraphql={isGraphql}
            isHeaders={false}
            currentDatasource={currentDatasource}
            items={currentDatasource.settings.params || {}}
            label='param'
          />
        </TabPanel>

        <TabPanel bg='white' p='6' py={!isBlock && 2} w='full'>
          <Component
            onChangeKey={(oldKey, newKey) => onChangeKey(oldKey, newKey, 'headers')}
            onChangeValue={(oldValue, newValue) => onChangeValue(oldValue, newValue, 'headers')}
            onCreate={(key, value) => onCreate(key, value, 'headers')}
            onDelete={(key) => onDelete(key, 'headers')}
            currentDatasource={currentDatasource}
            isGraphql={isGraphql}
            isHeaders={true}
            items={currentDatasource.settings.headers || {}}
            label='header'
          />
        </TabPanel>

        {currentDatasource.settings.method !== 'GET' &&
          currentDatasource.settings.method !== 'HEAD' &&
          currentDatasource.settings.body && (
            <TabPanel bg='white' p='6' py={!isBlock && 2} w='full'>
              {isFetch && (
                <Component
                  onChangeKey={(oldKey, newKey) => onChangeKey(oldKey, newKey, 'body')}
                  onChangeValue={(oldValue, newValue) => onChangeValue(oldValue, newValue, 'body')}
                  onCreate={(key, value) => onCreate(key, value, 'body')}
                  onDelete={(key) => onDelete(key, 'body')}
                  currentDatasource={currentDatasource}
                  isGraphql={isGraphql}
                  isHeaders={false}
                  items={currentDatasource.settings.body || {}}
                  label='body entry'
                />
              )}

              {isGraphql && (
                <>
                  <DatasourceQuery
                    currentDatasource={currentDatasource}
                    previousDatasource={previousDatasource}
                    onChange={onChange}
                    isBlock={isBlock}
                  />

                  <DatasourceVariables
                    currentDatasource={currentDatasource}
                    previousDatasource={previousDatasource}
                    onChange={onChange}
                  />
                </>
              )}
            </TabPanel>
          )}
      </TabPanels>
    </Tabs>
  )
}

export default DatasourceHttpSegment
