import React, { useState } from 'react'
import {
  Box,
  Divider,
  Icon,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Input,
  InputGroup,
  InputLeftElement,
  Button
} from '@chakra-ui/react'
import { mdiChevronDown, mdiMagnify } from '@mdi/js'
import { Style } from '@sitecore-feaas/sdk'

import PickerStylePreview from './PickerStylePreview.js'
import { CompactSelect } from '../Select.js'
import PickerThemesCombo from './PickerThemesCombo.js'

const SCOPE_OPTIONS = [
  { label: 'Current theme', value: 'theme' },
  { label: 'All available', value: 'all_available' }
]

const PickerElementSelector = ({
  element,
  allElements,
  elementStyle,
  rules,
  customRules = [],
  classList,
  themeClassList,
  theme,
  usedCombo,
  defaultCombo,
  context,
  onChange,
  onComboChange
}: {
  element: Style.Rule.Element
  allElements: Style.Rule.Element[]
  elementStyle: Style.Rule.Element
  rules: Style.Rule[]
  customRules?: Style.Rule[]
  classList: string[]
  themeClassList: string[]
  theme: Style.Rule<'theme'>
  usedCombo: Style.Theme.Combo
  defaultCombo: Style.Theme.Combo
  context: Style.Context
  onChange: (style: Style.Rule | Style.Empty) => void
  onComboChange: (combo: Style.Theme.Combo) => void
}) => {
  const assignedCombo = Style.Set.getContextAssignedCombo(rules, classList)
  const themeCombos = Style.Set.getElementsThemeCombos(allElements, theme)
  const aspects = Style.Set.getContextAspects(rules, context, themeClassList, customRules)

  const [scope, setScope] = useState(element ? 'all_available' : 'theme')
  const [term, setTerm] = useState('')

  const getStyleFromId = (id: string): Style.Rule => rules.find((style) => style.details.id === id)
  return (
    <Menu matchWidth>
      {({ isOpen }) => (
        <>
          <MenuButton w='100%' position='relative' aria-label='Select element'>
            {elementStyle ? (
              <PickerStylePreview
                w='100%'
                rules={rules}
                customRules={aspects}
                style={elementStyle}
                isSelected={false}
                isDefault={defaultCombo === assignedCombo || (!element && !assignedCombo)}
                pr={7}
              />
            ) : (
              <Button variant='dialogPane' as='div' pr={7} pl={3} h={10} fontWeight='normal'>
                No styles available
              </Button>
            )}
            <Icon
              transition='all .2s ease'
              position='absolute'
              top={0}
              right={2}
              height='100%'
              w={5}
              color={'blackAlpha.500'}
              style={{ rotate: isOpen ? '180deg' : '' }}
            >
              <path d={mdiChevronDown} />
            </Icon>
          </MenuButton>
          <MenuList py={0} aria-label='Element list'>
            <CompactSelect
              value={SCOPE_OPTIONS.find(({ value }) => value === scope)}
              options={SCOPE_OPTIONS}
              onChange={({ value }) => setScope(value)}
            />
            <Divider />
            {(scope !== 'theme' || !!themeCombos.length) && ( // Hide search element when current theme has no element combos
              <>
                <InputGroup>
                  <InputLeftElement h={8} color='blackAlpha.500'>
                    <Icon w='5' h='5'>
                      <path d={mdiMagnify} />
                    </Icon>
                  </InputLeftElement>
                  <Input h={8} variant='unstyle' placeholder='Search' onChange={(e) => setTerm(e.target.value)} />
                </InputGroup>
                <Divider />
              </>
            )}
            <Box m={2}>
              {scope === 'theme'
                ? themeCombos.length
                  ? themeCombos
                      .filter(({ refId }) => Style.Rule.matchesSearchQuery(getStyleFromId(refId), term))
                      .map((combo) => (
                        <PickerThemesCombo
                          key={combo.id}
                          rules={rules}
                          combo={combo}
                          isApplied={assignedCombo == combo}
                          isSelected={usedCombo === combo}
                          isDefault={defaultCombo == combo}
                          onChange={(combo) => {
                            if (elementStyle) onChange({ type: elementStyle.type })
                            onComboChange(combo)
                          }}
                          mb={2}
                        />
                      ))
                  : 'No element styles have been added to the current theme'
                : allElements
                    .filter((element) => Style.Rule.matchesSearchQuery(element, term))
                    .map((style, index) => (
                      <PickerStylePreview
                        key={index}
                        style={style}
                        rules={rules}
                        isSelected={element?.details?.id === style.details.id}
                        onChange={onChange}
                        mb={2}
                      />
                    ))}
            </Box>
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default PickerElementSelector
