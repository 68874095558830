import { Box, BoxProps, Divider, HStack, Icon, IconButton, Text } from '@chakra-ui/react'
import { mdiChevronLeft, mdiChevronRight, mdiClose, mdiDelete } from '@mdi/js'
import { PropsWithChildren } from 'react'

export function PickerWrapper({
  title,
  children,
  onBack,
  onClose,
  iconRight,
  variant,
  ...props
}: PropsWithChildren<
  BoxProps & {
    onBack?: () => void
    iconRight?: any
    onClose?: () => void
    title: string
    variant?: string
  }
>) {
  return (
    <Box h='full' {...props}>
      <HStack
        padding='2'
        alignItems={'center'}
        spacing={'2'}
        height='50px'
        boxShadow={variant == 'pages' ? 0 : 'base'}
        borderBottom={variant == 'pages' ? '1px solid' : 0}
        borderColor='gray.200'
        divider={<Divider orientation='vertical' height='100%' />}
        position={'relative'}
      >
        {onBack && (
          <>
            <IconButton
              zIndex={1}
              position={'relative'}
              onClick={onBack}
              size='sm'
              icon={
                <Icon fontSize='icon-lg'>
                  <path d={mdiChevronLeft}></path>
                </Icon>
              }
              aria-label='Back'
            />
          </>
        )}
        <Text
          textAlign={'center'}
          fontWeight={'600'}
          width='100%'
          ml={onBack ? '-49px' : ''}
          mr={onClose || iconRight ? '-49px' : ''}
        >
          {title}
        </Text>

        {onClose && (
          <>
            <IconButton
              zIndex={1}
              position={'relative'}
              onClick={onClose}
              size='sm'
              icon={
                <Icon fontSize='icon-lg'>
                  <path d={mdiClose}></path>
                </Icon>
              }
              aria-label='Back'
            />
          </>
        )}
        {iconRight}
      </HStack>
      <Box h='calc(100% - 50px)'>{children}</Box>
    </Box>
  )
}
