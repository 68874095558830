import { useState } from 'react'
import { Box, FormControl, FormLabel, Text } from '@chakra-ui/react'
import ButtonGroupSwitch from '../../ButtonGroupSwitch.js'

function SwitchGroupField<T>({
  value,
  label,
  required,
  onChange,
  error,
  children,
  mb,
  mt,
  my,
  ...more
}: {
  value: T
  onChange: (value: T) => void
  label?: string
  required?: boolean
  error?: string
  onBlur?: any
  disabled?: boolean
  children: any[]
  [prop: string]: any
}) {
  const [showErrors, setShowErrors] = useState(false)

  return (
    <FormControl isInvalid={!!error} {...{ mb, mt, my }}>
      {label != null && (
        <FormLabel>
          {label}

          {required && (
            <Box display='inline' color='red' ml='1'>
              *
            </Box>
          )}
        </FormLabel>
      )}

      <ButtonGroupSwitch value={value} onChange={onChange} {...more}>
        {children}
      </ButtonGroupSwitch>

      {showErrors && (
        <Text pos='absolute' mt={1} right='0' variant='microcopy' color='red' fontSize='10px'>
          {error}
        </Text>
      )}
    </FormControl>
  )
}

export default SwitchGroupField
