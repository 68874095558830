import { Button } from '@chakra-ui/react'
import { useState } from 'react'
import SwitchGroupField from '../../styles/fields/SwitchGroupField.js'
import DialogAttributesStatic from './DialogAttributesStatic.js'
import type { DialogAttributeGeneric } from './Dialog.js'

const DialogAttributesFallback = (props: DialogAttributeGeneric) => {
  const { above, below, type, value, jsonpath, onChange } = props

  const getIndex = () => {
    if (jsonpath) return 1
    if (value != null) return 2
    return 0
  }
  const [index, setIndex] = useState(getIndex)

  const onIndexChange = (newIndex: number) => {
    if (newIndex == index) return
    if (newIndex == 0) {
      onChange(null)
    }
    if (newIndex == 1) {
      onChange(false)
    }
    if (newIndex == 2) {
      onChange(true)
    }
    setIndex(newIndex)
  }

  return (
    <>
      {above?.({ ...props, index, setIndex: onIndexChange })}
      <SwitchGroupField
        value={index}
        label='What to do if data is missing?'
        onChange={onIndexChange}
        alignSelf={'flex-start'}
      >
        <Button size='sm'>Nothing</Button>
        <Button size='sm'>Hide</Button>
        <Button size='sm'>Show fallback</Button>
      </SwitchGroupField>
      {index == 2 && <DialogAttributesStatic {...props} />}
      {below?.({ ...props, index, setIndex: onIndexChange })}
    </>
  )
}
export default DialogAttributesFallback
