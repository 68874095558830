import { isDeepEquals, Style, Unformatted } from '@sitecore-feaas/sdk'
import { Box, Button, Grid, GridItem, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { mergeDeep } from '@sitecore-feaas/sdk'
import SizeField from '../fields/SizeField.js'
import SwitchGroupField from '../fields/SwitchGroupField.js'
import FieldsetField from '../../FieldsetField.js'
import { StyleFormProps } from './index.js'

const SpacingFieldset = ({ rule, currentRule, isActive, onChange }: StyleFormProps<'spacing'>) => {
  const {
    props: { paddingTop, paddingBottom, paddingLeft, paddingRight, rowGap, columnGap }
  } = currentRule

  const {
    props: {
      paddingTop: oldPaddingTop,
      paddingBottom: oldPaddingBottom,
      paddingLeft: oldPaddingLeft,
      paddingRight: oldPaddingRight,
      rowGap: oldRowGap,
      columnGap: oldColumnGap
    }
  } = rule

  const allEqualTo = (lengths: Style.Length[], target: Style.Length) =>
    lengths.every((item) => isDeepEquals(item, target))

  const getIndex = () => {
    if (allEqualTo([paddingBottom, paddingLeft, paddingRight], paddingTop) && allEqualTo([rowGap], columnGap)) {
      return 0
    }

    if (allEqualTo([paddingBottom], paddingTop) && allEqualTo([paddingRight], paddingLeft)) {
      return 1
    }

    return 2
  }

  const [index, setIndex] = useState(getIndex)

  useEffect(() => setIndex(getIndex), [rule, isActive])

  const onUpdate = (changes: Unformatted<Style.Spacing.Props>) => onChange(mergeDeep(currentRule, { props: changes }))

  useEffect(() => {
    if (index === 0) {
      onUpdate({
        paddingTop: paddingTop,
        paddingBottom: paddingTop,
        paddingLeft: paddingTop,
        paddingRight: paddingTop,
        columnGap: rowGap,
        rowGap: rowGap
      })
    } else if (index === 1) {
      onUpdate({
        paddingLeft: paddingRight,
        paddingBottom: paddingTop,
        columnGap: columnGap,
        rowGap: rowGap
      })
    }
  }, [index])

  return (
    <Box>
      <SwitchGroupField value={index} onChange={setIndex} mb={6} display={'flex'} maxWidth={'fit-content'}>
        <Button>Sync</Button>
        <Button>Sides</Button>
        <Button>Custom</Button>
      </SwitchGroupField>

      <Grid templateColumns='repeat(2, 1fr)' columnGap={3} rowGap={5}>
        {index === 0 && (
          <>
            <GridItem colSpan={1}>
              <FieldsetField label='Padding' isChanged={!isDeepEquals(paddingTop, oldPaddingTop)}>
                <SizeField
                  size={!isActive ? 'sm' : null}
                  length={paddingTop}
                  onChange={(value) => {
                    onUpdate({
                      paddingTop: value,
                      paddingLeft: value,
                      paddingRight: value,
                      paddingBottom: value
                    })
                  }}
                />
              </FieldsetField>
            </GridItem>
            <GridItem colSpan={1}>
              <FieldsetField label='Gaps' isChanged={!isDeepEquals(rowGap, oldRowGap)}>
                <SizeField
                  size={!isActive ? 'sm' : null}
                  length={rowGap}
                  onChange={(value) => onUpdate({ rowGap: value, columnGap: value })}
                />
              </FieldsetField>
            </GridItem>
          </>
        )}

        {index === 1 && (
          <>
            <GridItem colSpan={1}>
              <FieldsetField label='Vertical padding' isChanged={!isDeepEquals(paddingTop, oldPaddingTop)}>
                <SizeField
                  size={!isActive ? 'sm' : null}
                  length={paddingTop}
                  onChange={(value) => {
                    onUpdate({
                      paddingTop: value,
                      paddingBottom: value
                    })
                  }}
                />
              </FieldsetField>
            </GridItem>

            <GridItem colSpan={1}>
              <FieldsetField label='Vertical gap' isChanged={!isDeepEquals(rowGap, oldRowGap)}>
                <SizeField
                  size={!isActive ? 'sm' : null}
                  length={rowGap}
                  onChange={(value) => onUpdate({ rowGap: value })}
                />
              </FieldsetField>
            </GridItem>
            <GridItem colSpan={1}>
              <FieldsetField label='Horizontal padding' isChanged={!isDeepEquals(paddingLeft, oldPaddingLeft)}>
                <SizeField
                  size={!isActive ? 'sm' : null}
                  length={paddingLeft}
                  onChange={(value) => {
                    onUpdate({
                      paddingLeft: value,
                      paddingRight: value
                    })
                  }}
                />
              </FieldsetField>
            </GridItem>
            <GridItem colSpan={1}>
              <FieldsetField label='Horizontal gap' isChanged={!isDeepEquals(columnGap, oldColumnGap)}>
                <SizeField
                  size={!isActive ? 'sm' : null}
                  length={columnGap}
                  onChange={(value) => onUpdate({ columnGap: value })}
                />
              </FieldsetField>
            </GridItem>
          </>
        )}

        {index === 2 && (
          <>
            <GridItem colSpan={1}>
              <FieldsetField label='Top padding' isChanged={!isDeepEquals(paddingTop, oldPaddingTop)}>
                <SizeField
                  size={!isActive ? 'sm' : null}
                  length={paddingTop}
                  onChange={(value) => onUpdate({ paddingTop: value })}
                />
              </FieldsetField>
            </GridItem>

            <GridItem colSpan={1}>
              <FieldsetField label='Right padding' isChanged={!isDeepEquals(paddingRight, oldPaddingRight)}>
                <SizeField
                  size={!isActive ? 'sm' : null}
                  length={paddingRight}
                  onChange={(value) => onUpdate({ paddingRight: value })}
                />
              </FieldsetField>
            </GridItem>

            <GridItem colSpan={1}>
              <FieldsetField label='Bottom padding' isChanged={!isDeepEquals(paddingBottom, oldPaddingBottom)}>
                <SizeField
                  size={!isActive ? 'sm' : null}
                  length={paddingBottom}
                  onChange={(value) => onUpdate({ paddingBottom: value })}
                />
              </FieldsetField>
            </GridItem>

            <GridItem colSpan={1}>
              <FieldsetField label='Left padding' isChanged={!isDeepEquals(paddingLeft, oldPaddingLeft)}>
                <SizeField
                  size={!isActive ? 'sm' : null}
                  length={paddingLeft}
                  onChange={(value) => onUpdate({ paddingLeft: value })}
                />
              </FieldsetField>
            </GridItem>

            <GridItem colSpan={1}>
              <FieldsetField label='Vertical gap' isChanged={!isDeepEquals(rowGap, oldRowGap)}>
                <SizeField
                  size={!isActive ? 'sm' : null}
                  length={rowGap}
                  onChange={(value) => onUpdate({ rowGap: value })}
                />
              </FieldsetField>
            </GridItem>
            <GridItem colSpan={1}>
              <FieldsetField label='Horizontal gap' isChanged={!isDeepEquals(columnGap, oldColumnGap)}>
                <SizeField
                  size={!isActive ? 'sm' : null}
                  length={columnGap}
                  onChange={(value) => onUpdate({ columnGap: value })}
                />
              </FieldsetField>
            </GridItem>
          </>
        )}
      </Grid>
    </Box>
  )
}

export default SpacingFieldset
