import React, { useState } from 'react'
import { isDate, Optional } from '@sitecore-feaas/sdk'
import { List } from '@chakra-ui/react'
import DatasourceTreeLeafWrapper, { DataLeafProps } from './DatasourceTreeLeafWrapper.js'
import { default as styled } from '@emotion/styled'

const Item = (styled as any).li`
  &[data-action='rooting'][data-action='rooting'] > ul {
    border-left-color: var(--chakra-colors-green-300);
  }
`

export type ConfigureAction =
  | 'mapping'
  | 'trimming'
  | 'scoping'
  | 'rooting'
  | 'repeating'
  | 'nesting'
  | 'repeating-mapping'
  | 'indicating'

export interface BranchParams {
  datasourceName?: string
  prefix: string
  bits: string[]
  path: string | null
  label?: string
  intent: ConfigureAction
  action: ConfigureAction | 'reporting' | null
  isVerbose: boolean
  scopes: string[]
  validator?: (value: any) => string
  onConfigure: (value: string | null) => void
  data: any
  renderLeafTree?: (
    props: DataLeafProps,
    collapsedLeaves: string[],
    onCollapsedLeavesChange: (leaves: string[]) => void
  ) => JSX.Element
  collapsedLeaves?: string[]
  onCollapsedLeavesChange?: (leaves: string[]) => void
  isValid?: boolean
}

export default function DatasourceTree({
  datasourceName,
  validator = null,
  prefix = '$',
  label,
  path = undefined,
  intent,
  isVerbose,
  scopes = [],
  onConfigure = undefined,
  data,
  renderLeafTree
}: Optional<
  Pick<
    BranchParams,
    | 'validator'
    | 'prefix'
    | 'label'
    | 'path'
    | 'intent'
    | 'isVerbose'
    | 'scopes'
    | 'onConfigure'
    | 'data'
    | 'renderLeafTree'
    | 'datasourceName'
  >,
  'scopes' | 'prefix' | 'validator' | 'path'
>) {
  const [collapsedLeaves, setCollapsedLeaves] = useState([])
  return (
    <List userSelect='none'>
      {ConfigureDataBranch({
        datasourceName,
        validator,
        prefix,
        label: label || prefix,
        bits: [prefix],
        path,
        intent,
        action: null,
        isVerbose,
        scopes,
        onConfigure,
        data,
        collapsedLeaves,
        onCollapsedLeavesChange: setCollapsedLeaves,
        renderLeafTree
      })}
    </List>
  )
}

function walk(callback: (data: any, prop: string) => any, data: any, key: string) {
  if (data != null && Array.isArray(data)) {
    for (var prop of data) {
      callback(data[prop], prop)
      walk(callback, data[prop], prop)
    }
  } else if (data != null && typeof data == 'object') {
    for (var prop1 in data) {
      callback(data[prop1], prop1)
      walk(callback, data[prop1], prop1)
    }
  } else {
    callback(data, key)
  }
}

function isPrimitive(value: any) {
  return (
    !(value != null && (Array.isArray(value) || typeof value == 'object')) ||
    (value?.type == 'doc' && Array.isArray(value.content))
  )
}

// check if paths equal, or if left path is qualifying the right with array expression ([], .*)
function matchPath(left: string, right: string) {
  return left.startsWith(right) && left.substring(right.length).match(/^(|\.\*|\[[^]+\])$/)
}

// this following function is not great, but it handles all the cases
// as long as this mess is contained in here, it works alright.
function ConfigureDataBranch({
  datasourceName,
  validator,
  prefix,
  data,
  bits,
  path,
  label = prefix,
  intent,
  action,
  isVerbose,
  scopes = [],
  onConfigure,
  renderLeafTree,
  collapsedLeaves,
  onCollapsedLeavesChange
}: BranchParams): JSX.Element {
  var currentPath = bits.join('.')
  var descendIntent = intent

  if (!action && scopes.find((scope) => matchPath(scope, currentPath))) {
    action = 'nesting'
  }
  if (path && matchPath(path, bits.join('.'))) {
    action = intent == 'trimming' ? 'rooting' : 'indicating'
    //    descendIntent = false
  }
  if (typeof data == 'object' && data != null && !isPrimitive(data) && !isDate(data)) {
    if (!action && intent == 'trimming' && !isPrimitive(data)) {
      action = 'trimming'
    }
    if (!action && intent == 'scoping' && !isPrimitive(data)) {
      action = 'scoping'
    }
    var entries = Object.entries(data)
    if (entries.length > 0 && Array.isArray(data)) {
      bits = bits.concat('*')
      if (intent == 'repeating' && !action) {
        action = 'repeating'
      }
      if (!isPrimitive(entries[0][1])) {
        entries = Object.entries(entries[0][1])
      } else {
        descendIntent = null
      }
      // go deeper quicker by skipping objects with a single key and going into arrays direcly
    } else if (entries.length == 1 && intent != 'trimming' && intent != 'scoping') {
      return ConfigureDataBranch({
        datasourceName,
        prefix: (bits.length == 1 ? '' : prefix + '.') + entries[0][0],
        validator,
        path,
        data: entries[0][1],
        bits: bits.concat(entries[0][0]),
        intent,
        action,
        isVerbose,
        onConfigure,
        scopes,
        renderLeafTree,
        collapsedLeaves,
        onCollapsedLeavesChange
      })
    }
  }

  // This was to prevent from showing nodes that don't contain arrays when repeating.
  // It has been commented out since UI has changed
  // if (intent == 'repeating') {
  //   // always show array children, just disabled
  //   var hasArrays = action == 'repeating' || action == 'nesting'
  //   walk(
  //     (value: any) => {
  //       if (Array.isArray(value)) hasArrays = true
  //     },
  //     data,
  //     ''
  //   )
  //
  //   if (!hasArrays && action != 'repeating' && action != 'nesting' && action != 'indicating') {
  //     if (isPrimitive(data)) {
  //       var error = 'Only collections can be used for repeating'
  //     } else {
  //       var error = 'Does not contain collections for repeating'
  //     }
  //   }
  //
  //   if (!hasArrays || action == 'repeating') descendIntent = null
  // }
  if (intent == 'scoping' && action != 'scoping') {
    if (Array.isArray(data)) currentPath += '.*' // ugh lol
    if (action == 'indicating') {
      descendIntent = null
    } else if (Array.isArray(data)) {
      descendIntent = null
      if (action != 'nesting') {
        if (scopes.length) {
          error = 'This collection is not in the current scope'
        } else {
          error = 'Use repeating on this collection to map its values'
        }
      } else {
        action = 'scoping'
      }
    }
  }
  if (intent == 'mapping' && (!action || action == 'nesting')) {
    if (action == 'nesting' && isPrimitive(Object.entries(data)[0][1])) {
      action = 'repeating-mapping'
      descendIntent = null
      currentPath += '.*' // ugh lol
    } else if (isPrimitive(data)) {
      var warning = validator ? validator(data) : null
      action = 'mapping'
    } else {
      if (Array.isArray(data) && action != 'nesting') {
        if (scopes.length) {
          descendIntent = null
          error = 'This collection is not in the current scope'
        } else {
          //  error = 'Use repeating on this collection to map its values'
        }
      }
      var hasPrimitives = true
      walk(
        (value) => {
          if (isPrimitive(value)) hasPrimitives = true
        },
        data,
        ''
      )

      if (!hasPrimitives) {
        var error = 'Does not contain primitive value'
      }
    }
  }

  const DataLeaf = (props: DataLeafProps) => {
    return renderLeafTree ? (
      renderLeafTree(props, collapsedLeaves, onCollapsedLeavesChange)
    ) : (
      <DatasourceTreeLeafWrapper {...props}>{label}</DatasourceTreeLeafWrapper>
    )
  }

  return (
    <Item key={prefix} data-action={action} data-intent={intent}>
      <DataLeaf
        onClick={() => onConfigure(currentPath)}
        warning={warning}
        onClear={() => onConfigure(null)}
        label={label == '$' ? datasourceName : label}
        action={action}
        error={error}
        isVerbose={isVerbose}
        path={currentPath}
      />

      {entries && descendIntent && entries.length > 0 && (
        <List paddingLeft='19px' borderLeft='1px solid var(--chakra-colors-blackAlpha-200)'>
          {entries.map(([key, value]) =>
            ConfigureDataBranch({
              datasourceName,
              prefix: key,
              validator,
              path,
              data: value,
              bits: bits.concat(key),
              intent,
              action: null,
              isVerbose,
              scopes,
              onConfigure,
              renderLeafTree,
              collapsedLeaves,
              onCollapsedLeavesChange
            })
          )}
        </List>
      )}
    </Item>
  )
}
