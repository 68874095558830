import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import EntityProvider from '../entities/EntityProvider.js'
import { Flex, useToast, VStack, Text, Grid, GridItem, Button, Box } from '@chakra-ui/react'
import DatasourceConflictDefinition from './DatasourceConflictDefinition.js'
import DatasourceGeneratorSelect from './DatasourceGeneratorSelect.js'
import DatasourceTreeRoot from './DatasourceTreeRoot.js'
import DatasourceName from './DatasourceName.js'
import DatasourceDescription from './DatasourceDescription.js'
import DatasourceSample from './DatasourceSample.js'
import DatasourceDetails from './DatasourceDetails.js'
import DatasourceHttpSegment from './DatasourceHttpSegment.js'
import { DatasourceModel } from '@sitecore-feaas/sdk'
import useConfirmation from '../../hooks/useConfirmation.js'
import DatasourceMethod from './DatasourceMethod.js'
import DatasourceEndpoint from './DatasourceEndpoint.js'
import DatasourceFetchButton from './DatasourceFetchButton.js'
import FieldsetField from '../FieldsetField.js'
import ButtonGroupSwitch from '../ButtonGroupSwitch.js'
import { DataSettings } from '@sitecore-feaas/clientside/headless'

export const Headers = [
  'A-IM',
  'Accept',
  'Accept-Charset',
  'Accept-Encoding',
  'Accept-Language',
  'Accept-Datetime',
  'Access-Control-Request-Method',
  'Access-Control-Request-Headers',
  'Authorization',
  'Cache-Control',
  'Connection',
  'Content-Length',
  'Content-Type',
  'Cookie',
  'Date',
  'Expect',
  'Forwarded',
  'From',
  'Host',
  'If-Match',
  'If-Modified-Since',
  'If-None-Match',
  'If-Range',
  'If-Unmodified-Since',
  'Max-Forwards',
  'Origin',
  'Pragma',
  'Proxy-Authorization',
  'Range',
  'Referer',
  'TE',
  'User-Agent',
  'Upgrade',
  'Via',
  'Warning'
]

export enum HttpSegment {
  PARAMS = 0,
  HEADER = 1,
  BODY = 2
}

export enum SampleGeneration {
  PASTE = 0,
  FETCH = 1,
  GRAPHQL = 2,
  EXTERNAL = 3
}

const Datasource = ({
  datasource,
  variant = 'block',
  isInteractive = true,
  onChangeCurrent,
  onDiscard,
  componentCount = 0
}: {
  datasource: DatasourceModel
  variant?: 'block' | 'open'
  isInteractive?: boolean
  onChangeCurrent?: (datasource: DatasourceModel) => void
  onDiscard?: () => void
  componentCount?: number
}) => {
  const [currentDatasource, onChange] = useState(datasource.clone().snapshot())
  const [httpSegmentIndex, setHttpSegmentIndex] = useState(HttpSegment.HEADER)
  const [isSampleInvalid, setIsSampleInvalid] = useState(false)
  const calculateSampleGenerationIndex = () => {
    if (currentDatasource.type !== 'internal') return SampleGeneration.EXTERNAL
    if (!currentDatasource.settings.url) return SampleGeneration.PASTE
    if (currentDatasource.settings.url && currentDatasource.settings.body && currentDatasource.settings.body.query)
      return SampleGeneration.GRAPHQL
    if (currentDatasource.settings.url) return SampleGeneration.FETCH
  }
  const [sampleGenerationIndex, setSampleGenerationIndex] = useState(calculateSampleGenerationIndex)

  const toast = useToast()
  const ref = useRef(null)
  const hasErrors = !currentDatasource.name || isSampleInvalid

  const hasChanges = !datasource.isEqual(currentDatasource)
  const isBlock = variant === 'block'
  const hasComponents = componentCount > 0

  useEffect(() => {
    onChange(datasource)
  }, [datasource])

  useEffect(() => {
    if (currentDatasource.settings.method === 'GET' && httpSegmentIndex === HttpSegment.BODY) {
      setHttpSegmentIndex(HttpSegment.PARAMS)
    }
  }, [currentDatasource.settings.method])

  const onSave = useCallback(async () => {
    if (hasComponents && currentDatasource.isConflictingTowards(datasource)) {
      onSaveWithConfirmation()

      return
    }

    currentDatasource.change({ settings: DataSettings(currentDatasource.export().settings) }).save()
  }, [currentDatasource, datasource])

  const onSaveWithConfirmation = useConfirmation(
    async () => {
      currentDatasource.change({ settings: DataSettings(currentDatasource.export().settings) }).save()

      toast({
        duration: 4000,
        status: 'success',
        title: 'Changes saved'
      })
    },
    {
      title: `The data source you are saving is being used by ${componentCount} component${
        componentCount === 1 ? '' : 's'
      }`,
      button: 'Save',
      body: (
        <Text>
          The changes you made in <strong>{currentDatasource.name}</strong>'s structure might break the components that
          are using it. Are you sure you want to save it?
        </Text>
      )
    },
    [currentDatasource]
  )

  const onChangeSampleGenerationIndex = (index: number) => {
    if (index === SampleGeneration.GRAPHQL) {
      onChange(
        currentDatasource.change({
          settings: {
            body: { query: '', variables: {} },
            jsonpath: currentDatasource.settings.jsonpath || '$',
            url: currentDatasource.settings.url || '',
            params: currentDatasource.settings.params || {},
            method: 'POST',
            headers: { ...(currentDatasource.settings.headers || {}), 'Content-Type': 'application/json' }
          }
        })
      )

      setHttpSegmentIndex(HttpSegment.BODY)
    }

    if (index === SampleGeneration.FETCH) {
      onChange(
        currentDatasource.change({
          settings: {
            body: {},
            jsonpath: currentDatasource.settings.jsonpath || '$',
            url: currentDatasource.settings.url || '',
            headers: currentDatasource.settings.headers || {},
            method: currentDatasource.settings.method || 'GET',
            params: currentDatasource.settings.params || {}
          }
        })
      )
    }
    // DO NOT WIPE OUT THE SETTINGS WHEN SWITCHING TO CUSTOM JSON
    // if (index === SampleGeneration.PASTE) {
    //   onChange(currentDatasource.change({ settings: DataSettings({ jsonpath: '$' }) }))
    // }

    setSampleGenerationIndex(index)
  }

  return (
    <EntityProvider
      id={currentDatasource.id}
      onDelete={currentDatasource.type !== 'xmTemplate' && isBlock ? () => currentDatasource.delete() : null}
      safeDeleteLabel={
        <>
          Are you sure you want to delete <strong>{currentDatasource.name}</strong>? Components that use it may stop
          working.
        </>
      }
      hasChanges={hasChanges}
      onDiscard={() => {
        if (!isBlock) onDiscard()
        else onChange(datasource)
      }}
      isNonEditable={!isBlock}
      mode={isBlock ? 'block' : 'open'}
      onSave={onSave}
      preventSaveToast={hasComponents && currentDatasource.isConflictingTowards(datasource)}
      hasErrors={hasErrors}
      invalidNonEmptyFields={[{ title: 'Name', value: currentDatasource.name }]
        .filter(({ title, value }) => !value)
        .map(({ title }) => title)}
      emptyRequiredFields={[{ title: 'Sample', value: currentDatasource.sample }]
        .filter(({ title, value }) => !value)
        .map(({ title }) => title)}
      isNew={currentDatasource.isNew}
      name='data source'
      ref={ref}
    >
      <DatasourceDetails
        slot='details'
        componentCount={componentCount}
        previousDatasource={datasource}
        currentDatasource={currentDatasource}
        isBlock={isBlock}
      />

      {isInteractive && (
        <Flex slot='form'>
          <VStack flex='1' alignItems='stretch' spacing='6' w='full'>
            {isBlock && (
              <>
                <DatasourceName
                  currentDatasource={currentDatasource}
                  previousDatasource={datasource}
                  isBlock={isBlock}
                  onChange={onChange}
                />

                <DatasourceDescription
                  currentDatasource={currentDatasource}
                  previousDatasource={datasource}
                  isBlock={isBlock}
                  onChange={onChange}
                />

                {currentDatasource.isInternal() && (
                  <FieldsetField label='Sample data'>
                    <ButtonGroupSwitch onChange={onChangeSampleGenerationIndex} value={sampleGenerationIndex}>
                      <Button>Paste JSON</Button>
                      <Button>Fetch from URL</Button>
                      <Button>Fetch from GraphQL</Button>
                    </ButtonGroupSwitch>
                  </FieldsetField>
                )}
              </>
            )}

            {!isBlock && currentDatasource.type === 'internal' && (
              <DatasourceGeneratorSelect
                currentDatasource={currentDatasource}
                setSampleGenerationIndex={onChangeSampleGenerationIndex}
                sampleGenerationIndex={sampleGenerationIndex}
              />
            )}

            {!currentDatasource.isInternal() && !!currentDatasource.conflictDefinition ? (
              <DatasourceConflictDefinition currentDatasource={currentDatasource} onChange={onChange} />
            ) : (
              (currentDatasource.type === 'contentHubOne' || currentDatasource.type === 'xmTemplate') && (
                <DatasourceFetchButton currentDatasource={currentDatasource} isBlock={isBlock} onChange={onChange} />
              )
            )}

            <Box
              display={
                (sampleGenerationIndex === 0 || sampleGenerationIndex === 3) &&
                (currentDatasource.isInternal() || !!!currentDatasource.conflictDefinition)
                  ? 'block'
                  : 'none'
              }
            >
              <DatasourceSample
                previousDatasource={datasource}
                currentDatasource={currentDatasource}
                isSampleInvalid={isSampleInvalid}
                setIsSampleInvalid={setIsSampleInvalid}
                isBlock={isBlock}
                onChange={onChange}
              />
            </Box>

            {(sampleGenerationIndex === 1 || sampleGenerationIndex === 2) && (
              <>
                <Grid templateColumns='repeat(12, 1fr)' gap={isBlock ? 3 : 0}>
                  <GridItem colSpan={isBlock ? 3 : 12}>
                    <DatasourceMethod
                      currentDatasource={currentDatasource}
                      previousDatasource={datasource}
                      isBlock={isBlock}
                      onChange={onChange}
                    />
                  </GridItem>

                  <GridItem colSpan={isBlock ? 8 : 12}>
                    <DatasourceEndpoint
                      currentDatasource={currentDatasource}
                      previousDatasource={datasource}
                      isBlock={isBlock}
                      onChange={onChange}
                    />
                  </GridItem>

                  <GridItem colSpan={isBlock ? 1 : 12}>
                    <DatasourceFetchButton
                      currentDatasource={currentDatasource}
                      isBlock={isBlock}
                      onChange={onChange}
                    />
                  </GridItem>
                </Grid>

                <DatasourceHttpSegment
                  onChange={onChange}
                  httpSegmentIndex={httpSegmentIndex}
                  setHttpSegmentIndex={setHttpSegmentIndex}
                  currentDatasource={currentDatasource}
                  previousDatasource={datasource}
                  isBlock={isBlock}
                  isFetch={sampleGenerationIndex === SampleGeneration.FETCH}
                  isGraphql={sampleGenerationIndex === SampleGeneration.GRAPHQL}
                />
              </>
            )}
          </VStack>

          {isBlock && (
            <DatasourceTreeRoot
              currentDatasource={currentDatasource}
              previousDatasource={datasource}
              onChange={onChange}
            />
          )}
        </Flex>
      )}
    </EntityProvider>
  )
}

export default Datasource
