import { Box, Button, Flex, Icon, IconButton, Text, Tooltip, useToast } from '@chakra-ui/react'
import { mdiCheck, mdiContentCopy } from '@mdi/js'
import { Style } from '@sitecore-feaas/sdk'
import { useRef, useState } from 'react'

const StyleCSSReference = ({
  rule,
  variant = 'block',
  rules
}: {
  rule: Style.Rule
  variant?: 'block' | 'button'
  rules: Style.Rule[]
}) => {
  const isVariable = rule.type == 'color'
  const className = (isVariable ? '--' : '') + Style.Rule.getClassName(rule, rules)

  const [checkmark, setCheckmark] = useState(false)
  const toast = useToast()

  const copyVariableTimeout = useRef(null)

  const copyVariable = () => {
    setCheckmark(true)
    navigator.clipboard.writeText(isVariable ? `var(${className})` : className)

    if (copyVariableTimeout.current) {
      clearTimeout(copyVariableTimeout.current)
    }

    copyVariableTimeout.current = setTimeout(() => {
      setCheckmark(false)
    }, 4200)

    toast({
      isClosable: true,
      duration: 4000,
      status: 'success',
      title: `CSS ${isVariable ? 'variable' : 'class'} is copied`
    })
  }

  const label = isVariable ? 'CSS variable' : 'CSS class'

  if (rule.type == 'breakpoint' || rule.type === 'collection' || rule.type === 'font') return

  if (variant === 'button') {
    return (
      <Tooltip label={className}>
        <Button
          className='clickable'
          _hover={{ bg: 'gray.50' }}
          _active={{ bg: 'gray.50' }}
          bg='white'
          variant='secondary'
          size='xs'
          aria-label={`Copy ${label}`}
          onClick={copyVariable}
        >
          {label}
          <Icon boxSize='icon-md' ml='2'>
            <path d={!checkmark ? mdiContentCopy : mdiCheck} />
          </Icon>
        </Button>
      </Tooltip>
    )
  }

  return (
    <Flex p={3} mt={3} bg='gray.100' maxW='sm' borderRadius='md' justifyContent='space-between' alignItems='center'>
      <Box>
        <Text color='blackAlpha.500' fontWeight='semibold'>
          {label}
        </Text>

        <Box mt={1} fontWeight='bold'>
          {className}
        </Box>
      </Box>

      <IconButton
        size='md'
        className='clickable'
        variant='minimal'
        aria-label={`Copy ${label}`}
        onClick={copyVariable}
        mr={1}
        icon={
          <Icon boxSize='icon-xl'>
            <path d={!checkmark ? mdiContentCopy : mdiCheck} />
          </Icon>
        }
      />
    </Flex>
  )
}

export default StyleCSSReference
