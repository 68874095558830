import { Button, Grid, GridItem, Switch, Text, FormLabel } from '@chakra-ui/react'
import { ReactElement, useEffect, useState } from 'react'
import { Unformatted } from '@sitecore-feaas/sdk'
import { Style } from '@sitecore-feaas/sdk'
import { mergeDeep } from '@sitecore-feaas/sdk'
import ColorField from '../fields/ColorField.js'
import NumberField from '../fields/NumberField.js'
import OpacityField from '../fields/OpacityField.js'
import SwitchGroupField from '../fields/SwitchGroupField.js'
import FieldsetField from '../../FieldsetField.js'
import { isDeepEquals } from '@sitecore-feaas/sdk'
import { StyleFormProps } from './index.js'

const DecorationBoxShadowFieldset = ({
  rule,
  rules,
  currentRule,
  isActive,
  onChange,
  label
}: StyleFormProps<'decoration'>): ReactElement => {
  const {
    props: {
      boxShadowInset,
      boxShadowOffsetX,
      boxShadowColor,
      boxShadowOffsetY,
      boxShadowBlurRadius,
      boxShadowSpreadRadius
    }
  } = currentRule

  const {
    props: {
      boxShadowOffsetX: oldBoxShadowOffsetX,
      boxShadowColor: oldBoxShadowColor,
      boxShadowOffsetY: oldBoxShadowOffsetY,
      boxShadowBlurRadius: oldBoxShadowBlurRadius,
      boxShadowSpreadRadius: oldBoxShadowSpreadRadius
    }
  } = rule

  const colors = rules.filter((s) => s.type == 'color') as Style.Rule<'color'>[]

  const onUpdate = (changes: Unformatted<Style.Decoration.BoxShadow>) =>
    onChange(mergeDeep(currentRule, { props: mergeDeep(currentRule.props, changes) }))

  const getIndex = () => {
    if (!Style.Decoration.BoxShadow.isValid(currentRule.props)) {
      return 0
    }

    if (boxShadowInset === false) {
      return 1
    }

    if (boxShadowInset === true) {
      return 2
    }

    return 0
  }

  const [index, setIndex] = useState(getIndex)

  useEffect(() => setIndex(getIndex), [rule, isActive])

  useEffect(() => {
    if (index === 0) {
      onUpdate(Style.Decoration.BoxShadow())

      return
    }

    if (index === 1) {
      onUpdate({ boxShadowInset: false })
      return
    }

    if (index === 2) {
      onUpdate({ boxShadowInset: true })
    }
  }, [index])

  return (
    <>
      {!isActive && (
        <FormLabel display='flex' alignItems='center' mb={0}>
          <Switch isChecked={index !== 0} onChange={(e) => setIndex(e.target.checked ? 1 : 0)} />
          <Text ml='2'>{label}</Text>
        </FormLabel>
      )}
      {(isActive || index !== 0) && (
        <>
          <SwitchGroupField value={index} onChange={setIndex} mb={6}>
            {isActive && <Button>None</Button>}
            <Button>Outer</Button>
            <Button>Inner</Button>
          </SwitchGroupField>

          {index !== 0 && (
            <Grid templateColumns='repeat(2, 1fr)' rowGap={isActive ? 5 : 4} columnGap={3}>
              <GridItem>
                <FieldsetField isChanged={!Style.areSameColors(boxShadowColor, oldBoxShadowColor)} label='Color'>
                  <ColorField
                    rules={rules}
                    colorReference={boxShadowColor}
                    onChange={(color: Style.ColorReference) => onUpdate({ boxShadowColor: color })}
                    size={!isActive ? 'sm' : null}
                  />
                </FieldsetField>
              </GridItem>

              <GridItem>
                <FieldsetField
                  isChanged={Style.findAlpha(boxShadowColor, colors) !== Style.findAlpha(oldBoxShadowColor, colors)}
                  label='Opacity'
                >
                  <OpacityField
                    value={Style.findAlpha(boxShadowColor, colors)}
                    onChange={(alpha: number) => onUpdate({ boxShadowColor: { alpha } })}
                    size={!isActive ? 'sm' : null}
                  />
                </FieldsetField>
              </GridItem>

              <GridItem>
                <FieldsetField
                  isChanged={!isDeepEquals(boxShadowOffsetX, oldBoxShadowOffsetX)}
                  label='Horizontal offset'
                >
                  <NumberField
                    value={boxShadowOffsetX.value}
                    min={-200}
                    max={200}
                    onChange={(value: number) => onUpdate({ boxShadowOffsetX: { value } })}
                    withAddon='px'
                    size={!isActive ? 'sm' : null}
                  />
                </FieldsetField>
              </GridItem>

              <GridItem>
                <FieldsetField isChanged={!isDeepEquals(boxShadowOffsetY, oldBoxShadowOffsetY)} label='Vertical offset'>
                  <NumberField
                    value={boxShadowOffsetY.value}
                    min={-200}
                    max={200}
                    onChange={(value: number) => onUpdate({ boxShadowOffsetY: { value } })}
                    withAddon='px'
                    size={!isActive ? 'sm' : null}
                  />
                </FieldsetField>
              </GridItem>

              <GridItem>
                <FieldsetField isChanged={!isDeepEquals(boxShadowBlurRadius, oldBoxShadowBlurRadius)} label='Blur'>
                  <NumberField
                    value={boxShadowBlurRadius.value}
                    onChange={(value: number) => onUpdate({ boxShadowBlurRadius: { value } })}
                    withAddon='px'
                    size={!isActive ? 'sm' : null}
                  />
                </FieldsetField>
              </GridItem>

              <GridItem>
                <FieldsetField
                  isChanged={!isDeepEquals(boxShadowSpreadRadius, oldBoxShadowSpreadRadius)}
                  label='Spread'
                >
                  <NumberField
                    value={boxShadowSpreadRadius.value}
                    onChange={(value: number) => onUpdate({ boxShadowSpreadRadius: { value } })}
                    withAddon='px'
                    size={!isActive ? 'sm' : null}
                  />
                </FieldsetField>
              </GridItem>
            </Grid>
          )}
        </>
      )}
    </>
  )
}
export default DecorationBoxShadowFieldset
