import { Badge, Box, Button, ButtonProps, HStack, Icon, IconButton, Tooltip, Spacer, Flex } from '@chakra-ui/react'
import Preview, { checkerboard } from '../styles/previews/index.js'
import { mdiAlertOutline, mdiPencilOutline, mdiTrashCanOutline, mdiTuneVariant } from '@mdi/js'
import { Style } from '@sitecore-feaas/sdk'

const PickerStylePreview = ({
  style,
  customRules,
  preview,
  isDefault,
  isSelected,
  isApplied = isSelected,
  isCustomized,
  isCustomizable,
  isAllowed = true,
  isOneOff,
  enableCustomizationMode,
  onChange,
  rules,
  onEdit,
  onDelete,
  ...props
}: {
  style: Style.Rule
  rules: Style.Rule[]
  customRules?: Record<string, Style.Rule>
  preview?: Style.Rule
  isCustomized?: boolean
  isDefault?: boolean
  isSelected: boolean
  isApplied?: boolean
  isCustomizable?: boolean
  isAllowed?: boolean
  isOneOff?: boolean
  enableCustomizationMode?: () => void
  onChange?: (style: Style.Rule) => void
  onEdit?: () => void
  onDelete?: () => void
} & Omit<ButtonProps, 'onChange'>) => {
  const isFullWidthPreview = style.type === 'spacing' || style.type === 'theme'
  const wraperStyles = isFullWidthPreview && { pt: 2, pr: 2, pb: 1, pl: 2 }
  const titleStyles = isFullWidthPreview ? { mt: 1, minHeight: 8 } : { ml: 2 }
  const infoStackStyles = isFullWidthPreview ? { mt: 2, spacing: 1 } : { pr: 2, spacing: 0 }
  return (
    <Button
      variant='dialogPane'
      as='div'
      isActive={isSelected}
      className='picker-style-preview-item'
      onClick={() => !isSelected && onChange(style)}
      {...props}
    >
      <Flex flexWrap='wrap' alignItems='center' {...wraperStyles}>
        {style ? (
          <Preview variant='compact' customRules={customRules} rule={preview || style} rules={rules} />
        ) : (
          <Box width={'100%'} height={'100%'} css={checkerboard} />
        )}
        {style && (
          <Flex alignItems='center' fontWeight='normal' {...titleStyles}>
            {style.details.title}
          </Flex>
        )}
        <Spacer />
        <HStack {...infoStackStyles}>
          {isCustomized && <Badge colorScheme={'teal'}>Customized</Badge>}
          {isSelected && isCustomizable && (
            <Tooltip label='Customize' placement='top-end'>
              <IconButton variant='minimal' size='sm' onClick={enableCustomizationMode} aria-label='Customize'>
                <Icon fontSize='icon-md'>
                  <path d={mdiTuneVariant} />
                </Icon>
              </IconButton>
            </Tooltip>
          )}
          {isDefault && (
            <Badge borderRadius='md' colorScheme='blackAlpha'>
              Default
            </Badge>
          )}
          {!isAllowed && (
            <Tooltip hasArrow={false} maxW={56} label='This style has not been assigned to this element'>
              <Badge borderRadius='md' colorScheme='orange' px={2}>
                <Icon w={4} h={4}>
                  <path d={mdiAlertOutline} />
                </Icon>
              </Badge>
            </Tooltip>
          )}
          {onEdit && (
            <IconButton
              variant='minimal'
              size='xs'
              aria-label='Edit custom style'
              onClick={onEdit}
              icon={
                <Icon color='blackAlpha.500' boxSize='icon-lg'>
                  <path d={mdiPencilOutline} />
                </Icon>
              }
            />
          )}
          {onDelete && (
            <IconButton
              variant='minimal'
              size='xs'
              aria-label='Delete custom style'
              onClick={onDelete}
              icon={
                <Icon color='blackAlpha.500' boxSize='icon-lg'>
                  <path d={mdiTrashCanOutline} />
                </Icon>
              }
            />
          )}
        </HStack>
      </Flex>
    </Button>
  )
}

export default PickerStylePreview
