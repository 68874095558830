import { Style, Unformatted } from '@sitecore-feaas/sdk'
import { FormControl, FormLabel, Grid, GridItem, Switch } from '@chakra-ui/react'
import { mergeDeep } from '@sitecore-feaas/sdk'
import FlexGrid from '../../FlexGrid.js'
import ColorField from '../fields/ColorField.js'
import FieldsetField from '../../FieldsetField.js'
import { StyleFormProps } from './index.js'
import { ChangeEvent } from 'react'

const PaletteTextFieldset = ({
  currentRule: currentStyle,
  onChange,
  rule,
  rules,
  onlyTextColor
}: StyleFormProps<'palette'> & { onlyTextColor?: boolean }) => {
  const {
    props: { textColor, linkDecoration }
  } = currentStyle

  const {
    props: { textColor: oldTextColor, linkDecoration: oldLinkDecoration }
  } = rule

  const onUpdate = (changes: Unformatted<Style.Palette.Props>) =>
    onChange(mergeDeep(currentStyle, { props: mergeDeep(currentStyle.props, changes) }))

  return (
    <FlexGrid>
      <Grid templateColumns='repeat(1, 1fr)' gap={6}>
        <GridItem colSpan={1}>
          <FieldsetField isChanged={!Style.areSameColors(textColor, oldTextColor)} label='Text color'>
            <ColorField rules={rules} colorReference={textColor} onChange={(textColor) => onUpdate({ textColor })} />
          </FieldsetField>

          {!onlyTextColor && (
            <FormControl display='flex' flexDirection='row' mt={5}>
              <Switch
                isChecked={linkDecoration == 'underline'}
                {...{ mr: 3 }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onUpdate({ linkDecoration: e.target.checked ? 'underline' : 'none' })
                }
              />
              <FormLabel>Underline link text</FormLabel>
            </FormControl>
          )}
        </GridItem>
      </Grid>
    </FlexGrid>
  )
}

export default PaletteTextFieldset
