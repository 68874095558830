import { Box, HStack, Text } from '@chakra-ui/react'
import { mergeDeep, Style } from '@sitecore-feaas/sdk'
import * as inflection from 'inflection'
import Preview from '../previews/index.js'
import { getFontVariantLabel } from './FontFieldset.js'
import { StyleFormProps } from './index.js'
import { Select } from '../../Select.js'

const TypographyTypeFieldset = ({ onChange, currentRule, rules, isActive, label }: StyleFormProps<'typography'>) => {
  const fonts = Style.Set.filterByType(rules, 'font')

  const currentFontVariant = currentRule.props?.fontVariant as Style.Font.VariantReference

  const onChangeVariant = (font: Style.Rule<'font'>, variant: Style.Font.Variant): void => {
    let nextFontVariant = null

    if (currentFontVariant?.id !== font.details.id || currentFontVariant.name !== variant.name) {
      nextFontVariant = { id: font.details.id, name: variant.name }
    }

    onChange(mergeDeep(currentRule, { props: { fontVariant: nextFontVariant } }))
  }

  const fontOptions = fonts
    .filter((font: Style.Rule<'font'>) => font.props.variants.length)
    .reduce((acc, font: Style.Rule<'font'>) => {
      const variants = font.props.variants.map((variant) => ({
        label: (
          <HStack display='inline-flex'>
            <Preview
              variant='compact'
              rules={rules}
              rule={{
                ...font,
                props: {
                  ...font.props,
                  variants: [variant]
                }
              }}
            />
            <Text color='blackAlpha.500' cursor='default'>
              {getFontVariantLabel(variant.weight)} {variant.weight}{' '}
              {variant.style !== 'normal' && inflection.titleize(variant.style)}
            </Text>
          </HStack>
        ),
        value: `${font.details.id} ${variant.name}`,
        font,
        variant
      }))

      return [...acc, ...variants]
    }, [])

  return (
    <Box maxW='sm'>
      {label && (
        <Text fontSize='md' as='h4' fontWeight='600' mb={2}>
          {label}
        </Text>
      )}
      <Select
        menuPosition='fixed'
        isClearable={false}
        isSearchable={false}
        options={fontOptions}
        onChange={({ font, variant }) => onChangeVariant(font, variant)}
        size={!isActive ? 'sm' : null}
        value={fontOptions.find(
          ({ font, variant }) => font.details.id === currentFontVariant?.id && variant.name === currentFontVariant?.name
        )}
      />
    </Box>
  )
}

export default TypographyTypeFieldset
