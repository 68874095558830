import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Card,
  CardBody,
  HStack,
  Icon,
  Tag,
  Text,
  VStack
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import DatasourceBadge from '../DatasourceBadge.js'
import { mdiCheckCircle, mdiCircleOutline } from '@mdi/js'
import React from 'react'
import { DatasourceModel } from '@sitecore-feaas/sdk'
import DataItem from '../DataItem.js'

const PickerDatasourcesSource = ({
  datasources,
  datasource,
  onDatasourceChange,
  byocProps
}: {
  datasources: DatasourceModel[]
  datasource: DatasourceModel
  onDatasourceChange: (datasource: DatasourceModel) => void
  byocProps?: Record<string, any>
}) => {
  return (
    <>
      {datasources.map((d) => {
        const isSelected = datasource?.id === d.id
        const byocMatchingValues = byocProps && Object.keys(d.sample).filter((key) => byocProps.includes(key))?.length

        return (
          <Card
            className='datasource'
            key={d.id}
            onClick={() => onDatasourceChange(d)}
            variant={'outline'}
            width={'100%'}
            css={css`
              ${isSelected && 'outline: 1px solid var(--chakra-colors-primary-600);'}
              &:hover {
                cursor: pointer;
                background: var(--chakra-colors-blackAlpha-100);
                outline: 1px solid var(--chakra-colors-blackAlpha-400);
              }
            `}
          >
            <CardBody p={3}>
              <VStack alignItems={'start'}>
                <HStack justifyContent={'space-between'} width={'100%'}>
                  <Box>
                    <DatasourceBadge my={1} datasources={[d]} />
                    {byocProps && (
                      <Tag ml={1} mt={1}>
                        <Text fontSize={13} fontWeight={400}>
                          {byocMatchingValues} matching value{byocMatchingValues === 1 ? '' : 's'}
                        </Text>
                      </Tag>
                    )}
                  </Box>
                  {isSelected ? (
                    <Box color={'primary.600'} alignSelf={'start'}>
                      <Icon boxSize={'icon-lg'}>
                        <path d={mdiCheckCircle} />
                      </Icon>
                    </Box>
                  ) : (
                    <Box color={'blackAlpha.400'} alignSelf={'start'}>
                      <Icon boxSize={'icon-lg'}>
                        <path d={mdiCircleOutline} />
                      </Icon>
                    </Box>
                  )}
                </HStack>
                <Text
                  color='blackAlpha.800'
                  fontWeight='semibold'
                  fontSize='2xl'
                  mt={'0!important'}
                  mr={3}
                  noOfLines={1}
                >
                  {d.name}
                </Text>
                <Accordion mt={'0!important'} width={'100%'} allowMultiple>
                  <AccordionItem border={0}>
                    <AccordionButton px={0} _hover={{ background: 'transparent' }}>
                      <Box as='span' flex='1' textAlign='left'>
                        Sample
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel p={0}>
                      <DataItem
                        isDisabled={true}
                        as={Box}
                        data-datasource-type={d.type}
                        data-datasource-id={d.id}
                        data={d.sample}
                        byocProps={byocProps}
                      />
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </VStack>
            </CardBody>
          </Card>
        )
      })}
    </>
  )
}

export default PickerDatasourcesSource
