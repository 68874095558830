import Datasource from '../datasources/Datasource.js'
import React from 'react'
import { DatasourceModel } from '@sitecore-feaas/sdk'
import EntitiesProvider from '../entities/EntitiesProvider.js'
import { Box } from '@chakra-ui/react'

const PickerDatasourcesFetchUpdates = ({
  datasource,
  onChange,
  onDiscard
}: {
  datasource: DatasourceModel
  onChange: (datasource: DatasourceModel) => void
  onDiscard: () => void
}) => {
  return (
    <Box
      pb={'50px'}
      sx={{
        '.entity-open-actions': {
          position: 'absolute',
          bottom: '-50px',
          zIndex: 1,
          height: '50px'
        },
        '.close-entity': {
          display: 'none'
        }
      }}
    >
      <EntitiesProvider
        url={`/datasources`}
        activeEntityId={datasource.id}
        ids={[datasource.id]}
        navigate={(url) => {}}
      >
        <Datasource
          onChangeCurrent={onChange}
          datasource={datasource}
          onDiscard={onDiscard}
          variant='open'
          isInteractive={true}
        />
      </EntitiesProvider>
    </Box>
  )
}

export default PickerDatasourcesFetchUpdates
