import { Button, Flex, FormLabel, Grid, GridItem, Icon, Switch, Text } from '@chakra-ui/react'
import {
  mdiArrowBottomLeft,
  mdiArrowBottomRight,
  mdiArrowDown,
  mdiArrowLeft,
  mdiArrowRight,
  mdiArrowTopLeft,
  mdiArrowTopRight,
  mdiArrowUp
} from '@mdi/js'
import { Style, Unformatted, cloneDeep, mergeDeep } from '@sitecore-feaas/sdk'
import { Fragment, useEffect, useState } from 'react'
import FieldsetField from '../../FieldsetField.js'
import { Select } from '../../Select.js'
import ColorField from '../fields/ColorField.js'
import NumberField from '../fields/NumberField.js'
import OpacityField from '../fields/OpacityField.js'
import SwitchGroupField from '../fields/SwitchGroupField.js'
import { StyleFormProps } from './index.js'

const Label = ({ iconPath, text }: { iconPath: string; text: string }) => (
  <Flex align='center' display={'inline-flex'} verticalAlign={'middle'}>
    <Icon mr={2} boxSize='icon-md'>
      <path d={iconPath} />
    </Icon>

    <Text>{text}</Text>
  </Flex>
)

const angleOptions = [
  { label: <Label iconPath={mdiArrowUp} text='Top' />, value: 0 },
  { label: <Label iconPath={mdiArrowTopRight} text='Top right' />, value: 45 },
  { label: <Label iconPath={mdiArrowRight} text='Right' />, value: 90 },
  { label: <Label iconPath={mdiArrowBottomRight} text='Bottom right' />, value: 135 },
  { label: <Label iconPath={mdiArrowDown} text='Bottom' />, value: 180 },
  { label: <Label iconPath={mdiArrowBottomLeft} text='Bottom left' />, value: 225 },
  { label: <Label iconPath={mdiArrowLeft} text='Left' />, value: 270 },
  { label: <Label iconPath={mdiArrowTopLeft} text='Top left' />, value: 315 }
]

const FillColorFieldset = ({ rule, rules, currentRule: currentStyle, isActive, onChange }: StyleFormProps<'fill'>) => {
  const {
    props: { backgroundColor, backgroundGradient }
  } = currentStyle

  const {
    props: { backgroundColor: oldBackgroundColor, backgroundGradient: oldBackgroundGradient }
  } = rule

  const angleInOptions = angleOptions.find((angle) => angle.value === backgroundGradient.angle)

  const onUpdate = (changes: Unformatted<Style.Fill.Color>) =>
    onChange(mergeDeep(currentStyle, { props: mergeDeep(currentStyle.props, changes) }))

  const getIndex = () => {
    if (!Style.Fill.Color.isValid(currentStyle.props)) return 0

    if (Style.ColorReference.isValid(backgroundColor)) {
      return 1
    }

    if (Style.Gradient.isValid(backgroundGradient)) {
      return 2
    }
    return 0
  }

  const [index, setIndex] = useState(getIndex)

  useEffect(() => setIndex(getIndex), [rule, isActive])

  const [customAngle, setCustomAngle] = useState<boolean>(!angleInOptions)

  const stops = cloneDeep(backgroundGradient.stops)

  const onGroupChange = (index: number) => {
    setIndex(index)

    if (index === 0) {
      onUpdate(Style.Fill.Color())
      return
    }

    if (index === 1) {
      onUpdate({
        backgroundColor: Style.gradientToBackgroundColor(backgroundGradient),
        backgroundGradient: Style.Gradient({
          angle: 0
        })
      })
    }

    if (index === 2) {
      onUpdate({
        backgroundColor: Style.ColorReference(),
        backgroundGradient: Style.backgroundColorToGradient(backgroundColor)
      })
      return
    }
  }

  return (
    <>
      <SwitchGroupField value={index} onChange={onGroupChange}>
        <Button>None</Button>
        <Button>Solid</Button>
        <Button>Gradient</Button>
      </SwitchGroupField>

      {index === 1 && (
        <Grid templateColumns='60% auto' rowGap={isActive ? 5 : 4} columnGap={3} mt={5}>
          <GridItem>
            <FieldsetField label='Color' isChanged={!Style.areSameColors(backgroundColor, oldBackgroundColor)}>
              <ColorField
                rules={rules}
                colorReference={backgroundColor}
                onChange={(backgroundColor: Style.ColorReference) => onUpdate({ backgroundColor })}
                size={!isActive ? 'sm' : null}
              />
            </FieldsetField>
          </GridItem>

          <GridItem>
            <FieldsetField label='Opacity' isChanged={backgroundColor.alpha !== oldBackgroundColor?.alpha}>
              <OpacityField
                value={backgroundColor.alpha}
                onChange={(alpha) => onUpdate({ backgroundColor: { alpha } })}
                size={!isActive ? 'sm' : null}
              />
            </FieldsetField>
          </GridItem>
        </Grid>
      )}

      {index === 2 && (
        <>
          <Grid templateColumns='60% auto' rowGap={isActive ? 5 : 4} columnGap={3} mt={5}>
            <GridItem colSpan={2}>
              {customAngle && (
                <FieldsetField isChanged={backgroundGradient.angle !== oldBackgroundGradient?.angle} label='Direction'>
                  <NumberField
                    value={backgroundGradient.angle}
                    min={0}
                    max={360}
                    onChange={(value) => onUpdate({ backgroundGradient: { angle: value } })}
                    size={!isActive ? 'sm' : null}
                  />
                </FieldsetField>
              )}

              {!customAngle && (
                <FieldsetField isChanged={backgroundGradient.angle !== oldBackgroundGradient?.angle} label='Direction'>
                  <Select
                    menuPosition='fixed'
                    isSearchable={false}
                    options={angleOptions}
                    value={angleInOptions}
                    onChange={({ value: angle }) => onUpdate({ backgroundGradient: { angle } })}
                    size={!isActive ? 'sm' : null}
                  />
                </FieldsetField>
              )}
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel display='flex' alignItems='center' mb={0}>
                <Switch isChecked={customAngle} onChange={() => setCustomAngle(!customAngle)} />

                <Text ml='2'>Custom direction</Text>
              </FormLabel>
            </GridItem>

            {stops.map((stop, index) => (
              <Fragment key={`Color ${index + 1}`}>
                <GridItem>
                  <FieldsetField
                    isChanged={
                      !Style.areSameColors(
                        stop.color as Style.ColorReference,
                        oldBackgroundGradient?.stops[index]?.color as Style.ColorReference
                      )
                    }
                    label={`Color ${index + 1}`}
                  >
                    <ColorField
                      rules={rules}
                      colorReference={stop.color as Style.ColorReference}
                      onChange={(value: Style.ColorReference) => {
                        stops[index].color = value
                        onUpdate({ backgroundGradient: { stops } })
                      }}
                      size={!isActive ? 'sm' : null}
                    />
                  </FieldsetField>
                </GridItem>

                <GridItem>
                  <FieldsetField
                    label={`Opacity ${index + 1}`}
                    isChanged={stops[index].color.alpha !== oldBackgroundGradient?.stops[index]?.color?.alpha}
                  >
                    <OpacityField
                      value={stop.color.alpha}
                      onChange={(value) => {
                        stops[index].color.alpha = value
                        onUpdate({ backgroundGradient: { stops } })
                      }}
                      size={!isActive ? 'sm' : null}
                    />
                  </FieldsetField>
                </GridItem>
              </Fragment>
            ))}
          </Grid>
        </>
      )}
    </>
  )
}

export default FillColorFieldset
