import { Box, Icon } from '@chakra-ui/react'
import { mdiCircleMedium } from '@mdi/js'
import { ReactNode } from 'react'
import { LeafStatus } from './DatasourceTreeLeafWrapper.js'

const DatasourceStatus = ({ leafStatus, path }: { leafStatus: LeafStatus; path: string }) => {
  if (!['removed', 'new', 'changed'].includes(leafStatus)) return null

  let bg = ''
  let color = ''
  let content: string | ReactNode = ''

  if (leafStatus === 'new') {
    bg = 'green.500'
    color = 'green.600'
    content = '+'
  }

  if (leafStatus === 'removed') {
    bg = 'red.500'
    color = 'red.600'
    content = '-'
  }

  if (leafStatus === 'changed') {
    bg = 'orange.500'
    color = 'red.600'
    content = (
      <Icon>
        <path d={mdiCircleMedium} />
      </Icon>
    )
  }

  return (
    <>
      <Box w='100%' h='30px' left={0} position='absolute' display='inline-block' opacity={0.1} background={bg} />
      <Box color={color} left={1} position={'absolute'} display={'inline-block'} opacity={1}>
        {content}
      </Box>
    </>
  )
}

export default DatasourceStatus
