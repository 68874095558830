import { useContext, useRef } from 'react'
import {
  Button,
  AlertDialogOverlay,
  AlertDialog,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent,
  List,
  ListItem,
  Text,
  Box
} from '@chakra-ui/react'
import { EntitiesContext } from '../../contexts/EntitiesContext.js'

const FieldsMessage = ({ msg, fields }: { msg: string; fields: string[] }) => (
  <Box>
    {msg}
    {/*<List mt='5'>*/}
    {/*  {fields.map((f) => (*/}
    {/*    <ListItem>*/}
    {/*      <Text fontSize='sm'>{f}</Text>*/}
    {/*    </ListItem>*/}
    {/*  ))}*/}
    {/*</List>*/}
  </Box>
)

const EntitiesAlert = () => {
  const { editingAlert, setEditingAlert } = useContext(EntitiesContext)

  const cancelRef = useRef()

  if (!editingAlert) return null

  const { onDiscard, invalidNonEmptyFields, emptyRequiredFields } = editingAlert

  const onClose = () => setEditingAlert(null)

  return (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={!!editingAlert} isCentered={false} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {emptyRequiredFields.length > 0 && (
              <FieldsMessage fields={emptyRequiredFields} msg='Some required fields are empty' />
            )}
            {invalidNonEmptyFields.length > 0 && (
              <FieldsMessage fields={invalidNonEmptyFields} msg='Some fields have invalid entries' />
            )}
            {invalidNonEmptyFields.length === 0 && emptyRequiredFields.length === 0 && 'There are unsaved changes'}
          </AlertDialogHeader>

          <AlertDialogFooter>
            <Button ref={cancelRef} size='sm' variant='secondary' onClick={onClose} ml={3}>
              Keep editing
            </Button>

            <Button
              ml='3'
              variant='solid'
              colorScheme='red'
              size='sm'
              onClick={() => {
                onDiscard()
                onClose()
              }}
            >
              Discard changes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default EntitiesAlert
