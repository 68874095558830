import {
  Box,
  Flex,
  Icon,
  InputGroup,
  InputLeftElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/react'
import { Style } from '@sitecore-feaas/sdk'
import { Select } from '../../Select.js'
import { useEffect, useMemo, useState } from 'react'
import { Option } from '../../../types/index.js'

const defaultUnits = ['px', '%', 'em', 'rem']

// SizeField has to be able to accept null for value
// For things like typography optional font-size
const SizeField = ({
  icon,
  units = defaultUnits,
  placeholder,
  length,
  min = 0,
  max,
  allowEmpty,
  onChange,
  ariaLabel,
  allowDecimal,
  withStepper,
  size
}: {
  icon?: string
  units?: any[]
  placeholder?: string
  length: Style.Length
  min?: number
  max?: number
  allowEmpty?: boolean
  ariaLabel?: string
  onChange: (length: Style.Length) => void
  allowDecimal?: boolean
  withStepper?: boolean
  size?: 'sm' | 'md' | 'lg'
}) => {
  const [unit, setUnit] = useState(length?.unit || 'px')
  const [current, setCurrent] = useState(String(length?.value ?? ''))

  allowDecimal ??= unit == 'em' || unit == 'rem' || unit == '%'
  // Value is given as length from outside
  // Component stores current values in local state to allow intermediate malformed state of the length
  // When text input is blurred, it normalizes value back to usable state
  useEffect(() => {
    if (length?.value != (allowEmpty ? null : 0) || current != '') {
      setCurrent(String(length?.value ?? ''))
    }
  }, [length?.value])

  useEffect(() => {
    if (length) setUnit(length?.unit)
  }, [length?.unit])

  // using useEffect  here can create a situation where changes de-sync with upstream
  // if typing too rapidly. This slows things down by commiting changes synchronously
  const onValueChange = (current: any, unit: Style.Length['unit']) => {
    var value = length?.value
    if (current == '' && allowEmpty) {
      value = null
    } else if (current == '') {
      value = 0
    } else if (current == '-') {
      value == 0
    } else {
      value = current
    }
    if (unit != null && value != null) {
      onChange(Style.Length({ unit, value }))
    } else if (length?.value != null) {
      onChange(null)
    }
  }

  const unitOptions = useMemo(
    () =>
      units.map((unit) => ({
        label: unit,
        value: unit
      })),
    units
  )
  return (
    <Flex>
      <InputGroup flex={size === 'sm' ? 3 : 4}>
        {icon && (
          <InputLeftElement
            children={
              <Icon w='5' h='5'>
                <path d={icon} />
              </Icon>
            }
          />
        )}

        <NumberInput
          min={min}
          max={max}
          name={'number-value'}
          value={current}
          width='full'
          aria-label={ariaLabel}
          inputMode='numeric'
          size={size}
          onChange={(valueAsString) => {
            // Clean up decimal part if not allowed (depends on unit setting)
            // Value is internally set string, and then parsed by Style.Length
            const nextValue = !allowDecimal ? valueAsString.replace(/\..*/, '') : valueAsString
            setCurrent(nextValue)
            onValueChange(nextValue, unit)
          }}
          onBlur={() => {
            // Set value to 0, if it can't be empty or incorrect
            // Some inputs allow numeric value to be empty, leading to observed `null` length
            if (Number.isNaN(current) || (current.trim() == '' && !allowEmpty)) {
              setCurrent('0')
            }
          }}
        >
          <NumberInputField {...(icon ? { pl: '36px' } : {})} pr={!withStepper && 4} placeholder={placeholder} />
          {withStepper && (
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          )}
        </NumberInput>
      </InputGroup>

      <Box flex={size === 'sm' ? 3 : 2} ml={1}>
        <Select
          menuPosition='fixed'
          isSearchable={false}
          size={size}
          options={unitOptions}
          value={{ value: unit, label: unit }}
          onChange={(option: Option<Style.Unit, Style.Unit>) => {
            setUnit(option.value)
            onValueChange(current, option.value)
          }}
        />
      </Box>
    </Flex>
  )
}

export default SizeField
