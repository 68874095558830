import { createContext, Dispatch } from 'react'

export const EntitiesContext = createContext<{
  activeEntityId: string
  editedEntityId: string
  setEditedEntityId: Dispatch<string>
  onToggle: (id: string, force?: boolean) => void
  editingAlert: {
    onDiscard: () => void
    invalidNonEmptyFields: string[]
    emptyRequiredFields: string[]
  }
  setEditingAlert: Dispatch<{
    onDiscard: () => void
    invalidNonEmptyFields: string[]
    emptyRequiredFields: string[]
  }>
  newEntityId: string
  setNewEntityId: Dispatch<string>
}>({
  activeEntityId: null,
  editedEntityId: null,
  setEditedEntityId: () => {},
  onToggle: () => {},
  editingAlert: null,
  setEditingAlert: () => {},
  newEntityId: null,
  setNewEntityId: () => {}
})
