import { Style } from '@sitecore-feaas/sdk'
import { getFontVariantLabel } from '../fieldsets/FontFieldset.js'
import { Divider, HStack, Text } from '@chakra-ui/react'

const StyleFontSummary = ({ rule, rules }: { rule: Style.Rule; rules: Style.Rule[] }) => {
  let r: Style.Rule<'typography'>

  if (rule.type === 'typography') {
    r = rule
  } else if ((rule.type === 'text' || rule.type === 'inline') && rule.props.typographyIds) {
    r = Style.Set.findById(rules, rule.props.typographyIds[0]) as Style.Rule<'typography'>
  } else {
    return null
  }

  if (!r) return null
  if (!r.props.fontVariant) {
    return (
      <Text fontSize='sm' color='blackAlpha.400' pb={1}>
        Inherit
      </Text>
    )
  }

  const {
    props: {
      fontVariant: { id: fontVariantId }
    }
  } = r

  const font = Style.Set.findById(rules, fontVariantId) as Style.Rule<'font'>
  const fontVariant = font.props.variants.find(({ name }) => name === r.props.fontVariant.name)
  const fontFamily = Style.Props.summarize('typography', r.props, rules, 'typographyType')
  const fontSize = Style.Props.summarize('typography', r.props, rules, 'typographySize')

  return (
    <Text
      fontSize='sm'
      color='blackAlpha.400'
      whiteSpace='nowrap'
      maxW='full'
      overflow='hidden'
      textOverflow='ellipsis'
    >
      {fontFamily} {getFontVariantLabel(fontVariant.weight)} {fontVariant.weight} | {fontSize}
    </Text>
  )
}

export default StyleFontSummary
