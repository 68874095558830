import FieldsetField from '../FieldsetField.js'
import { Input } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { DatasourceModel } from '@sitecore-feaas/sdk'

const DatasourceName = ({
  currentDatasource,
  previousDatasource,
  onChange,
  isBlock
}: {
  currentDatasource: DatasourceModel
  previousDatasource: DatasourceModel
  onChange: (datasource: DatasourceModel) => void
  isBlock: boolean
}) => {
  const [isDirty, setIsDirty] = useState(false)

  const errors = !currentDatasource.name ? 'Name is required' : null
  const hasChanges = currentDatasource.name !== previousDatasource.name
  const showErrors = isDirty && errors

  useEffect(() => {
    if (isDirty) return

    setIsDirty(!isDirty && hasChanges)
  }, [hasChanges])

  return (
    <FieldsetField extraProps={{ maxW: '80' }} label='Name' error={showErrors} required={true} isChanged={hasChanges}>
      <Input
        value={currentDatasource.name}
        onChange={(e) => onChange(currentDatasource.change({ name: e.target.value }))}
        isDisabled={!currentDatasource.isInternal()}
        isInvalid={!!showErrors}
        placeholder='Enter name'
      />
    </FieldsetField>
  )
}

export default DatasourceName
