import { Style } from '@sitecore-feaas/sdk'
import { Checkbox, Flex, Grid, GridItem } from '@chakra-ui/react'
import * as inflection from 'inflection'
import Preview from './previews/index.js'
import { getFontVariantLabel } from './fieldsets/FontFieldset.js'
import { useLibrary } from '../../exports.js'

const FontVariants = ({
  allVariants,
  fontVariants,
  onSelectVariant,
  onClickVariant,
  exampleContent,
  platform,
  familyName,
  order = 1
}: {
  allVariants: Style.Font.Variant[]
  fontVariants?: Style.Font.Variant[]
  order?: number
  onSelectVariant?: (variant: Style.Font.Variant) => void
  onClickVariant?: () => void
  exampleContent: string
  platform: Style.Font.Platform
  familyName: string
}) => {
  const rules = useLibrary('stylesheets.first.rules')
  return (
    <Grid templateColumns='repeat(auto-fill, minmax(200px, 1fr))' gap={4} mt={3} order={order}>
      {allVariants.map((variant: Style.Font.Variant) => (
        <GridItem
          key={variant.name}
          className='font-variant'
          as='label'
          cursor='pointer'
          onClick={() => onClickVariant && onClickVariant()}
          layerStyle='outline.interactive'
          py='3'
          px='2'
        >
          <Flex justifyContent='space-between'>
            <Preview
              rules={rules}
              rule={Style.Rule({
                type: 'font',
                details: {
                  id: 'a',
                  exampleContent: exampleContent,
                  title: `${getFontVariantLabel(variant.weight)} ${variant.weight}${
                    variant.style === 'italic' ? ` ${inflection.titleize(variant.style)}` : ''
                  }`
                },
                props: { platform, variants: [variant], familyName }
              })}
            />
            {onSelectVariant && (
              <Checkbox
                isChecked={!!fontVariants.find((v) => v.name === variant.name)}
                onChange={() => onSelectVariant(variant)}
              />
            )}
          </Flex>
        </GridItem>
      ))}
    </Grid>
  )
}

export default FontVariants
