export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function isValidHttpUrl(value: string) {
  let url

  try {
    url = new URL(value)
  } catch (_) {
    return false
  }

  return !!(url.protocol === 'http:' || url.protocol === 'https:')
}

export function isValidJSON(value: string) {
  try {
    const json = JSON.parse(value)

    if(json === null) return false

    return typeof json === 'object'
  } catch (e) {
    return false
  }
}

export function strip(string: string) {
  return string.replace(/(\r\n|\n|\r)/gm, '').replace(/ /g, '')
}

export function stringToOption(string: string) {
  return { value: string, label: string }
}

export const stringify = (objectOrNull: object) => (objectOrNull ? JSON.stringify(objectOrNull) : '')
