import { Box, HStack, Icon, Tag, Text } from '@chakra-ui/react'
import DatasourceBadge from '../DatasourceBadge.js'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { useData, useLibrary } from '../../hooks/useData.js'
import { mdiAlertOutline } from '@mdi/js'
import { ComponentModel, DatasourceModel } from '@sitecore-feaas/sdk'
import datasource from './Datasource.js'
import { Link } from '@chakra-ui/react'

const DatasourceDetails = ({
  currentDatasource,
  previousDatasource,
  componentCount,
  isBlock,
  slot
}: {
  currentDatasource: DatasourceModel
  previousDatasource: DatasourceModel
  componentCount?: number
  isBlock: boolean
  slot?: string
}) => {
  return (
    <Box maxH='200px' className={['xmTemplate', 'contentHubOne'].includes(currentDatasource.type) ? 'external' : ''}>
      {!isBlock && (
        <HStack mt={3} mb='3'>
          <DatasourceBadge datasources={[currentDatasource]} />
        </HStack>
      )}

      <HStack>
        <Text color='blackAlpha.800' fontWeight='semibold' fontSize='2xl' mr={3} noOfLines={1}>
          {currentDatasource.name || 'New data source'}
        </Text>
      </HStack>

      {currentDatasource.description && (
        <Text fontSize='sm' mt='1'>
          {currentDatasource.description}
        </Text>
      )}

      {isBlock && (
        <HStack mt={3}>
          <DatasourceBadge datasources={[currentDatasource]} />

          {componentCount > 0 && (
            <Link href={'/libraries/' + currentDatasource.libraryId + '?datasources=' + currentDatasource.id}>
              Used by {componentCount} component
              {componentCount === 1 ? '' : 's'}
            </Link>
          )}

          {previousDatasource.conflictDefinition && (
            <Box p={1} background={'orange.100'} borderRadius={'4px'}>
              <HStack color={'orange.800'} mx={1}>
                <Icon boxSize='icon-sm'>
                  <path d={mdiAlertOutline} />
                </Icon>

                <Text fontSize='sm'>
                  There are conflicts between the fields of remote datasource and the current one
                </Text>
              </HStack>
            </Box>
          )}
        </HStack>
      )}
    </Box>
  )
}

export default DatasourceDetails
