import { Box, Flex } from '@chakra-ui/react'
import { useContext } from 'react'
import { EntityContext } from '../../contexts/EntityContext.js'
import { useSlots } from '../../hooks/useSlots.js'
import EntityActions from './EntityActions.js'
import EntityActionsMenu from './EntityActionsMenu.js'

const EntityOverlay = ({ children, className }: { children: any; className?: string }) => {
  const {
    isActive,
    ref,
    id,
    getClassname,
    activityAttribute,
    isNonEditable,
    onToggleEntity,
    onMouseEnterUpper,
    onMouseLeaveUpper,
    wrapperClassname
  } = useContext(EntityContext)

  return useSlots(children, ({ preview, details, form, extraActions, menuDetails, extraMenuActions, children }) => (
    <Box position='relative' paddingBottom='0' id={`entity-${id}`} ref={ref} className={wrapperClassname}>
      <Box
        {...activityAttribute}
        p='5'
        onClick={onToggleEntity}
        layerStyle={isNonEditable ? 'outline' : 'outline.interactive'}
        borderBottomColor={isActive && 'transparent'}
        borderBottomLeftRadius={isActive && 0}
        borderBottomRightRadius={isActive && 0}
        onMouseEnter={onMouseEnterUpper}
        onMouseLeave={onMouseLeaveUpper}
        cursor='pointer'
      >
        {preview && <Box className={getClassname('preview')}>{preview}</Box>}

        <Flex
          paddingTop='0'
          marginTop='3'
          borderRadius='base'
          minHeight='30px'
          position='relative'
          className={getClassname('details')}
        >
          {details}

          {!isActive && (
            <EntityActionsMenu marginLeft='auto' extraMenuActions={extraMenuActions} menuDetails={menuDetails} />
          )}
        </Flex>
      </Box>

      {isActive && (
        <Box className='anchor' position='relative' w='full'>
          <Box
            {...activityAttribute}
            position='absolute'
            padding='5'
            paddingTop='0'
            top='0'
            width='full'
            left='0'
            className={getClassname('form')}
            layerStyle={isNonEditable ? 'outline' : 'outline.interactive'}
            borderTop={isActive && 'none'}
            borderTopLeftRadius={0}
            borderTopRightRadius={0}
          >
            {form}

            <Flex alignItems='center' justifyContent='end' marginTop='5'>
              <EntityActions
                extraMenuActions={extraMenuActions}
                extraActions={extraActions}
                menuDetails={menuDetails}
              />
            </Flex>
          </Box>
        </Box>
      )}
    </Box>
  ))
}

export default EntityOverlay
