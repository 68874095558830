import { BoxProps, Flex } from '@chakra-ui/react'
import { DataOptionsById } from '@sitecore-feaas/clientside/headless'
import {
  CollectionModel,
  ComponentModel,
  DatasourceModel,
  EmbeddableComponent,
  ExternalComponentModel,
  Style
} from '@sitecore-feaas/sdk'
import { useMemo } from 'react'
import { AttributeGetter, AttributeSetter, ContextCallback, RuleSetter } from '../editor/dialog/Dialog.js'
import PickerContextElement from './PickerContext.js'
import PickerDatasources from './PickerDatasources.js'
import PickerElement from './PickerElement.js'
import PickerLibrary from './PickerLibrary.js'
import PickerLibraryComponent from './PickerLibraryComponent.js'
import PickerThemes from './PickerThemes.js'
import PickerResponsiveBundles from './PickerResponsiveBundles.js'
import PickerOverview from './PickerOverview.js'

export interface PickerProps {
  mode:
    | 'context'
    | 'library'
    | 'element'
    | 'themes'
    | 'datasources'
    | 'versions'
    | 'overview'
    | 'attributes'
    | 'bundles'
  dialog?: string
  context: Style.Context
  contextLink: Style.Context
  contextVariable: Style.Context
  rules: Style.Rule[]
  customRules: Style.Rule[]
  themeContext: Style.Context
  component: ComponentModel
  embed?: EmbeddableComponent
  externalComponents: ExternalComponentModel[]
  collections: CollectionModel[]
  datasources: DatasourceModel[]
  data: DataOptionsById
  placeholder: string
  onModeChange: (mode?: PickerProps['mode'], dialog?: string) => void
  children: any
  variant: 'internal' | 'pages'
  setAttribute?: AttributeSetter
  getAttribute?: AttributeGetter
  onSelect?: ContextCallback
  setLocalRule: RuleSetter
  versionId: string
  cdn?: string
}

export default function Picker(props: Partial<PickerProps & BoxProps>) {
  const {
    setLocalRule,
    rules = [],
    customRules = [],
    collections = [],
    embed,
    data,
    versionId,
    placeholder,
    variant,
    component,
    themeContext,
    mode,
    dialog,
    datasources,
    context,
    contextLink = context,
    contextVariable = context,
    setAttribute,
    getAttribute,
    cdn,
    onSelect,
    onModeChange = () => {},
    externalComponents,
    ...box
  } = props
  const themeClassList = Style.Context.getClassList(themeContext)
  const theme =
    Style.Set.getContextTheme(rules, themeClassList) ||
    Style.Rule({
      type: 'theme',
      details: {
        title: Style.ClassList.getThemeSlug(Style.Context.getClassList(context)) ? 'Deleted theme' : 'No theme',
        slug: 'deleted-theme',
        id: 'deleted-theme'
      }
    })
  const classList = Style.Context.getClassList(context)
  const datasource = datasources.find((d) => d.id == dialog)

  const setAttributeWithCallback: AttributeSetter = (context, name, value) => {
    if (!setAttribute) {
      if (context instanceof HTMLElement) {
        context.setAttribute(name, value as any)
      }
    } else {
      setAttribute(context, name, value)
    }
  }

  const setLocalRuleWithCallback: RuleSetter = (context, rule) => {
    if (setLocalRule) {
      setLocalRule(context, rule)
    }
  }

  const onSelectWithCallback = onSelect || ((context: Style.Context) => void 0)

  const getAttributeWithCallback =
    getAttribute ||
    ((context: Style.Context, name: string) => {
      return context.getAttribute(name)
    })

  // on context change panel should be re-mounted to reset internal state
  const uid = useMemo(() => Math.random(), [context])
  const datasourceId = datasource?.type == 'xmTemplate' ? '_' : datasource?.id
  return (
    <Flex
      key={uid}
      hidden={mode == null}
      {...box}
      className='ui'
      overflowY={'scroll'}
      overflowX='hidden'
      height='100%'
      position='relative'
      flexDirection='column'
      flex={1}
      css={{
        '--picker-width': variant == 'pages' ? '300px' : '300px',
        '[id*="react-select"]': {
          maxWidth: '280px !important'
        }
      }}
    >
      {mode == 'overview' && <PickerOverview component={component} onModeChange={onModeChange} />}
      {mode == 'bundles' && (
        <PickerResponsiveBundles onModeChange={onModeChange} component={component} dialog={dialog} />
      )}
      {mode == 'versions' && embed && !ExternalComponentModel.isExternalComponent(embed) && (
        <PickerLibraryComponent
          variant={variant}
          component={embed}
          versionId={versionId}
          onChange={(e) => {
            setAttributeWithCallback(context, 'version', e.id)
          }}
          onModeChange={onModeChange}
        />
      )}
      {mode == 'context' && (
        <PickerContextElement
          data={data}
          variant={variant}
          rules={rules}
          dialog={dialog as any}
          customRules={customRules}
          onModeChange={onModeChange}
          themeClassList={themeClassList}
          classList={classList}
          onRuleChange={(style, target = context) => {
            setLocalRuleWithCallback(target, style)
          }}
          externalComponents={externalComponents}
          collections={collections}
          theme={theme}
          embed={embed}
          context={context}
          contextLink={contextLink}
          contextVariable={contextVariable}
          onAttributeChange={(name, value, target = context) => {
            setAttributeWithCallback(target, name, value)
          }}
          getAttribute={getAttributeWithCallback}
          onSelect={onSelectWithCallback}
          datasources={datasources}
        />
      )}
      {mode == 'element' && (
        <PickerElement
          variant={variant}
          classList={classList}
          themeClassList={themeClassList}
          theme={theme}
          rules={rules}
          customRules={customRules}
          onComboChange={(combo) => {
            const element = Style.Set.findById(rules, combo?.refId)
            const cleanClassList = element ? Style.ClassList.setStyle(classList, { type: element.type }) : classList
            setAttributeWithCallback(context, 'class', Style.ClassList.setCombo(cleanClassList, combo).join(' '))
          }}
          onChange={(style) => {
            setLocalRule(context, style)
            setAttributeWithCallback(context, 'class', Style.ClassList.setStyle(classList, style).join(' '))
          }}
          onModeChange={onModeChange}
          context={context}
        />
      )}
      {mode == 'themes' && (
        <PickerThemes
          classList={themeClassList}
          placeholder={placeholder}
          onModeChange={onModeChange}
          rules={rules}
          onChange={(theme) => {
            setAttributeWithCallback(themeContext, 'class', Style.ClassList.setTheme(themeClassList, theme).join(' '))
          }}
          onComboChange={(combo) => {
            setAttributeWithCallback(
              themeContext,
              'class',
              Style.ClassList.setThemeCombo(themeClassList, combo, rules).join(' ')
            )
          }}
        />
      )}
      {mode == 'library' && (
        <PickerLibrary
          embed={embed}
          component={component}
          externalComponents={externalComponents}
          context={context}
          onModeChange={onModeChange}
          collections={collections}
          onChange={(component: EmbeddableComponent, attributes) => {
            if (ExternalComponentModel.isExternalComponent(component)) {
              setAttributeWithCallback(context, 'data-embed-title', component.title)
              setAttributeWithCallback(context, 'data-embed-as', 'feaas-external')
              setAttributeWithCallback(context, 'data-external-id', component.id)
              setAttributeWithCallback(context, 'revision', null)
              setAttributeWithCallback(context, 'data-attributes', JSON.stringify(attributes))
            } else if (!attributes?.['data-embed-src']) {
              setAttributeWithCallback(context, 'data-embed-title', component.name)
              setAttributeWithCallback(context, 'data-embed-as', 'feaas-component')
              setAttributeWithCallback(context, 'data-external-id', null)
              setAttributeWithCallback(context, 'data-attributes', null)
              setAttributeWithCallback(context, 'revision', 'staged')
              setAttributeWithCallback(context, 'version', null)
              setAttributeWithCallback(context, 'library', component.libraryId)
              setAttributeWithCallback(context, 'component', component.id)
              setAttributeWithCallback(context, 'cdn', cdn)
            } else {
              setAttributeWithCallback(context, 'data-embed-as', attributes['data-embed-as'])
              setAttributeWithCallback(context, 'data-embed-src', attributes['data-embed-src'])
            }
          }}
        />
      )}
      {mode == 'datasources' && (
        <PickerDatasources
          embed={embed}
          context={context}
          datasource={datasource}
          onModeChange={onModeChange}
          settings={data?.[datasourceId]}
          variant={variant}
          onChange={(settings) => {
            if (datasourceId) {
              const newData = {
                ...data,
                [datasourceId]: settings
              }
              if (!settings) {
                delete newData[datasourceId]
              }
              setAttributeWithCallback(context, 'data', newData)
            }
          }}
        />
      )}
    </Flex>
  )
}
