import React, { useContext, useState } from 'react'
import { PickerWrapper } from './PickerWrapper.js'
import { ComponentModel, Style, VersionModel } from '@sitecore-feaas/sdk'
import { PickerProps } from './Picker.js'
import {
  Divider,
  Text,
  FormLabel,
  Textarea,
  VStack,
  Switch,
  FormControl,
  Box,
  HStack,
  Button,
  Alert,
  AlertIcon
} from '@chakra-ui/react'
import TextField from '../TextField.js'
import FieldsetField from '../FieldsetField.js'
import { useLibrary, useModelObserver } from '../../hooks/useData.js'
import ComponentVersionThumbnail from '../ComponentVersionThumbnail.js'
import ComponentVersionThumbnailWrapper from '../ComponentVersionThumbnailWrapper.js'
import PickerResponsiveBundlesBreakpoint from './PickerResponsiveBundlesBreakpoint.js'
import { ConfirmationContext } from '../providers/ConfirmationProvider.js'

const PickerResponsiveBundles = ({
  component,
  onModeChange,
  dialog
}: {
  component: ComponentModel
  onModeChange: (mode?: PickerProps['mode'], dialog?: string) => void
  dialog: string
}) => {
  useModelObserver(component)
  const { setConfirm } = useContext(ConfirmationContext)
  const rules = useLibrary('stylesheets.first.rules')
  const bundle = useModelObserver(component.getBundles().find((b) => b.id === dialog))
  const title = dialog === 'specific' ? 'Embeddable versions' : 'Responsive bundles'
  const [name, setName] = useState<string>(bundle?.name || '')
  const [description, setDescription] = useState<string>(bundle?.description || '')
  const bundleableVersions = component.getBundlableVersions(component.getVersions(['staged', 'published']))
  const versions = VersionModel.getOrderedVersions(bundleableVersions)
  const breakpoints = Style.Set.filterByType(rules, 'breakpoint')

  return (
    <PickerWrapper title={title} onBack={() => onModeChange(null)} mb={10}>
      {!bundleableVersions.length ? (
        <Alert>
          <AlertIcon />
          <Text>
            There are no staged or published versions for your component, please stage/publish versions before you
            create responsive bundles
          </Text>
        </Alert>
      ) : dialog === 'specific' ? (
        /* EMBEDDABLE VERSIONS */
        <VStack p={3} mt={5}>
          {versions.map((version, index) => (
            <VStack key={index}>
              <Box
                borderRadius={3}
                p={3}
                outline={'1px solid var(--chakra-colors-blackAlpha-200)'}
                opacity={version.isEmbeddable ? 1 : 0.7}
              >
                <Box mb={1}>
                  <FormControl display='flex' alignItems='center' width='auto'>
                    <Switch
                      id={`specific-version-toggle-${version.id}`}
                      isChecked={version.isEmbeddable}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        version.setIsEmbeddable(e.target.checked)
                      }}
                      size='md'
                    />
                    <FormLabel htmlFor={`specific-version-toggle-${version.id}`} mb='0' mr={0} ml={2}>
                      <Text fontSize={12}>Allow to use as a specific version</Text>
                    </FormLabel>
                  </FormControl>
                </Box>
                <VStack>
                  <Box width={230}>
                    <ComponentVersionThumbnailWrapper height={150} padding={0}>
                      <ComponentVersionThumbnail targetHeight={150} component={version.component} version={version} />
                    </ComponentVersionThumbnailWrapper>
                  </Box>
                </VStack>
                <Text fontWeight={600} fontSize={13}>
                  {version.name}
                </Text>
                <Text fontSize={13} color={'blackAlpha.800'}>
                  {version
                    .getBreakpoints()
                    .map((b: Style.Rule<'breakpoint'>) => b.details.title)
                    .join(', ')}
                </Text>
              </Box>
            </VStack>
          ))}
        </VStack>
      ) : (
        /* RESPONSIVE BUNDLES */
        <>
          <VStack alignItems={'stretch'} px={3} spacing={6} py={6}>
            <FieldsetField label='Name'>
              <TextField
                error={!name.length ? 'Name should not be empty' : null}
                onChange={(value) => setName(value)}
                value={name}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  bundle.save({ name: e.target.value })
                }}
              />
            </FieldsetField>
            <FieldsetField label='Description'>
              <Textarea
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                onBlur={(e) => bundle.save({ description: e.target.value })}
              />
            </FieldsetField>
            <Divider />
            <Text fontWeight={600}>Define version per breakpoint</Text>
            {breakpoints.map((breakpoint) => (
              <PickerResponsiveBundlesBreakpoint
                bundle={bundle}
                breakpoint={breakpoint}
                component={component}
                versions={versions}
                key={breakpoint.details.id}
              />
            ))}
          </VStack>
          {/* FOOTER */}
          {bundle.id !== 'responsive' && (
            <HStack
              justifyContent={'flex-end'}
              bottom={0}
              right={0}
              width='var(--picker-width, 100%)'
              position={'fixed'}
              background={'white'}
              boxShadow={'0px -1px 2px 0px #0000000F; box-shadow: 0px -1px 3px 0px #0000001A'}
              p={2}
              pr={3}
              ml={-3}
            >
              <Button
                onClick={() => {
                  setConfirm({
                    variant: 'danger',
                    action: () => {
                      component.bundles.removeItem(bundle.id)
                      bundle.delete()
                      onModeChange(null)
                    },
                    title: 'Deleting bundle',
                    button: 'Delete',
                    body: (
                      <Text>
                        Deleting <b>{bundle.name}</b> is a dangerous operation. Embedded components using that bundle
                        will not be displayed anymore and will need to be re-configured in Pages.
                      </Text>
                    )
                  })
                }}
              >
                <Text color={'red.600'}>Delete bundle</Text>
              </Button>
            </HStack>
          )}
        </>
      )}
    </PickerWrapper>
  )
}

export default PickerResponsiveBundles
