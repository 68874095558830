import { config, ElementType } from '../styles/style/StyleTheme.js'
import { cloneDeep, Style } from '@sitecore-feaas/sdk'
import { useData, useLibrary } from '../../hooks/useData.js'
import React, { useMemo } from 'react'
import PickerStylePreview from './PickerStylePreview.js'
import { ButtonProps } from '@chakra-ui/react'

const PickerThemesCombo = ({
  combo,
  isSelected,
  isApplied = isSelected,
  isDefault,
  onChange,
  rules,
  ...props
}: {
  combo: Style.Theme.Combo
  isSelected: boolean
  isApplied?: boolean
  isDefault: boolean
  onChange: (combo: any) => void
  rules: Style.Rule[]
} & Omit<ButtonProps, 'onChange' | 'style'>) => {
  const getStyleFromId = (id: string): Style.Rule => rules.find((style) => style.details.id === id)

  const style = getStyleFromId(combo.refId)
  const type = style.type as ElementType
  const preview = useMemo(() => {
    if (!style) return
    const configForType = config[type]
    const clone = cloneDeep(style)
    for (const configOption of configForType) {
      if (configOption.isElement) continue
      // @ts-ignore: This is not a good way to do it, we should pass styles to Preview as customStyles instead
      clone.props[configOption.elementProperty] = [combo[configOption.comboProperty]]
    }
    return clone
  }, [style, combo, config])

  return (
    <PickerStylePreview
      rules={rules}
      style={style}
      preview={preview}
      isDefault={isDefault}
      isSelected={isSelected}
      isApplied={isApplied}
      onChange={() => onChange(combo)}
      {...props}
    />
  )
}

export default PickerThemesCombo
