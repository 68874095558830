import { Box, BoxProps, Icon, Tooltip, useToast } from '@chakra-ui/react'
import { CSS, DELETE_PROPERTY, Style, cloneDeep, mergeDeep } from '@sitecore-feaas/sdk'
import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { useCodeMirror } from '../../../hooks/useCodemirror.js'
import useOnClickOutside from '../../../hooks/useOnClickOutside.js'
import { StyleFormProps } from './index.js'
import { mdiAlert } from '@mdi/js'

const OverrideFieldset = ({
  rule,
  rules,
  currentRule,
  isActive,
  onChange,
  errors,
  prompt,
  ...props
}: StyleFormProps<Style.Type> & { prompt?: string } & Omit<BoxProps, 'onChange'>) => {
  const propsStyle = CSS.produceProps(currentRule.type, currentRule.props)
  var overrideStyle = currentRule.details.override ? CSS.parse(currentRule.details.override) : []

  const value = CSS.concat(
    CSS.process(
      CSS.unnest(
        CSS.reify(
          prompt
            ? [
                ['comment-single-line', ' Custom CSS font styles'],
                ['comment-single-line', ' ' + prompt],
                ...(overrideStyle.length ? overrideStyle : ' '),
                ''
              ]
            : [
                [
                  'rule',
                  Style.Rule.getSelector(currentRule),
                  ['comment-single-line', ' Styles generated by style guides (not editable)'],
                  ...propsStyle,
                  ['comment-single-line', ' Add custom styles below (supports nested selectors and &)'],
                  ...(overrideStyle.length ? overrideStyle : [''])
                ]
              ],
          {
            rules: rules
          }
        ),
        false,
        false
      )
    ),
    ''
  )
  const wrapperRef = useRef(null as HTMLDivElement)
  const state = useRef({
    nextChange: null as ReturnType<typeof setTimeout>,
    errors: null as typeof errors,
    rule: null as typeof currentRule
  })
  state.current.errors = errors
  state.current.rule = currentRule
  const change = (editor: CodeMirror.Editor) => {
    const override = editor
      .getValue()
      .split(/\n/g)
      .slice(customIndex + 1, -1)
      .join('\n')
    const parsed = CSS.parse(override)
    const error = parsed?.[0] as any as CSS.ErrorAST
    if (error?.[0] == 'error') {
      editor.setGutterMarker(disabledLines.length + error[2] - 1, 'error', errorRef.current)
      errorRef.current.style.display = 'block'
    } else {
      errorRef.current.style.display = 'none'
    }

    return onChange(
      mergeDeep(state.current.rule, {
        details: {
          override: !parsed || parsed.every((l) => l.length == 0) ? DELETE_PROPERTY : override
        }
      })
    )
  }
  const options: Parameters<typeof useCodeMirror>[0] = {
    value,
    config: {
      theme: 'material-darker',
      mode: 'scss',
      lineNumbers: true,
      gutters: ['CodeMirror-linenumbers', 'error']
    },
    onChange: (currentValue: string, docName, changes, doc) => {
      clearTimeout(state.current.nextChange)
      state.current.nextChange = setTimeout(
        () => {
          change(doc.getEditor())
        },
        // change immediately when already in error state, or in 600ms
        state.current.errors ? 32 : 600
      )
    }
  }

  const codeMirror = useCodeMirror(options)

  const lines = value.split(/\n/) as string[]
  const customIndex = lines.findIndex((line) => line.includes(prompt || 'Add custom'))
  const disabledLines = lines.slice(0, customIndex + 1).map((v, index) => {
    return index
  })

  const editor = useMemo(
    () => (
      <Box>
        <Box ref={codeMirror.ref} height='auto' maxHeight='700px' />
      </Box>
    ),
    [rule, isActive]
  )

  const toast = useToast()
  const copyClassName = () => {
    navigator.clipboard.writeText(Style.Rule.getClassName(rule, rules))

    toast({
      isClosable: true,
      duration: 4000,
      status: 'success',
      title: `CSS class is copied to clipboard`
    })
  }
  useEffect(() => {
    if (codeMirror.editor) {
      codeMirror.editor.setSize('100%', 'auto')
      codeMirror.editor.getScrollerElement().style.maxHeight = String(props.maxHeight || '700px')
      const disabledLinesWithLast = [...disabledLines, codeMirror.editor.lineCount() - 1]
      // dont allow editing inside disabled lines
      codeMirror.editor.on('beforeChange', function (cm, change) {
        const disabledLinesWithLast = [...disabledLines, codeMirror.editor.lineCount() - 1]
        if (change.origin != 'setValue' && disabledLinesWithLast.indexOf(change.from.line) > -1) {
          change.cancel()
        }
      })
      // dont allow selecting inside disabled lines
      codeMirror.editor.on('beforeSelectionChange', function (cm, data) {
        const from = disabledLines[disabledLines.length - 1] + 1
        const to = codeMirror.editor.lineCount() - 2
        const updatedRanges = data.ranges.map((range) => {
          let startLine = range.anchor.line
          let endLine = range.head.line
          let startCh = range.anchor.ch
          let endCh = range.head.ch

          if (rule.type !== 'font' && range.anchor.line == 0 && range.anchor.line == 0) {
            copyClassName()
          }
          if (range.anchor.line < from) {
            startCh = 0
            startLine = from
          }
          if (range.head.line < from) {
            endCh = 0
            endLine = from
          }
          if (range.head.line > to) {
            endCh = cm.getLine(to).length
            endLine = to
          }
          if (range.anchor.line > to) {
            startCh = cm.getLine(to).length
            startLine = to
          }
          return { anchor: { line: startLine, ch: startCh }, head: { line: endLine, ch: endCh } }
        })
        // @ts-ignore
        data.update(updatedRanges)
      })
      // add disabled styles
      codeMirror.editor.addLineClass(0, 'wrap', 'first-line')
      disabledLinesWithLast.map((d) => {
        codeMirror.editor.addLineClass(
          d,
          'wrap',
          'line-disabled' +
            (d == disabledLinesWithLast[disabledLinesWithLast.length - 2] ? ' line-disabled-before' : '') +
            (d == disabledLinesWithLast[disabledLinesWithLast.length - 1] ? ' line-disabled-after' : '')
        )
        codeMirror.editor.addLineClass(d, 'background', 'line-disabled-background')
        codeMirror.editor.addLineClass(d, 'gutter', 'line-disabled-gutter')
        //  codeMirror.editor.addLineWidget(d, null, { className: 'line-disabled' })
      })
      // make whole generated css a single token
      codeMirror.editor.markText(
        { line: 0, ch: 0 },
        { line: disabledLinesWithLast[disabledLinesWithLast.length - 2] + 1, ch: 0 },
        { atomic: true }
      )
      codeMirror.editor.focus()
      codeMirror.editor.setSelection({ line: disabledLines[disabledLines.length - 1] + 1, ch: 2 })
    }
  }, [codeMirror.editor])
  const errorRef = useRef<HTMLDivElement>()
  useLayoutEffect(() => {
    // return error element back
    return () => {
      if (errorRef.current.parentElement != wrapperRef.current)
        wrapperRef.current.insertBefore(errorRef.current, wrapperRef.current.firstElementChild)
    }
  }, [])
  useOnClickOutside(wrapperRef, () => {
    change(codeMirror.editor)
  })
  return (
    <>
      <style>
        {`.CodeMirror .line-disabled:not(.line-disabled-before) .CodeMirror-line {
        opacity: 0.8;
        filter: saturate(0.5);
      }
      .CodeMirror .line-disabled-before .CodeMirror-line {
        filter: saturate(150%) brightness(150%);
      }
      .CodeMirror .line-disabled {
        cursor: not-allowed;
      }
      .CodeMirror .first-line {
        cursor: pointer;
      }
      .CodeMirror .line-disabled-before .line-disabled-background{
        border-bottom: 1px solid rgba(255,255,255,0.1)
      }
      .CodeMirror .line-disabled-after .line-disabled-background{
        border-top: 1px solid rgba(255,255,255,0.1)
      }
      .CodeMirror .line-disabled-gutter,
      .CodeMirror .line-disabled-background {
        background: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0.01) 0, rgba(255,255,255, 0.01) 20px, transparent 20px, transparent 40px);
        background-attachment: fixed;
      }
      .CodeMirror-activeline-background {
        background: transparent !important;
      }
      `}
      </style>
      <Box ref={wrapperRef} maxWidth='100%'>
        <Box display={'none'} ref={errorRef} marginLeft='-22px' marginTop='-2px' borderRadius={'full'} color='red.500'>
          <Tooltip label={errors?.details?.override} placement='top-start'>
            <Icon boxSize='26px'>
              <path d={mdiAlert} />
            </Icon>
          </Tooltip>
        </Box>
      </Box>

      {editor}
    </>
  )
}

export default OverrideFieldset
