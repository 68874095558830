import { checkerboard } from './styles/previews/index.js'
import { Flex, FlexProps } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { css } from '@emotion/react'

export const Wrapper: FlexProps = {
  borderRadius: 'base',
  overflowY: 'hidden',
  position: 'relative',
  width: 'auto',
  cursor: 'pointer',
  padding: '5',
  background: 'white'
}

export const Shadow: FlexProps = {
  display: 'none',
  position: 'absolute',
  bottom: '-2px',
  border: '1px solid transparent',
  boxShadow: '0px -2px 4px  rgba(0, 0, 0, 0.15)'
}

const wrapperCSS = (hasCheckerboard: boolean) => css`
  &.overflowing .shadow {
    display: block;
  }

  & {
    ${hasCheckerboard ? checkerboard : null}
  }
`

const ComponentVersionThumbnailWrapper = ({
  children,
  hasCheckerboard,
  shadowExtent = 0,
  height = 220,
  className,
  ...props
}: {
  children: ReactNode
  shadowExtent?: any
  hasCheckerboard?: boolean
  className?: string
  height?: number
} & FlexProps) => (
  <Flex
    {...Wrapper}
    css={wrapperCSS(hasCheckerboard)}
    h={height + 'px'}
    className={`${className || ''} thumbnail-wrapper`}
    {...props}
  >
    {children}

    <Flex {...Shadow} className='shadow' left={shadowExtent} right={shadowExtent} />
  </Flex>
)

export default ComponentVersionThumbnailWrapper
