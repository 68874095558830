import { Badge, Text } from '@chakra-ui/react'
import * as FEAAS from '@sitecore-feaas/clientside/headless'
import { DialogAttributeGeneric } from './Dialog.js'

const DialogAttributeBadge = ({ type, label, labels, value, jsonpath }: DialogAttributeGeneric) => {
  return (
    <>
      {jsonpath ? (
        <>
          {label == 'Visibility' && (
            <Text fontWeight='400' fontSize='12px' mr={1} ml={4} whiteSpace='nowrap'>
              if set:
            </Text>
          )}
          <Badge
            colorScheme={'teal'}
            display={'block'}
            overflow='hidden'
            textOverflow='ellipsis'
            whiteSpace='nowrap'
            flexShrink={0.1}
          >
            {FEAAS.DataPath.getLastPathBit(jsonpath)}
          </Badge>
        </>
      ) : !jsonpath && value == '' ? (
        <Badge
          colorScheme={'red'}
          display={'block'}
          overflow='hidden'
          textOverflow='ellipsis'
          flexShrink={0.1}
          whiteSpace='nowrap'
        >
          Empty {type == 'url' ? 'URL' : type == 'string' ? 'Text' : null}
        </Badge>
      ) : !jsonpath && typeof value == 'boolean' ? (
        <Badge
          colorScheme={'gray'}
          display={'block'}
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          flexShrink={0.1}
        >
          {value == true ? labels.static : labels.none}
        </Badge>
      ) : !jsonpath && value != '' && typeof value !== 'boolean' ? (
        <Badge
          colorScheme={'gray'}
          display={'block'}
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          flexShrink={0.1}
        >
          {value}
        </Badge>
      ) : (
        <Badge
          colorScheme={'gray'}
          display={'block'}
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          flexShrink={0.1}
        >
          {labels.none || 'None'}
        </Badge>
      )}
    </>
  )
}

export default DialogAttributeBadge
