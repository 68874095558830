import { Button, VStack } from '@chakra-ui/react'
import type * as CK from '@sitecore-feaas/ckeditor5'
import { Style, mergeDeep } from '@sitecore-feaas/sdk'
import ButtonGroupSwitch from '../../ButtonGroupSwitch.js'
import FieldsetField from '../../FieldsetField.js'
import { DialogAttributeGeneric, DialogStyleProps } from './Dialog.js'

interface Props {
  editor: CK.Editor
  onCommand?: (name: string, ...values: any[]) => void | (() => void)
  context: CK.ModelElement
}

export default function DialogTextAlignment({ context, customRules, rules, onChange }: DialogStyleProps) {
  const style = Style.Set.getContextElementAspect(rules, context, 'lines', customRules) || Style.Rule({ type: 'lines' })

  function setProperties(props: Partial<typeof style.props>) {
    onChange(
      mergeDeep(style, {
        props: props
      })
    )
  }
  const definition = Style.Context.getDefinition(context)

  return (
    <VStack spacing={4} alignItems={'stretch'}>
      <FieldsetField label='Text alignment'>
        <ButtonGroupSwitch
          value={style.props.textAlign}
          onChange={(textAlign) =>
            setProperties({
              textAlign
            })
          }
        >
          <Button size='sm' value='left'>
            Left
          </Button>
          <Button size='sm' value='center'>
            Center
          </Button>
          <Button size='sm' value='right'>
            Right
          </Button>
          <Button size='sm' value='justify'>
            Justify
          </Button>
        </ButtonGroupSwitch>
      </FieldsetField>
      {definition.type == 'text' && (
        <FieldsetField label='Line limit'>
          <ButtonGroupSwitch
            value={style.props.lines}
            onChange={(lines) =>
              setProperties({
                lines,
                ellipsis: lines == 1
              })
            }
          >
            <Button size='sm' value={null}>
              None
            </Button>
            <Button size='sm' value={1}>
              1
            </Button>
            <Button size='sm' value={2}>
              2
            </Button>
            <Button size='sm' value={3}>
              3
            </Button>
            <Button size='sm' value={4}>
              4
            </Button>
            <Button size='sm' value={5}>
              5
            </Button>
          </ButtonGroupSwitch>
        </FieldsetField>
      )}
      {definition.type == 'text' && style.props.lines == 1 && (
        <FieldsetField label='Overflow behavior'>
          <ButtonGroupSwitch
            value={style.props.ellipsis}
            onChange={(ellipsis) =>
              setProperties({
                ellipsis
              })
            }
          >
            {/*@ts-ignore */}
            <Button size='sm' value={true}>
              Show ellipsis
            </Button>
            <Button size='sm' value={null}>
              Display
            </Button>
          </ButtonGroupSwitch>
        </FieldsetField>
      )}
      {definition.type == 'text' && style.props.lines != 1 && (
        <FieldsetField label='Break words'>
          <ButtonGroupSwitch
            value={style.props.wordBreak}
            onChange={(wordBreak) =>
              setProperties({
                wordBreak
              })
            }
          >
            <Button size='sm' value={Style.Lines.WordBreak.normal}>
              Never
            </Button>
            <Button size='sm' value={Style.Lines.WordBreak['break-word']}>
              If overflows
            </Button>
            <Button size='sm' value={Style.Lines.WordBreak['hyphens']}>
              Hyphens
            </Button>
          </ButtonGroupSwitch>
        </FieldsetField>
      )}
    </VStack>
  )
}
