import { Box, Button, FormLabel, Switch, Text } from '@chakra-ui/react'
import type * as CK from '@sitecore-feaas/ckeditor5'
import { Style, isDeepEquals, mergeDeep } from '@sitecore-feaas/sdk'
import { ChangeEvent, useState } from 'react'
import ButtonGroupSwitch from '../../ButtonGroupSwitch.js'
import FieldsetField from '../../FieldsetField.js'
import { CreatableSelect } from '../../Select.js'
import AlignmentField from '../../styles/fields/AlignmentField.js'
import SizeField from '../../styles/fields/SizeField.js'
import type { DialogStyleProps } from './Dialog.js'
interface Props {
  editor: CK.Editor
  context: CK.ModelElement
}

export default function DialogMediaSizing({ context, rules, customRules, onChange }: DialogStyleProps) {
  const style = Style.Set.getContextElementAspect(rules, context, 'media', customRules) || Style.Rule({ type: 'media' })

  const onObjectFitChange = (objectFit: Style.Media.Fit) => {
    setProperties({
      objectFit: objectFit
    })
  }

  function setProperties(props: Partial<typeof style.props>) {
    onChange(
      mergeDeep(style, {
        props: props
      })
    )
  }

  const [isCustomHeight, setCustomHeight] = useState(style.props.width == null)

  const [customSize, setCustomSize] = useState<Style.Length>(style.props.height)
  const defaultSizeOptions = [
    { value: null, label: 'Intrinsic proportion' },
    { value: { value: 1, unit: 'ratio' } as Style.Length, label: 'Ratio 1:1' },
    { value: { value: 2 / 1, unit: 'ratio' } as Style.Length, label: 'Ratio 2:1' },
    { value: { value: 4 / 3, unit: 'ratio' } as Style.Length, label: 'Ratio 4:3' },
    { value: { value: 16 / 9, unit: 'ratio' } as Style.Length, label: 'Ratio 16:9' }
  ]
  const sizeOptions = [
    ...defaultSizeOptions,
    customSize != null &&
      !defaultSizeOptions.find((v) => isDeepEquals(v.value, style.props.height)) && {
        value: customSize,
        label:
          customSize?.unit == 'ratio'
            ? 'Ratio ' + parseFloat(customSize.value.toFixed(2)) + ':1'
            : Style.stringifyLength(customSize)
      }
  ].filter(Boolean)

  return (
    <>
      {Style.Context.getContextName(context) == 'media' && (
        <>
          <Box>
            {/*@ts-ignore */}
            <FormLabel display='flex' align='center' mt={'2px'} position='absolute' right={1} zIndex={1}>
              <Text fontSize='12px' mt={0} mr={2} color={'gray'}>
                Full
              </Text>
              <Switch
                isChecked={style.props.width?.unit == '%' && style.props.width?.value == 100}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.checked) setProperties({ width: { value: 100, unit: '%' } })
                  else setProperties({ width: null })
                }}
              />
            </FormLabel>
            <FieldsetField label='Width'>
              <SizeField
                length={style.props.width}
                placeholder='Intrinsic'
                onChange={(width) => {
                  if (style.props.height == null) {
                    setCustomHeight(false)
                  }
                  if (width == null) {
                    setCustomHeight(true)
                  }
                  setProperties({ width })
                }}
                allowEmpty={true}
              />
            </FieldsetField>
          </Box>
          <Box>
            {/*@ts-ignore */}
            <FormLabel display='flex' align='center' mt={'2px'} position='absolute' right={1} zIndex={1}>
              <Text fontSize='12px' mt={0} mr={2} color={'gray'}>
                Proportional
              </Text>
              <Switch
                isDisabled={style.props.width == null}
                isChecked={!isCustomHeight}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setCustomHeight(!e.target.checked)
                  if (!e.target.checked) {
                    if (style.props.height?.unit == 'ratio') setProperties({ height: null })
                  } else {
                    setProperties({ height: null })
                  }
                }}
              />
            </FormLabel>
            <FieldsetField label='Height'>
              {!isCustomHeight && (
                <CreatableSelect
                  options={sizeOptions}
                  value={sizeOptions.find((v) => isDeepEquals(v.value, style.props.height)) || sizeOptions[0]}
                  onChange={({ value }: (typeof sizeOptions)[0]) => setProperties({ height: value })}
                  onCreateOption={(value: any) => {
                    // FIXME: Typing of argument doesnt work in TS.5
                    const height = Style.Length(value)?.value != 0 ? Style.Length(value) : null
                    setCustomSize(height)
                    setProperties({ height })
                  }}
                ></CreatableSelect>
              )}
              {isCustomHeight && (
                <SizeField
                  placeholder='Intrinsic'
                  allowEmpty={true}
                  length={style.props.height}
                  onChange={(height) => setProperties({ height })}
                  units={['px', 'rem', 'em']}
                />
              )}
              {isCustomHeight && style.props.width != null && (
                <Text fontSize='12px' color='blackAlpha.500' mt={2}>
                  You can type in custom ratio (e.g. 3:4) or size (100% or 300px)
                </Text>
              )}
            </FieldsetField>
          </Box>
        </>
      )}
      <Box>
        <Text color='blackAlpha.800' fontWeight={500} mb={2}>
          Size fitting method
        </Text>
        <ButtonGroupSwitch value={style.props.objectFit} onChange={onObjectFitChange}>
          <Button size='sm' value='cover'>
            Cover
          </Button>
          <Button size='sm' value='contain'>
            Contain
          </Button>
          <Button size='sm' value='fill'>
            Stretch
          </Button>
          <Button size='sm' value='none'>
            Crop
          </Button>
        </ButtonGroupSwitch>
      </Box>
      {style.props.objectFit != 'fill' && (
        <AlignmentField
          value={[style.props.objectPositionX, style.props.objectPositionY]}
          onChange={([objectPositionX, objectPositionY]) => setProperties({ objectPositionX, objectPositionY })}
        />
      )}
    </>
  )
}
