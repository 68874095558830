import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { mdiCactus, mdiMagnifyRemoveOutline } from '@mdi/js'

const Title = {
  fontWeight: 'bold'
}

const EmptyContent = ({
  title,
  subtitle,
  state = 'empty',
  className,
  onClick
}: {
  title: any
  subtitle?: any
  className?: string
  state?: 'add' | 'empty' | 'search'
  onClick?: () => void
}) => (
  <Flex
    className={className}
    cursor={onClick && 'pointer'}
    onClick={onClick}
    flexDirection='column'
    alignItems='center'
    justifyContent='center'
  >
    {state === 'empty' ? (
      <svg xmlns='http://www.w3.org/2000/svg' width='96' height='96' viewBox='0 0 512 512' fill='none'>
        <path
          d='M213.333 384H192C156.587 384 128 355.413 128 320V256C128 244.267 137.6 234.667 149.333 234.667C161.067 234.667 170.667 244.267 170.667 256V320C170.667 331.947 180.267 341.333 192 341.333H213.333V384ZM298.667 298.667H320C331.733 298.667 341.333 289.067 341.333 277.333V234.667C341.333 222.933 350.933 213.333 362.667 213.333C374.4 213.333 384 222.933 384 234.667V277.333C384 312.747 355.413 341.333 320 341.333H298.667V298.667Z'
          fill='url(#paint0_linear_2_97)'
        />
        <path
          d='M298.666 448H213.333V128C213.333 104.533 232.533 85.3333 256 85.3333C279.466 85.3333 298.666 104.533 298.666 128V448Z'
          fill='#02999A'
        />
        <defs>
          <linearGradient
            id='paint0_linear_2_97'
            x1='256'
            y1='213.333'
            x2='256'
            y2='384'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#02999A' />
            <stop offset='1' stopColor='#005E6E' />
          </linearGradient>
        </defs>
      </svg>
    ) : (
      <Icon boxSize={16} color='blackAlpha.600'>
        <path d={state === 'search' ? mdiMagnifyRemoveOutline : mdiCactus} />
      </Icon>
    )}

    <Text
      {...Title}
      mt={state === 'empty' && 5}
      fontSize={state === 'empty' ? 'lg' : 'md'}
      color={state !== 'empty' ? 'blackAlpha.600' : ''}
    >
      {title}
    </Text>
    {subtitle && <Box mt='3'>{subtitle}</Box>}
  </Flex>
)

export default EmptyContent
