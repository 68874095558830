import { Box, Flex, HStack, Icon, IconButton, Text, VStack } from '@chakra-ui/react'
import { DialogMenu } from './DialogMenu.js'
import { DataOptionsById, Datasource, DatasourceModel } from '@sitecore-feaas/sdk'
import { DataPath, DataSettings } from '@sitecore-feaas/clientside/headless'
import { PickerProps } from '../../picker/Picker.js'
import DataItem from '../../DataItem.js'
import DatasourceBadge from '../../DatasourceBadge.js'
import { mdiConnection, mdiTrashCan } from '@mdi/js'

export default function DialogMenuDatasources({
  data,
  variant,
  datasources,
  onModeChange,
  onDeleteItem
}: {
  data: DataOptionsById
  datasources: DatasourceModel[]
  onModeChange: PickerProps['onModeChange']
  variant: PickerProps['variant']
  onDeleteItem: (datasourceId: Datasource['id']) => void
}) {
  return (
    <DialogMenu
      title='Data item'
      items={
        !datasources.length
          ? [
              {
                onNavigate: () => onModeChange('datasources'),
                id: 'attributes',
                label: null,
                icon: null,
                preview: () => (
                  <VStack>
                    <Box p={2} color={'lightgray'}>
                      <Icon color={'lightgray'} boxSize={16}>
                        <path d={mdiConnection} />
                      </Icon>
                    </Box>
                    <Text>No data available</Text>
                  </VStack>
                ),
                badge: () => (
                  <Text fontWeight={400} fontSize='11px'>
                    Unconfigured
                  </Text>
                )
              }
            ]
          : datasources.map((datasource) => {
              const jsonpath = (data?.[datasource?.id] as DataSettings)?.jsonpath
              const qualifier = jsonpath ? DataPath.getQualifier(jsonpath) : null
              const repeating = DataPath.parseLimit(qualifier)
                ? 'limit'
                : DataPath.parseRange(qualifier)
                ? 'range'
                : 'no-limit'
              const sample = data?.[datasource.id]
              return {
                onNavigate: () => onModeChange('datasources', datasource.id),
                id: 'attributes',
                label: null,
                icon: null,
                preview: () => (
                  <Flex align='stretch' flexDir={'column'} position={'relative'}>
                    <HStack justifyContent={'space-between'}>
                      <Box>
                        <DatasourceBadge
                          datasources={[datasource]}
                          alignSelf={'flex-start'}
                          position={'absolute'}
                          zIndex={1}
                          top={'1px'}
                          left={'1px'}
                        />
                      </Box>
                      {onDeleteItem && (
                        <IconButton
                          as={'div'}
                          aria-label={'Remove data item'}
                          onClick={(e) => {
                            e.stopPropagation()
                            onDeleteItem(datasource.id)
                          }}
                          color={'red.600'}
                          size={'sm'}
                          icon={
                            <Icon>
                              <path d={mdiTrashCan} />
                            </Icon>
                          }
                        />
                      )}
                    </HStack>
                    <DataItem
                      pt={9}
                      isDisabled={true}
                      data-datasource-type={datasource.type}
                      data-datasource-id={datasource.id}
                      key={datasource.id}
                      variant={variant}
                      data={sample && !DataSettings.isDataSettings(sample) ? sample : datasource.sample}
                      limit={4}
                      as={Box}
                      p={0}
                    />
                  </Flex>
                ),
                badge: () => (
                  <Text fontWeight={400} fontSize='11px'>
                    {repeating === 'limit' ? 'Limited' : repeating === 'range' ? 'Specific' : 'All'}
                  </Text>
                )
              }
            })
      }
    ></DialogMenu>
  )
}
