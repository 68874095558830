import { Style } from '@sitecore-feaas/sdk'
import { Badge } from '@chakra-ui/react'
import type { DialogStyleGeneric } from './Dialog.js'

const DialogStyleBadge = ({ id, rules, customRules, context, themeClassList }: DialogStyleGeneric) => {
  const type = id as Style.Type.Aspect
  const aspect = Style.Set.getContextElementAspect(rules, context, type, customRules)
  const combo = Style.isTypeElementAspect(type)
    ? Style.Set.getContextComboAspect(rules, context, type, themeClassList)
    : null
  let style = aspect || combo || Style.Rule({ type })
  const summary = Style.Props.summarize(type, style.props)
  return (
    <Badge
      colorScheme={style?.details.instanceId && aspect ? 'orange' : 'gray'}
      display={'block'}
      overflow='hidden'
      textOverflow='ellipsis'
      whiteSpace='nowrap'
      flexShrink={0.1}
    >
      {summary}
    </Badge>
  )
}

export default DialogStyleBadge
