import { Flex, FormControl, FormLabel, Icon, IconButton } from '@chakra-ui/react'
import { mdiChevronUp, mdiPlus } from '@mdi/js'
import { isDeepEquals, Style } from '@sitecore-feaas/sdk'
import { FunctionComponent, ReactElement } from 'react'

interface Props {
  onChange: (value: [x: Style.Length, y: Style.Length]) => void
  value: [x: Style.Length, y: Style.Length]
  label?: string
  disabled?: boolean
}

const AlignmentField: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { onChange, value, label = 'Alignment', disabled } = props

  return (
    <Flex align='center'>
      <FormControl>
        <FormLabel>{label}</FormLabel>

        <Flex display='flex' flexWrap={'wrap'} width='160px' gap='4'>
          <IconButton
            colorScheme={
              isDeepEquals(value, [Style.LengthOrPosition('left'), Style.LengthOrPosition('top')])
                ? 'purple'
                : undefined
            }
            isActive={isDeepEquals(value, [Style.LengthOrPosition('left'), Style.LengthOrPosition('top')])}
            onClick={() => onChange([Style.LengthOrPosition('left'), Style.LengthOrPosition('top')])}
            aria-label='Top left'
            icon={
              <Icon transform='rotate(-45deg)'>
                <path d={mdiChevronUp} />
              </Icon>
            }
          />
          <IconButton
            colorScheme={
              isDeepEquals(value, [Style.LengthOrPosition('center'), Style.LengthOrPosition('top')])
                ? 'purple'
                : undefined
            }
            isActive={isDeepEquals(value, [Style.LengthOrPosition('center'), Style.LengthOrPosition('top')])}
            onClick={() => onChange([Style.LengthOrPosition('center'), Style.LengthOrPosition('top')])}
            aria-label='Top'
            icon={
              <Icon>
                <path d={mdiChevronUp} />
              </Icon>
            }
          />
          <IconButton
            colorScheme={
              isDeepEquals(value, [Style.LengthOrPosition('right'), Style.LengthOrPosition('top')])
                ? 'purple'
                : undefined
            }
            isActive={isDeepEquals(value, [Style.LengthOrPosition('right'), Style.LengthOrPosition('top')])}
            onClick={() => onChange([Style.LengthOrPosition('right'), Style.LengthOrPosition('top')])}
            aria-label='Top right'
            icon={
              <Icon transform='rotate(45deg)'>
                <path d={mdiChevronUp} />
              </Icon>
            }
          />
          <IconButton
            colorScheme={
              isDeepEquals(value, [Style.LengthOrPosition('left'), Style.LengthOrPosition('center')])
                ? 'purple'
                : undefined
            }
            isActive={isDeepEquals(value, [Style.LengthOrPosition('left'), Style.LengthOrPosition('center')])}
            onClick={() => onChange([Style.LengthOrPosition('left'), Style.LengthOrPosition('center')])}
            aria-label='Left'
            icon={
              <Icon transform='rotate(-90deg)'>
                <path d={mdiChevronUp} />
              </Icon>
            }
          />
          <IconButton
            colorScheme={
              isDeepEquals(value, [Style.LengthOrPosition('center'), Style.LengthOrPosition('center')])
                ? 'purple'
                : undefined
            }
            isActive={isDeepEquals(value, [Style.LengthOrPosition('center'), Style.LengthOrPosition('center')])}
            onClick={() => onChange([Style.LengthOrPosition('center'), Style.LengthOrPosition('center')])}
            aria-label='Center'
            icon={
              <Icon>
                <path d={mdiPlus} />
              </Icon>
            }
          />
          <IconButton
            colorScheme={
              isDeepEquals(value, [Style.LengthOrPosition('right'), Style.LengthOrPosition('center')])
                ? 'purple'
                : undefined
            }
            isActive={isDeepEquals(value, [Style.LengthOrPosition('right'), Style.LengthOrPosition('center')])}
            onClick={() => onChange([Style.LengthOrPosition('right'), Style.LengthOrPosition('center')])}
            aria-label='Right'
            icon={
              <Icon transform='rotate(90deg)'>
                <path d={mdiChevronUp} />
              </Icon>
            }
          />
          <IconButton
            colorScheme={
              isDeepEquals(value, [Style.LengthOrPosition('left'), Style.LengthOrPosition('bottom')])
                ? 'purple'
                : undefined
            }
            isActive={isDeepEquals(value, [Style.LengthOrPosition('left'), Style.LengthOrPosition('bottom')])}
            onClick={() => onChange([Style.LengthOrPosition('left'), Style.LengthOrPosition('bottom')])}
            aria-label='Bottom left'
            icon={
              <Icon transform='rotate(-135deg)'>
                <path d={mdiChevronUp} />
              </Icon>
            }
          />
          <IconButton
            colorScheme={
              isDeepEquals(value, [Style.LengthOrPosition('center'), Style.LengthOrPosition('bottom')])
                ? 'purple'
                : undefined
            }
            isActive={isDeepEquals(value, [Style.LengthOrPosition('center'), Style.LengthOrPosition('bottom')])}
            onClick={() => onChange([Style.LengthOrPosition('center'), Style.LengthOrPosition('bottom')])}
            aria-label='Bottom'
            icon={
              <Icon transform='rotate(180deg)'>
                <path d={mdiChevronUp} />
              </Icon>
            }
          />
          <IconButton
            colorScheme={
              isDeepEquals(value, [Style.LengthOrPosition('right'), Style.LengthOrPosition('bottom')])
                ? 'purple'
                : undefined
            }
            isActive={isDeepEquals(value, [Style.LengthOrPosition('right'), Style.LengthOrPosition('bottom')])}
            onClick={() => onChange([Style.LengthOrPosition('right'), Style.LengthOrPosition('bottom')])}
            aria-label='Bottom right'
            icon={
              <Icon transform='rotate(135deg)'>
                <path d={mdiChevronUp} />
              </Icon>
            }
          />
        </Flex>
      </FormControl>
    </Flex>
  )
}

export default AlignmentField
