import {
  Badge,
  BadgeProps,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Divider,
  Flex,
  FlexProps,
  FormLabel,
  FormLabelProps,
  GridItem,
  Icon,
  IconButton,
  Grid,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanelProps,
  TabPanels,
  Tabs,
  Text,
  TextProps,
  VStack
} from '@chakra-ui/react'
import { mdiMinusCircleOutline, mdiPencilOutline, mdiPlus } from '@mdi/js'
import { Style, cloneDeep, isDeepEquals, mergeDeep, nanoid } from '@sitecore-feaas/sdk'
import * as inflection from 'inflection'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLibrary } from '../../../hooks/useData.js'
import { scrollForTogglingCard } from '../../../utils/dom.js'
import { Dot } from '../../Dot.js'
import EmptyContent from '../../EmptyContent.js'
import FieldsetField from '../../FieldsetField.js'
import { Select } from '../../Select.js'
import DetailsFieldset from '../fieldsets/DetailsFieldset.js'
import Preview from '../previews/index.js'
import { StyleFormProps } from '../fieldsets/index.js'
import StyleFontSummary from './StyleFontSummary.js'

const Panel: TabPanelProps = {
  paddingLeft: 0,
  paddingRight: 0
}

const CreateButton: ButtonProps = {
  marginLeft: 'auto'
}

const ComboOverlay: BoxProps = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  cursor: 'pointer'
}

const ComboPreviewWrapper: FlexProps = {
  justifyContent: 'stretch',
  position: 'relative',
  overflow: 'hidden'
}

const ComboFormWrapper: BoxProps = {
  position: 'relative',
  boxShadow: 'base'
}

const ComboForm: BoxProps = {
  position: 'absolute',
  left: -3,
  right: -3,
  top: 3,
  paddingLeft: 3,
  paddingRight: 3,
  paddingBottom: 3,
  paddingTop: 4,
  backgroundColor: 'white',
  boxShadow: 'xl',
  zIndex: 1,
  borderBottomRadius: 'md'
}

const ComboTop: BoxProps = {
  px: 2
}

const ComboDetailsWrapper: BoxProps = {
  marginTop: 2
}

const ComboTitle: TextProps = {
  color: 'gray.800',
  fontSize: 'md',
  fontWeight: 'medium'
}

const ComboControls: FlexProps = {
  marginLeft: 'auto'
}

const CollectionTitle: TextProps = {
  fontSize: 'md',
  color: 'blackAlpha.800',
  fontWeight: 'medium',
  mb: 5
}

const IsDefaultToggleLabel: FormLabelProps = {
  // @ts-ignore
  marginLeft: '3',
  fontSize: 'sm',
  // @ts-ignore
  marginBottom: 0
}

const IsDefaultBadge: BadgeProps = {
  position: 'absolute',
  fontSize: 'sm',
  textTransform: 'none',
  fontWeight: 'medium',
  top: 0,
  left: 0
}

const SingleRuleAssigned: TextProps = {
  marginTop: '1',
  fontSize: 'sm',
  color: 'blackAlpha.500'
}

export type ThemePropsProperty = 'blocks' | 'inlines' | 'texts'
export type ElementType = 'inline' | 'text' | 'block'
export type ComboProperty = 'refId' | 'decorationId' | 'typographyId' | 'fillId' | 'spacingId' | 'paletteId'
export type ElementProperty = 'typographyIds' | 'fillIds' | 'spacingIds' | 'decorationIds' | 'paletteIds'

export interface ConfigOption {
  group: ThemePropsProperty
  comboProperty: ComboProperty
  label?: string
  isElement?: boolean
  isHidden?: boolean
  type: Style.Type.ElementAspect | ElementType
  elementProperty?: ElementProperty
}

interface ThemeProps {
  themePropsProperty: ThemePropsProperty
  type: ElementType
  label: string
}

const themeProps: ThemeProps[] = [
  { themePropsProperty: 'blocks', type: 'block', label: 'Block elements' },
  { themePropsProperty: 'inlines', type: 'inline', label: 'Inline elements' },
  { themePropsProperty: 'texts', type: 'text', label: 'Text elements' }
]

export const config: Record<ElementType, ConfigOption[]> = {
  inline: [
    {
      group: 'inlines',
      comboProperty: 'refId',
      label: 'Inline element',
      isElement: true,
      type: 'inline'
    },
    {
      group: 'inlines',
      comboProperty: 'typographyId',
      label: 'Typography',
      elementProperty: 'typographyIds',
      type: 'typography'
    },
    {
      group: 'inlines',
      comboProperty: 'fillId',
      label: 'Fill',
      elementProperty: 'fillIds',
      type: 'fill'
    },
    {
      group: 'inlines',
      comboProperty: 'decorationId',
      label: 'Decoration',
      elementProperty: 'decorationIds',
      type: 'decoration'
    },
    {
      group: 'inlines',
      comboProperty: 'spacingId',
      label: 'Spacing',
      elementProperty: 'spacingIds',
      type: 'spacing'
    },
    {
      group: 'inlines',
      comboProperty: 'paletteId',
      elementProperty: 'paletteIds',
      isHidden: true,
      type: 'palette'
    }
  ],
  block: [
    {
      group: 'blocks',
      comboProperty: 'refId',
      label: 'Block element',
      isElement: true,
      type: 'block'
    },
    {
      group: 'blocks',
      comboProperty: 'fillId',
      label: 'Fill',
      elementProperty: 'fillIds',
      type: 'fill'
    },
    {
      group: 'blocks',
      comboProperty: 'decorationId',
      label: 'Decoration',
      elementProperty: 'decorationIds',
      type: 'decoration'
    },
    {
      group: 'blocks',
      comboProperty: 'spacingId',
      label: 'Spacing',
      elementProperty: 'spacingIds',
      type: 'spacing'
    }
  ],
  text: [
    {
      group: 'texts',
      comboProperty: 'refId',
      label: 'Text element',
      isElement: true,
      type: 'text'
    },
    {
      group: 'texts',
      comboProperty: 'paletteId',
      elementProperty: 'paletteIds',
      isHidden: true,
      type: 'palette'
    },
    {
      group: 'texts',
      comboProperty: 'typographyId',
      label: 'Typography',
      elementProperty: 'typographyIds',
      type: 'typography'
    }
  ]
}

const StyleTheme = ({
  currentRule,
  isNew,
  rule,
  rules,
  onChange,
  errors
}: StyleFormProps<'theme'> & { isNew: boolean }) => {
  const library = useLibrary()
  const navigate = useNavigate()

  const [activeCombo, setActiveCombo] = useState<Style.Theme.Combo>(null)
  const [tabIndex, setTab] = useState(isNew ? 0 : 1)
  const [makeDefault, setMakeDefault] = useState(null)

  const getStyleFromId = (id: string): Style.Rule => rules.find((style: Style.Rule) => style.details.id === id)

  const elementInCollection = (collection: Style.Rule, style: Style.Rule): boolean =>
    style.details.collectionId === collection.details.id && ['block', 'inline', 'text'].includes(style.type)

  const getCollectionElements = (collection: Style.Rule): Style.Rule[] =>
    rules.filter((style: Style.Rule) => elementInCollection(collection, style))

  const getCollectionsOfType = (type: ElementType) =>
    rules.filter((style: Style.Rule) => style.type === 'collection' && style.props.type === type)

  const getElementAvailableRules = (
    element: Style.RuleMerged<Style.Type.Element>,
    property: ElementProperty,
    type: Style.Type.ElementAspect
  ): Style.Rule[] => {
    const ids = element.props[property]

    if (ids.length === 0) return rules.filter((style: Style.Rule) => style.type === type)

    return ids.map(getStyleFromId).filter(Boolean)
  }

  const getRulesConfigForType = (type: ElementType): ConfigOption[] =>
    config[type].filter((typeElementOrRule) => !typeElementOrRule.isElement)

  const filterCombosByCollection = (collection: Style.Rule, combos: Style.Theme.Combo[]): Style.Theme.Combo[] =>
    combos.filter((combo: Style.Theme.Combo) =>
      rules.find(
        (style: Style.Rule) => style.details.id === combo.refId && style.details.collectionId === collection.details.id
      )
    )

  const groupStylesByCollection = (styles: Style.Rule[]): { collection?: Style.Rule; styles: Style.Rule[] }[] => {
    const collectionGroups: any = {}

    styles
      .filter((s) => s && s.details)
      .map((style: Style.Rule) => {
        const collectionId = style.details.collectionId

        if (collectionId) {
          if (!collectionGroups[collectionId]) {
            collectionGroups[collectionId] = { styles: [style] }
          } else {
            collectionGroups[collectionId].styles = [...collectionGroups[collectionId].styles, style]
          }
        } else {
          if (!collectionGroups['no-collection']) {
            collectionGroups['no-collection'] = { styles: [style] }
          } else {
            collectionGroups['no-collection'].styles = [...collectionGroups['no-collection'].styles, style]
          }
        }
      })

    const result = []

    for (const collectionId in collectionGroups) {
      if (collectionId === 'no-collection') {
        result.push({ styles: collectionGroups[collectionId].styles })
      } else {
        result.push({ collection: getStyleFromId(collectionId), styles: collectionGroups[collectionId].styles })
      }
    }

    return result
  }

  const onToggle = (combo: Style.Theme.Combo) => {
    setActiveCombo(activeCombo?.id === combo.id ? null : combo)
  }

  const calculateCombo = (type: ElementType, element: Style.RuleMerged<Style.Type.Element>) =>
    getRulesConfigForType(type).reduce((otherProps, { elementProperty, comboProperty, type: t }: ConfigOption) => {
      const ids = element.props[elementProperty]
      const blankId = Style.Set.findBlankId(rules, t)
      let id = ids[0]
      if (blankId === ids[0] && ids.length > 1) id = ids[1]
      return { ...otherProps, [comboProperty]: id }
    }, {})

  const onCreateCombo = (themePropsProperty: ThemePropsProperty, collection: Style.Rule, type: ElementType) => {
    const combos: Style.Theme.Combo[] = currentRule.props[themePropsProperty]
    const collectionElements = getCollectionElements(collection) as Style.Rule.Element[]
    const firstCollectionElement = collectionElements[0]
    const combo = Style.Theme.Combo({
      id: nanoid(10),
      refId: firstCollectionElement.details.id,
      ...calculateCombo(type, firstCollectionElement)
    })
    const newCombos = [...combos, combo]
    onChange(mergeDeep(currentRule, { props: { [themePropsProperty]: isDefaultAdjusted(newCombos, collection) } }))
    onToggle(combo)
  }

  const isDefaultAdjusted = (combos: Style.Theme.Combo[], collection: Style.Rule) => {
    const collectionStylesIds = rules
      .filter((style: Style.Rule) => style.details.collectionId === collection.details.id)
      .map((style: Style.Rule) => style.details.id)
    const findIsDefault = combos.findIndex(({ isDefault, refId }) => isDefault && collectionStylesIds.includes(refId))
    if (findIsDefault !== -1) return combos
    const first = combos.findIndex(({ refId }) => collectionStylesIds.includes(refId))
    const newCombos = [...combos]
    newCombos[first] = { ...newCombos[first], isDefault: true }
    return newCombos
  }

  const onDeleteCombo = (id: string, themePropsProperty: ThemePropsProperty, collection: Style.Rule) => {
    const combos: Style.Theme.Combo[] = currentRule.props[themePropsProperty]
    const newCombos = combos.filter((combo: Style.Theme.Combo) => combo.id !== id)
    onChange(mergeDeep(currentRule, { props: { [themePropsProperty]: isDefaultAdjusted(newCombos, collection) } }))
  }

  const setDefaultCombo = (id: string, themePropsProperty: ThemePropsProperty, collection: Style.Rule) => {
    const combos: Style.Theme.Combo[] = currentRule.props[themePropsProperty]
    const newCombos = combos.map((combo: Style.Theme.Combo) => {
      if (getStyleFromId(combo.refId).details.collectionId !== collection.details.id) return combo
      return {
        ...combo,
        isDefault: false
      }
    })
    const index = combos.findIndex((combo: Style.Theme.Combo) => combo.id === id)
    newCombos[index] = { ...newCombos[index], isDefault: true }
    onChange(mergeDeep(currentRule, { props: { [themePropsProperty]: isDefaultAdjusted(newCombos, collection) } }))
  }

  const onChangeCombo = (value: string, comboProperty: ComboProperty, type: ElementType) => {
    let combo = { ...activeCombo, [comboProperty]: value }
    if (comboProperty === 'refId') {
      const element = getStyleFromId(combo.refId) as Style.Rule.Element
      combo = { ...combo, ...calculateCombo(type, element) }
    }
    setActiveCombo(combo)
  }

  const onSaveCombo = (themePropsProperty: ThemePropsProperty) => {
    const newCombos: Style.Theme.Combo[] = cloneDeep(currentRule.props[themePropsProperty])
    const index = newCombos.findIndex((combo: Style.Theme.Combo) => combo.id === activeCombo.id)
    newCombos[index] = activeCombo
    onChange(mergeDeep(currentRule, { props: { [themePropsProperty]: newCombos } }))
    onToggle(activeCombo)
  }

  const getElementOptions = (collection: Style.Rule) => {
    const elements = getCollectionElements(collection)
    return elements.map((element: Style.Rule) => ({
      label: element.details.title,
      value: element.details.id
    }))
  }

  const getElementRuleOptions = (
    elementId: string,
    elementProperty: ElementProperty,
    type: Style.Type.ElementAspect
  ) => {
    const element = getStyleFromId(elementId) as Style.Rule.Element

    const availableRules = getElementAvailableRules(element, elementProperty, type)

    const groups = groupStylesByCollection(availableRules)

    return groups.reduce((options, group) => options.concat(getOption(group)), [])
  }

  const getOption = (group: { collection?: Style.Rule; styles: Style.Rule[] }) => {
    if (group.collection) {
      return {
        label: group.collection.details.title,
        options: group.styles.map((style: Style.Rule) => ({
          label: style.details.title,
          value: style.details.id
        }))
      }
    }

    return group.styles.map((style: Style.Rule) => ({
      label: style.details.title,
      value: style.details.id
    }))
  }

  const getOptions = (type: ElementType, property: ComboProperty, combo: Style.Theme.Combo, collection: Style.Rule) => {
    const typeConfig: ConfigOption[] = config[type]

    const matched: ConfigOption = typeConfig.find(({ comboProperty }: ConfigOption) => comboProperty === property)

    if (matched.isElement) return getElementOptions(collection)

    const elementId = combo.refId

    return getElementRuleOptions(elementId, matched.elementProperty, matched.type as Style.Type.ElementAspect)
  }

  const getSelectedOption = (combo: Style.Theme.Combo, comboProperty: ComboProperty) => ({
    value: combo[comboProperty],
    label: getStyleFromId(combo[comboProperty])?.details.title
  })

  const hasOnlyOneRule = (options: any) => {
    if (options.length > 1) return false
    if (options[0]?.options) {
      return options[0].options.length === 1
    }
    return options.length === 1
  }

  const isSelectDisabled = (options: any) => {
    if (!options.length) return true

    if (options.length === 1) {
      if (!options[0].options) return true

      if (options[0].options.length === 1) return true
    }

    return false
  }

  const getLabel = (isElement: boolean, collection: Style.Rule, label?: string) =>
    isElement ? inflection.capitalize(inflection.singularize(collection.details.title)) + ' element' : label

  const getPreview = (
    type: ElementType,
    comboStyle: Style.RuleMerged<Style.Type.Element>,
    combo: Style.Theme.Combo
  ) => {
    if (!comboStyle) return
    const configForType = config[type]
    const clone = cloneDeep(comboStyle)
    for (const configOption of configForType) {
      if (configOption.isElement) continue
      clone.props[configOption.elementProperty] = [combo[configOption.comboProperty]]
    }

    return clone
  }

  useEffect(() => {
    if (activeCombo) {
      return scrollForTogglingCard(document.getElementById(activeCombo.id))
    }
  }, [activeCombo])

  return (
    <>
      <Tabs index={tabIndex} onChange={setTab}>
        <TabList>
          <Tab>Details</Tab>

          {themeProps.map(({ themePropsProperty, label }: ThemeProps) => (
            <Tab key={themePropsProperty}>
              {label} ({currentRule.props[themePropsProperty].length})
              {!isDeepEquals(currentRule.props[themePropsProperty], rule.props[themePropsProperty]) && <Dot />}
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          <TabPanel {...Panel}>
            <DetailsFieldset rules={rules} currentRule={currentRule} rule={rule} onChange={onChange} errors={errors} />
          </TabPanel>

          {themeProps.map(({ themePropsProperty, type }: ThemeProps) => {
            const combos: Style.Theme.Combo[] = currentRule.props[themePropsProperty]
            const previousCombos: Style.Theme.Combo[] = rule.props[themePropsProperty]

            return (
              <TabPanel {...Panel} key={themePropsProperty} pt={6} pb={0} px={0}>
                <VStack spacing={5} divider={<Divider />}>
                  {getCollectionsOfType(type).map((collection: Style.Rule) => {
                    const collectionCombos = filterCombosByCollection(collection, combos)
                    const collectionPreviousCombos = filterCombosByCollection(collection, previousCombos)
                    const collectionElements = getCollectionElements(collection)
                    const noCollectionElements = collectionElements.length === 0
                    const title = inflection.singularize(collection.details.title).toLowerCase()

                    return (
                      <Box key={collection.details.id} width='full' position='relative'>
                        <Flex align='center'>
                          <Text {...CollectionTitle}>{collection.details.title}</Text>
                        </Flex>

                        {!noCollectionElements && (
                          <Button
                            {...CreateButton}
                            isDisabled={noCollectionElements}
                            onClick={() => onCreateCombo(themePropsProperty, collection, type)}
                            variant='secondary'
                            size='sm'
                            position={collectionCombos.length === 0 && !noCollectionElements ? 'static' : 'absolute'}
                            right={0}
                            top='-6px'
                          >
                            <Icon mr={2} boxSize='icon-xl'>
                              <path d={mdiPlus} />
                            </Icon>
                            Add {title}
                          </Button>
                        )}

                        {noCollectionElements && (
                          <EmptyContent
                            state='add'
                            title={`No ${title} elements available`}
                            subtitle={
                              <Box fontSize='md' color='blackAlpha.600'>
                                Create {title} styles under{' '}
                                <Box
                                  as='span'
                                  textDecoration='underline'
                                  cursor='pointer'
                                  fontWeight='bold'
                                  onClick={() => navigate(`/libraries/${library.id}/styles/elements/${type}`)}
                                >
                                  {inflection.pluralize(type)}
                                </Box>{' '}
                                tab
                              </Box>
                            }
                          />
                        )}

                        <Grid templateColumns='repeat(auto-fill, minmax(220px, 1fr))' gap={4}>
                          {collectionCombos.map((collectionCombo: Style.Theme.Combo, comboIndex: number) => {
                            const isActiveCombo = activeCombo?.id === collectionCombo.id
                            const combo = isActiveCombo ? activeCombo : collectionCombo
                            const previousCombo = collectionPreviousCombos.find(
                              (previousCombo: Style.Theme.Combo) => previousCombo.id === combo.id
                            )
                            const comboStyle = getStyleFromId(combo.refId) as Style.Rule.Element
                            const configForType = config[type]
                            const preview = getPreview(type, comboStyle, combo) as Style.Rule
                            const hasEditedChanges = !isDeepEquals(combo, collectionCombo)

                            return (
                              <GridItem
                                position='relative'
                                layerStyle='outline'
                                pt={2}
                                pb={1}
                                m={0}
                                className={`entity-overlay ${isActiveCombo && 'entity-is-active'}`}
                                key={combo.id}
                                id={combo.id}
                                onMouseEnter={() => setMakeDefault(combo.id)}
                                onMouseLeave={() => setMakeDefault(null)}
                                _before={
                                  isActiveCombo && {
                                    content: '""',
                                    position: 'absolute',
                                    top: -3,
                                    right: -3,
                                    bottom: -3,
                                    left: -3,
                                    background: 'white',
                                    zIndex: -1,
                                    boxShadow: 'lg',
                                    borderTopRadius: 'md'
                                  }
                                }
                              >
                                <Box {...ComboOverlay} onClick={() => onToggle(combo)} />

                                <Box {...ComboTop}>
                                  <Flex {...ComboPreviewWrapper} onClick={() => onToggle(combo)} cursor='pointer'>
                                    <Preview rules={rules} rule={preview} />

                                    <Badge
                                      variant={!combo.isDefault ? 'clickable' : 'subtle'}
                                      opacity={!combo.isDefault && makeDefault !== combo.id ? 0 : 1}
                                      selected={combo.isDefault}
                                      pos='absolute'
                                      fontSize='sm'
                                      size='sm'
                                      pointerEvents={combo.isDefault ? 'none' : null}
                                      top={1}
                                      left={1}
                                      colorScheme='purple'
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        setDefaultCombo(combo.id, themePropsProperty, collection)
                                      }}
                                    >
                                      {combo.isDefault ? 'Default' : 'Make default'}
                                    </Badge>
                                  </Flex>

                                  <Box {...ComboDetailsWrapper}>
                                    <Flex mt={1} alignItems='start' justifyContent='space-between'>
                                      <VStack spacing={-1} alignItems='start'>
                                        <Flex h={8} alignItems='center'>
                                          <Text {...ComboTitle}>{comboStyle.details.title}</Text>
                                        </Flex>
                                        <StyleFontSummary rule={comboStyle} rules={rules} />
                                      </VStack>

                                      {!isActiveCombo && (
                                        <Flex {...ComboControls}>
                                          <IconButton
                                            aria-label='toggle combo'
                                            size='sm'
                                            variant='minimal'
                                            onClick={() => onToggle(combo)}
                                            icon={
                                              <Icon boxSize='icon-xl'>
                                                <path d={mdiPencilOutline} />
                                              </Icon>
                                            }
                                          />
                                          <IconButton
                                            aria-label='delete combo'
                                            size='sm'
                                            variant='minimal'
                                            onClick={() => onDeleteCombo(combo.id, themePropsProperty, collection)}
                                            icon={
                                              <Icon boxSize='icon-xl'>
                                                <path d={mdiMinusCircleOutline} />
                                              </Icon>
                                            }
                                          />
                                        </Flex>
                                      )}
                                    </Flex>
                                  </Box>
                                </Box>

                                <Box {...ComboFormWrapper}>
                                  {isActiveCombo && (
                                    <Box {...ComboForm} className='entity-overlay-form'>
                                      {configForType
                                        .filter(({ isHidden }) => !isHidden)
                                        .map(({ comboProperty, label, group, isElement }: ConfigOption, i) => {
                                          const options = getOptions(type, comboProperty, combo, collection)

                                          return (
                                            <Box mt={i > 0 && 5} key={comboProperty}>
                                              <FieldsetField
                                                error={errors?.props?.[group]?.[comboIndex]?.[comboProperty as any]}
                                                key={comboProperty}
                                                label={getLabel(isElement, collection, label)}
                                                isChanged={collectionCombo[comboProperty] !== combo[comboProperty]}
                                              >
                                                <Select
                                                  menuPosition='fixed'
                                                  isSearchable={false}
                                                  value={getSelectedOption(combo, comboProperty)}
                                                  placeholder='Select pre-created element'
                                                  isDisabled={isSelectDisabled(options)}
                                                  onChange={({ value }) => onChangeCombo(value, comboProperty, type)}
                                                  options={options}
                                                />

                                                {hasOnlyOneRule(options) && (
                                                  <Text {...SingleRuleAssigned}>{label} has single rule assigned</Text>
                                                )}
                                              </FieldsetField>
                                            </Box>
                                          )
                                        })}
                                      <Flex mt={10} justifyContent='end'>
                                        <Button
                                          size='sm'
                                          variant='minimal'
                                          colorScheme='primary'
                                          className='close-entity'
                                          aria-label='Close combo'
                                          onClick={() => onToggle(combo)}
                                        >
                                          {hasEditedChanges ? 'Cancel' : 'Close'}
                                        </Button>

                                        <Button
                                          size='sm'
                                          variant='primary'
                                          ml={2}
                                          aria-label='Save combo'
                                          isDisabled={!hasEditedChanges}
                                          onClick={() => onSaveCombo(themePropsProperty)}
                                        >
                                          Save
                                        </Button>
                                      </Flex>
                                    </Box>
                                  )}
                                </Box>
                              </GridItem>
                            )
                          })}
                        </Grid>
                      </Box>
                    )
                  })}
                </VStack>
              </TabPanel>
            )
          })}
        </TabPanels>
      </Tabs>
    </>
  )
}

export default StyleTheme
