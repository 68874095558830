import { GridItem, SimpleGrid } from '@chakra-ui/react'
import { mergeDeep } from '@sitecore-feaas/sdk'
import TextField from '../../TextField.js'
import FieldsetField from '../../FieldsetField.js'
import NumberField from '../fields/NumberField.js'
import { StyleFormProps } from './index.js'

const BreakpointFieldset = ({ rule, currentRule, errors, onChange }: StyleFormProps<'breakpoint'>) => {
  const minWidth = currentRule.props.minWidth
  const maxWidth = currentRule.props.maxWidth && isFinite(currentRule.props.maxWidth) ? currentRule.props.maxWidth : ''

  const onMinWidthChange = (minWidth: number) =>
    onChange(mergeDeep(currentRule, { props: { ...currentRule.props, minWidth, query: null } }))

  const onMaxWidthChange = (maxWidth: string) => {
    const width = !maxWidth ? Infinity : parseInt(maxWidth)

    onChange(mergeDeep(currentRule, { props: { ...currentRule.props, maxWidth: width, query: null } }))
  }

  return (
    <SimpleGrid columns={[2]} spacing={4} mt={5}>
      <GridItem>
        <FieldsetField label='Starts at'>
          <NumberField withAddon='px' value={minWidth} onChange={onMinWidthChange} max={10000} min={0} />
        </FieldsetField>
      </GridItem>

      <GridItem>
        <FieldsetField label='Ends at'>
          <TextField withAddon='px' placeholder='∞' value={maxWidth} onChange={onMaxWidthChange} />
        </FieldsetField>
      </GridItem>
    </SimpleGrid>
  )
}

export default BreakpointFieldset
