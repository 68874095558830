import { createContext } from 'react'

export const EntityContext = createContext<{
  isActive: boolean
  onToggleEntity: (event?: any, force?: boolean) => void
  ref: any
  isNew: boolean
  onSafeDelete: () => void
  onDiscard: () => void
  hasChanges: boolean
  isBordered?: boolean
  onUnsafeDelete: () => void
  onDelete: () => void
  onSave: () => void
  hasErrors: boolean
  onCopy: () => void
  isNonEditable: boolean
  activityAttribute: any
  onMouseEnterUpper?: () => void
  onMouseLeaveUpper?: () => void
  wrapperClassname?: string
  preventSaveToast?: boolean
  selectRef: any
  invalidNonEmptyFields: string[]
  emptyRequiredFields: string[]
  name: string
  modalHeader?: any
  modalFooter?: any
  type?: string
  id: string
  getClassname: any
  withModal?: boolean
}>({
  isActive: false,
  preventSaveToast: false,
  onToggleEntity: () => {},
  ref: null,
  isNew: false,
  onSafeDelete: () => {},
  id: '',
  onDiscard: () => {},
  isBordered: true,
  hasChanges: false,
  onUnsafeDelete: () => {},
  onDelete: () => {},
  invalidNonEmptyFields: [],
  emptyRequiredFields: [],
  onSave: () => {},
  hasErrors: false,
  onCopy: () => {},
  selectRef: null,
  isNonEditable: false,
  onMouseEnterUpper: () => {},
  onMouseLeaveUpper: () => {},
  activityAttribute: '',
  wrapperClassname: '',
  getClassname: () => {},
  name: '',
  modalHeader: '',
  modalFooter: '',
  type: '',
  withModal: false
})
