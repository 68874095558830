import { mdiPlus } from '@mdi/js'
import { CreatableSelect } from '../Select.js'
import { Box, Button, Divider, Flex, Icon, Input, Text } from '@chakra-ui/react'
import { Headers } from './Datasource.js'
import { DatasourceModel } from '@sitecore-feaas/sdk'

const DatasourceHttpSegmentFlex = ({
  onChangeKey,
  onChangeValue,
  onDelete,
  onCreate,
  items,
  currentDatasource,
  isHeaders,
  isGraphql,
  label
}: {
  items: Record<string, string>
  label: string
  currentDatasource: DatasourceModel
  isGraphql: boolean
  isHeaders: boolean
  onChangeKey: (oldKey: string, newKey: string) => void
  onChangeValue: (oldValue: string, newValue: string) => void
  onDelete: (key: string) => void
  onCreate: (key: string, value: string) => void
}) => (
  <>
    {Object.keys(items).map((oldKey, i: number) => {
      const oldValue = items[oldKey]

      const isDisabled = i === 0 && isGraphql && oldKey === 'Content-Type'

      return (
        <Box mt='5' key={i}>
          <Box mt='3'>
            <Text fontSize='12px' fontWeight='semibold'>
              KEY
            </Text>

            <Box mt='3'>
              {isHeaders ? (
                <CreatableSelect
                  onChange={({ value: newKey }: { value: string }) => onChangeKey(oldKey, newKey)}
                  value={{ label: oldKey, value: oldKey }}
                  options={Headers.filter(h => items[h] === undefined).map((h: string) => ({ value: h, label: h }))}
                />
              ) : (
                <Input
                  isDisabled={isDisabled}
                  placeholder={`My ${label} key`}
                  onChange={(e) => onChangeKey(oldKey, e.target.value)}
                  value={oldKey}
                />
              )}
            </Box>
          </Box>

          <Box mt='3'>
            <Text fontSize='12px' fontWeight='semibold'>
              VALUE
            </Text>

            <Input
              mt='3'
              isDisabled={isDisabled}
              placeholder={`My ${label} value`}
              onChange={(e) => onChangeValue(oldKey, e.target.value)}
              value={oldValue}
            />
          </Box>

          {!isDisabled && (
            <Button
              mt='5'
              variant='solid'
              size='sm'
              colorScheme='red'
              aria-label='Delete entry'
              onClick={() => onDelete(oldKey)}
            >
              Delete
            </Button>
          )}

          {i !== Object.keys(items).length - 1 && (
            <Divider my='5' orientation='horizontal' border='1px solid blackAlpha.100' />
          )}
        </Box>
      )
    })}

    <Flex justifyContent='start' mt='5'>
      <Button onClick={() => onCreate('', '')} variant='subtle' size='sm'>
        <Icon mr='2'>
          <path d={mdiPlus} />
        </Icon>
        Add {label}
      </Button>
    </Flex>
  </>
)

export default DatasourceHttpSegmentFlex
