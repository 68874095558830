import { Style } from '@sitecore-feaas/sdk'
import { FunctionComponent } from 'react'
import ColorsForm from './ColorsFieldset.js'
import DecorationBorderFieldset from './DecorationBorderFieldset.js'
import DecorationBorderRadiusFieldset from './DecorationBorderRadiusFieldset.js'
import DecorationBoxShadowFieldset from './DecorationBoxShadowFieldset.js'
import DetailsFieldset from './DetailsFieldset.js'
import FillColorFieldset from './FillColorFieldset.js'
import FillEffectsFieldset from './FillEffectsFieldset.js'
import FontForm from './FontFieldset.js'
import PaletteTextFieldset from './PaletteTextFieldset.js'
import SpacingFieldset from './SpacingFieldset.js'
import TypographySizeFieldset from './TypographySizeFieldset.js'
import TypographyTypeFieldset from './TypographyTypeFieldset.js'
import GraphicFieldset from './GraphicFieldset.js'
import BreakpointFieldset from './BreakpointFieldset.js'

export interface StyleFormProps<T extends Style.Type = Style.Type> {
  currentRule: Style.Rule<T>
  onChange: (style: Style.Rule<T>) => void
  rule: Style.Rule<T>
  rules: Style.Rule[]
  errors?: Style.RuleError<Style.Rule<T>>
  isActive?: boolean
  forceShowErrors?: boolean
  activeIds?: string[]
  label?: string
}

// Fixme: This looks fieldsets by label, not great
const Fieldsets: { [key in Style.Form['id']]: FunctionComponent<StyleFormProps<any>> } = {
  color: ColorsForm,
  font: FontForm,
  fillColor: FillColorFieldset,
  details: DetailsFieldset,
  fillEffects: FillEffectsFieldset,
  typographyType: TypographyTypeFieldset,
  typographySize: TypographySizeFieldset,
  decorationShadow: DecorationBoxShadowFieldset,
  decorationRadius: DecorationBorderRadiusFieldset,
  decorationBorder: DecorationBorderFieldset,
  palette: PaletteTextFieldset,
  spacing: SpacingFieldset,
  graphic: GraphicFieldset,
  breakpoint: BreakpointFieldset
}

export default Fieldsets
