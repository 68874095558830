import { Select } from '../Select.js'
import FieldsetField from '../FieldsetField.js'
import { DatasourceModel } from '@sitecore-feaas/sdk'

const DatasourceSettingsMethod = ({
  currentDatasource,
  previousDatasource,
  onChange,
  isBlock
}: {
  currentDatasource: DatasourceModel
  previousDatasource: DatasourceModel
  onChange: (datasource: DatasourceModel) => void
  isBlock: boolean
}) => (
  <FieldsetField
    extraProps={{ flex: 1, minW: '40' }}
    label='Method'
    isChanged={currentDatasource.settings.method !== previousDatasource.settings.method}
  >
    <Select
      isDisabled={!!currentDatasource.settings.body?.query}
      value={{
        label: currentDatasource.settings.method?.toUpperCase() || 'GET',
        value: currentDatasource.settings.method?.toUpperCase() || 'GET'
      }}
      options={[
        { value: 'GET', label: 'GET' },
        { value: 'POST', label: 'POST' }
      ]}
      onChange={({ value }: any) =>
        onChange(currentDatasource.change({ settings: { ...currentDatasource.settings, method: value } }))
      }
    />
  </FieldsetField>
)
export default DatasourceSettingsMethod
