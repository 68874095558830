import { Box, Button, Divider, HStack, Icon, IconButton, Tooltip, VStack } from '@chakra-ui/react'
import { Style, cloneDeep, mergeDeep } from '@sitecore-feaas/sdk'
import { useMemo, useRef } from 'react'
import FieldsetField from '../../FieldsetField.js'
import NumberField from '../../styles/fields/NumberField.js'
import SizeField from '../../styles/fields/SizeField.js'
import { Grid } from '../chrome/ChromeGrid.js'
import type { DialogStyleProps } from './Dialog.js'
import { Select } from '../../Select.js'
import { mdiInformationOutline, mdiMinus, mdiPlus } from '@mdi/js'
import { Text } from '@chakra-ui/react'

export default function DialogStyleGrid({ onChange, context, customRules, rules }: DialogStyleProps) {
  const style = Style.Set.getContextElementAspect(rules, context, 'grid', customRules) || Style.Rule({ type: 'grid' })
  const grid = useMemo(() => new Grid(style).snapshot(), [context, style])

  function setProperties(props: Partial<typeof style.props>) {
    onChange(
      mergeDeep(style, {
        props: props
      })
    )
  }

  function setGrid(grid: Grid) {
    setProperties(grid.props)
  }

  const expandableRows = grid.getExpandableRows()

  const nextExpandableRowSuggestion = [grid.props.rows.length - 1, 0]
    .concat(grid.props.rows.map((r, i) => i))
    .find((index) => expandableRows.indexOf(index) == -1)

  const setRowExpandable = (index: number) => {
    grid.props.rows[index].max = null
    setGrid(grid)
  }
  const setRowStatic = (index: number) => {
    grid.props.rows[index].max = grid.props.rows[index].min
    setGrid(grid)
  }

  const debounce = useRef<Record<string, ReturnType<typeof setTimeout>>>({ columns: null, rows: null })

  return (
    <VStack spacing={4} alignItems={'stretch'}>
      <HStack spacing='4' justifyContent={'stretch'}>
        <FieldsetField label='Number of rows'>
          <NumberField
            value={grid.props.rows.length}
            onChange={(value) => {
              clearTimeout(debounce.current.rows)
              debounce.current.rows = setTimeout(() => {
                setGrid(grid.setRowNumber(value))
              }, 600)
            }}
            withStepper={true}
          />
        </FieldsetField>
        <FieldsetField label='Columns'>
          <NumberField
            value={grid.props.columns.length}
            onChange={(value) => {
              clearTimeout(debounce.current.columns)
              debounce.current.columns = setTimeout(() => {
                setGrid(grid.setColumnNumber(value))
              }, 600)
            }}
            withStepper={true}
          />
        </FieldsetField>
      </HStack>
      <FieldsetField label='Row height'>
        <NumberField
          withStepper={true}
          value={grid.getRowHeight()}
          onChange={(value) => {
            setGrid(
              grid.setRowHeight({
                value,
                unit: 'px'
              })
            )
          }}
        />
      </FieldsetField>
      <Divider></Divider>
      <Text as='h2' fontSize='14px' fontWeight='600'>
        Expandable rows{' '}
        <Tooltip label='Rows marked as expandable will grow beyond its initial size to fit content like text or images'>
          <Icon fontSize='icon-lg' ml={2} mt={'-2px'}>
            <path d={mdiInformationOutline} />
          </Icon>
        </Tooltip>
      </Text>
      {expandableRows.map((row, index) => {
        return (
          <HStack alignItems={'center'} key={index}>
            <Box flexGrow={1}>
              <Select
                value={{
                  name: String(row + 1),
                  value: row
                }}
                options={[
                  {
                    label: 'Positions',
                    options: [
                      { name: 'First', value: 0, isDisabled: expandableRows.includes(0) },
                      grid.props.rows.length > 1 && {
                        name: 'Middle',
                        value: Math.floor(grid.props.rows.length / 2),
                        isDisabled: expandableRows.includes(Math.floor(grid.props.rows.length / 2))
                      },
                      grid.props.rows.length > 1 && {
                        name: 'Last',
                        value: grid.props.rows.length - 1,
                        isDisabled: expandableRows.includes(grid.props.rows.length - 1)
                      }
                    ].filter(Boolean)
                  },
                  {
                    label: 'Rows',
                    options: grid.props.rows.map((row, index) => {
                      return {
                        name: String(index + 1),
                        value: index,
                        isDisabled: expandableRows.includes(index)
                      }
                    })
                  }
                ]}
                getOptionLabel={(c) => c.name}
                getOptionValue={(c) => String(c.value)}
                onChange={(choice) => {
                  setRowStatic(row)
                  setRowExpandable(choice.value)
                }}
                placeholder='Select version to embed'
              />
            </Box>
            <Tooltip label='Make selected row fixed-height'>
              <IconButton
                size='sm'
                variant={'secondary'}
                aria-label='Unassign flexible row'
                onClick={() => setRowStatic(row)}
                icon={
                  <Icon boxSize='icon-lg'>
                    <path d={mdiMinus} />
                  </Icon>
                }
              />
            </Tooltip>
          </HStack>
        )
      })}
      {nextExpandableRowSuggestion != null && (
        <Button
          variant={'link'}
          alignSelf='flex-start'
          size='xs'
          leftIcon={
            <Icon boxSize='icon-lg'>
              <path d={mdiPlus} />
            </Icon>
          }
          onClick={() => setRowExpandable(nextExpandableRowSuggestion)}
        >
          Make a row expandable
        </Button>
      )}
    </VStack>
  )
}
