import FieldsetField from '../FieldsetField.js'
import { Select } from '../Select.js'
import { SampleGeneration } from './Datasource.js'

const DatasourceGeneratorSelect = ({
  sampleGenerationIndex,
  currentDatasource,
  setSampleGenerationIndex
}: {
  setSampleGenerationIndex: any
  sampleGenerationIndex: number
  currentDatasource: any
}) => (
  <FieldsetField label='Data'>
    <Select
      onChange={({ value }: { value: number }) => setSampleGenerationIndex(value)}
      options={[
        { label: 'Paste JSON', value: SampleGeneration.PASTE },
        { label: 'Fetch from URL', value: SampleGeneration.FETCH },
        { label: 'Fetch from GraphQL', value: SampleGeneration.GRAPHQL }
      ]}
      value={{
        label:
          sampleGenerationIndex === SampleGeneration.PASTE
            ? 'Paste JSON'
            : sampleGenerationIndex === SampleGeneration.FETCH
            ? 'Fetch from URL'
            : 'Fetch from GraphQL',
        value:
          sampleGenerationIndex === SampleGeneration.PASTE
            ? SampleGeneration.PASTE
            : sampleGenerationIndex === SampleGeneration.FETCH
            ? SampleGeneration.FETCH
            : SampleGeneration.GRAPHQL
      }}
    />
  </FieldsetField>
)

export default DatasourceGeneratorSelect
