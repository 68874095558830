import { useEffect, useRef, useState } from 'react'
import FormError from './FormError.js'
import { Input, InputGroup, InputRightAddon, Text } from '@chakra-ui/react'

const TextField = ({
  value,
  error,
  onBlur = () => {},
  disabled,
  ariaLabel,
  placeholder,
  caption,
  forceShowErrors,
  withAddon,
  onChange,
  type = 'text'
}: {
  value: any
  error?: string
  onBlur?: any
  disabled?: boolean
  ariaLabel?: string
  placeholder?: string
  caption?: string
  forceShowErrors?: boolean
  withAddon?: string
  onChange: (value: string) => void
  type?: string
}) => {
  const [isDirty, setIsDirty] = useState(false)

  const showErrors = error && (isDirty || forceShowErrors)

  const onFieldChange = ({ target }: { target: HTMLInputElement }) => {
    setIsDirty(true)

    onChange(target.value)
  }

  return (
    <>
      <InputGroup>
        <Input
          isInvalid={showErrors}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={onFieldChange}
          onBlur={onBlur}
          aria-label={ariaLabel}
          type={type}
        />

        {withAddon && <InputRightAddon children={withAddon} />}
      </InputGroup>

      {caption && (
        <Text fontSize='xs' mt='2'>
          {caption}
        </Text>
      )}

      {showErrors && <FormError error={error} />}
    </>
  )
}

export default TextField
