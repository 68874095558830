import { Box, BoxProps, Flex, FlexProps, Skeleton, SkeletonProps, Text } from '@chakra-ui/react'
import { renderComponent, Thumbnail } from '@sitecore-feaas/clientside/headless'
import { ComponentModel, Style, StylesheetModel, VersionModel } from '@sitecore-feaas/sdk'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { EnvironmentVariables } from './providers/EnvironmentProvider.js'

const ThumbnailEmpty = (status: string): FlexProps => ({
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
  display: 'flex',
  background: 'gray.50',
  position: 'absolute',
  inset: 0,
  flexDirection: 'column'
})

export const ThumbnailEmptyIcon = {
  width: '100px',
  height: '100px'
}

export const Wrapper = (overflow: number, hovered: boolean): BoxProps => ({
  justifyContent: 'center',
  pointerEvents: 'none',
  userSelect: 'none',
  alignItems: 'center',
  width: 'full',
  padding: 0,
  transitionTimingFunction: 'linear',
  willChange: 'transform',
  transition: hovered ? `transform ${overflow * 0.009}s linear` : `transform ${overflow * 0.002}s`,
  transform: hovered && `translateY(-${overflow - 10}px)`
})

export const ThumbnailSkeleton = (status: any): SkeletonProps => ({
  height: 'full',
  pointerEvents: 'none',
  opacity: 0,
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
})

type ImgRef = React.MutableRefObject<HTMLImageElement> & {
  setImage: (img: HTMLImageElement) => void
}

const ComponentVersionThumbnail = ({
  children,
  component: original,
  version,
  targetHeight = 200,
  onLoad,
  stylesheet,
  ...props
}: {
  children?: any
  component: ComponentModel
  version?: VersionModel
  targetHeight?: number
  stylesheet?: StylesheetModel
  onLoad?: (image: HTMLImageElement) => any

  [key: string]: any
}) => {
  const component = useMemo(() => original.clone(), [original])
  const [status, setNextStatus] = useState<'fetching' | 'showing' | 'cached' | 'empty'>(
    component.isNew || version?.isViewEmpty() ? 'empty' : 'fetching'
  )
  const imgRef = useRef<HTMLImageElement>(null) as ImgRef

  function setStatus(state: typeof status = status) {
    setNextStatus(state)
    const skeleton = imgRef.current?.closest('.scroll-wrapper')?.nextElementSibling as HTMLElement
    if (skeleton) {
      Object.assign(skeleton.style, {
        opacity: state !== 'fetching' ? 0 : 0.3,
        transition: state == 'cached' ? 'opacity .0s' : 'opacity 0.3s'
      })
    }
    const empty = skeleton?.nextElementSibling as HTMLElement
    if (empty) {
      Object.assign(empty.style, {
        transition: state == 'fetching' || state == 'cached' ? 'opacity .0s' : 'opacity 0.3s',
        opacity: state == 'empty' ? '1' : '0'
      })
    }
  }
  useLayoutEffect(() => {
    if (stylesheet) {
      Thumbnail.generate(component, version, stylesheet).then((img) => {
        if (img) {
          imgRef.setImage(img)
          onLoad?.(imgRef.current)
        }
        setStatus(!img ? 'empty' : 'showing')
      })
    } else {
      Thumbnail.get(component, version, (img, isCached) => {
        if (img) {
          imgRef.setImage(img)
          onLoad?.(imgRef.current)
        }
        setStatus(!img ? 'empty' : isCached ? 'cached' : 'showing')
      })
    }
  }, [component, version])

  useLayoutEffect(() => {
    setStatus(status)
  }, [])

  return (
    <ScrollableThumbnail imgRef={imgRef} targetHeight={targetHeight} {...props}>
      <Flex {...ThumbnailEmpty(status)} className='thumbnail-empty' alignItems={'center'} justifyContent={'center'}>
        <img
          {...ThumbnailEmptyIcon}
          src='https://sitecorecontenthub.stylelabs.cloud/api/public/content/5d2c4616a0304589818f265edc4471ce'
          alt='Click to start editing'
        />
        {children}
      </Flex>
    </ScrollableThumbnail>
  )
}

type ThumbnailStatus = 'fetching' | 'showing' | 'cached' | 'empty'

export const ScrollableThumbnail = ({
  imgRef,
  src,
  targetHeight = 200,
  status,
  children,
  ...props
}: {
  imgRef?: ImgRef
  src?: string
  children?: any
  targetHeight?: number
  status?: ThumbnailStatus
  [key: string]: any
}) => {
  const [overflow, setOverflow] = useState(0)
  const [hovered, setHovered] = useState(false)

  const ownRef = useRef<HTMLImageElement>(null) as ImgRef
  imgRef ||= ownRef

  if (!status) {
    status = imgRef.current?.src ? 'showing' : 'fetching'
  }

  const setImage = (img: HTMLImageElement) => {
    if (!imgRef.current) return
    imgRef.current.src = img.src

    const wrapper = imgRef.current.closest('.thumbnail-wrapper') as HTMLElement
    const height = (img.height / img.width) * wrapper.offsetWidth
    wrapper.classList.toggle('overflowing', height > targetHeight)
    setOverflow(Math.max(0, height - targetHeight))
    imgRef.current.style.opacity = '1'
    // important to do it here to avoid flashing
    ;(imgRef.current.closest('.scroll-wrapper') as HTMLElement).style.alignSelf =
      height > targetHeight ? 'flex-start' : 'center'
  }
  if (imgRef) {
    imgRef.setImage = setImage
  }

  useLayoutEffect(() => {
    if (src) {
      const img = new Image()
      img.src = src
      img.onload = () => {
        setImage(img)
      }
    }
  }, [])

  useLayoutEffect(() => {
    const enable = () => setHovered(true)
    const disable = () => setHovered(false)
    const wrapper = imgRef.current.closest('.thumbnail-wrapper')
    wrapper.addEventListener('mouseenter', enable)
    wrapper.addEventListener('mouseleave', disable)

    return () => {
      wrapper.removeEventListener('mouseenter', enable)
      wrapper.removeEventListener('mouseleave', disable)
    }
  }, [])

  return (
    <>
      <Box {...Wrapper(overflow, hovered)} {...props} className='scroll-wrapper'>
        <img
          style={{
            pointerEvents: 'none',
            opacity: 0,
            transition: status == 'cached' ? 'none' : 'opacity 0.3s 0.3s'
          }}
          ref={imgRef}
        />
      </Box>
      <Skeleton {...ThumbnailSkeleton(status)} />
      {children}
    </>
  )
}

export default ComponentVersionThumbnail
