import { Box, useQuery } from '@chakra-ui/react'
import { Style, mergeDeep } from '@sitecore-feaas/sdk'
import { useLibrary } from '../../../hooks/useData.js'
import FieldsetField from '../../FieldsetField.js'
import FlexGrid from '../../FlexGrid.js'
import { Select } from '../../Select.js'
import TextField from '../../TextField.js'
import StyleCSSReference from '../StyleCSSReference.js'
import { StyleFormProps } from './index.js'
import useWindowQueryUpdate from '../../../hooks/useWindowQueryUpdate.js'

const DetailsFieldset = ({ rule, rules, currentRule, onChange, errors, forceShowErrors }: StyleFormProps) => {
  const [{ origin }] = useWindowQueryUpdate()

  const collectionsOptions = rules
    .filter((s) => s.type === 'collection' && s.props.type === currentRule.type)
    .map((s) => ({
      label: s.details.title,
      value: s.details.id
    }))

  const {
    type,
    details: { title, description, exampleContent }
  } = currentRule

  const {
    details: { title: oldTitle, description: oldDescription, exampleContent: oldExampleContent }
  } = rule

  let caption =
    rule.type !== 'collection' && rule.type !== 'font' && origin
      ? '*Names need to be unique before accessing other tabs'
      : `*Name is required before accessing other tabs`

  if (
    rule.details.title ||
    rule.type === 'color' ||
    rule.type === 'breakpoint' ||
    rule.type === 'graphic' ||
    rule.type == 'collection'
  ) {
    caption = ``
  }

  const hasCollectionOption = !['collection', 'font', 'block', 'inline', 'text', 'theme'].includes(currentRule.type)

  const hasCSSReference = !(
    rule.type === 'collection' ||
    rule.type === 'font' ||
    rule.type === 'color' ||
    rule.type === 'breakpoint' ||
    rule.type === 'graphic'
  )

  return (
    <FlexGrid childrenWidth='100%'>
      <Box>
        {type !== 'font' && (
          <FieldsetField label='Name' isChanged={title !== oldTitle} required={true} maxWidth='sm'>
            <TextField
              value={title}
              ariaLabel='Name'
              caption={caption}
              error={errors?.details?.title}
              forceShowErrors={forceShowErrors}
              onChange={(value: string) => {
                onChange(mergeDeep(currentRule, { details: { title: value } }))
              }}
            />
          </FieldsetField>
        )}

        {hasCollectionOption && (
          <Box mt={5}>
            <FieldsetField
              label='Collection'
              isChanged={rule.details.collectionId !== currentRule.details.collectionId}
              maxWidth='sm'
            >
              <Select
                menuPosition='fixed'
                isSearchable={false}
                isDisabled={collectionsOptions.length === 1}
                options={collectionsOptions}
                value={{
                  value: currentRule.details.collectionId,
                  label: collectionsOptions.find(({ value }) => value === currentRule.details.collectionId).label
                }}
                onChange={({ value, label }: { value: any; label: any }) => {
                  onChange(mergeDeep(currentRule, { details: { collectionId: value } }))
                }}
              />
            </FieldsetField>
          </Box>
        )}
      </Box>

      {hasCSSReference && (
        <Box
          mt={'-1px'}
          pt={'1px'}
          onClick={(e) => {
            if (e.metaKey || e.ctrlKey || e.altKey) {
              const slug = prompt('Change slug? Ensure to re-apply the style again', rule.details.slug)
              if (slug && slug != rule.details.slug) {
                onChange(
                  mergeDeep(rule, {
                    details: {
                      slug: slug
                    }
                  })
                )
              }
            }
          }}
        >
          <StyleCSSReference rule={currentRule} rules={rules} />
        </Box>
      )}
    </FlexGrid>
  )
}

export default DetailsFieldset
