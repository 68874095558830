import { isDeepEquals, Style, Unformatted } from '@sitecore-feaas/sdk'
import { Button, Grid, GridItem, FormLabel, Text, Switch } from '@chakra-ui/react'
import { useState } from 'react'
import { mergeDeep } from '@sitecore-feaas/sdk'
import FieldsetField from '../../FieldsetField.js'
import { Select } from '../../Select.js'
import ColorField from '../fields/ColorField.js'
import NumberField from '../fields/NumberField.js'
import SwitchGroupField from '../fields/SwitchGroupField.js'
import OpacityField from '../fields/OpacityField.js'
import { StyleFormProps } from './index.js'

const DecorationBorderFieldset = ({
  rule,
  currentRule,
  isActive,
  onChange,
  rules,
  label
}: StyleFormProps<'decoration'>) => {
  const {
    props: { borderRightWidth, borderLeftWidth, borderTopWidth, borderBottomWidth, borderStyle, borderColor }
  } = currentRule

  const borderStyles = [
    { value: 'solid', label: 'Solid' },
    { value: 'dotted', label: 'Dotted' },
    { value: 'dashed', label: 'Dashed' }
  ]

  const {
    props: {
      borderRightWidth: oldBorderRightWidth,
      borderLeftWidth: oldBorderLeftWidth,
      borderTopWidth: oldBorderTopWidth,
      borderBottomWidth: oldBorderBottomWidth,
      borderStyle: oldBorderStyle,
      borderColor: oldBorderColor
    }
  } = rule

  const colors = rules.filter((s) => s.type == 'color') as Style.Rule<'color'>[]

  const onUpdate = (changes: Unformatted<Style.Decoration.Border>) =>
    onChange(mergeDeep(currentRule, { props: mergeDeep(currentRule.props, changes) }))

  const borders = [borderRightWidth, borderLeftWidth, borderBottomWidth, borderTopWidth]

  const getIndex = () => {
    if (!Style.Decoration.Border.isValid(currentRule.props)) {
      return 0
    }

    if (borders.every((width) => width.value === borderTopWidth.value)) {
      return 1
    }

    return 2
  }

  const onIndexChange = (index: number) => {
    if (index == 1 || index == 2) {
      onUpdate({
        borderBottomWidth: { value: borderTopWidth.value },
        borderRightWidth: { value: borderTopWidth.value },
        borderLeftWidth: { value: borderTopWidth.value },
        borderTopWidth: { value: borderTopWidth.value }
      })
    }
    if (index == 0) {
      onUpdate(Style.Decoration.Border())
    }
    setIndex(index)
  }

  const [index, setIndex] = useState(getIndex)

  return (
    <>
      {!isActive && (
        <FormLabel display='flex' alignItems='center' mb={0}>
          <Switch isChecked={index !== 0} onChange={(e) => setIndex(e.target.checked ? 1 : 0)} />
          <Text ml='2'>{label}</Text>
        </FormLabel>
      )}{' '}
      {(isActive || index !== 0) && (
        <>
          <SwitchGroupField value={index} onChange={onIndexChange} mb={6}>
            {isActive && <Button>None</Button>}
            <Button>Same width</Button>
            <Button>Per side</Button>
          </SwitchGroupField>

          {index > 0 && (
            <Grid templateColumns='repeat(2, 1fr)' rowGap={isActive ? 5 : 4} columnGap={3}>
              <GridItem>
                <FieldsetField isChanged={!Style.areSameColors(borderColor, oldBorderColor)} label='Color'>
                  <ColorField
                    rules={rules}
                    colorReference={borderColor}
                    onChange={(color) => onUpdate({ borderColor: color })}
                    size={!isActive ? 'sm' : null}
                  />
                </FieldsetField>
              </GridItem>

              <GridItem>
                <FieldsetField
                  isChanged={Style.findAlpha(borderColor, colors) !== Style.findAlpha(oldBorderColor, colors)}
                  label='Opacity'
                >
                  <OpacityField
                    value={Style.findAlpha(borderColor, colors)}
                    onChange={(alpha: number) => onUpdate({ borderColor: { alpha } })}
                    size={!isActive ? 'sm' : null}
                  />
                </FieldsetField>
              </GridItem>

              <GridItem>
                <FieldsetField label='Style'>
                  <Select
                    menuPosition='fixed'
                    options={borderStyles}
                    value={borderStyles.find(({ value }) => borderStyle === value)}
                    onChange={({ value }) => onUpdate({ borderStyle: value })}
                    size={!isActive ? 'sm' : null}
                  />
                </FieldsetField>
              </GridItem>

              {index == 2 && (
                <>
                  <GridItem>
                    <FieldsetField label='Top border' isChanged={!isDeepEquals(borderTopWidth, oldBorderTopWidth)}>
                      <NumberField
                        value={borderTopWidth.value}
                        onChange={(value) => onUpdate({ borderTopWidth: { value } })}
                        withAddon='px'
                        size={!isActive ? 'sm' : null}
                      />
                    </FieldsetField>
                  </GridItem>

                  <GridItem>
                    <FieldsetField
                      label='Right border'
                      isChanged={!isDeepEquals(borderRightWidth, oldBorderRightWidth)}
                    >
                      <NumberField
                        value={borderRightWidth.value}
                        onChange={(value) => onUpdate({ borderRightWidth: { value } })}
                        withAddon='px'
                        size={!isActive ? 'sm' : null}
                      />
                    </FieldsetField>
                  </GridItem>

                  <GridItem>
                    <FieldsetField
                      isChanged={!isDeepEquals(borderBottomWidth, oldBorderBottomWidth)}
                      label='Bottom border'
                    >
                      <NumberField
                        value={borderBottomWidth.value}
                        onChange={(value) => onUpdate({ borderBottomWidth: { value } })}
                        withAddon='px'
                        size={!isActive ? 'sm' : null}
                      />
                    </FieldsetField>
                  </GridItem>

                  <GridItem>
                    <FieldsetField isChanged={!isDeepEquals(borderLeftWidth, oldBorderLeftWidth)} label='Left border'>
                      <NumberField
                        value={borderLeftWidth.value}
                        onChange={(value) => onUpdate({ borderLeftWidth: { value } })}
                        withAddon='px'
                        size={!isActive ? 'sm' : null}
                      />
                    </FieldsetField>
                  </GridItem>
                </>
              )}

              {index == 1 && (
                <FieldsetField isChanged={!isDeepEquals(borderLeftWidth, oldBorderLeftWidth)} label='Border'>
                  <NumberField
                    withAddon='px'
                    value={borderLeftWidth.value}
                    onChange={(value) => {
                      onUpdate({
                        borderBottomWidth: { value },
                        borderRightWidth: { value },
                        borderLeftWidth: { value },
                        borderTopWidth: { value }
                      })
                    }}
                    size={!isActive ? 'sm' : null}
                  />
                </FieldsetField>
              )}
            </Grid>
          )}
        </>
      )}
    </>
  )
}
export default DecorationBorderFieldset
