import { Badge, BadgeProps, Box, HStack, Icon, Tooltip } from '@chakra-ui/react'
import { mdiCached, mdiClose } from '@mdi/js'
import { BranchParams } from './DatasourceTree.js'
import { ReactNode } from 'react'
import { css } from '@emotion/react'

export type LeafStatus = 'new' | 'removed' | 'changed' | 'unchanged'

export interface DataLeafProps extends Pick<BranchParams, 'action' | 'isVerbose' | 'label' | 'path'> {
  children?: ReactNode
  warning: string
  error: string
  onClick: (...args: any[]) => any
  onClear: (...args: any[]) => any
}

const DatasourceTreeLeafWrapper = ({
  children,
  warning,
  action,
  isVerbose,
  error,
  onClick,
  onClear
}: DataLeafProps) => {
  const badgeProps: BadgeProps = {
    borderRadius: 'lg',
    margin: '1',
    variant: 'datapath'
  }
  const buttonBadgeProps: BadgeProps = {
    ...badgeProps,
    cursor: 'pointer',
    as: 'button',
    onClick
  }
  if (error) {
    if (!isVerbose) return
    return (
      <Tooltip label={error}>
        <Badge {...badgeProps} colorScheme='red'>
          {children}
        </Badge>
      </Tooltip>
    )
  }

  if (action == 'trimming') {
    return (
      <Badge
        {...buttonBadgeProps}
        colorScheme='gray'
        css={css`
          &:hover {
            outline: 1px solid var(--chakra-colors-primary-600);
          }
        `}
      >
        {children}
      </Badge>
    )
  } else if (action == 'repeating') {
    return (
      <Badge
        {...buttonBadgeProps}
        colorScheme='gray'
        css={css`
          &:hover {
            outline: 1px solid var(--chakra-colors-primary-600);
          }
        `}
      >
        {children}{' '}
      </Badge>
    )
  } else if (action == 'mapping' || action == 'scoping') {
    if (warning) {
      return (
        <Tooltip label={warning}>
          <Badge {...buttonBadgeProps} colorScheme='gray' opacity={0.5}>
            {children}
          </Badge>
        </Tooltip>
      )
    } else {
      return (
        <Badge
          {...buttonBadgeProps}
          colorScheme='gray'
          css={css`
            &:hover {
              outline: 1px solid var(--chakra-colors-blackAlpha-400);
            }
          `}
        >
          {children}
        </Badge>
      )
    }
  } else if (action == 'repeating-mapping') {
    return (
      <Badge {...buttonBadgeProps} colorScheme='gray' cursor='pointer'>
        <HStack display='inline-flex' verticalAlign={'middle'}>
          {children}
        </HStack>
      </Badge>
    )
  } else if (action == 'nesting') {
    return (
      <Badge {...badgeProps} bg='white'>
        <HStack display='inline-flex' verticalAlign={'middle'}>
          {children}{' '}
        </HStack>
      </Badge>
    )
  } else if (action == 'indicating') {
    return (
      <>
        <Badge {...buttonBadgeProps} colorScheme='primary' variant={'outline'} background={'primary.100'}>
          <HStack display='inline-flex' verticalAlign={'middle'}>
            {children}
            <Icon
              onClick={(e) => {
                e.stopPropagation()
                onClear()
              }}
            >
              <path d={mdiClose} />
            </Icon>
          </HStack>
        </Badge>
      </>
    )
  } else if (action == 'rooting') {
    return (
      <Badge {...buttonBadgeProps} colorScheme='primary'>
        {children}
      </Badge>
    )
  } else {
    return (
      <Badge {...badgeProps} bg='blackAlpha.50' px={0}>
        <Box cursor={'not-allowed'} display={'inline-block'} color={'blackAlpha.300'} verticalAlign={'middle'}>
          {children}
        </Box>
      </Badge>
    )
  }
}

export default DatasourceTreeLeafWrapper
