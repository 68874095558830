import { Box, Flex } from '@chakra-ui/react'
import { useContext } from 'react'
import { useSlots } from '../../hooks/useSlots.js'
import EntityActions from './EntityActions.js'
import { EntityContext } from '../../contexts/EntityContext.js'
import EntityModal from './EntityModal.js'

const EntityCollection = ({ children, className }: any) => {
  const {
    isActive,
    isNonEditable,
    ref,
    id,
    getClassname,
    activityAttribute,
    onToggleEntity,
    wrapperClassname,
    withModal
  } = useContext(EntityContext)

  return useSlots(children, ({ preview, details, form, extraActions, extraMenuActions, children }) => {
    if (!preview && !details && !extraActions && !extraMenuActions) return children

    return (
      <Box
        {...activityAttribute}
        position='relative'
        borderRadius='base'
        background={!withModal && isActive && 'blackAlpha.100'}
        marginTop={5}
        marginLeft={-5}
        marginRight={-5}
        paddingLeft={5}
        paddingRight={5}
        paddingBottom={3}
        id={`entity-${id}`}
        ref={ref}
        className={wrapperClassname}
      >
        <Flex onClick={onToggleEntity} cursor={!isNonEditable && 'pointer'}>
          <Box flex='1' position='relative' className={getClassname('upper')}>
            {preview && <Box className={getClassname('preview')}>{preview}</Box>}

            {details && (
              <Box mt={preview && '5'} className={getClassname('details')}>
                {details}
              </Box>
            )}
          </Box>

          <Flex ml='auto' pt={!withModal ? 5 : 0} className={getClassname('actions')}>
            <EntityActions extraMenuActions={extraMenuActions} extraActions={extraActions} />
          </Flex>
        </Flex>

        {withModal && <EntityModal>{form}</EntityModal>}
        {!withModal && isActive && <Box className={getClassname('form')}>{form}</Box>}

        {children}
      </Box>
    )
  })
}

export default EntityCollection
