import { DialogAttributeGeneric } from './Dialog.js'
import { useSDK } from '../../../hooks/useData.js'
import React, { useEffect, useState } from 'react'
import * as CK from '@sitecore-feaas/ckeditor5'
import { Box } from '@chakra-ui/react'
import { DatasourceModel } from '@sitecore-feaas/sdk'
import { DataPath } from '@sitecore-feaas/clientside/headless'
import PickerWizard from '../../PickerWizard.js'
import PickerDatasourcesSource from '../../picker/PickerDatasourcesSource.js'
import PickerDatasourcesPath from '../../picker/PickerDatasourcesPath.js'
import PickerDatasourcesItems from '../../picker/PickerDatasourcesItems.js'
import { PickerProps } from '../../picker/Picker.js'
function getDefaultDatasource(model: CK.ModelElement, datasources: DatasourceModel[]) {
  function getDatasourceFromElement(model: CK.ModelElement) {
    for (const [name, value] of model.getAttributes()) {
      if (name.startsWith('data-path')) {
        const id = String(value).replace(/^\$.?/, '').split(/\.|\[/)[0]

        const datasource = datasources.find((d) => d.id == id)

        return datasource
      }
    }
  }

  for (var m = model; m; m = m.parent as CK.ModelElement) {
    const datasource = getDatasourceFromElement(m)

    if (datasource) return datasource
  }

  function walkModel(element: CK.ModelElement, callback: (element: CK.ModelElement) => void) {
    callback(element)

    for (const child of element.getChildren()) {
      if (child.is('element')) {
        walkModel(child, callback)
      }
    }
  }

  var datasource
  for (const rootName of model.root.document.getRootNames()) {
    const root = model.root.document.getRoot(rootName)

    if (!datasource)
      walkModel(root as CK.ModelElement, (child) => {
        datasource ||= getDatasourceFromElement(child)
      })
  }

  return datasource
}

const DialogAttributesMapping = ({
  context,
  jsonpath,
  type,
  onConfigure,
  label,
  onModeChange
}: DialogAttributeGeneric) => {
  const [showWizard, setShowWizard] = useState(true)

  const datasources = useSDK('datasources')
  const qualifier = DataPath.getQualifier(jsonpath)
  const [repeating, setRepeating] = useState<'no-limit' | 'limit' | 'range'>(() => {
    return DataPath.parseLimit(qualifier) ? 'limit' : DataPath.parseRange(qualifier) ? 'range' : 'no-limit'
  })

  const [textpath, setTextpath] = useState(jsonpath)
  useEffect(() => {
    setTextpath(jsonpath)
    if (!jsonpath) setRepeating('no-limit')
  }, [jsonpath])

  const [datasource, setDatasource] = useState(() => getDefaultDatasource(context as CK.ModelElement, datasources))

  const steps =
    type === 'array'
      ? [
          {
            title: 'Source'
          },
          { title: 'Path' },
          { title: 'Items' }
        ]
      : [{ title: 'Source' }, { title: 'Path' }]

  if (!showWizard) return null

  return (
    <Box mt={'-24px'} background={'white'} zIndex={2} className={'datasource-mapping'}>
      <PickerWizard
        defaultStep={!datasource ? 0 : type === 'array' && !jsonpath ? 1 : steps.length - 1}
        steps={steps}
        onComplete={() => onModeChange(null)}
      >
        <PickerDatasourcesSource datasources={datasources} datasource={datasource} onDatasourceChange={setDatasource} />
        <PickerDatasourcesPath
          context={context}
          type={type}
          datasource={datasource}
          textpath={textpath}
          jsonpath={jsonpath}
          onTextpathChange={setTextpath}
          onConfigure={onConfigure}
          onRepeatingChange={setRepeating}
        />
        <PickerDatasourcesItems
          datasource={datasource}
          jsonpath={jsonpath}
          repeating={repeating}
          qualifier={qualifier}
          onRepeatingChange={setRepeating}
          onConfigure={onConfigure}
        />
      </PickerWizard>
    </Box>
  )
}

export default DialogAttributesMapping
