import { isDeepEquals, Style, Unformatted } from '@sitecore-feaas/sdk'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { mergeDeep } from '@sitecore-feaas/sdk'
import NumberField from '../fields/NumberField.js'
import OpacityField from '../fields/OpacityField.js'
import FieldsetField from '../../FieldsetField.js'
import { StyleFormProps } from './index.js'

const FillEffectsFieldset = ({
  rule: style,
  currentRule: currentStyle,
  onChange,
  isActive,
  label
}: StyleFormProps<'fill'>) => {
  const {
    props: { blur, opacity }
  } = currentStyle

  const {
    props: { blur: oldBlur, opacity: oldOpacity }
  } = style

  const onUpdate = (changes: Unformatted<Style.Fill.Props>) =>
    onChange(mergeDeep(currentStyle, { props: mergeDeep(currentStyle.props, changes) }))

  return (
    <>
      {label && (
        <Text fontSize='md' as='h4' fontWeight='600'>
          {label}
        </Text>
      )}
      <Grid templateColumns={isActive ? 'repeat(1, 1fr)' : '60% auto'} gap={isActive ? 6 : 3}>
        <GridItem colSpan={1}>
          <FieldsetField label='Blur' isChanged={!isDeepEquals(blur, oldBlur)}>
            <NumberField
              value={blur.value}
              onChange={(value) => onUpdate({ blur: { value } })}
              size={!isActive ? 'sm' : null}
            />
          </FieldsetField>
        </GridItem>

        <GridItem colSpan={1}>
          <FieldsetField label='Opacity' isChanged={opacity !== oldOpacity}>
            <OpacityField
              value={opacity}
              onChange={(opacity) => onUpdate({ opacity })}
              size={!isActive ? 'sm' : null}
            />
          </FieldsetField>
        </GridItem>
      </Grid>
    </>
  )
}

export default FillEffectsFieldset
