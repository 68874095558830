import FieldsetField from '../FieldsetField.js'
import { Textarea } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { DatasourceModel, isDeepEquals } from '@sitecore-feaas/sdk'
import { isValidJSON } from '../../utils/string.js'

const DatasourceSettingsGraphqlVariables = ({
  currentDatasource,
  previousDatasource,
  onChange
}: {
  currentDatasource: DatasourceModel
  previousDatasource: DatasourceModel
  onChange: (datasource: DatasourceModel) => void
}) => {
  const [input, setInput] = useState('')

  useEffect(() => {
    if (!isValidJSON(input)) return

    onChange(
      currentDatasource.change({
        settings: {
          ...currentDatasource.settings,
          body: { ...currentDatasource.settings?.body, variables: JSON.parse(input) }
        }
      })
    )
  }, [input])

  return (
    <FieldsetField
      extraProps={{ mt: 6 }}
      label='Variables'
      isChanged={
        !isDeepEquals(currentDatasource.settings?.body?.variables, previousDatasource.settings?.body?.variables)
      }
    >
      <Textarea rows={4} value={input} onChange={(e) => setInput(e.target.value)} />
    </FieldsetField>
  )
}

export default DatasourceSettingsGraphqlVariables
