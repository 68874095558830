import { Box } from '@chakra-ui/react'
import { mergeDeep, Style } from '@sitecore-feaas/sdk'
import TextField from '../../TextField.js'
import FieldsetField from '../../FieldsetField.js'
import { StyleFormProps } from './index.js'

const GraphicFieldset = ({ currentRule, onChange }: StyleFormProps<'graphic'>) => {
  return (
    <Box mt={5}>
      <FieldsetField label='Paste URL' required={true}>
        <TextField
          value={currentRule.props.url}
          onChange={(url) => onChange(mergeDeep(currentRule, { props: { url } }))}
        />
      </FieldsetField>
    </Box>
  )
}

export default GraphicFieldset
