import { Box, Button, Divider, Flex, HStack, Icon, IconButton, Switch, Text, Tooltip, VStack } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { mdiArrowLeftRight, mdiInformationOutline, mdiMinus, mdiPlus } from '@mdi/js'
import type * as CK from '@sitecore-feaas/ckeditor5'
import { Style, mergeDeep } from '@sitecore-feaas/sdk'
import * as React from 'react'
import { ReactComponent as HorizontallyCenterIcon } from '../../../svg/Horizontally-Center.svg'
import { ReactComponent as HorizontallyDistributeIcon } from '../../../svg/Horizontally-Distribute.svg'
import { ReactComponent as HorizontallyJustifyIcon } from '../../../svg/Horizontally-Justify.svg'
import { ReactComponent as HorizontallyLeftIcon } from '../../../svg/Horizontally-Left.svg'
import { ReactComponent as HorizontallyRightIcon } from '../../../svg/Horizontally-Right.svg'
import { ReactComponent as HorizontallyStretchIcon } from '../../../svg/Horizontally-Stretch.svg'
import { ReactComponent as VerticallyJustify } from '../../../svg/Vertical-Space-Between.svg'
import { ReactComponent as VerticallyBottomIcon } from '../../../svg/Vertically-Bottom.svg'
import { ReactComponent as VerticallyCenterIcon } from '../../../svg/Vertically-Center.svg'
import { ReactComponent as VerticallyStretchIcon } from '../../../svg/Vertically-Stretch.svg'
import { ReactComponent as VerticallyTopIcon } from '../../../svg/Vertically-Top.svg'
import ButtonGroupSwitch from '../../ButtonGroupSwitch.js'
import type { DialogStyleProps } from './Dialog.js'

interface Alignment {
  property: keyof Style.Rule<'layout'>['props']
  value: `${Style.Layout.AlignItems | Style.Layout.JustifyContent}`
  label: string
  IconComponent: React.FC<React.ComponentProps<'svg'> & { title?: string }>
}

const horizontalAlignmentRow: Alignment[] = [
  { property: 'justifyContent', value: 'flex-start', label: 'Left', IconComponent: HorizontallyLeftIcon },
  { property: 'justifyContent', value: 'center', label: 'Center', IconComponent: HorizontallyCenterIcon },
  { property: 'justifyContent', value: 'space-between', label: 'Justify', IconComponent: HorizontallyJustifyIcon },
  { property: 'justifyContent', value: 'space-evenly', label: 'Distribute', IconComponent: HorizontallyDistributeIcon },
  { property: 'justifyContent', value: 'stretch', label: 'Stretch', IconComponent: HorizontallyStretchIcon },
  { property: 'justifyContent', value: 'flex-end', label: 'Right', IconComponent: HorizontallyRightIcon }
]

const verticalAlignmentRow: Alignment[] = [
  { property: 'alignItems', value: 'flex-start', label: 'Top', IconComponent: VerticallyTopIcon },
  { property: 'alignItems', value: 'center', label: 'Center', IconComponent: VerticallyCenterIcon },
  { property: 'alignItems', value: 'stretch', label: 'Stretch', IconComponent: VerticallyStretchIcon },
  { property: 'alignItems', value: 'flex-end', label: 'Bottom', IconComponent: VerticallyBottomIcon }
]

const horizontalAlignmentColumn: Alignment[] = [
  { property: 'alignItems', value: 'flex-start', label: 'Left', IconComponent: HorizontallyLeftIcon },
  { property: 'alignItems', value: 'center', label: 'Center', IconComponent: HorizontallyCenterIcon },
  { property: 'alignItems', value: 'stretch', label: 'Stretch', IconComponent: HorizontallyStretchIcon },
  { property: 'alignItems', value: 'flex-end', label: 'Right', IconComponent: HorizontallyRightIcon }
]

const verticalAlignmentColumn: Alignment[] = [
  { property: 'justifyContent', value: 'flex-start', label: 'Top', IconComponent: VerticallyTopIcon },
  { property: 'justifyContent', value: 'center', label: 'Center', IconComponent: VerticallyCenterIcon },
  { property: 'justifyContent', value: 'space-between', label: 'Justify', IconComponent: VerticallyJustify },
  { property: 'justifyContent', value: 'stretch', label: 'Stretch', IconComponent: VerticallyStretchIcon },
  { property: 'justifyContent', value: 'flex-end', label: 'Bottom', IconComponent: VerticallyBottomIcon }
]

function getDefaultOption(options: Alignment[]) {
  return (
    options.find((v) => v.value == 'stretch') ||
    options.find((v) => v.value == 'center') ||
    options.find((v) => v.value == 'space-between')
  )
}

const boxesCss = css`
  .top {
    position: absolute;
    top: 4px;
    opacity: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  .bottom {
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }
  .box {
    position: relative;
    border-radius: 4px;
    flex-grow: 1;
    border: 1px dashed var(--chakra-colors-blackAlpha-800);
    height: 92px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    .top {
      opacity: 1;
    }
  }
`

export default function DialogLayout({ customRules, rules, context, onChange }: DialogStyleProps) {
  const style =
    Style.Set.getContextElementAspect(rules, context, 'layout', customRules) || Style.Rule({ type: 'layout' })

  const verticalOptions = style.props.columnCount == 1 ? verticalAlignmentColumn : verticalAlignmentRow
  const horizontalOptions = style.props.columnCount == 1 ? horizontalAlignmentColumn : horizontalAlignmentRow

  const vertical = verticalOptions.find((v) => style.props[v.property] == v.value)
  const horizontal = horizontalOptions.find((h) => style.props[h.property] == h.value)

  function setLayoutProperty<P extends keyof Style.Rule<'layout'>['props']>(
    property: P,
    value: Style.Rule<'layout'>['props'][P]
  ) {
    var props: Partial<typeof style.props> = {
      [property]: value
    }
    if ((property as string) == 'columnCount') {
      props.weights = new Array(value as number).fill(1)

      // swap horizontal/vertical
      if (value == 1 || style.props.columnCount == 1) {
        const nextVerticalOptions = value == 1 ? verticalAlignmentColumn : verticalAlignmentRow
        const nextHorizontalOptions = value == 1 ? horizontalAlignmentColumn : horizontalAlignmentRow

        // going from 1 to X - force stretch layout
        // going from X to 1 - reset stretch to top
        const nextV =
          style.props.columnCount == 1
            ? nextVerticalOptions.find((v) => v.value == 'stretch')
            : value == 1 && vertical.value == 'stretch'
            ? nextVerticalOptions.find((v) => v.value == 'flex-start')
            : nextVerticalOptions.find((v) => v.value == vertical.value) || getDefaultOption(nextVerticalOptions)
        const nextH =
          style.props.columnCount == 1
            ? nextHorizontalOptions.find((v) => v.value == 'flex-start')
            : value == 1
            ? nextHorizontalOptions.find((v) => v.value == 'stretch')
            : nextHorizontalOptions.find((h) => h.value == horizontal.value) || getDefaultOption(nextHorizontalOptions)

        // @ts-ignore FIXME
        props[nextV.property] = nextV.value
        // @ts-ignore FIXME
        props[nextH.property] = nextH.value
      }
    }
    onChange(
      mergeDeep(style, {
        props: props
      })
    )
  }

  const weights = style.props.weights || [1]
  const sum = weights.reduce((s, v) => s + v, 0)
  const children = Array.from((context as CK.ModelElement).getChildren())
  const columns = weights.map((v, index) => {
    const child = children[index] as CK.ModelElement
    return child ? Style.Set.getContextElementAspect(rules, child, 'dimensions', customRules)?.props : null
  })
  const hasLimits = columns.some((c) => c?.maxWidth != null)
  const boxes = weights.map((v, index) => {
    const plus = hasLimits && style.props.columnCount != 1 && horizontal.value == 'stretch' ? '+' : ''
    const width = `${Math.floor((v / sum) * 100)}${plus}%`
    const minWidth = Style.stringifyLength(columns[index]?.minWidth, null)
    const maxWidth = Style.stringifyLength(columns[index]?.maxWidth, null)
    const isFixedWidth = minWidth == maxWidth && minWidth

    return (
      <Box className='box' bg='white' key={'box' + index}>
        <Text fontSize='18px' fontWeight={600}>
          {isFixedWidth && minWidth ? minWidth : width}
        </Text>
        {!isFixedWidth && (minWidth || maxWidth) && (
          <VStack className='bottom' whiteSpace={'nowrap'} spacing={0}>
            {minWidth && <Text fontSize={'10px'}>min: {minWidth}</Text>}
            {maxWidth && <Text fontSize={'10px'}>max: {maxWidth}</Text>}
          </VStack>
        )}
      </Box>
    )
  })

  const divider = (
    <Box width='16px' height={0} transform='scaleY(66%)' borderTop='2px solid var(--chakra-colors-blackAlpha-600)' />
  )
  const spacer = (
    <Icon width='16px' height='16px' color='blackAlpha.600'>
      <path d={mdiArrowLeftRight} />
    </Icon>
  )

  return (
    <>
      {style.props.columnCount != null && (
        <HStack p={0} spacing={null} py='4' css={boxesCss} bg='gray.50' borderRadius={'4px'}>
          {boxes
            .map((box, index) => {
              return [
                // first
                index == 0 &&
                  (horizontal.value == 'flex-start' ||
                  horizontal.value == 'stretch' ||
                  horizontal.value == 'space-between'
                    ? divider
                    : spacer),

                // between
                index != 0 &&
                  (horizontal.value == 'space-between' || horizontal.value == 'space-evenly' ? spacer : divider),

                box,

                // last
                index == boxes.length - 1 &&
                  (horizontal.value == 'flex-end' ||
                  horizontal.value == 'stretch' ||
                  horizontal.value == 'space-between'
                    ? divider
                    : spacer)
              ]
            })
            .filter(Boolean)
            .flat()}
        </HStack>
      )}
      <Box>
        <Text color='blackAlpha.800' fontWeight={500} mb={2}>
          Number of columns
        </Text>
        <ButtonGroupSwitch
          onChange={(c) => {
            if (c == null) {
              setLayoutProperty('columnCount', null)
            } else {
              setLayoutProperty('columnCount', c + 1)
            }
          }}
          value={style.props.columnCount == null ? null : style.props.columnCount - 1}
        >
          <Button value={0}>1</Button>
          <Button value={1}>2</Button>
          <Button value={2}>3</Button>
          <Button value={3}>4</Button>
          <Button value={4}>5</Button>
          <Button value={null}>Auto</Button>
        </ButtonGroupSwitch>
      </Box>
      <Box hidden={style.props.columnCount == 1}>
        <HStack as='label'>
          <Switch
            isChecked={style.props.flexWrap}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLayoutProperty('flexWrap', e.target.checked)
            }}
          />
          <Text fontSize='12px'>Allow wrapping</Text>{' '}
          <Tooltip
            label={
              columns.length <= 1
                ? 'Allow children that dont fit within a single row to be displayed on the next row below. Disabling wrapping will force children to overflow the container horizontally when they dont fit.'
                : `Group children elements in rows of up to ${columns.length}, creating two dimensional grid. Disabling wrapping will squeeze children equally in attempt to fit elements more than number of columns.`
            }
          >
            <Icon fontSize='icon-lg' ml={2} mt={'-2px'}>
              <path d={mdiInformationOutline} />
            </Icon>
          </Tooltip>
        </HStack>
      </Box>

      {columns.length > 1 && (
        <Box>
          <Text color='blackAlpha.800' fontWeight={500}>
            Column proportions
          </Text>
          <VStack w={'full'}>
            {weights.map((weight, index) => (
              <HStack
                w={'full'}
                justifyContent={'space-between'}
                borderRadius={4}
                px={3}
                py={2}
                border={'1px solid'}
                borderColor={'blackAlpha.400'}
              >
                <Text>Column {index + 1}</Text>
                <HStack>
                  <IconButton
                    icon={
                      <Icon boxSize='icon-md'>
                        <path d={mdiMinus} />
                      </Icon>
                    }
                    onClick={() =>
                      setLayoutProperty('weights', Object.assign(weights.slice(), { [index]: Math.max(1, weight - 1) }))
                    }
                    bg='white'
                    variant='secondary'
                    size='xs'
                    aria-label='Decrease weight'
                    isDisabled={weight <= 1}
                  />
                  <Text fontSize='12px' width={3} m={0} textAlign={'center'} whiteSpace={'nowrap'}>
                    {weight}
                  </Text>
                  <IconButton
                    m={0}
                    icon={
                      <Icon boxSize='icon-md'>
                        <path d={mdiPlus} />
                      </Icon>
                    }
                    variant='secondary'
                    onClick={() =>
                      setLayoutProperty('weights', Object.assign(weights.slice(), { [index]: weight + 1 }))
                    }
                    bg='white'
                    size='xs'
                    aria-label='Increase weight'
                  />
                </HStack>
              </HStack>
            ))}
          </VStack>
        </Box>
      )}
      <Divider />
      <Box>
        <Text color='blackAlpha.800' fontSize={16} fontWeight={500} mb={1}>
          Alignment
        </Text>
        <Text color='blackAlpha.800' fontWeight={500} mb={3} mt={4}>
          Horizontally{' '}
          <Tooltip
            label={
              columns.length <= 1
                ? 'In single column layouts horizontal alignment will collapse its children to their minimum width. Using stretch setting avoids that.'
                : 'Horizontal alignment only makes a difference when there are less items than columns, or when number of columns is set to auto.'
            }
          >
            <Icon fontSize='icon-lg' ml={2} mt={'-2px'}>
              <path d={mdiInformationOutline} />
            </Icon>
          </Tooltip>
        </Text>
        <Flex rowGap={2} columnGap={4} flexWrap={'wrap'}>
          {horizontalOptions.map(({ label, IconComponent, property, value }, index) => (
            <VStack
              key={index}
              spacing={0}
              cursor={'pointer'}
              css={css`
                > svg {
                  background: var(--chakra-colors-blackAlpha-50);
                  ${horizontal.value === value
                    ? 'background: var(--chakra-colors-primary-100);' +
                      'border-radius: 2px;' +
                      'outline: 2px solid var(--chakra-colors-primary-600);' +
                      '> rect {' +
                      'border-radius: 2px;' +
                      'outline: 1px solid var(--chakra-colors-primary-300);' +
                      '}'
                    : '&:hover {' + 'background: var(--chakra-colors-blackAlpha-200);' + '}'}
                }
              `}
              onClick={() => {
                setLayoutProperty(property, value as any)
              }}
            >
              <IconComponent />
              <Text fontWeight={600} color={'blackAlpha.500'} fontSize='12px'>
                {label}
              </Text>
            </VStack>
          ))}
        </Flex>
        <Text color='blackAlpha.800' fontWeight={500} mb={3} mt={4}>
          Vertically{' '}
          <Tooltip
            label={
              columns.length <= 1
                ? 'Aligns children vertically within layout if there is any free space. Using stretch setting distributes available free space between all elements'
                : 'Aligns children within their rows vertically if there is any free space. Using stretch setting ensures that all elements within row are equal height'
            }
          >
            <Icon fontSize='icon-lg' ml={2} mt={'-2px'}>
              <path d={mdiInformationOutline} />
            </Icon>
          </Tooltip>
        </Text>
        <Flex rowGap={2} columnGap={4} flexWrap={'wrap'}>
          {verticalOptions.map(({ label, IconComponent, property, value }, index) => (
            <VStack
              key={index}
              spacing={0}
              cursor={'pointer'}
              css={css`
                > svg {
                  background: var(--chakra-colors-blackAlpha-50);
                  ${vertical.value === value
                    ? 'background: var(--chakra-colors-primary-100);' +
                      'border-radius: 2px;' +
                      'outline: 2px solid var(--chakra-colors-primary-600);' +
                      '> rect {' +
                      'border-radius: 2px;' +
                      'outline: 1px solid var(--chakra-colors-primary-300);' +
                      '}'
                    : ''}
                  &:hover {
                    background: var(--chakra-colors-blackAlpha-200);
                  }
                }
              `}
              onClick={() => {
                setLayoutProperty(property, value as any)
              }}
            >
              <IconComponent />
              <Text fontWeight={600} color={'blackAlpha.500'} fontSize='12px'>
                {label}
              </Text>
            </VStack>
          ))}{' '}
        </Flex>
      </Box>
    </>
  )
}
