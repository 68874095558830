import {
  ComponentModel,
  EmbeddableComponent,
  ExternalComponentModel,
  VersionModel,
  getEmbedDefinition
} from '@sitecore-feaas/sdk'
import { DialogMenu } from './DialogMenu.js'
import { PickerProps } from '../../picker/Picker.js'
import ComponentVersionThumbnailWrapper from '../../ComponentVersionThumbnailWrapper.js'
import ComponentVersionThumbnail from '../../ComponentVersionThumbnail.js'
import { Badge, Text } from '@chakra-ui/react'
import { EmbeddableComponentThumbnail } from '../../picker/PickerLibrary.js'
import { mdiListBox, mdiListBoxOutline, mdiSelect } from '@mdi/js'

export default function DialogMenuComponent({
  variant,
  version,
  collections,
  embed,
  context,
  externalComponents,
  onModeChange
}: {
  version: VersionModel
  variant: PickerProps['variant']
  context: PickerProps['context']
  collections: PickerProps['collections']
  externalComponents: PickerProps['externalComponents']
  embed: PickerProps['embed']
  onModeChange: PickerProps['onModeChange']
}) {
  const externalComponent = ExternalComponentModel.isExternalComponent(embed) ? embed : null
  const component = embed && !ExternalComponentModel.isExternalComponent(embed) ? embed : null
  const embedDefinition = getEmbedDefinition(context)
  const stagedComponents = collections.flatMap((c) => c.components).filter((comp) => comp.stagedAt)
  const allComponents = [...stagedComponents, ...externalComponents] as EmbeddableComponent[]
  const choices = !embedDefinition
    ? []
    : embedDefinition.type == 'form'
    ? allComponents.filter(ExternalComponentModel.isComponentForm)
    : allComponents
  version ||= component?.getBundles()[0].aggregate('staged')

  return component && variant == 'pages' ? (
    <DialogMenu
      title='Version'
      variant={variant}
      onNavigate={() => onModeChange('versions')}
      items={[
        {
          onNavigate: () => onModeChange('versions'),
          id: 'versions',
          label: version ? version?.name : 'Responsive' + component.stagedVersionCount,
          icon: null,
          isDisabled: component.stagedVersionCount < 2,
          preview: () => {
            return (
              <ComponentVersionThumbnailWrapper height={80} p={0} border='1px solid' borderColor='blackAlpha.200'>
                <ComponentVersionThumbnail component={component} version={version} targetHeight={80} />
              </ComponentVersionThumbnailWrapper>
            )
          },
          badge:
            component.embeddableVersionCount > 1
              ? () => {
                  return (
                    <Text fontWeight={400} fontSize='11px'>
                      {component.embeddableVersionCount} versions
                    </Text>
                  )
                }
              : null
        }
      ]}
    />
  ) : (
    <DialogMenu
      title='Component'
      variant={variant}
      onNavigate={() => onModeChange('library')}
      items={[
        {
          onNavigate: () => onModeChange('library'),
          id: 'versions',
          label: embed ? ('title' in embed ? embed.title : embed.name) : 'Unknown component',
          icon: null,
          isDisabled: choices.length < 2,
          preview: () => {
            return (
              <ComponentVersionThumbnailWrapper
                height={80}
                p={0}
                border='1px solid'
                width='100%'
                borderColor='blackAlpha.200'
              >
                <EmbeddableComponentThumbnail component={embed} height={80} />
              </ComponentVersionThumbnailWrapper>
            )
          },
          badge: () => {
            return (
              <Text fontWeight={400} fontSize='11px'>
                {embedDefinition.type == 'form' ? choices.length + ' forms' : choices.length + ' components'}
              </Text>
            )
          }
        },
        (component?.stagedVersionCount > 1 || version) && {
          onNavigate: () => onModeChange('versions'),
          id: 'versions',
          label: 'Version',
          icon: mdiListBoxOutline,
          badge: () => (
            <Badge display={'block'} overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' flexShrink={0.1}>
              {version.name}
            </Badge>
          )
        }
      ].filter(Boolean)}
    />
  )
}
