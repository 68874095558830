import {
  Box,
  HStack,
  Switch,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spacer,
  Icon,
  Tooltip,
  Divider,
  IconButton,
  Text,
  Badge
} from '@chakra-ui/react'
import { useState } from 'react'
import { Style } from '@sitecore-feaas/sdk'
import PickerStylePreview from './PickerStylePreview.js'
import PickerElementAspectCustom from './PickerElementAspectCustom.js'
import { mdiDotsHorizontal, mdiInformationOutline, mdiPaletteOutline, mdiRestore, mdiAlertOutline } from '@mdi/js'

const PickerElementAspect = <AspectType extends Style.Type.ElementAspect>({
  aspect,
  aspectOptions,
  assignedAspects,
  rules,
  isDefaultCombo,
  onChange,
  setHandleBackButton,
  context,
  themeClassList
}: {
  aspect: AspectType
  aspectOptions: Style.AspectsByType
  assignedAspects: Style.Rule[]
  rules: Style.Rule[]
  isDefaultCombo: boolean
  onChange: (rule: Style.Rule) => void
  setHandleBackButton: React.Dispatch<React.SetStateAction<() => void | (() => void)>>
  context: Style.Context
  themeClassList: string[]
}) => {
  const allowedStyles = aspectOptions[aspect]
  const allAvailableStyles = Style.Set.join(
    allowedStyles,
    Style.Set.filterByType(rules, aspect).filter((s) => s.details.elementId == null)
  )

  /** The theme default style */
  const defaultStyle = isDefaultCombo
    ? Style.Set.getContextComboAspect(rules, context, aspect, themeClassList)
    : Style.Set.getAspectDefaultOriginal(rules, aspectOptions[aspect])
  /** The selected style of the element (loaded state - it can contain one-off edits) */
  const assignedAspect = Style.Set.filterByType(assignedAspects, aspect)[0] || defaultStyle
  /** The edited style of the element (on load this has same value as activeStyle, on further one-off changes, it contains the new changes as well) */
  const [currentStyle, setCurrentStyle] = useState(assignedAspect)
  /** The selected style without one-off changes. */
  const originalStyle = Style.Set.findStyle(rules, currentStyle)
  const type = assignedAspect
    ? Style.Set.getAspectCustomizationType(rules, assignedAspect, aspectOptions[aspect])
    : null

  const [showAll, setShowAll] = useState(type === 'nonAllowed' || false)
  const [editCustom, setEditCustom] = useState(false)

  const handleEditCustom = () => {
    setEditCustom(true)
    setHandleBackButton((prevState) => () => {
      setEditCustom(false)
      setHandleBackButton(() => prevState)
      return prevState
    })
  }

  const hasChanges = defaultStyle.details.id !== assignedAspect.details.id
  const onOneOffChange = (style: Style.Rule<AspectType>) => {
    setCurrentStyle(style)
    onChange(style)
  }
  if (!assignedAspect) return

  if (editCustom) {
    return (
      <PickerElementAspectCustom
        rules={rules}
        style={originalStyle}
        onChange={onOneOffChange}
        currentStyle={currentStyle}
        onSave={() => {
          setEditCustom(false)
          setHandleBackButton((prevState) => () => {
            // Get previous state of previous state (setHandleBackButton updater in handleEditCustom)
            const prevPrevState = prevState() as () => void
            // Skip previous state and go directly to previous previous
            setHandleBackButton(prevPrevState)
          })
        }}
      />
    )
  }
  return (
    <VStack align='stretch' spacing={0} pb={6}>
      <HStack as='label' mb={5}>
        <Switch isChecked={showAll} onChange={(e) => setShowAll(e.target.checked)} />
        <Text>Show all available styles</Text>
        <Tooltip
          hasArrow={false}
          maxW={56}
          placement='top-end'
          label='By switching this option on you will also view styles that have not been assigned to this element'
        >
          <Icon boxSize={5} color='blackAlpha.400'>
            <path d={mdiInformationOutline} />
          </Icon>
        </Tooltip>
      </HStack>
      <Divider />
      <Box>
        <HStack mt={2} mb={4}>
          <Text fontWeight='semibold'>
            <Text as='span' textTransform='capitalize'>
              {aspect}
            </Text>{' '}
            styles
          </Text>
          <Spacer />
          {(hasChanges || type === 'oneOff') && (
            <Tooltip hasArrow={false} label='Reset to default style' placement='top-end'>
              <IconButton
                aria-label='Reset to default style'
                icon={
                  <Icon display='block' mx='auto' color='primary.600' boxSize='icon-xl'>
                    <path d={mdiRestore} />
                  </Icon>
                }
                size='xs'
                onClick={() => {
                  onChange(defaultStyle)
                }}
              ></IconButton>
            </Tooltip>
          )}
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label='Options'
              variant='ghost'
              h={7}
              minW={7}
              icon={
                <Icon boxSize={5}>
                  <path d={mdiDotsHorizontal} />
                </Icon>
              }
            />
            <MenuList>
              <MenuItem
                aria-label='Custom'
                icon={
                  <Icon boxSize='icon-xl' color='blackAlpha.500'>
                    <path d={mdiPaletteOutline} />
                  </Icon>
                }
                onClick={handleEditCustom}
              >
                Custom
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Box>
      {!showAll ? (
        <VStack spacing={4} align='stretch'>
          {allowedStyles.map((style) => (
            <PickerStylePreview
              rules={rules}
              key={style.details.id}
              style={style}
              isSelected={style.details.id === assignedAspect.details.id && type !== 'oneOff'}
              isDefault={style.details.id === defaultStyle.details.id}
              onChange={onChange}
            />
          ))}
        </VStack>
      ) : (
        <VStack spacing={4} align={'stretch'}>
          {allAvailableStyles.map((style) => (
            <PickerStylePreview
              key={style.details.id}
              rules={rules}
              style={style}
              isSelected={style.details.id === assignedAspect.details.id && type !== 'oneOff'}
              isAllowed={!!allowedStyles.some((s) => s.details.id === style.details.id)}
              isDefault={style.details.id === defaultStyle.details.id}
              onChange={onChange}
            />
          ))}
        </VStack>
      )}

      {type === 'oneOff' && (
        <Box>
          <VStack align='stretch' mt={4}>
            <HStack>
              <Text textTransform='capitalize' fontWeight='semibold'>
                Custom
              </Text>
              <Tooltip label='This is a custom design and the style library will not be affected' hasArrow={false}>
                <Badge borderRadius='md' colorScheme='orange' px={2} mr={2}>
                  <Icon boxSize='icon-md'>
                    <path d={mdiAlertOutline} />
                  </Icon>
                </Badge>
              </Tooltip>
            </HStack>
            <PickerStylePreview
              rules={rules}
              style={currentStyle}
              isSelected
              onEdit={handleEditCustom}
              onDelete={() => onChange(defaultStyle)}
            />
          </VStack>
        </Box>
      )}
    </VStack>
  )
}

export default PickerElementAspect
