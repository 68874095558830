import { mdiClose, mdiPlus } from '@mdi/js'
import { CreatableSelect } from '../Select.js'
import { Button, Flex, Grid, GridItem, Icon, IconButton, Input, Text } from '@chakra-ui/react'
import { Headers } from './Datasource.js'
import { DatasourceModel } from '@sitecore-feaas/sdk'

const DatasourceHttpSegmentBodyTable = ({
  onChangeKey,
  onChangeValue,
  onDelete,
  onCreate,
  currentDatasource,
  items,
  isHeaders,
  isGraphql,
  label
}: {
  items: Record<string, string>
  label: string
  currentDatasource: DatasourceModel
  isGraphql: boolean
  isHeaders: boolean
  onChangeKey: (oldKey: string, newKey: string) => void
  onChangeValue: (oldValue: string, newValue: string) => void
  onDelete: (key: string) => void
  onCreate: (key: string, value: string) => void
}) => (
  <>
    <Grid templateColumns='repeat(12, 1fr)' gap={12}>
      <GridItem colSpan={5}>
        <Text fontSize='12px' fontWeight='semibold'>
          KEY
        </Text>
      </GridItem>

      <GridItem colSpan={5}>
        <Text fontSize='12px' fontWeight='semibold'>
          VALUE
        </Text>
      </GridItem>

      <GridItem colSpan={2}>
        <Text fontSize='12px'></Text>
      </GridItem>
    </Grid>

    {Object.keys(items).map((oldKey: keyof typeof items, i: number) => {
      const oldValue = items[oldKey]

      const isDisabled = i === 0 && isGraphql && oldKey === 'Content-Type'

      return (
        <Grid templateColumns='repeat(12, 1fr)' gap={12} alignItems='center' mt='5' key={i}>
          <GridItem colSpan={5}>
            {isHeaders ? (
              <CreatableSelect
                onChange={({ value }: any) => onChangeKey(oldKey, value)}
                value={{ label: oldKey, value: oldKey }}
                options={Headers.filter((h) => items[h] === undefined).map((h: string) => ({ value: h, label: h }))}
              />
            ) : (
              <Input
                isDisabled={isDisabled}
                placeholder={`My ${label} key`}
                onChange={(e) => onChangeKey(oldKey, e.target.value)}
                value={oldKey}
              />
            )}
          </GridItem>

          <GridItem colSpan={5}>
            <Input
              isDisabled={isDisabled}
              placeholder={`My ${label} value`}
              onChange={(e) => onChangeValue(oldKey, e.target.value)}
              value={oldValue}
            />
          </GridItem>

          <GridItem colSpan={1}>
            {!isDisabled && (
              <IconButton
                ml='3'
                size='small'
                aria-label='Delete entry'
                icon={
                  <Icon boxSize='icon-lg'>
                    <path d={mdiClose} />
                  </Icon>
                }
                onClick={() => onDelete(oldKey)}
              />
            )}
          </GridItem>
        </Grid>
      )
    })}

    {items[''] === undefined && (
      <Flex justifyContent='start' mt='5'>
        <Button onClick={() => onCreate('', '')} variant='subtle' size='sm'>
          <Icon mr='2'>
            <path d={mdiPlus} />
          </Icon>
          Add {label}
        </Button>
      </Flex>
    )}
  </>
)

export default DatasourceHttpSegmentBodyTable
