import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemProps,
  MenuList,
  Tooltip
} from '@chakra-ui/react'
import { mdiContentCopy, mdiDotsHorizontal, mdiPencilOutline, mdiTrashCanOutline } from '@mdi/js'
import { useContext } from 'react'
import { EntityContext } from '../../contexts/EntityContext.js'

const IconXL = ({ d, className }: { d: string; className?: string }) => (
  <Icon className={className} boxSize='icon-xl'>
    <path d={d} />
  </Icon>
)

const DeleteButton: MenuItemProps = {
  color: 'red.400'
}

const EntityActionsMenu = ({ extraMenuActions, menuDetails, className, isHidden }: any) => {
  const { isNew, onSafeDelete, hasChanges, name, id, onCopy, onToggleEntity, isActive, isNonEditable, onDelete } =
    useContext(EntityContext)

  const hasNoMenuItems = !onSafeDelete && !onCopy && !extraMenuActions && !menuDetails

  if (hasNoMenuItems && (isNonEditable || isActive)) return null

  const suffix = `entity ${name} with id: ${id}`

  return (
    <Box ml='auto' className={className}>
      <Menu isLazy={true} computePositionOnMount={true}>
        <Tooltip label='More actions' isDisabled={isHidden}>
          <IconButton
            opacity={isHidden ? 0 : 1}
            pointerEvents={isHidden ? 'none' : null}
            as={MenuButton}
            className='entity-menu'
            size='sm'
            variant='minimal'
            aria-label={`Edit ${suffix}`}
            icon={<IconXL d={mdiDotsHorizontal} />}
          />
        </Tooltip>

        <MenuList>
          {extraMenuActions}

          {!isNonEditable && (
            <MenuItem
              onClickCapture={(e) => onToggleEntity(e, true)}
              aria-label={`Toggle ${suffix}`}
              icon={<IconXL d={mdiPencilOutline} />}
            >
              Edit {name}
            </MenuItem>
          )}

          {onCopy && (
            <MenuItem
              isDisabled={isNew || hasChanges}
              onClick={onCopy}
              aria-label={`Copy ${suffix}`}
              icon={<IconXL d={mdiContentCopy} />}
            >
              Duplicate
            </MenuItem>
          )}

          {(extraMenuActions || onDelete) && <MenuDivider />}

          {onDelete && (
            <MenuItem
              {...DeleteButton}
              onClick={onDelete}
              className='delete-entity'
              aria-label={`Delete ${suffix}`}
              icon={
                <Icon className={className} boxSize='icon-xl'>
                  <path d={mdiTrashCanOutline} />
                </Icon>
              }
            >
              Delete
            </MenuItem>
          )}

          {menuDetails}
        </MenuList>
      </Menu>
    </Box>
  )
}

export default EntityActionsMenu
