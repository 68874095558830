import { css } from '@emotion/react'
import { ComponentModel, VersionModel, Style } from '@sitecore-feaas/sdk'
import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import { useEffect, useMemo } from 'react'
import { useModelObserver } from '../../hooks/useData.js'
import VersionThumbnail from '../ComponentVersionThumbnail.js'
import ComponentVersionThumbnailWrapper from '../ComponentVersionThumbnailWrapper.js'
import { PickerWrapper } from './PickerWrapper.js'
import { PickerProps } from './Picker.js'
import { mdiCellphoneLink, mdiInformationOutline } from '@mdi/js'
import { Text } from '@chakra-ui/react'

const PickerLibraryComponent = ({
  component,
  versionId,
  onChange,
  onModeChange,
  variant
}: {
  component: ComponentModel
  versionId: string
  onChange: (version: VersionModel) => void
  onModeChange: (mode: PickerProps['mode']) => void
  variant?: PickerProps['variant']
}) => {
  useEffect(() => {
    if (component.versions.length == 0) component.versions.fetch()
  }, [])

  useModelObserver(component.versions)

  const stagedVersions = component.getEmbeddableVersions('staged')
  const publishedVersions = component.getEmbeddableVersions('published')
  const versions = [
    ...publishedVersions,
    ...stagedVersions.filter((v) => !publishedVersions.map((v) => v.id).includes(v.id))
  ]
  const bundles = versions.filter((v) => v.bundle)
  const specificVersions = versions.filter((v) => !v.bundle)

  const version = versions.find((v) => v.id == versionId)

  return (
    <PickerWrapper title='Component versions' onBack={() => onModeChange(null)}>
      <VStack px={3} pr={3} py={6} spacing={4} alignItems={'stretch'}>
        <Text fontWeight='bold'>
          Bundles{' '}
          <Tooltip label='Version will be chosen based on window size' placement='top-end'>
            <IconButton
              aria-label='Version will be chosen based on window size'
              size='xs'
              icon={
                <Icon fontSize='icon-md'>
                  <path d={mdiInformationOutline} />
                </Icon>
              }
            />
          </Tooltip>
        </Text>
        {bundles.map((v) => (
          <PickerComponentVersion key={v.id} version={v} isSelected={v.id === version?.id} onChange={onChange} />
        ))}
        <Divider mt={'6 !important'} mb={'2 !important'} mx='-4 !important' width='auto'></Divider>
        <Text fontWeight='bold'>
          Embeddable versions{' '}
          <Tooltip label='Chosen version will be displayed at all breakpoints' placement='top-end'>
            <IconButton
              aria-label='Chosen version will be displayed at all breakpoints'
              size='xs'
              icon={
                <Icon fontSize='icon-md'>
                  <path d={mdiInformationOutline} />
                </Icon>
              }
            />
          </Tooltip>
        </Text>
        {specificVersions.map((ver) => (
          <PickerComponentVersion key={ver.id} version={ver} isSelected={ver.id == version?.id} onChange={onChange} />
        ))}
      </VStack>
    </PickerWrapper>
  )
}

export function PickerComponentVersion({
  version,
  isSelected,
  onChange
}: {
  version: VersionModel
  isSelected: boolean
  onChange: (version: VersionModel) => void
}) {
  const breakpoints = version.getBreakpoints()
  return (
    <Button
      variant={'dialogPane'}
      p={1}
      flexShrink={1}
      className='component'
      key={version.id}
      isActive={isSelected}
      onClick={() => {
        onChange(version)
      }}
    >
      <ComponentVersionThumbnailWrapper background={'white'} height={140} px={'3'} pt={'3'}>
        <VersionThumbnail version={version} component={version.component} />
      </ComponentVersionThumbnailWrapper>
      <VStack p={3} align='stretch'>
        <Box
          css={css`
            flex-shrink: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
          fontSize='md'
          fontWeight='semibold'
          cursor='pointer'
        >
          {version.bundle?.name || version.name}
        </Box>
        <HStack alignItems={'flex-start'}>
          <Icon fontSize='icon-sm' mt={'2px'}>
            <path d={mdiCellphoneLink} />
          </Icon>
          <Text fontSize='12px' lineHeight='18px' fontWeight={'normal'}>
            {version.id == 'responsive'
              ? 'All breakpoints'
              : breakpoints.length == 0
              ? 'All breakpoints'
              : breakpoints
                  .map((breakpoint) => {
                    return breakpoint?.details.title
                  })
                  .filter(Boolean)
                  .join(', ')}
          </Text>
        </HStack>
        {version.status === 'staged' && (
          <Flex>
            <Badge colorScheme={'red'} lineHeight={'18px'}>
              To be published
            </Badge>
          </Flex>
        )}
      </VStack>
    </Button>
  )
}

export default PickerLibraryComponent
