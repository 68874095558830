import { Badge, Box, Divider, Flex, HStack, Icon, VStack } from '@chakra-ui/react'
import { mdiAlertOutline, mdiTuneVariant } from '@mdi/js'
import { Style } from '@sitecore-feaas/sdk'
import React from 'react'
import { getIconPathByElementName } from '../../utils/element.js'
import { DialogMenu } from '../editor/dialog/DialogMenu.js'
import Preview from '../styles/previews/index.js'
import PickerThemesCollectionCombos from './PickerThemesCollectionCombos.js'

const PickerThemesCollections = ({
  currentTheme,
  usedComboIds,
  comboCategories,
  onComboChange,
  rules,
  currentCollection,
  setCurrentCollection,
  setHandleBackButton
}: {
  currentTheme: Style.Rule<'theme'>
  usedComboIds: string[]
  comboCategories: (keyof Style.Theme.Props)[]
  onComboChange: (combo: Style.Theme.Combo, category: keyof Style.Theme.Props, theme: Style.Rule<'theme'>) => any
  rules: Style.Rule[]
  currentCollection: Style.RuleCollection<Style.Type.Element>
  setCurrentCollection: (collection: Style.RuleCollection<Style.Type.Element>) => void
  setHandleBackButton: React.Dispatch<React.SetStateAction<() => void>>
}) => {
  if (!currentTheme) return
  const nonEmptyCategories = comboCategories.filter((category) => currentTheme.props[category].length)
  const defaultCollections = Style.Set.getDefaultCollections(rules) as Style.RuleCollection<Style.Type.Element>[]
  const handleCollectionSelect = (collection: Style.RuleCollection<Style.Type.Element>) => {
    setCurrentCollection(collection)
    setHandleBackButton((prevState) => () => {
      setCurrentCollection(null)
      setHandleBackButton(() => prevState)
    })
  }
  return (
    <>
      {!currentCollection ? (
        <Box>
          <Preview variant='compact' rule={currentTheme} rules={rules} />
          <VStack divider={<Divider mx='-4 !important' width='auto' />} align={'stretch'} spacing='6' mt={6}>
            {nonEmptyCategories.map((category, index) => {
              const type = category.substring(0, category.length - 1)
              const categoryDefaultCollections = defaultCollections.filter(
                (collection) => collection.props.type === type
              )
              const onNavigate = (id: string) =>
                handleCollectionSelect(categoryDefaultCollections.find((d) => d.details.id == id))
              return (
                <Box key={index}>
                  <DialogMenu
                    title={`Default ${type} element styles`}
                    items={categoryDefaultCollections.map((collection) => {
                      const collectionCombos: Style.Theme.Combo[] = currentTheme.props[category].filter((combo) => {
                        const comboStyle = Style.Set.findById(rules, combo.refId)
                        return comboStyle.details.collectionId === collection.details.id
                      })

                      const collectionDefaultCombo = collectionCombos.find((combo) => combo.isDefault)

                      const collectionUsedComboId = usedComboIds.find((id) => {
                        const combo = currentTheme.props[category].find((combo: Style.Theme.Combo) => combo.id === id)
                        const currentStyle = Style.Set.findById(rules, combo?.refId)
                        return currentStyle?.details.collectionId === collection.details.id
                      })

                      const collectionActiveCombo = (collectionCombos.find(
                        (combo) => combo.id === collectionUsedComboId
                      ) || collectionDefaultCombo) as Style.Theme.Combo

                      const collectionActiveComboStyle = Style.Set.findById(rules, collectionActiveCombo?.refId)

                      const icon =
                        getIconPathByElementName(collection.details.id) ||
                        getIconPathByElementName(collection.props.type)

                      return {
                        id: collection.details.id,
                        icon: icon,
                        label: collection.details.title,
                        isDisabled: collectionCombos.length < 2,
                        badge: () =>
                          collectionCombos.length ? (
                            <Badge
                              fontWeight={500}
                              textTransform={'none'}
                              colorScheme={collectionActiveCombo.isDefault ? 'blackAlpha' : 'teal'}
                              mr={collectionCombos.length < 2 ? '26px' : 0}
                            >
                              <Flex alignItems={'center'}>
                                {false && (
                                  <Icon mr={1} boxSize='icon-sm'>
                                    <path d={mdiAlertOutline} />
                                  </Icon>
                                )}
                                {!collectionActiveCombo.isDefault && (
                                  <Icon mr={1} boxSize='icon-sm'>
                                    <path d={mdiTuneVariant} />
                                  </Icon>
                                )}
                                <Box display={'block'} textOverflow={'ellipsis'} overflow={'hidden'} maxWidth={'100px'}>
                                  {collectionActiveComboStyle?.details.title}
                                </Box>
                              </Flex>
                            </Badge>
                          ) : (
                            <HStack>
                              <Box fontSize={12} fontWeight={500} color={'gray.800'}>
                                No styles available
                              </Box>
                            </HStack>
                          ),
                        onNavigate
                      }
                    })}
                    onNavigate={onNavigate}
                  ></DialogMenu>
                </Box>
              )
            })}
          </VStack>
        </Box>
      ) : (
        <PickerThemesCollectionCombos
          collection={currentCollection}
          theme={currentTheme}
          rules={rules}
          usedComboIds={usedComboIds}
          onChange={onComboChange}
        />
      )}
    </>
  )
}

export default PickerThemesCollections
