import React, { useEffect, useState } from 'react'
import {
  InputGroup,
  InputGroupProps,
  InputRightAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/react'

const NumberField = ({
  value,
  min = 0,
  max = 100,
  step = 1,
  disabled,
  onChange,
  placeholder,
  withAddon,
  withStepper,
  ...props
}: Omit<InputGroupProps, 'value' | 'onChange'> & {
  value: number
  placeholder?: string
  min?: number
  max?: number
  step?: number
  disabled?: boolean
  onChange: (value: number) => void
  withAddon?: string
  withStepper?: boolean
}) => {
  const [current, setCurrent] = useState<any>(value)

  const isCurrentNotValidNumber = Number.isNaN(current)
  const isCurrentValidNumber = !isCurrentNotValidNumber
  const isCurrentEmptyString = current == ''
  const isCurrentNotEmptyString = !isCurrentEmptyString
  const isCurrentValid = isCurrentNotEmptyString && isCurrentValidNumber
  const isValueNotZero = value != 0
  const isValueZero = !isValueNotZero
  const isCurrentDash = current === '-'
  const currentIsEqualToValue = current == value
  const sx = !withStepper ? { div: { display: 'none' } } : {}

  const onUpdateCurrentFromValue = () => setCurrent(value)
  const onUpdateValueFromCurrent = () => onChange(current)

  const onResetCurrent = () => setCurrent(0)
  const onResetValue = () => onChange(0)

  useEffect(() => {
    if (isCurrentDash) return

    if (isCurrentEmptyString) {
      onResetValue()

      return
    }

    if (currentIsEqualToValue) return

    onUpdateValueFromCurrent()
  }, [current])

  useEffect(() => {
    if (currentIsEqualToValue) return

    // Why are we checking with "isCurrentNotEmptyString" here?
    if (isValueZero && isCurrentNotEmptyString) return

    onUpdateCurrentFromValue()
  }, [value])

  const onBlur = () => {
    if (isCurrentValid) return

    onResetCurrent()
  }

  const onInputChange = (valueAsString: string, valueAsNumber: number) => {
    const largerThanMax = valueAsNumber > max
    const lowerThanMin = valueAsNumber < min

    if (largerThanMax || lowerThanMin) return

    const isValueAsNumberNotValidNumber = Number.isNaN(valueAsNumber)

    const newCurrent = isValueAsNumberNotValidNumber ? valueAsString : valueAsNumber

    setCurrent(newCurrent)
  }

  return (
    <InputGroup {...props}>
      <NumberInput
        width='100%'
        min={min}
        step={step}
        max={max}
        value={current}
        // @ts-ignore
        placeholder={placeholder}
        inputMode='numeric'
        isDisabled={disabled}
        onChange={onInputChange}
        onBlur={onBlur}
        sx={sx}
      >
        <NumberInputField borderRightRadius={withAddon && 0} pr={!withStepper && 4} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>

      {withAddon && <InputRightAddon children={withAddon} />}
    </InputGroup>
  )
}

export default NumberField
