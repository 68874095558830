import { DialogAttributeGeneric } from './Dialog.js'
import { useSDK } from '../../../hooks/useData.js'
import { Box, Input, Textarea } from '@chakra-ui/react'
import FieldsetField from '../../FieldsetField.js'
import { ChangeEvent } from 'react'
import FileField from '../../styles/fields/FileField.js'

const DialogAttributesStatic = ({ type, value, onChange }: DialogAttributeGeneric) => {
  const sdk = useSDK()

  return (
    type != 'boolean' &&
    type != 'custom' && (
      <Box>
        {type == 'string' && (
          <FieldsetField label='Text'>
            <Input
              type='text'
              defaultValue={value || ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
            />
          </FieldsetField>
        )}
        {type == 'url' && (
          <FieldsetField label='URL'>
            <Input
              type='url'
              defaultValue={value || ''}
              placeholder={'http://'}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange(e.target.value)
              }}
            />
          </FieldsetField>
        )}
        {type == 'object' && (
          <FieldsetField>
            <Textarea
              defaultValue={value || ''}
              placeholder={'Valid JSON'}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
            />
          </FieldsetField>
        )}
        {type == 'html' && (
          <FieldsetField>
            <Textarea
              defaultValue={value || ''}
              placeholder={'Raw HTML, data mapping syntax and web components are allowed'}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
            />
          </FieldsetField>
        )}
        {type == 'image' && (
          <FileField
            value={value}
            onChange={onChange}
            onUpload={(files) => {
              const file = files[0]
              var extension = file.name.split('.').pop()
              if (!extension.match(/(jpg|jpeg|png|gif|svg)/)) {
                extension = 'png'
              }
              sdk.uploadBlob('images', sdk.nanoid(32) + '.' + extension, files[0]).then(({ url }) => {
                onChange(url)
              })
            }}
          />
        )}
      </Box>
    )
  )
}

export default DialogAttributesStatic
