import { Box, Flex } from '@chakra-ui/react'
import { Style } from '@sitecore-feaas/sdk'
import { Select } from '../../Select.js'
import { ReactElement } from 'react'

const ColorLabel = ({ bg, name }: { bg: string; name: string }) => (
  <Flex align='center' display='inline-flex' verticalAlign={'middle'}>
    <Box bg={bg} boxSize='20px' mr='2' borderColor={'blackAlpha.400'} borderWidth={1} borderRadius='full' />
    {name}
  </Flex>
)

const ColorField = ({
  colorReference,
  onChange,
  rules,
  size
}: {
  colorReference: Style.ColorReference | null
  onChange: (colorReference: Style.ColorReference) => void
  rules: Style.Rule[]
  size?: 'sm' | 'md' | 'lg'
}): ReactElement => {
  const colors = rules.filter((s) => s.type == 'color') as Style.Rule<'color'>[]
  const collectionIds = colors.reduce(
    (prev, curr) => (prev.includes(curr.details.collectionId) ? prev : [...prev, curr.details.collectionId]),
    []
  )
  const collections = collectionIds.map((id) => rules.find((r) => r.details.id === id)).filter((c) => !!c)

  const colorOptions = collections.map((collection, collectionIndex) => {
    return {
      label: collection.details.title,
      options: colors
        .filter((color) => color.details.collectionId === collection.details.id)
        .map((color: Style.Rule<'color'>, index) => ({
          label: <ColorLabel bg={Style.rgbaToString(color.props)} name={color.details.title} />,
          value: { id: color.details.id, alpha: color.props.alpha, title: color.details.title }
        }))
    }
  })

  const selectedColorOption = colorOptions.reduce(
    (prev, curr) => curr.options.find((c) => c.value.id === colorReference.id) || prev,
    null
  ) as { label: any; value: { id: string; alpha: Style.Rule<'color'>['props']['alpha']; title: string } }

  const onColorOptionChange = ({ value }: { value: any }) => onChange({ id: value.id, alpha: value.alpha })

  return (
    <Select
      menuPosition='fixed'
      isSearchable={false}
      filterOption={(
        { label, value, data }: { label: string; value: any; data: { label: ReactElement; value: any } },
        input: string
      ) => data.value.title.toLocaleLowerCase().includes(input.toLocaleLowerCase()) && value.id !== 'blank-color'}
      placeholder='Select'
      options={colorOptions}
      value={selectedColorOption}
      onChange={onColorOptionChange}
      size={size}
    />
  )
}

export default ColorField
