import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Button,
  HStack,
  Icon,
  Switch,
  Text,
  Tooltip
} from '@chakra-ui/react'
import {
  mdiArrowExpandAll,
  mdiArrowExpandHorizontal,
  mdiArrowUpLeft,
  mdiContentPaste,
  mdiDatabaseOutline,
  mdiEyeOutline,
  mdiFormatAlignLeft,
  mdiGhostOutline,
  mdiImageOutline,
  mdiImageSizeSelectLarge,
  mdiLinkVariant,
  mdiScriptOutline,
  mdiSync,
  mdiTextLong,
  mdiToyBrickOutline,
  mdiViewColumnOutline
} from '@mdi/js'
import { DataPath } from '@sitecore-feaas/clientside/headless'
import { GroupBy, Style, getEmbedDefinition } from '@sitecore-feaas/sdk'
import { ChangeEvent, FunctionComponent } from 'react'
import { PickerProps } from '../../picker/Picker.js'
import DialogAttributeBadge from './DialogAttributeBadge.js'
import DialogAttributes from './DialogAttributes.js'
import DialogAttributesFallback from './DialogAttributesFallback.js'
import DialogStyleBadge from './DialogStyleBadge.js'
import DialogStyleElementBadge from './DialogStyleElementBadge.js'
import DialogStyleDimensions from './DialogStyleDimensions.js'
import DialogStyleGrid from './DialogStyleGrid.js'
import DialogStyleLayout from './DialogStyleLayout.js'
import DialogStyleMediaSizing from './DialogStyleMediaSizing.js'
import DialogStyleSpacing from './DialogStyleSpacing.js'
import DialogStyleTextAlignment from './DialogStyleTextAlignment.js'
import { singularize } from 'inflection'

/** A single configurable aspect of context element. It consists of static and dynamic parts, the static are readonly and unchangable, while the dynamic ones are computed on each re-render */
export type ContextCallback = (context: Style.Context) => void
export type AttributeSetter = (context: Style.Context, attribute: string, value: unknown) => void
export type AttributeCallback = (attribute: string, value: unknown, context?: Style.Context) => void
export type AttributeGetter = (context: Style.Context, attribute: string) => unknown
export type RuleSetter = (context: Style.Context, rule: Style.Rule | Style.Empty) => void
export type RuleCallback = (rule: Style.Rule | Style.Empty, context?: Style.Context) => void

export interface DialogEnvironment {
  context: Style.Context
  parent?: Style.Context
  definition: Style.ElementDefinition
  getAttribute: AttributeGetter
  onAttributeChange: AttributeCallback
  onNavigate: (id: Dialog['id']) => void
  onSelect: (style: Style.Context) => void
}

/** The following types achieve two tasks:
 * 1. Define a generic DialogBase<group> definition type that can be used to validate dialog definitions and as a unspecific type matching all dialogs
 * 2. And a specific Dialog/Dialog<id> type, that infers all of its defined properties from constant definition. It helps typescript to do extra type checking and narrowing. E.g. `dialog.id == 'repeating' && [dialog.group, dialog.label]` will infer that [`attribute`, `Repeating collection`]`. It is important though that unlike typical const definitions it also includes the types for generic properties of a DialogBase type.
 *
 * I have to say it turned out to be a mess to implement. Main issue was that typescript has quite strict rules with function calls.
 */

export interface DialogBasics {
  readonly icon: string
  readonly label: string
  readonly ariaLabel?: string
  readonly id: string
  readonly tooltip?: string
  readonly isDisabled?: boolean
  readonly level?: number
}
export interface DialogComponents<P> {
  component?: FunctionComponent<P>
  badge?: FunctionComponent<P>
  preview?: FunctionComponent<P>
  below?: FunctionComponent<P & { setIndex: (index: number) => void; index: number }>
  above?: FunctionComponent<P & { setIndex: (index: number) => void; index: number }>
}
export interface DialogBase<
  G extends DialogType | 'other' = DialogType,
  P = {},
  E extends Record<string, any> = DialogEnvironment
> extends DialogBasics,
    DialogComponents<DialogBasics & P & E> {
  readonly group: G
  readonly condition: (environment: E) => boolean
  readonly prepare?: (environment: E) => P
}
/** Map of types of configuration with extra runtime options */
export type DialogDefinitionTypeMap = {
  style: DialogBase<
    'style',
    {
      onChange: (rule: Style.Rule | Style.Empty) => void
    },
    DialogEnvironment & {
      rules: Style.Rule[]
      customRules: Style.Rule[]
      themeClassList: string[]
      classList: string[]
      onRuleChange: RuleCallback
    }
  >
  attribute: DialogBase<
    'attribute',
    {
      value: any
      type: 'url' | 'html' | 'image' | 'string' | 'boolean' | 'array' | 'object' | 'custom'
      jsonpath: string
      onChange: (value: any, attribute?: string) => void
      onConfigure?: (value: any, attribute?: string) => void
      onModeChange?: (mode?: PickerProps['mode'], dialog?: string) => void
      isNonNullable?: boolean
      labels: {
        none?: string
        static?: string
        mapped?: string
      }
    },
    DialogEnvironment & {
      contextVariable: Style.Context
      contextLink: Style.Context
      definition: Style.ElementDefinition
      //[name: string]: any
    }
  >
}

/** List of all dialog types types */
export type DialogType = keyof DialogDefinitionTypeMap

/** Helper to Retrieve runtime props interface from configure definition */
export type DialogProps<T extends DialogBase> = T extends DialogBase<any, infer P, infer E> ? P & E : never

/** Definition + runtime properties in one interface */
export type DialogResolved<T extends DialogBase> = T & DialogProps<T>

/** Map of resolved dialogs by type */
export type DialogTypeMap = {
  [key in DialogType]: DialogResolved<DialogDefinitionTypeMap[key]>
}

/** Union of all definitions (const) */
export type DialogList = (typeof dialogs)[number]

/** Dialog definitions by id */
export type DialogIdMap = GroupBy<DialogList, 'id'>

/* Combine generic type properties with properties inferred from const definitions */
export type DialogIdMapAugmented = {
  [key in keyof DialogIdMap]: Omit<DialogTypeMap[DialogIdMap[key]['group']], keyof DialogIdMap[key]> & DialogIdMap[key]
}

/** Union of all definitions */
export type DialogDefinition = DialogDefinitionTypeMap[DialogType]
export type DialogGeneric = DialogTypeMap[DialogType]

/** Union of all resolved configurations, but with components of generic types to avoid typescript complications  */
export type Dialog<Id extends DialogList['id'] = DialogList['id']> = DialogIdMapAugmented[Id] & DialogComponents<Dialog>
export type DialogMenuItem = DialogBasics &
  DialogComponents<{}> & {
    onNavigate: (id: string) => void
  }

/** Generic attribute dialog */
export type DialogAttributeGeneric = DialogTypeMap['attribute']
export type DialogAttribute = Extract<Dialog, { group: 'attribute' }>
/** Generic style dialog */
export type DialogStyleGeneric = DialogTypeMap['style']
export type DialogStyle = Extract<Dialog, { group: 'style' }>
/** Minimal amount of properties needed for style dialog */
export type DialogStyleProps = Pick<
  DialogStyleGeneric,
  'context' | 'rules' | 'customRules' | 'onChange' | 'themeClassList'
>

function getFallbackProperty(definition: Style.ElementDefinition) {
  return definition.type == 'block' ? 'data-path-src' : definition.name == 'var' ? 'data-path' : 'data-path-text'
}

export const dialogs = [
  {
    group: 'style',
    icon: mdiFormatAlignLeft,
    label: 'No style',
    ariaLabel: 'Pick style',
    id: 'style',
    component: null as DialogStyleGeneric['component'],
    condition: ({ definition }) => definition.name != 'container' && definition.name != 'var',
    prepare: ({ onRuleChange, rules, context, themeClassList }) => {
      const definition = Style.Set.getContextDefinition(rules, context)
      return {
        onChange: onRuleChange,
        label: `${definition.label} style`,
        badge: DialogStyleElementBadge
      }
    }
  } as const satisfies DialogDefinition,
  {
    group: 'style',
    icon: mdiFormatAlignLeft,
    label: 'Text settings',
    id: 'lines',
    component: DialogStyleTextAlignment,
    condition: ({ definition }) => definition.type == 'text' || definition.name == 'button',
    prepare: ({ onRuleChange }) => ({
      onChange: onRuleChange
    }),
    badge: DialogStyleBadge
  } as const satisfies DialogDefinition,
  {
    group: 'style',
    icon: mdiImageSizeSelectLarge,
    label: 'Image sizing',
    id: 'media',
    component: DialogStyleMediaSizing,
    condition: ({ context, getAttribute, definition }) =>
      definition.type == 'block' && getAttribute(context, 'image') != null,
    prepare: ({ onRuleChange }) => ({
      onChange: onRuleChange
    }),
    badge: DialogStyleBadge
  } as const satisfies DialogDefinition,
  {
    group: 'style',
    icon: mdiViewColumnOutline,
    label: 'Grid sizing',
    id: 'grid',
    component: DialogStyleGrid,
    condition: ({ definition }) => definition.name == 'section',
    prepare: ({ onRuleChange }) => ({
      onChange: onRuleChange
    }),
    badge: DialogStyleBadge
  } as const satisfies DialogDefinition,
  {
    group: 'style',
    icon: mdiViewColumnOutline,
    label: 'Layout & alignment',
    id: 'layout',
    component: DialogStyleLayout,
    condition: ({ definition }) =>
      definition.type == 'block' &&
      definition.name != 'section' &&
      definition.name != 'media' &&
      definition.name != 'blockquote' &&
      definition.group != 'external',
    prepare: ({ onRuleChange }) => ({
      onChange: onRuleChange
    }),
    badge: DialogStyleBadge
  } as const satisfies DialogDefinition,
  {
    group: 'style',
    icon: mdiArrowExpandAll,
    label: 'Dimensions',
    id: 'dimensions',
    component: DialogStyleDimensions,
    condition: ({ definition, parent }) =>
      definition.type != 'text' && definition.name != 'media' && Style.Context.getContextName(parent) != 'section',
    prepare: ({ onRuleChange }) => ({
      onChange: onRuleChange
    }),
    badge: DialogStyleBadge
  } as const satisfies DialogDefinition,
  {
    group: 'style',
    icon: mdiArrowExpandHorizontal,
    label: 'Spacing',
    id: 'spacing',
    component: DialogStyleSpacing,
    condition: ({ definition }) => definition.type != 'text',
    prepare: ({ onRuleChange }) => ({
      onChange: onRuleChange
    }),
    badge: DialogStyleBadge
  } as const satisfies DialogDefinition,
  {
    group: 'attribute',
    id: 'repeating',
    label: 'Data collection',
    icon: mdiSync,
    prepare: ({ context, getAttribute, onAttributeChange }) => ({
      labels: {
        none: 'No repeating',
        mapped: 'Mapped'
      },
      type: 'array',
      value: null,
      jsonpath: getAttribute(context, 'data-path-scope') as string,
      onConfigure: (datapath) => {
        onAttributeChange('data-path-scope', datapath)
      },
      onChange: () => null
    }),
    component: DialogAttributes,
    badge: DialogAttributeBadge,
    condition: ({ definition, context }) =>
      definition.name != 'var' &&
      definition.name != 'component' &&
      //YF: hack to avoid repeating on grid items
      !((context as any).parent?.name == 'section')
  } as const satisfies DialogDefinition,

  {
    group: 'attribute',
    id: 'component',
    label: 'Tag name',
    icon: mdiToyBrickOutline,
    prepare: ({ context, getAttribute, onAttributeChange }) => ({
      labels: {
        static: 'Static'
      },
      type: 'string',
      jsonpath: null,
      value: getAttribute(context, 'data-embed-as'),
      onChange: (value, attribute) => {
        onAttributeChange('data-embed-as', value)
      }
    }),
    component: DialogAttributes,
    badge: (props: DialogAttributeGeneric) => {
      const { getAttribute, context } = props
      return DialogAttributeBadge(props)
    },
    condition: ({ definition, context, getAttribute }) =>
      definition.name == 'component' && ['embed', 'web'].includes(getEmbedDefinition(context)?.type)
  } as const satisfies DialogDefinition,

  {
    group: 'attribute',
    id: 'html',
    label: 'HTML content',
    icon: mdiContentPaste,
    prepare: ({ context, getAttribute, onAttributeChange }) => ({
      type: 'html',
      labels: {
        none: 'None',
        static: 'Static ',
        mapped: 'Mapped'
      },
      value: getAttribute(context, 'data-embed-html'),
      jsonpath: (getAttribute(context, 'data-path') as string) || '',
      onConfigure: (datapath) => {
        if (getAttribute(context, 'data-embed-html') != null) onAttributeChange('data-embed-html', null)
        onAttributeChange('data-path', datapath || null)
      },
      onChange: (value) => {
        if (getAttribute(context, 'data-path')) onAttributeChange('data-path', null)
        onAttributeChange('data-embed-html', value || '')
      }
    }),
    component: DialogAttributes,
    badge: DialogAttributeBadge,
    below: ({ index }) =>
      index == 0 && (
        <>
          <Alert status='info' {...{ alignItems: 'flex-start', marginBottom: '12px' }}>
            <AlertIcon />
            <AlertDescription>
              In this mode component acts like a card. Any child elements will be passed to the component as children.
            </AlertDescription>
          </Alert>
        </>
      ),
    condition: ({ definition, context }) =>
      ['embed', 'component'].includes(definition.name) && !['feaas', 'byoc'].includes(getEmbedDefinition(context)?.type)
  } as const satisfies DialogDefinition,

  {
    group: 'attribute',
    id: 'embed-src',
    label: 'JavaScript code',
    icon: mdiScriptOutline,
    prepare: ({ onAttributeChange, getAttribute, context, contextVariable }) => ({
      type: 'url',
      labels: {
        none: 'None',
        static: 'From URL',
        mapped: 'Mapped '
      },
      value: getAttribute(context, 'data-embed-src') || null,
      jsonpath: (getAttribute(context, 'data-path-embed-src') as string) || '',
      onConfigure: (datapath) => {
        onAttributeChange('data-path-embed-src', datapath || null, contextVariable)
      },
      onChange: (value) => {
        onAttributeChange('data-embed-src', value || '')
      }
    }),
    above: ({ index }) =>
      index > 0 && (
        <>
          <Alert status='warning' {...{ alignItems: 'flex-start', marginBottom: '12px' }}>
            <AlertIcon />
            <AlertDescription>
              Loading untrusted code is a security risk. Script will be loaded once per page when component is first
              displayed.
            </AlertDescription>
          </Alert>
        </>
      ),
    component: DialogAttributes,
    badge: DialogAttributeBadge,
    condition: ({ definition, context }) =>
      ['embed', 'component'].includes(definition.name) && !['feaas', 'byoc'].includes(getEmbedDefinition(context)?.type)
  } as const satisfies DialogDefinition,
  {
    group: 'attribute',
    id: 'attributes',
    label: 'Attributes',
    icon: mdiDatabaseOutline,
    prepare: ({ context, getAttribute, onAttributeChange }) => ({
      type: 'object',
      value: getAttribute(context, 'data-attributes'),
      jsonpath: getAttribute(context, 'data-path-attributes') as string,
      onConfigure: (datapath) => {
        onAttributeChange('data-path-attributes', datapath)
      },
      onChange: (value, attribute = 'data-attributes') => {
        onAttributeChange(attribute, value)
      },
      labels: {
        none: 'None',
        static: getEmbedDefinition(context)?.type == 'byoc' ? null : 'Static',
        mapped: 'Mapped'
      },
      label: getEmbedDefinition(context)?.type == 'byoc' ? 'Data item' : 'Attributes'
    }),
    component: DialogAttributes,
    badge: DialogAttributeBadge,
    condition: ({ context, definition }) =>
      ['web', 'byoc'].includes(getEmbedDefinition(context)?.type) || definition.name == 'byoc'
  } as const satisfies DialogDefinition,
  {
    group: 'attribute',
    id: 'image',
    label: 'Image source',
    icon: mdiImageOutline,
    component: DialogAttributes,
    badge: DialogAttributeBadge,
    prepare: ({ definition, getAttribute, context, onAttributeChange }) => ({
      type: 'image',
      label:
        definition?.name == 'media' ? 'Image source' : definition.type != 'block' ? 'Icon source' : 'Background image',
      isNonNullable: true,
      value: getAttribute(context, 'image'),
      jsonpath: getAttribute(context, 'data-path-src') as string,
      onConfigure: (datapath) => {
        onAttributeChange('data-path-src', datapath)
      },
      onChange: (value) => {
        onAttributeChange('image', value)
      },
      labels: {
        none: definition?.name == 'media' ? null : 'None',
        static: 'Static',
        mapped: 'Mapped'
      }
    }),
    condition: ({ definition }) => definition.name != 'embed' && definition.group != 'external'
  } as const satisfies DialogDefinition,

  {
    group: 'attribute',
    id: 'fallback-image',
    label: 'Fallback',
    level: 2,
    prepare: ({ definition, getAttribute, onAttributeChange, context }) => {
      const prop = getFallbackProperty(definition)
      return {
        type: 'image',
        value: getAttribute(context, 'data-path-placeholder'),
        jsonpath:
          getAttribute(context, 'data-path-hidden') == getAttribute(context, prop)
            ? (getAttribute(context, prop) as string)
            : null,
        onConfigure: (datapath, attribute?: string) => {},
        onChange: (value, attribute?: string) => {
          if (value === false) {
            if (getAttribute(context, prop)) {
              onAttributeChange('data-path-placeholder', null, context)
              onAttributeChange('data-path-hidden', getAttribute(context, prop))
            }
          } else if (value == null) {
            if (getAttribute(context, 'data-path-hidden') == getAttribute(context, prop)) {
              onAttributeChange('data-path-hidden', null)
            }
            onAttributeChange('data-path-placeholder', null, context)
          } else {
            if (getAttribute(context, 'data-path-hidden') == getAttribute(context, prop)) {
              onAttributeChange('data-path-hidden', null)
            }
            if (value === true) {
              value = DataPath.getHumanizedLabel(String(getAttribute(context, prop)))
            }
            onAttributeChange('data-path-placeholder', value || null, context)
          }
        },
        labels: {}
      }
    },
    icon: mdiGhostOutline,
    above: ({ onNavigate, getAttribute, context }) =>
      getAttribute(context, 'hidden') != null && (
        <Alert status='warning' {...{ display: 'flex', flexDir: 'row' }}>
          <AlertIcon />
          <AlertTitle>Element is set to be hidden </AlertTitle>
          <Button
            variant='outline'
            ml={3}
            colorScheme='blackAlpha'
            onClick={() => onNavigate('visibility')}
            size='sm'
            style={{ marginLeft: 'auto', marginRight: 0 }}
          >
            Change
          </Button>
        </Alert>
      ),
    component: DialogAttributesFallback,
    badge: (props: DialogAttributeGeneric) => {
      const { jsonpath, value } = props
      return (
        <Badge
          colorScheme={'gray'}
          ml={4}
          display={'block'}
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
        >
          {jsonpath ? 'Hide element' : props.value == null ? 'No fallback' : value}
        </Badge>
      )
    },
    condition: ({ context, getAttribute, definition, contextVariable }) =>
      getAttribute(context, getFallbackProperty(definition)) != null && definition.type == 'block'
  } as const satisfies DialogDefinition,

  {
    group: 'attribute',
    id: 'text',
    label: 'Text',
    icon: mdiTextLong,
    prepare: ({ onAttributeChange, getAttribute, contextVariable, context, definition }) => ({
      type: 'string',
      value: Style.Context.getContextName(contextVariable) == 'var' ? null : getAttribute(context, 'data-child-text'),
      jsonpath: getAttribute(contextVariable, 'data-path') as string,
      onConfigure: (datapath) => {
        if (Style.Context.getContextName(contextVariable) == 'var') {
          onAttributeChange('data-path', datapath || '', contextVariable)
        } else {
          onAttributeChange('data-path-text', datapath)
        }
      },
      onChange: (value) => {
        onAttributeChange('data-child-text', value)
      },
      labels: {
        static: definition.name == 'var' ? null : 'Static',
        mapped: 'Mapped'
      },
      label: definition.name == 'media' ? 'Caption' : 'Text'
    }),
    component: DialogAttributes,
    badge: DialogAttributeBadge,
    condition: ({ definition }) => definition.type != 'block' || definition.name == 'media'
  } as const satisfies DialogDefinition,
  {
    group: 'attribute',
    id: 'fallback-text',
    label: 'Fallback',
    level: 2,
    prepare: ({ definition, getAttribute, onAttributeChange, context }) => {
      const prop = getFallbackProperty(definition)
      return {
        type: definition.name == 'media' ? 'image' : 'string',
        value: getAttribute(context, 'data-path-placeholder'),
        jsonpath:
          getAttribute(context, 'data-path-hidden') == getAttribute(context, prop)
            ? (getAttribute(context, prop) as string)
            : null,
        onConfigure: (datapath, attribute?: string) => {},
        onChange: (value, attribute?: string) => {
          if (value === false) {
            if (getAttribute(context, prop)) {
              onAttributeChange('data-path-placeholder', null, context)
              onAttributeChange('data-path-hidden', getAttribute(context, prop))
            }
          } else if (value == null) {
            if (getAttribute(context, 'data-path-hidden') == getAttribute(context, prop)) {
              onAttributeChange('data-path-hidden', null)
            }
            onAttributeChange('data-path-placeholder', null, context)
          } else {
            if (getAttribute(context, 'data-path-hidden') == getAttribute(context, prop)) {
              onAttributeChange('data-path-hidden', null)
            }
            if (value === true) {
              value = DataPath.getHumanizedLabel(String(getAttribute(context, prop)))
            }
            onAttributeChange('data-path-placeholder', value || null, context)
          }
        },
        labels: {}
      }
    },
    icon: mdiGhostOutline,
    above: ({ onNavigate, getAttribute, context }) =>
      getAttribute(context, 'hidden') != null && (
        <Alert status='warning' {...{ display: 'flex', flexDir: 'row' }}>
          <AlertIcon />
          <AlertTitle>Element is set to be hidden </AlertTitle>
          <Button
            variant='outline'
            ml={3}
            colorScheme='blackAlpha'
            onClick={() => onNavigate('visibility')}
            size='sm'
            style={{ marginLeft: 'auto', marginRight: 0 }}
          >
            Change
          </Button>
        </Alert>
      ),
    component: DialogAttributesFallback,
    badge: (props: DialogAttributeGeneric) => {
      const { jsonpath, value } = props
      return (
        <Badge
          colorScheme={'gray'}
          ml={4}
          display={'block'}
          overflow='hidden'
          textOverflow='ellipsis'
          whiteSpace='nowrap'
        >
          {jsonpath ? 'Hide element' : props.value == null ? 'No fallback' : value}
        </Badge>
      )
    },
    condition: ({ context, getAttribute, definition, contextVariable }) =>
      getAttribute(context, getFallbackProperty(definition)) != null && definition.type != 'block'
  } as const satisfies DialogDefinition,

  {
    group: 'attribute',
    label: 'Image alternative text',
    id: 'alt-text',
    icon: 'M4,7c-.5,0-1,.2-1.4,.6-.4,.4-.6,.9-.6,1.4v8h2v-4h2v4h2V9c0-.5-.2-1-.6-1.4-.4-.4-.9-.6-1.4-.6h-2Zm0,2h2v2h-2m6-4v10h6v-2h-4V7h-2Zm6,0v2h2v8h2V9h2v-2h-6Z',
    component: DialogAttributes,
    badge: DialogAttributeBadge,
    prepare: ({ getAttribute, onAttributeChange, context, contextVariable }) => ({
      type: 'string',
      value: getAttribute(context, 'alt') || null,
      jsonpath: (getAttribute(context, 'data-path-alt') as string) || '',
      onConfigure: (datapath) => {
        onAttributeChange('data-path-alt', datapath || null, context)
      },
      onChange: (value) => {
        onAttributeChange('alt', value || '')
      },
      labels: {
        none: 'No text',
        static: 'Static',
        mapped: 'Mapped'
      }
    }),
    condition: ({ definition }) => definition.name == 'media'
  } as const satisfies DialogDefinition,
  {
    group: 'attribute',
    id: 'link',
    label: 'Link',
    icon: mdiLinkVariant,
    prepare: ({ getAttribute, onAttributeChange, contextLink }) => ({
      type: 'url',
      value: getAttribute(contextLink, 'linkHref'),
      jsonpath: getAttribute(contextLink, 'data-path-href') as string,
      onConfigure: (datapath) => {
        onAttributeChange('data-path-href', datapath, contextLink)
      },
      onChange: (value, attribute = 'linkHref') => {
        onAttributeChange(attribute, value, contextLink)
      },
      labels: {
        none: 'None',
        static: 'Static',
        mapped: 'Mapped'
      }
    }),
    component: DialogAttributes,
    badge: DialogAttributeBadge,
    above: ({ contextLink, context, onSelect }) =>
      contextLink != context && (
        <Alert status='warning' {...{ display: 'flex', flexDir: 'row' }}>
          <AlertIcon />
          <AlertTitle>Set on parent </AlertTitle>
          <Button
            onClick={() => onSelect(contextLink)}
            size='sm'
            variant='solid'
            colorScheme='purple'
            style={{ marginLeft: 'auto', marginRight: 0 }}
            leftIcon={
              <Icon boxSize='icon-md'>
                <path d={mdiArrowUpLeft} />
              </Icon>
            }
          >
            {Style.Context.getDefinition(contextLink).label}
          </Button>
        </Alert>
      ),
    below: ({ onAttributeChange, getAttribute, contextLink, index }) =>
      index != 0 && (
        <>
          <HStack as='label' alignItems={'center'}>
            <Switch
              isChecked={getAttribute(contextLink, 'linkIsExternal') === true}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onAttributeChange('linkIsExternal', e.target.checked ? true : null, contextLink)
              }
            ></Switch>
            <Text as='p'>Open in a new tab</Text>
          </HStack>
          {/* false &&
            (contextLink.name == 'card' ||
              contextLink.name == 'button' ||
              contextLink.name == 'inline' ||
              contextLink.name == 'block') && (
              <HStack as='label' alignItems={'center'}>
                <Switch
                  isChecked={getAttribute(contextLink, 'linkStyle') == 'explicit'}
                  onChange={(e) => onAttributeChange(contextLink, 'linkStyle', e.target.checked ? 'explicit' : null)}
                ></Switch>
                <Text as='p'>Apply link style to contents</Text>
              </HStack>
            )*/}
        </>
      ),
    condition: ({ definition }) =>
      definition.name != 'section' && definition.name != 'embed' && definition.group != 'external'
  } as const satisfies DialogDefinition,

  {
    group: 'attribute',
    id: 'visibility',
    label: 'Visibility',
    icon: mdiEyeOutline,
    component: DialogAttributes,
    badge: DialogAttributeBadge,
    prepare: ({ context, getAttribute, onAttributeChange }) => ({
      type: 'boolean' as const,
      remember: false,
      value: getAttribute(context, 'hidden') ?? null,
      jsonpath: getAttribute(context, 'data-path-hidden') as string,
      onConfigure: (datapath) => {
        onAttributeChange('data-path-hidden', datapath)
      },
      onChange: (value) => {
        onAttributeChange('hidden', value === null && getAttribute(context, 'hidden') == null ? 'hidden' : null)
      },
      labels: {
        none: 'Visible',
        static: 'Hidden',
        mapped: 'Conditional'
      }
    }),
    condition: () => true
  } as const satisfies DialogDefinition
] as const satisfies readonly DialogDefinition[]
