import FieldsetField from '../FieldsetField.js'
import { Input } from '@chakra-ui/react'
import { DatasourceModel } from '@sitecore-feaas/sdk'

const DatasourceSettingsEndpoint = ({
  currentDatasource,
  previousDatasource,
  onChange,
  isBlock
}: {
  currentDatasource: DatasourceModel
  previousDatasource: DatasourceModel
  onChange: (datasource: DatasourceModel) => void
  isBlock: boolean
}) => (
  <FieldsetField
    extraProps={{ mt: !isBlock && '4' }}
    label='Retrieve data'
    isChanged={currentDatasource.settings.url !== previousDatasource.settings.url}
  >
    <Input
      value={currentDatasource.settings?.url || ''}
      onChange={(e) =>
        onChange(
          currentDatasource.change({
            settings: {
              ...currentDatasource.settings,
              url: e.target.value
            }
          })
        )
      }
      placeholder='URL like https://example.com/data.json'
    />
  </FieldsetField>
)

export default DatasourceSettingsEndpoint
