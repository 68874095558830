import { CSS, Style, mergeDeep } from '@sitecore-feaas/sdk'
import { useMemo } from 'react'

interface Props {
  rules: Style.Rule[]
  rule: Style.Rule
  customRules?: Record<string, Style.Rule>
  children: any
  isCompact?: boolean
}

export function GeneratedPreviewExternal({ rules, rule, children, customRules, isCompact }: Props) {
  const id = useMemo(() => 'preview-' + String(Math.floor(Math.random() * 1000000000)), [])

  const compactOverrides = [
    ['property', 'font-size', '14px'], // override font-size so that everything looks uniform
    ['property', 'padding', '0'],
    ['property', 'box-shadow', 'none']
  ]

  var ruleProp = [
    ...CSS.produceProps(rule.type, rule.props),
    ...(rule.details.override ? CSS.parse(rule.details.override) : []),
    ...(isCompact ? compactOverrides : [])
  ]

  if (rule.type == 'font') {
    if (rule.props.variants) {
      ruleProp = CSS.produceFontVariantProps({
        id: rule.details.id,
        ...rule.props.variants[0]
      })
    }
  } else if ((rule.type == 'block' && rule.details.collectionId == 'section') || rule.type == 'theme') {
    var extraStyles = [
      Style.Rule({
        type: 'layout',
        details: {
          id: 'preview-layout',
          slug: 'preview-layout'
        },
        props: {
          columnCount: 2,
          justifyContent: 'flex-top' as any
        }
      }),
      Style.Rule({
        type: 'spacing',
        details: {
          id: 'preview-spacing'
        },
        props: {
          rowGap: 20,
          columnGap: 20,
          paddingTop: 20,
          paddingLeft: 20,
          paddingBottom: 20,
          paddingRight: 20
        }
      })
    ]
    rule = mergeDeep(rule, {
      props: {
        layoutIds: ['preview-layout'],
        spacingIds: ['preview-spacing'],
        blocks:
          rule.type == 'theme'
            ? rule.props.blocks.map((b) => ({ ...b, spacingId: 'preview-spacing', layoutId: 'preview-layout' }))
            : null
      }
    })
    ruleProp = [
      ...ruleProp,
      ['rule', '.-section', ...CSS.produceProps('layout', extraStyles[0].props)],
      [
        'rule',
        ':not(div):not(a) + *',
        ['property', '---self--paragraph-spacing', 'var(---typography--paragraph-spacing)']
      ]
    ]
  }
  if (!ruleProp) return children
  const cssText = CSS.stringify(
    [
      [
        'rule',
        [`:wrapper(#${id}) + *`],
        ['rule', '.target.target', ...ruleProp] // reoslve `&` to `.target`,
      ]
    ],
    {
      rules: (rules as Style.Rule[]).concat(extraStyles || []),
      customRules,
      bundle: true
    }
  )
  return (
    <>
      <style id={id}>{cssText}</style>
      {children}
    </>
  )
}
