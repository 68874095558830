import { ExternalComponent, Style } from '@sitecore-feaas/sdk'
import { PickerProps } from '../../picker/Picker.js'
import { DialogMenu } from './DialogMenu.js'
import { Divider, HStack } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { titleize } from 'inflection'
import { getSchemaProperties } from '@sitecore/byoc'
export default function DialogMenuAttributes({
  setDialogName,
  variant,
  options,
  externalComponent
}: {
  setDialogName: (dialog: string) => void
  variant: PickerProps['variant']
  options: any
  externalComponent: ExternalComponent
}) {
  const keys = Object.keys(externalComponent.schema.properties || {})
    .sort((a, b) => Number(!!options[b]) - Number(!!options[a]))
    .filter((k) => !externalComponent.schema.properties[k]?.isHidden)
  const values = getSchemaProperties(externalComponent.schema, options)

  return (
    <DialogMenu
      title='Configuration'
      variant={variant}
      onNavigate={() => setDialogName('schema')}
      items={[
        {
          onNavigate: () => setDialogName('schema'),
          id: 'schema',
          label: null,
          icon: null,
          preview: () => {
            return (
              <>
                {keys.slice(0, 3).map((key) => {
                  const definition = externalComponent.schema.properties[key]
                  return (
                    <HStack key={key} align='flex-start'>
                      <Text
                        width='50%'
                        flexShrink={0}
                        flexBasis={'50%'}
                        color='blackAlpha.500'
                        fontSize='12px'
                        noOfLines={3}
                      >
                        {definition.title || titleize(key)}
                      </Text>
                      <Text
                        size='sm'
                        variant='solid'
                        flexGrow={1}
                        noOfLines={3}
                        color='blackAlpha.800'
                        fontWeight={400}
                        fontSize='12px'
                      >
                        {values[key]}
                      </Text>
                    </HStack>
                  )
                })}
                {keys.length > 3 && (
                  <>
                    <Divider orientation='horizontal' />
                    <Text
                      size='sm'
                      variant='solid'
                      flexGrow={1}
                      noOfLines={3}
                      color='blackAlpha.400'
                      fontWeight={400}
                      fontSize='12px'
                    >
                      Has {keys.length - 3} more options
                    </Text>
                  </>
                )}
              </>
            )
          },

          badge: () => {
            return (
              <Text fontWeight={400} fontSize='11px'>
                Update options
              </Text>
            )
          }
        }
      ]}
    />
  )
}
