import { Box, Button, Flex, FlexProps, Icon } from '@chakra-ui/react'
import { mdiInformationOutline } from '@mdi/js'
import React from 'react'

type Props = FlexProps & {}

const PickerElementFloatingFooter = ({ children, ...props }: Props) => (
  <Flex
    justifyContent='center'
    position='sticky'
    bottom={0}
    left={0}
    right={0}
    mt='auto'
    py={4}
    borderTop='1px'
    borderStyle='solid'
    borderColor='blackAlpha.300'
    bg='white'
    boxShadow='0 -1px 3px 0 rgba(0, 0, 0, 0.1), 0 -1px 2px 0 rgba(0, 0, 0, 0.06)'
    {...props}
  >
    {children}
  </Flex>
)

export default PickerElementFloatingFooter
