import { Box, Flex, FormControl, FormLabel, Text } from '@chakra-ui/react'
import DatasourceTree from './DatasourceTree.js'
import DatasourceTreeLeafWrapper, { LeafStatus } from './DatasourceTreeLeafWrapper.js'
import { DatasourceModel } from '@sitecore-feaas/sdk'
import DatasourceStatus from './DatasourceStatus.js'
import { formatDate } from '../../utils/date.js'
import { cloneDeep, mixDeep } from '@sitecore-feaas/sdk'
import { queryObject } from '@sitecore-feaas/clientside/headless'

import datasource from './Datasource.js'
import DatasourceTreeLeafContent from './DatasourceTreeLeafContent.js'

const DatasourceTreeRoot = ({
  currentDatasource,
  previousDatasource,
  onChange
}: {
  currentDatasource: DatasourceModel
  previousDatasource: DatasourceModel
  onChange: (datasource: DatasourceModel) => void
}) => {
  return (
    <Flex ml='8' flexDirection='column' width='80'>
      <Flex>
        <FormControl>
          <Flex align={'base'}>
            <FormLabel>Data tree root</FormLabel>

            {currentDatasource.sampledAt && (
              <Text ml='auto' fontWeight='bold' fontSize='xs' variant='microcopy'>
                Sampled on: {formatDate(currentDatasource.sampledAt)}
              </Text>
            )}
          </Flex>
        </FormControl>
      </Flex>

      <Box
        flex='1'
        bg='white'
        w='full'
        borderRadius='base'
        border='1px'
        borderColor='blackAlpha.400'
        overflowY='auto'
        py='3'
        px='5'
        pos='relative'
        className='data-tree'
      >
        <DatasourceTree
          datasourceName={datasource.name}
          isVerbose={true}
          intent='trimming'
          prefix='$'
          path={currentDatasource.settings.jsonpath}
          onConfigure={(jsonpath: string) =>
            onChange(
              currentDatasource.change({
                settings: {
                  ...currentDatasource.settings,
                  jsonpath
                }
              })
            )
          }
          data={
            !previousDatasource.sample || !currentDatasource.sample
              ? currentDatasource.sample || previousDatasource.sample
              : mixDeep(cloneDeep(previousDatasource.sample), cloneDeep(currentDatasource.sample))
          }
          renderLeafTree={(props, collapsedLeaves, onCollapsedLeavesChange) => {
            const { path, label } = props
            const parentPath = path.substring(0, path.lastIndexOf('.'))
            const leafStatus: LeafStatus =
              path === '$'
                ? 'unchanged'
                : DatasourceModel.getTreeViewLeafState(
                    queryObject(previousDatasource.sample, path),
                    queryObject(currentDatasource.sample, path)
                  )

            // collapsed
            if (collapsedLeaves.some((p) => parentPath.includes(p))) return null

            // expanded
            return (
              <>
                <DatasourceTreeLeafWrapper {...props}>
                  {
                    <DatasourceTreeLeafContent
                      path={path}
                      datasource={currentDatasource}
                      label={label}
                      collapsedLeaves={collapsedLeaves}
                      onCollapsedLeavesChange={onCollapsedLeavesChange}
                      leafStatus={leafStatus}
                      action={props.action}
                    />
                  }
                </DatasourceTreeLeafWrapper>
                <DatasourceStatus leafStatus={leafStatus} path={path} />
              </>
            )
          }}
        />
      </Box>
    </Flex>
  )
}
export default DatasourceTreeRoot
