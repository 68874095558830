import { DatasourceModel } from '@sitecore-feaas/sdk'
import { mdiInformationOutline } from '@mdi/js'
import { Box, FormControl, FormLabel, HStack, Icon, Input, Link, Switch, Tooltip } from '@chakra-ui/react'
import FieldsetField from '../FieldsetField.js'
import DatasourceTree from '../datasources/DatasourceTree.js'
import DatasourceTreeLeafWrapper from '../datasources/DatasourceTreeLeafWrapper.js'
import DatasourceTreeLeafContent from '../datasources/DatasourceTreeLeafContent.js'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { DataPath, DataScope } from '@sitecore-feaas/clientside/headless'
import { DialogAttributeGeneric } from '../editor/dialog/Dialog.js'
import * as CK from '@sitecore-feaas/ckeditor5'
import { Text } from '@chakra-ui/react'

const PickerDatasourcesPath = ({
  context,
  type,
  datasource,
  textpath,
  jsonpath,
  onTextpathChange,
  onConfigure,
  onRepeatingChange
}: {
  context: DialogAttributeGeneric['context']
  type: DialogAttributeGeneric['type']
  datasource: DatasourceModel
  textpath: string
  jsonpath: string
  onTextpathChange: (textpath: string) => void
  onConfigure: (value: string) => void
  onRepeatingChange: (repeating: 'no-limit' | 'limit' | 'range') => void
}) => {
  if (!datasource) return <>Please select a datasource to be able to set its path</>

  const [showDetails, setShowDetails] = useState(false)

  const scopes = (context as CK.ModelElement)
    .getAncestors({ includeSelf: true })
    .reduce((scopes, el: CK.ModelElement) => {
      if (el.getAttribute('data-path-scope') == null) return scopes
      return [el.getAttribute('data-path-scope')].concat(scopes)
    }, [])
  const validate = (value: string) => {
    if (type == 'url' || type == 'image') {
      if (typeof value != 'string' || (!value.startsWith('/') && !value.includes('//'))) {
        return 'Value does not look like an URL'
      }
    }
    if (type == 'boolean') {
      if (typeof value != 'boolean' && typeof value != 'number') {
        return 'Value does not look like an boolean'
      }
    }
  }

  return (
    <>
      <>
        <HStack justifyContent='space-between' alignItems='middle' width='100%' mb='2'>
          <FieldsetField
            label='Expert mode '
            htmlFor='is-expert'
            extraProps={{
              display: 'flex',
              whiteSpace: 'nowrap',
              color: 'blackAlpha.600',
              labelRight: true,
              pb: 0,
              width: 'auto'
            }}
          >
            <Switch
              size={'sm'}
              mt={'3px'}
              mr={2}
              id='is-expert'
              onChange={() => setShowDetails((v) => !v)}
              isChecked={showDetails}
            ></Switch>
          </FieldsetField>
          <Tooltip label='JSON Path syntax cheat sheet'>
            <Link
              target='_blank'
              color='primary.700'
              href='https://www.npmjs.com/package/jsonpath-plus'
              justifyContent={'space-between'}
              visibility={showDetails ? 'visible' : 'hidden'}
              height={'20px'}
              whiteSpace={'nowrap'}
            >
              JSONPath{' '}
              <Icon boxSize='icon-lg' verticalAlign={'-3px'}>
                <path d={mdiInformationOutline} />
              </Icon>
            </Link>
          </Tooltip>
        </HStack>

        {showDetails && (
          <Input
            type='text'
            mb={4}
            fontFamily='mono'
            value={textpath}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onTextpathChange(e.target.value)}
            onBlur={(e: ChangeEvent<HTMLInputElement>) => onConfigure(e.target.value)}
            placeholder='JSON Path'
          />
        )}

        <FormControl>
          <DatasourceTree
            validator={validate}
            isVerbose={showDetails}
            onConfigure={(path) => {
              onRepeatingChange('no-limit')
              onConfigure(path)
            }}
            intent={type == 'array' ? 'repeating' : type == 'object' ? 'scoping' : 'mapping'}
            data={DataScope.queryObjectRaw(datasource.sample, datasource.settings.jsonpath)[0]}
            prefix={
              datasource?.id +
              (datasource.settings.jsonpath == '$' ? '' : '.' + datasource.settings.jsonpath.replace('$.', '') + '.$')
            }
            label={datasource.settings.jsonpath != '$' ? datasource.name : undefined}
            path={jsonpath}
            scopes={scopes}
            renderLeafTree={(props, collapsedLeaves, onCollapsedLeavesChange) => {
              const { path, label } = props
              const currentPath = path.replace(datasource.id, '$')
              const parentPath = currentPath.substring(0, currentPath.lastIndexOf('.'))

              // collapsed
              if (collapsedLeaves.some((p) => parentPath.includes(p))) return null

              // expanded
              return (
                <>
                  <DatasourceTreeLeafWrapper {...props}>
                    {
                      <DatasourceTreeLeafContent
                        path={currentPath}
                        datasource={datasource}
                        label={label}
                        collapsedLeaves={collapsedLeaves}
                        onCollapsedLeavesChange={onCollapsedLeavesChange}
                        action={props.action}
                      />
                    }
                  </DatasourceTreeLeafWrapper>
                </>
              )
            }}
          />
        </FormControl>
      </>
    </>
  )
}

export default PickerDatasourcesPath
