import {
  Box,
  Button,
  HStack,
  Icon,
  Spacer,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useSteps,
  VStack
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js'
import React, { FC, ReactElement } from 'react'

export type WizardStep = {
  title: string
}

const PickerWizard = ({
  defaultStep,
  steps,
  extraButton,
  onComplete,
  children
}: {
  defaultStep: number
  steps: WizardStep[]
  extraButton?: FC
  onComplete?: () => void
  children: ReactElement[]
}) => {
  const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
    index: defaultStep,
    count: steps.length
  })
  const filteredChildren = children.filter((c) => !!c)
  return (
    <>
      <VStack alignItems={'start'} mb={'50px'}>
        {steps.length > 1 && (
          <Stepper colorScheme={'primary'} index={activeStep} mt={3} mb={9} px={1} width={'100%'}>
            {steps.map((step, index) => (
              <Step key={index}>
                {/* @ts-ignore */}
                <StepIndicator cursor={'pointer'} onClick={() => setActiveStep(index)}>
                  <VStack
                    css={css`
                      && h3 {
                        color: black;
                      }
                    `}
                  >
                    <Text fontSize={'13px'}> &nbsp; </Text>
                    <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
                    <StepTitle>
                      <Text pl={index === 0 ? 3 : 0}>{step.title}</Text>
                    </StepTitle>
                  </VStack>
                </StepIndicator>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        )}
        {filteredChildren[activeStep]}
      </VStack>

      {/* Footer */}
      {(steps.length > 1 || extraButton) && (
        <HStack
          justifyContent={'space-between'}
          bottom={0}
          right={0}
          width='var(--picker-width, 100%)'
          position={'fixed'}
          background={'white'}
          boxShadow={'0px -1px 2px 0px #0000000F; box-shadow: 0px -1px 3px 0px #0000001A'}
          p={2}
          pr={3}
          ml={-3}
        >
          <Box>{extraButton && extraButton({ activeStep })}</Box>
          <HStack justifyContent={'flex-end'}>
            {activeStep > 0 && (
              <Button size={'xs'} variant={'outline'} onClick={goToPrevious}>
                <HStack>
                  <Icon boxSize={'icon-lg'}>
                    <path d={mdiArrowLeft} />
                  </Icon>
                  <Text>Back</Text>
                </HStack>
              </Button>
            )}
            {activeStep < steps.length - 1 ? (
              <Button size={'xs'} variant={'primary'} onClick={goToNext}>
                <HStack spacing='4px'>
                  <Text>Next</Text>
                  <Icon boxSize={'icon-lg'}>
                    <path d={mdiArrowRight} />
                  </Icon>
                </HStack>
              </Button>
            ) : onComplete ? (
              <Button size={'xs'} variant={'primary'} onClick={onComplete}>
                <HStack>
                  <Text>Complete</Text>
                </HStack>
              </Button>
            ) : null}
          </HStack>
        </HStack>
      )}
    </>
  )
}

export default PickerWizard
