import { VStack } from '@chakra-ui/react'
import { Style } from '@sitecore-feaas/sdk'
import React from 'react'
import PickerThemesCombo from './PickerThemesCombo.js'

const PickerThemesCollectionCombos = ({
  collection,
  theme,
  rules: rules,
  usedComboIds,
  onChange
}: {
  collection: Style.RuleCollection<Style.Type.Element>
  theme: Style.Rule<'theme'>
  rules: Style.Rule[]
  usedComboIds: string[]
  onChange: (combo: Style.Theme.Combo, category: keyof Style.Theme.Props, theme: Style.Rule<'theme'>) => void
}) => {
  const category = `${collection.props.type}s` as const

  const collectionCombos: Style.Theme.Combo[] = theme.props[category].filter((combo) => {
    const comboStyle = Style.Set.findById(rules, combo.refId)
    return comboStyle.details.collectionId === collection.details.id
  })

  const collectionDefaultCombo = collectionCombos.find((combo) => combo.isDefault)

  const collectionUsedComboId = usedComboIds.find((id) => {
    const combo = theme.props[category].find((combo: Style.Theme.Combo) => combo.id === id)
    const currentStyle = Style.Set.findById(rules, combo?.refId)
    return currentStyle?.details.collectionId === collection.details.id
  })

  const activeCombo = (collectionCombos.find((combo) => combo.id === collectionUsedComboId) ||
    collectionDefaultCombo) as Style.Theme.Combo

  return (
    <VStack alignItems='stretch' spacing={2}>
      {collectionCombos.map((combo) => (
        <PickerThemesCombo
          rules={rules}
          key={combo.id}
          combo={combo}
          isDefault={combo.isDefault}
          isSelected={activeCombo.id === combo.id || (!activeCombo && combo.isDefault)}
          onChange={(style) => onChange(combo, category, theme)}
        />
      ))}
    </VStack>
  )
}

export default PickerThemesCollectionCombos
