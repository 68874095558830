import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import { FunctionComponent, ReactElement, useEffect, useRef, useState } from 'react'
import { formatDate } from '../../../utils/date.js'
import FieldsetField from '../../FieldsetField.js'
import TextField from '../../TextField.js'
import { SDKContext } from '../../providers/SDKProvider.js'
import { checkerboard } from '../previews/index.js'
import { useSDK } from '../../../hooks/useData.js'

interface Props {
  onChange: (value: string) => void
  onUpload: (filelist: FileList) => void
  value: string
  label?: string
  disabled?: boolean
}

const FileField: FunctionComponent<Props> = (props: Props): ReactElement => {
  const { onChange, value, label = 'Alignment', disabled, onUpload } = props

  const [date, setDate] = useState<Date>(null)
  const previewRef = useRef<HTMLDivElement>()
  const sdk = useSDK()
  const [state, setState] = useState<string>()
  const ref = useRef<ReturnType<typeof setTimeout>>(null)
  useEffect(() => {
    if (!value) return
    clearTimeout(ref.current)
    ref.current = setTimeout(() => {
      setState(null)
      sdk
        .proxy(value, { method: 'GET' })
        .then((response) => {
          const c = new Date(response.headers.get('Created-At') || 'Nothing')
          if (isFinite(Number(c))) {
            setDate(c)
          } else {
            const m = new Date(response.headers.get('Last-Modified') || 'Nothing')
            if (isFinite(Number(m))) {
              setDate(m)
            } else {
              setDate(null)
            }
          }
        })
        .catch(() => {
          setDate(null)
        })
    }, 300)
  }, [value])

  return (
    <VStack spacing={4} className='file-field'>
      <HStack align='center' width='100%'>
        <Box css={checkerboard} flexShrink={0} borderRadius={'4px'} borderWidth='1px' borderColor='blackAlpha.200'>
          <Box
            className='file-preview'
            ref={previewRef}
            width={'80px'}
            height={'80px'}
            backgroundPosition='center'
            backgroundSize='contain'
            backgroundRepeat='no-repeat'
            backgroundImage={`url("` + value + `")`}
          ></Box>
        </Box>
        <Box flexShrink={1} minWidth={0} flexGrow={1}>
          <Text whiteSpace='nowrap' overflow={'hidden'} minWidth={0} textOverflow='ellipsis' width={'100%'}>
            {value?.split('/').pop().split('?')[0]}
          </Text>
          <Text color='blackAlpha.500' fontSize='12px'>
            {state ?? formatDate(date)}
          </Text>
        </Box>

        <Button as='label' ml='auto' flexShrink={0} size='sm' variant='primary' position='relative' overflow={'hidden'}>
          <input
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              height: '100%',
              width: '100%',
              opacity: 0.01
            }}
            type='file'
            accept='.png,.gif,.jpg,.jpeg,.svg'
            onChange={(e) => {
              setState('Uploading...')
              onUpload(e.target.files)
              const fr = new FileReader()
              fr.readAsArrayBuffer(e.target.files[0])
              fr.onload = function () {
                // you can keep blob or save blob to another position
                const blob = new Blob([fr.result])
                // url for download
                const url = URL.createObjectURL(blob)
                if (previewRef.current) previewRef.current.style.backgroundImage = `url("${url}")`
              }
              e.target.value = null
            }}
          />
          Change
        </Button>
      </HStack>
      <FieldsetField label='or paste URL'>
        <TextField value={value || ''} onChange={onChange} placeholder='http://' type='url' />
      </FieldsetField>
    </VStack>
  )
}

export default FileField
