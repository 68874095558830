import React from 'react'
import { Flex } from '@chakra-ui/react'
import { default as styled } from '@emotion/styled'

const FlexGrid = ({
  spacing = 24,
  children,
  className,
  extraProps,
  childrenWidth = 320
}: {
  spacing?: number
  className?: string
  children: React.ReactNode
  extraProps?: Record<string, any>
  childrenWidth?: number | string
}) => (
  <Flex
    className={className}
    wrap='wrap'
    m={`-${spacing / 2}px`}
    mt={`-${spacing / 2}px`}
    maxWidth={`calc(100% + ${spacing}px)`}
    sx={{
      '& > *': {
        margin: `${spacing / 2}px`,
        maxWidth: `100%`,
        width: typeof childrenWidth === 'string' ? childrenWidth : `${childrenWidth}px`
      },
      alignItems: 'flex-start'
    }}
    {...extraProps}
  >
    {children}
  </Flex>
)

export default FlexGrid

export const LargeFlexGrid = ({ children }: any) => (
  <FlexGrid childrenWidth={344} extraProps={{ mt: 3 }}>
    {children}
  </FlexGrid>
)

export const Row = ({ children, width, gap }: any) => (
  <FlexGrid childrenWidth={width} spacing={gap} extraProps={{ mt: 3, mb: 0 }}>
    {children}
  </FlexGrid>
)
