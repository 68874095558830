import { Badge, Box, Flex, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { CSS, Style } from '@sitecore-feaas/sdk'
import { GeneratedPreview } from './GeneratedPreview.js'
import { css } from '@emotion/react'
import { useMemoWithComparator } from 'react-use-memo-with-comparator'
import { isDeepEquals } from '@sitecore-feaas/sdk'
import { GeneratedPreviewExternal } from './GeneratedPreviewExternal.js'
import { singularize } from 'inflection'

export const checkerboard = css`
  background-image: linear-gradient(45deg, var(--chakra-colors-blackAlpha-100) 25%, transparent 25%),
    linear-gradient(-45deg, var(--chakra-colors-blackAlpha-100) 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var(--chakra-colors-blackAlpha-100) 75%),
    linear-gradient(-45deg, transparent 75%, var(--chakra-colors-blackAlpha-100) 75%);
  background-size: 16px 16px;
  background-position: 0 0, 0 8px, 8px -8px, -8px 0px;
`

const SpacingStyle = css`
  grid-template-areas:
    '. . t . . '
    '. c c c . '
    'l c c c r '
    '. c c c . '
    '. . b . . ';
  grid-template-columns: auto 0px 1fr 0px auto;
  grid-template-rows: auto 0px 1fr 0px auto;
  height: 100px;

  .value {
    > span {
      position: absolute;
      color: var(--chakra-colors-primary-500);
      font-size: var(--chakra-fontSizes-sm);
      font-weight: var(--chakra-fontWeights-extrabold);
    }

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;

    &.left,
    &.right {
      min-width: 32px;
      flex-direction: column;

      > span {
        top: 50%;
        transform: translateY(calc(-100% - 4px));
      }
    }

    &.top,
    &.bottom,
    &.vgap {
      min-height: 24px;

      > span {
        left: 50%;
        transform: translateX(calc(-100% - 6px));
      }
    }

    &.left {
      grid-area: l;
    }

    &.right {
      grid-area: r;
    }

    &.bottom {
      grid-area: b;
    }

    &.top {
      grid-area: t;
    }

    &.vgap {
      min-height: 0;
      flex: 1;
    }

    &.hgap {
      min-height: 18px;
      min-width: 18px;

      > span {
        left: 50%;
        transform: translateX(calc(-100% - 13px));
      }
    }
  }

  .column {
    flex-basis: 50%;
    align-self: stretch;
    align-items: stretch;
  }

  .row {
    background: var(--chakra-colors-blackAlpha-200);
    margin: 0;
    flex-basis: 18px;
    border-radius: 4px;
  }

  .center {
    grid-area: c;
    border-radius: 4px;
    outline: 1px solid var(--chakra-colors-primary-500);
    align-items: start;
  }

  .arrow {
    position: relative;
    background-color: var(--chakra-colors-primary-500);

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      display: block;
      border-style: solid;
      border-color: var(--chakra-colors-primary-500);
      rotate: 45deg;
    }

    &.up-down {
      width: 1px;
      height: 70%;
      margin-left: 6px;
      margin-right: 6px;

      &:before {
        left: -2px;
        border-width: 1px 0px 0px 1px;
      }
      &:after {
        bottom: 0px;
        left: -2px;
        border-width: 0px 1px 1px 0px;
      }
    }

    &.left-right {
      width: 80%;
      height: 1px;
      margin-top: 6px;
      margin-bottom: 6px;

      &:before {
        top: -2px;
        border-width: 0px 0px 1px 1px;
      }
      &:after {
        top: -2px;
        right: 0;
        border-width: 1px 1px 0px 0px;
      }
    }
  }
`

function Thumbnail({
  maxWidth = 512,
  hasCheckerboard = false,
  children,
  isCompact,
  ...props
}: {
  maxWidth?: number
  hasCheckerboard?: boolean
  children?: any
  isCompact?: boolean
  [key: string]: any
}) {
  return (
    <Flex
      maxWidth={maxWidth}
      boxShadow={!isCompact && hasCheckerboard && '0 0 1px rgba(0,0,0,0.2)'}
      direction='row'
      justifyContent='center'
      pointerEvents='none'
      css={hasCheckerboard && checkerboard}
      maxHeight={160}
      align='stretch'
      width={!isCompact && 'full'}
      borderRadius='md'
      flexDirection='column'
      overflow='hidden'
      {...props}
    >
      {children}
    </Flex>
  )
}

interface PreviewProps<T = Style.Rule> {
  rule: T
  customRules?: Record<string, Style.Rule>
  variant?: string
  rules: Style.Rule[]
  isCompact?: boolean
  [prop: string]: any
}

const previewTypes: Partial<Record<Style.Rule['type'] | 'fontVariant', (props: PreviewProps) => JSX.Element>> = {
  block: ({
    rule: {
      details: { collectionId }
    },
    rules,
    customRules,
    isCompact,
    ...box
  }) =>
    isCompact ? (
      <Thumbnail hasCheckerboard p={1} isCompact {...box}>
        <Box flexGrow={1} className='target' h={30} w={30} />
      </Thumbnail>
    ) : collectionId === 'section' ? (
      <Thumbnail hasCheckerboard h={15}>
        <Box className='target' />
      </Thumbnail>
    ) : (
      <Thumbnail hasCheckerboard h={15} align='center'>
        <Box className='target' w='50%' style={{ padding: '20px' }} />
      </Thumbnail>
    ),
  typography: ({
    rule: {
      details: { title, exampleContent }
    },
    rules,
    customRules: customRules,
    isCompact,
    ...box
  }: PreviewProps) =>
    isCompact ? (
      <Thumbnail paddingX={2} h={8} isCompact {...box}>
        <Text className='target'>Ag</Text>
      </Thumbnail>
    ) : (
      <Thumbnail hasCheckerboard h={15} px={3} border='1px' borderColor='blackAlpha.400' whiteSpace='nowrap'>
        <Text textAlign='center' className='target'>
          The quick brown fox
        </Text>
      </Thumbnail>
    ),
  inline: ({
    rule: {
      details: { title, exampleContent, collectionId }
    },
    rules = [],
    customRules,
    isCompact,
    ...box
  }: PreviewProps) =>
    isCompact ? (
      <Thumbnail hasCheckerboard p={1} px={collectionId === 'link' && 2} alignItems={'center'} isCompact {...box}>
        <Flex
          className={collectionId !== 'link' ? 'target' : ''}
          minW={8}
          h={8}
          justifyContent='center'
          alignItems='center'
        >
          {collectionId === 'link' ? (
            <Box textDecoration='var(---typography--text-decoration, none)' as='a' className='target'>
              Link
            </Box>
          ) : (
            Style.Set.findById(rules, collectionId)?.details.title[0] || 'I'
          )}
        </Flex>
      </Thumbnail>
    ) : (
      <Thumbnail hasCheckerboard p={5} h={15} alignItems={'center'} {...box}>
        <Box
          textDecoration={collectionId == 'link' ? 'var(---typography--text-decoration, none)' : null}
          as={collectionId == 'link' ? 'a' : 'span'}
          className='target'
          width='max-content'
        >
          {exampleContent ||
            (collectionId == 'link'
              ? 'Link'
              : singularize(Style.Set.findById(rules, collectionId)?.details.title || 'Inline element'))}
        </Box>
      </Thumbnail>
    ),
  text: ({
    rule: {
      details: { title, exampleContent }
    },
    rules,
    customRules,
    isCompact,
    ...box
  }: PreviewProps<Style.Rule<'text'>>) =>
    isCompact ? (
      <Thumbnail hasCheckerboard h={10} p={2} isCompact {...box}>
        <Text as={'p'} className='target' color='blackAlpha.800' textShadow={'0 0 1px rgba(0,0,0,0.2)'}>
          Ag
        </Text>
      </Thumbnail>
    ) : (
      <Thumbnail
        hasCheckerboard
        height={15}
        px={3}
        border='1px'
        borderColor='blackAlpha.400'
        whiteSpace='nowrap'
        {...box}
      >
        <Text as={'p'} align='center' className='target' color='blackAlpha.800' textShadow={'0 0 1px rgba(0,0,0,0.2)'}>
          The quick brown fox
        </Text>
      </Thumbnail>
    ),
  decoration: ({ isCompact }) =>
    isCompact ? (
      <Thumbnail hasCheckerboard p={1} isCompact>
        <Box h={30} w={30} className='target' />
      </Thumbnail>
    ) : (
      <Thumbnail hasCheckerboard h={15}>
        <Box mx='auto' w='50%' className='target' h={10} bg='gray.100' />
      </Thumbnail>
    ),
  fill: ({ isCompact }) =>
    isCompact ? (
      <Thumbnail px={3} py='6px' isCompact>
        <Box w={5} h={5} className='target' borderRadius='50%' border='1px solid' borderColor='blackAlpha.200' />
      </Thumbnail>
    ) : (
      <Thumbnail hasCheckerboard>
        <Box
          className='target'
          h={15}
          border='1px solid'
          borderColor='blackAlpha.400'
          borderRadius='4px'
          bg='gray.50'
        />
      </Thumbnail>
    ),
  font: ({
    rule: {
      details: { title, exampleContent }
    },
    isCompact
  }: PreviewProps) => (
    <Thumbnail fontSize={!isCompact ? 'lg' : ''} isCompact>
      <Text className='target'>{title || 'Example text'}</Text>
    </Thumbnail>
  ),
  breakpoint: ({
    rule: {
      details: { title, exampleContent },
      props
    }
  }: PreviewProps<Style.Rule<'breakpoint'>>) => {
    const { minWidth, maxWidth } = props
    return (
      <Thumbnail
        fontSize='3xl'
        display='flex'
        alignItems='center'
        justifyContent='center'
        height={15}
        backgroundColor='red.50'
        px='5'
      >
        <svg
          className='target'
          height='15'
          viewBox='0 0 302 15'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          width='100%'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0.292894 8.07107C-0.0976304 7.68054 -0.0976305 7.04738 0.292894 6.65685L6.65685 0.292893C7.04738 -0.0976317 7.68054 -0.0976318 8.07107 0.292893C8.46159 0.683417 8.46159 1.31658 8.07107 1.70711L3.41421 6.36396L298.586 6.36394L293.929 1.70708C293.538 1.31656 293.538 0.683392 293.929 0.292868C294.319 -0.0976568 294.953 -0.0976569 295.343 0.292867L301.707 6.65683C302.098 7.04735 302.098 7.68052 301.707 8.07104L295.343 14.435C294.953 14.8255 294.319 14.8255 293.929 14.435C293.538 14.0445 293.538 13.4113 293.929 13.0208L298.586 8.36394L3.41421 8.36396L8.07107 13.0208C8.46159 13.4113 8.46159 14.0445 8.07107 14.435C7.68054 14.8256 7.04738 14.8256 6.65686 14.435L0.292894 8.07107Z'
            fill='red'
            color='#C53030'
          />
        </svg>

        <Flex position='absolute'>
          <Badge colorScheme='red' textTransform='none'>
            {minWidth}px - {isFinite(maxWidth) ? `${maxWidth}px` : '∞'}
          </Badge>
        </Flex>
      </Thumbnail>
    )
  },
  graphic: ({
    rule: {
      details: { title, exampleContent },
      props
    }
  }: PreviewProps) => {
    //@ts-ignore
    const { url } = props
    return (
      <Thumbnail
        fontSize='3xl'
        hasCheckerboard
        height='300px'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <img width='200' className='target' src={url} alt='' />
      </Thumbnail>
    )
  },
  theme: ({
    variant,
    rule: {
      details: { title, exampleContent },
      props
    }
  }: PreviewProps) => {
    return (
      <Thumbnail hasCheckerboard boxShadow='none'>
        <Box className='-feaas target -theme--preview' height={15} overflow={'hidden'}>
          <Box
            className='-section'
            height='100%'
            display='flex'
            alignItems={'center !important'}
            justifyContent={'center'}
            overflow='hidden !important'
            p='8px !important'
            gap={'8px !important'}
            m={'0 !important'}
            borderRadius={'0 !important'}
          >
            <Box
              className='-card'
              boxSize='100% !important'
              flex='33% 1 1 !important'
              p={'0 !important'}
              m={'0 !important'}
            ></Box>
            <Box
              className='-button'
              boxSize='100% !important'
              flex='33% 1 1 !important'
              p={'0 !important'}
              display='flex !important'
              alignItems='center !important'
              justifyContent='center !important'
              fontSize='25px !important'
              lineHeight='1 !important'
              m={'0 !important'}
            >
              B
            </Box>
            <Box
              as='p'
              className='-heading1'
              flex='33% 1 1 !important'
              fontSize='25px !important'
              lineHeight='1 !important'
              minWidth='0'
              whiteSpace={'nowrap'}
              ml='8px !important'
            >
              Title
            </Box>
          </Box>
        </Box>
      </Thumbnail>
    )
  },
  color: ({
    rule: {
      details: { title, exampleContent },
      props
    }
  }: PreviewProps<Style.Rule<'color'>>) => {
    const bg = Style.rgbaToString(props)

    return (
      <Thumbnail hasCheckerboard>
        <Box className='target' h={15} borderRadius='base' border='1px solid' backgroundColor={bg} />
      </Thumbnail>
    )
  },
  palette: ({ rule: { props }, rules, isCompact }: PreviewProps<Style.Rule<'palette'>>) => {
    const color = Style.Set.findById<'color'>(rules, props.textColor.id)
    const bg = Style.rgbaToString(color?.props)
    return isCompact ? (
      <Thumbnail px={3} py='6px' isCompact>
        <Box
          w={5}
          h={5}
          className='target'
          borderRadius='50%'
          border='1px solid'
          borderColor='blackAlpha.200'
          bgColor={bg}
        />
      </Thumbnail>
    ) : (
      <Thumbnail hasCheckerboard fontSize='2xl' h='70px' borderRadius='base' border='1px solid'>
        <Box className='target' h='70px' backgroundColor={bg} />
      </Thumbnail>
    )
  },
  fontVariant: ({
    rule: {
      details: { title, exampleContent }
    }
  }: PreviewProps) => (
    <Thumbnail fontSize='2xl'>
      <Text className='target'>{exampleContent || 'Example text'}</Text>
    </Thumbnail>
  ),
  spacing: ({ rule: { props } }: PreviewProps<Style.Rule<'spacing'>>) => {
    return (
      <Box background='blackAlpha.50' w='full' maxW='512px' borderRadius='md'>
        <SimpleGrid css={SpacingStyle}>
          <Box className='value top'>
            <span>{Style.stringifyLength(props.paddingTop).replace('px', '')}</span>
            <Box className='arrow up-down' />
          </Box>
          <Box className='value bottom'>
            <span>{Style.stringifyLength(props.paddingBottom).replace('px', '')}</span>
            <Box className='arrow up-down' />
          </Box>
          <Box className='value left'>
            <span>{Style.stringifyLength(props.paddingLeft).replace('px', '')}</span>
            <Box className='arrow left-right' />
          </Box>
          <Box className='value right'>
            <span>{Style.stringifyLength(props.paddingRight).replace('px', '')}</span>
            <Box className='arrow left-right' />
          </Box>
          <HStack className='center' spacing={0}>
            <VStack className='column' spacing={0}>
              <Box className='row' />
            </VStack>
            <Box className='value hgap'>
              <span>{Style.stringifyLength(props.columnGap).replace('px', '')}</span>
              <Box className='arrow left-right' />
            </Box>
            <VStack className='column' spacing={0}>
              <Box className='row' />
              <Box className='value vgap'>
                <span>{Style.stringifyLength(props.rowGap).replace('px', '')}</span>
                <Box className='arrow up-down' />
              </Box>
              <Box className='row' />
            </VStack>
          </HStack>
        </SimpleGrid>
      </Box>
    )
  }
}

export default function Preview({ rule, rules, customRules, ...props }: PreviewProps) {
  const PreviewType = previewTypes[rule.type]
  if (!PreviewType) return null
  const Generated = rules ? GeneratedPreviewExternal : GeneratedPreview
  const isCompact = props.variant === 'compact'
  return (
    <Generated rules={rules} rule={rule} customRules={customRules} isCompact={isCompact}>
      <PreviewType rule={rule} rules={rules} customRules={customRules} isCompact={isCompact} {...props} />
    </Generated>
  )
}

export function PreviewFontsStylesheet({ styles }: { styles: Style.Rule[] }) {
  const usedFonts = Style.Set.filterByType(styles, 'font')

  const cssText = useMemoWithComparator(
    () => {
      return CSS.stringify(usedFonts.map((font) => CSS.produceStyle(font), { styles: styles }))
    },
    [usedFonts],
    isDeepEquals
  )
  return <style>{cssText}</style>
}
