import { Box, Flex } from '@chakra-ui/react'
import { useContext } from 'react'
import { EntityContext } from '../../contexts/EntityContext.js'
import { useSlots } from '../../hooks/useSlots.js'
import EntityActions from './EntityActions.js'

const EntityBlockOpen = ({ children, className }: { children: any; className?: string }) => {
  const {
    isActive,
    isNonEditable,
    ref,
    id,
    getClassname,
    activityAttribute,
    onToggleEntity,
    onMouseEnterUpper,
    wrapperClassname,
    onMouseLeaveUpper
  } = useContext(EntityContext)

  return useSlots(children, ({ preview, details, form, extraActions, extraMenuActions, children }) => (
    <Box
      {...activityAttribute}
      position='relative'
      borderRadius='base'
      marginTop='5'
      id={`entity-${id}`}
      ref={ref}
      onMouseEnter={onMouseEnterUpper}
      onMouseLeave={onMouseLeaveUpper}
      className={wrapperClassname}
    >
      <Flex onClick={onToggleEntity} cursor={!isNonEditable && 'pointer'}>
        <Box flex='1' position='relative' className={getClassname('upper')} w='full'>
          {preview && <Box className={getClassname('preview')}>{preview}</Box>}

          {details && (
            <Box mt={preview && '5'} className={getClassname('details')}>
              {details}
            </Box>
          )}
        </Box>

        <Flex pt='5' ml='auto' className={getClassname('actions')}>
          <EntityActions extraMenuActions={extraMenuActions} extraActions={extraActions} />
        </Flex>
      </Flex>

      <Box mt='5' className={getClassname('form')}>
        {form}
      </Box>

      {children}
    </Box>
  ))
}

export default EntityBlockOpen
