import { Button } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import ButtonGroupSwitch from '../../ButtonGroupSwitch.js'
import type { DialogAttributeGeneric } from './Dialog.js'
import DialogAttributesStatic from './DialogAttributesStatic.js'
import DialogAttributesMapping from './DialogAttributesMapping.js'
export const useDialogRemembering = (
  props: DialogAttributeGeneric
): { props: DialogAttributeGeneric; values: Pick<DialogAttributeGeneric, 'value' | 'jsonpath'> } => {
  const { value, jsonpath, onChange, onConfigure } = props
  const remembered = useRef({ value, jsonpath })

  const onRememberingChange = (value: any, attribute?: string) => {
    if (!attribute) remembered.current.value = value
    onChange(value, attribute)
  }
  const onRememberingConfiugre = (jsonpath: string, attribute?: string) => {
    remembered.current.jsonpath = jsonpath
    onConfigure(jsonpath, attribute)
  }
  return {
    values: remembered.current,
    props: {
      ...props,
      onChange: onRememberingChange,
      onConfigure: onRememberingConfiugre
    }
  }
}

const DialogAttributes = (props: DialogAttributeGeneric) => {
  const { isNonNullable, above, below, type, value, jsonpath, labels, label, onChange, onConfigure } = props
  const getIndex = () => {
    if (jsonpath) return 2
    if (value != null) return 1
    if (!labels.none) {
      if (labels.static) return 1
      if (labels.mapped) return 2
    }
    return 0
  }

  const [index, setIndex] = useState(getIndex)

  const remembered = useDialogRemembering(props)

  const onIndexChange = (newIndex: number) => {
    if (newIndex == index) return
    if (isNonNullable && newIndex == 2) {
      onChange('')
    } else if (newIndex != 1) {
      if (value != null && label != 'Text') {
        onChange(null)
      }
    }
    if (newIndex != 2) {
      if (jsonpath != null) {
        onConfigure(null)
      }
    } else {
      onConfigure(remembered.values.jsonpath)
    }
    if (newIndex == 1 && (value == null || value == '')) {
      onChange(remembered.values.value ?? (type == 'boolean' ? null : ''))
    }
    setIndex(newIndex)
  }
  return (
    <>
      {above?.({ ...remembered.props, index, setIndex: onIndexChange })}
      {Object.values(labels).filter(Boolean).length > 1 && (
        <ButtonGroupSwitch value={index} onChange={onIndexChange} alignSelf={'flex-start'} mb={4}>
          {labels.none && <Button>{labels.none}</Button>}
          {labels.static && <Button>{labels.static}</Button>}
          {labels.mapped && <Button>{labels.mapped}</Button>}
        </ButtonGroupSwitch>
      )}

      {index == 1 && <DialogAttributesStatic {...remembered.props} />}
      {index == 2 && <DialogAttributesMapping {...remembered.props} />}
      {below?.({ ...remembered.props, index, setIndex: onIndexChange })}
    </>
  )
}
export default DialogAttributes
