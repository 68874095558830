import { VersionBundleModel, VersionModel, ComponentModel, Style, Version } from '@sitecore-feaas/sdk'
import FieldsetField from '../FieldsetField.js'
import { Select } from '../Select.js'
import { Alert, AlertIcon, Box, HStack, Icon, Link, Text } from '@chakra-ui/react'
import { mdiAlert, mdiAutoFix, mdiAutorenew } from '@mdi/js'
import React from 'react'

const PickerResponsiveBundlesBreakpoint = ({
  bundle,
  component,
  breakpoint,
  versions
}: {
  bundle: VersionBundleModel
  versions: VersionModel[]
  component: ComponentModel
  breakpoint: Style.Rule<'breakpoint'>
}) => {
  const label =
    breakpoint.props.minWidth === 0
      ? `${breakpoint.details.title} (< ${breakpoint.props.maxWidth})`
      : breakpoint.props.maxWidth === Infinity
      ? `${breakpoint.details.title} (≥ ${breakpoint.props.minWidth})`
      : `${breakpoint.details.title} (${breakpoint.props.minWidth} - ${breakpoint.props.maxWidth})`

  const breakpointDefaultVersion = component.getVersionForWidth(breakpoint.props.minWidth, null, versions)
  const breakpointAvailableVersions = bundle
    .getAvailableVersionsForBreakpoint(breakpoint, versions)
    .filter((v) => v.id !== breakpointDefaultVersion?.id)
  const breakpointUnavailableVersions = versions.filter(
    (v) => !breakpointAvailableVersions.map((v) => v.id).includes(v.id) && v.id !== breakpointDefaultVersion.id
  )
  const breakpointOptions = [
    {
      label: 'Default',
      options: [breakpointDefaultVersion]
    },
    {
      label: 'Allowed versions',
      options: breakpointAvailableVersions
    },
    { label: 'Other versions', options: breakpointUnavailableVersions }
  ]
  const version = bundle.getVersionForBreakpoint(breakpoint)
  const { warning } = bundle.getWarnings().find((warning) => warning.breakpoint.details.id === breakpoint.details.id)

  if (!version) return <Text>There are no staged or published versions in your component</Text>
  return (
    <FieldsetField label={label}>
      <Select
        value={version}
        options={breakpointOptions}
        formatOptionLabel={(v) => (
          <>
            {!bundle.breakpoints[breakpoint.details.id] && version.id === v.id && (
              <Icon mr={2} boxSize={'icon-lg'}>
                <path d={mdiAutoFix} />
              </Icon>
            )}
            {v.name}
          </>
        )}
        getOptionValue={(v) => v.id}
        isOptionDisabled={(v) => breakpointUnavailableVersions.includes(v)}
        onChange={(v) => {
          if (!v) {
            const breakpoints = { ...bundle.breakpoints }
            delete breakpoints[breakpoint.details.id]
            bundle.save({ breakpoints })
          } else {
            bundle.save({ breakpoints: { ...bundle.breakpoints, [breakpoint.details.id]: v.id } })
          }
        }}
        isClearable={!!bundle.breakpoints[breakpoint.details.id]}
        placeholder='Select version'
        components={{
          MenuList: ({ children }) => (
            <Box p={2} backgroundColor={'white'} maxHeight={'350px'} overflowY={'auto'}>
              {children}
              {breakpointUnavailableVersions.length > 0 && (
                <Alert status={'info'}>
                  <AlertIcon />
                  <Text>
                    To use disabled versions, update specific version breakpoint sizes.{' '}
                    <Link
                      textDecoration={'underline'}
                      href={'https://doc.sitecore.com/xmc/en/users/xm-cloud/build-responsive-components.html'}
                      target={'_blank'}
                    >
                      Learn more.
                    </Link>
                  </Text>
                </Alert>
              )}
            </Box>
          )
        }}
      />
      {warning && (
        <Box>
          <HStack mt={2}>
            <Icon boxSize={'icon-lg'}>
              <path color={'var(--chakra-colors-orange-500)'} d={mdiAlert} />
            </Icon>
            <Text color={'orange.500'}>{warning.label}</Text>
          </HStack>
          {warning.breakpoints
            .filter((b) => b.details.id === breakpoint.details.id)
            .map((breakpoint) => (
              <Text mt={2} fontSize={13} fontWeight={400} color={'blackAlpha.500'}>
                {warning.description}
              </Text>
            ))}
        </Box>
      )}
    </FieldsetField>
  )
}

export default PickerResponsiveBundlesBreakpoint
