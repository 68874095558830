import { Box, Button, HStack, Icon, Spacer, Text, Tooltip, VStack } from '@chakra-ui/react'
import { mdiChevronRight } from '@mdi/js'
import { Dialog, DialogMenuItem } from './Dialog.js'
import { PickerProps } from '../../picker/Picker.js'

export function DialogMenu<T extends DialogMenuItem>({
  items = [],
  title,
  children,
  onNavigate,
  variant
}: React.PropsWithChildren<{
  items?: T[]
  title: string
  onNavigate?: (id: Dialog['id']) => void
  variant?: PickerProps['variant']
}>) {
  return (
    <VStack alignItems={'stretch'} role='menu'>
      <Text as='h2' fontWeight='600'>
        {title}
      </Text>
      <VStack alignItems={'stretch'} spacing={0}>
        {children}
        {items.map((item, index) =>
          item.preview ? (
            /**
             * When we pass onNavigate, it overrides items own onNavigate. We use the following syntax to make sure
             * that if navigate is not available (eg. in pages), the items own method will be used
             * and not overwritten by undefined
             */
            <DialogMenuPane {...item} key={index} {...(onNavigate ? { onNavigate } : {})} />
          ) : (
            <DialogMenuItem {...item} key={index} variant={variant} {...(onNavigate ? { onNavigate } : {})} />
          )
        )}
      </VStack>
    </VStack>
  )
}

export function DialogMenuPane(props: DialogMenuItem) {
  const { id, onNavigate, tooltip, preview, label, badge, ariaLabel, isDisabled } = props
  const renderedBadge = badge?.(props)
  return (
    <Tooltip label={tooltip}>
      <VStack
        justifyContent={'stretch'}
        alignItems={'stretch'}
        onClick={() => onNavigate(id)}
        as={Button}
        aria-label={ariaLabel}
        variant='dialogPane'
        borderRadius={'4px'}
        color='blackAlpha.500'
        height='auto'
        padding={'3'}
        spacing={2}
        lineHeight='18px'
        isDisabled={isDisabled}
        sx={{
          _disabled: {
            opacity: 1,
            pointerEvents: 'none'
          }
        }}
      >
        {label && (
          <HStack width='100%' spacing={'2'}>
            <Box width='55%' height='80px'>
              <Box width='100%' display='flex' alignItems={'stretch'} flexDirection={'column'}>
                {preview?.(props)}
              </Box>
            </Box>
            <Box color='blackAlpha.800' fontWeight='400' lineHeight='150%'>
              {label}
            </Box>
          </HStack>
        )}
        {!label && preview?.(props)}
        {(renderedBadge || !isDisabled) && (
          <HStack justifyContent={'flex-end'}>
            {renderedBadge}
            {!isDisabled && (
              <Icon fontSize={'xl'}>
                <path d={mdiChevronRight} />
              </Icon>
            )}
          </HStack>
        )}
      </VStack>
    </Tooltip>
  )
}

export function DialogMenuItem(props: React.PropsWithChildren<DialogMenuItem & { className?: string }>) {
  const { onNavigate, id, icon, label, badge, ariaLabel, isDisabled, className, children, level } = props
  return (
    <Button
      className={className}
      role='menuitem'
      onClick={() => onNavigate(id)}
      aria-label={ariaLabel}
      justifyContent={'stretch'}
      variant='dialogItem'
      size='md'
      isDisabled={!!isDisabled}
      rightIcon={
        isDisabled ? null : (
          <Icon fontSize={'xl'}>
            <path d={mdiChevronRight} />
          </Icon>
        )
      }
    >
      <Text
        fontWeight={400}
        color='blackAlpha.800'
        noOfLines={1}
        flexShrink={0}
        textAlign='left'
        mr={2}
        flexBasis={'min-content'}
        display={'block'}
      >
        {label}
      </Text>
      <Spacer />
      {badge?.(props)}
      {children}
    </Button>
  )
}
